import { ActionObject } from '../types'
import AT from '../types/ActionTypes'

export const login = (decodedIdToken: any, accessToken: string): ActionObject =>
({
    type: AT.USER_LOGIN,
    decodedIdToken,
    accessToken
})

export const loginFulfilled = (id: string, uid: string, name: string, email: string, avatar: string, omise_cust_id: string, token: string, expiration: number): ActionObject =>
({
    type: AT.USER_LOGIN_FULFILLED,
    id,
    uid,
    name,
    email,
    avatar,
    omise_cust_id,
    token, 
    expiration
})

export const logout = (token: string): ActionObject => 
    ({
        type: AT.USER_LOGOUT,
        token
    })

export const logoutFulfilled = (): ActionObject =>
({
    type: AT.USER_LOGOUT_FULFILLED
})

export const setCurrentUri = (currentUri: string): ActionObject => 
({
    type: AT.USER_SET_CURRENT_URI,
    currentUri
})

export const igLoginFulfilled = (igid: string, iguser: string, igtoken: string, igtokenExpireOn: number): ActionObject => 
({
    type: AT.IG_LOGIN_FULFILLED,
    igid,
    iguser,
    igtoken,
    igtokenExpireOn
})