import { Checkbox, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Modal } from '@mui/material'
import * as React from 'react'
import { toAbsoluteUrl } from '../../../../_helpers'
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { AddPaymentCardForm } from '../forms/AddPaymentCard';
import { getdefaultcardAPI, listcardAPI } from '../../../../reducers/func/userFunc';
import { PaymentCard } from '../../../../types';

interface ICreditDebit {
    selectedCard: string | null
    onSelectCard: (card: string) => void
}
export const ManageCreditDebit: React.FC<ICreditDebit> = (props: ICreditDebit) => {
    const { selectedCard, onSelectCard } = props
    const [openaddcard, setOpenaddcard] = React.useState(false)
    const [cards, setCards] = React.useState<PaymentCard[] | null>(null)
    const handleOpenAddCard = () => {
        setOpenaddcard(true)
    }

    const handleCloseAddCard = () => {
        setOpenaddcard(false)
    }

    const handleAfterAddCardSuccess = () => {
        setOpenaddcard(false)
        listcardAPI()
        .then((response) => {
            let cardslist: PaymentCard[] = []
                response.data.forEach((c: any) => {
                    cardslist.push(c._attributes)
            })
            setCards(cardslist)
            // get default card
            return getdefaultcardAPI()
        })
        .then((response) => {
            // select default card
            onSelectCard(response.data)
        })
        .catch((e) => {
            console.log(e)
        })
    }

    React.useEffect(() => {
        if (!cards) {
            listcardAPI()
            .then((response) => {
                let cardslist: PaymentCard[] = []
                response.data.forEach((c: any) => {
                    cardslist.push(c._attributes)
                })
                setCards(cardslist)
                // get default card
                return getdefaultcardAPI()
                
                
            })
            .then((response) => {
                // select default card
                onSelectCard(response.data)
            })
            .catch((e) => {
                console.log(e)
            })
        }
        
    }, [])

    return (
        <>
        <List>
            
            {cards?.map((c: PaymentCard) => (
                <ListItem
                    key={c.id}
                    secondaryAction={
                        <>
                        <IconButton edge='end' aria-label='remove'>
                            <DeleteIcon sx={{ fontSize: 30 }} />
                        </IconButton>
                        </>
                    }
                >
                    <ListItemIcon>
                    <Checkbox
                    edge="start"
                    checked={c.id === selectedCard}
                    tabIndex={-1}
                    disableRipple
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    onChange={(e) => onSelectCard(c.id)}
                    />
                    </ListItemIcon>
                    <ListItemAvatar>
                        <img src={toAbsoluteUrl(`media/png/${c.brand.toLowerCase()}.png`)} width={64} />
                    </ListItemAvatar>
                    <ListItemText primary={`XXXX-XXXX-XXXX-${c.last_digits}`} secondary={`Expire on ${c.expiration_month}/${c.expiration_year}`} sx={{ pl: 3 }} />

                </ListItem>
            ))}
            <Divider />
            <ListItemButton onClick={handleOpenAddCard}>
                <ListItemIcon>
                    <AddIcon fontSize='large' />
                </ListItemIcon>
                <ListItemText primary="Add New Card" />
            </ListItemButton>
        </List>
        <Modal
            open={openaddcard}
            onClose={(_, reason: any) => {
                if (reason !== 'backdropClick') {
                    handleCloseAddCard()
                }
            }}
            aria-labelledby="addcard-modal-title"
            aria-describedby="addcard-modal-desc"
            sx={{ zIndex: 1000 }}
        >
            <AddPaymentCardForm onClose={handleCloseAddCard} onAddSuccess={handleAfterAddCardSuccess} />
        </Modal>
        </>
    )
}