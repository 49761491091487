import { Grid, RadioGroup, FormControlLabel, Radio, Slider, LinearProgress, Typography, FormControl, FormLabel, Box, Button } from '@mui/material'
import * as React from 'react'
import { getyourgraphicAPI, graphicpublishAPI } from '../../../../reducers/func/graphicFunc';
import { getyourphotoAPI, photopublishAPI } from '../../../../reducers/func/photoFunc';
import { generaterandomstr } from '../../../../utilities/utilities';


interface PublishDesignConfig {
    mimeType: string
    quality?: number
}

interface PublishToolProps {
    exportstage?: any
    yourphotosviewset?: any
    yourgraphicsviewset?: any
    setAnchorEl?: (el: any) => void
    setBtnCls?: (cls: string) => void
    setPhotosViewset?: (results: any, totalpages: number, page: number, searchtags: string, sortby: string) => void
    setGraphicsViewset?: (results: any, totalpages: number, page: number, searchtags: string, sortby: string) => void
}

const PublishTool = (props: PublishToolProps) => {
    const { exportstage, 
            yourphotosviewset,
            yourgraphicsviewset,
            setAnchorEl, setBtnCls, setPhotosViewset, setGraphicsViewset } = props
    const [publishdesignOption, setPublishdesignOption] = React.useState("graphic")
    const [JPEGQuality, setJPEGQuality] = React.useState<number>(0.8)
    const [publishdesignprogress, setPublishdesignprogress] = React.useState(0)
    const [isPublishingDesign, setIsPublishingDesign] = React.useState(false)
    
    const handleChangePublishdesignOption = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPublishdesignOption((event.target as HTMLInputElement).value)
    }

    const handleJpegQualityChange = (event: Event, newValue: number | number[]) => {
        setJPEGQuality(newValue as number)
    }

    const handlePublishdesignSubmit = () => {
        var config: PublishDesignConfig = {
            mimeType: 'image/png'
        }
        if (publishdesignOption === 'photo') {
            config = {
                ...config,
                mimeType: 'image/jpeg',
                quality: JPEGQuality as number
            }
            
        }
        const exportdata = exportstage.current.toDataURL(config)
        const exportname = `${generaterandomstr(6)}`
        setPublishdesignprogress(0)
        setIsPublishingDesign(true)
        if (publishdesignOption === 'photo') {
            photopublishAPI(exportdata, `${exportname}.jpg`, (event: any) => {
                setPublishdesignprogress(Math.round((100 * event.loaded) / event.total))
            }).then((response) => {
                setIsPublishingDesign(false)
                if (setAnchorEl) setAnchorEl(null)
                if (setBtnCls) setBtnCls('')
                return getyourphotoAPI(yourphotosviewset?.searchtags, 1, yourphotosviewset?.sortby as string)
            }).then((photos) => {
                if (setPhotosViewset) setPhotosViewset(photos.data.results, photos.data.total_pages, 1, yourphotosviewset?.searchtags, yourphotosviewset?.sortby as string)
            }).catch((e) => { 
                setIsPublishingDesign(false)
                console.log("Error publishing design: ", e.message)
            })
        } else {
            graphicpublishAPI(exportdata, `${exportname}.png`, (event: any) => {
                setPublishdesignprogress(Math.round((100 * event.loaded) / event.total))
            }).then((response) => {
                setIsPublishingDesign(false)
                if (setAnchorEl) setAnchorEl(null)
                if (setBtnCls) setBtnCls('')
                return getyourgraphicAPI(yourgraphicsviewset?.searchtags, 1, yourgraphicsviewset?.sortby as string)
            }).then((graphics) => {
                if (setGraphicsViewset) setGraphicsViewset(graphics.data.results, graphics.data.total_pages, 1, yourgraphicsviewset?.searchtags, yourgraphicsviewset?.sortby as string)
            }).catch((e) => { 
                setIsPublishingDesign(false)
                console.log("Error publishing design: ", e)
            })
        }
    }

    const handleCancelPublish = () => {
        if (setAnchorEl) setAnchorEl(null)
        if (setBtnCls) setBtnCls('')
    }

    return (
        <Grid container 
            direction="column"
            alignItems="center"
            justifyContent="center">
            <Grid item>
            <FormControl>
            <FormLabel id="publish-design-option-label">Publish design as</FormLabel>
            <RadioGroup
                row
                aria-labelledby="publish-design-option-label"
                name="publish-design-option"
                defaultValue="graphic"
                value={publishdesignOption}
                onChange={handleChangePublishdesignOption}
                sx={{ paddingLeft: '5px'}}
            >
                <FormControlLabel value="photo" control={<Radio />} label="Photo (.jpg)" />
                <FormControlLabel value="graphic" control={<Radio />} label="Graphic (.png)" />

            </RadioGroup>
            </FormControl>
            </Grid>
            <Grid item>
            <FormControl>
            <FormLabel id="publish-design-jpeg-quality-label">JPEG Quality</FormLabel>
            <Slider
                aria-labelledby="publish-design-jpeg-quality-label"
                value={JPEGQuality}
                defaultValue={0.6}
                step={0.1}
                marks
                min={0.0}
                max={1.0}
                valueLabelDisplay="auto"
                disabled={publishdesignOption !== 'photo'}
                color="secondary"
                sx={{ width: '200px'}}
                onChange={handleJpegQualityChange}/>

            
            
            </FormControl>
            </Grid>
            {isPublishingDesign && (
            <Grid item>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Box sx={{ width: '200px' }}>
                    <LinearProgress 
                        variant='determinate' 
                        color='secondary'
                        value={publishdesignprogress} />
                    </Box>
                    <Typography variant='caption'>Publishing layer...</Typography>
                </Grid>
            </Grid>
            )}
            <Grid item>
            <Grid container direction="row" spacing={1} alignItems="center" justifyContent="center">
                <Grid item>
                    <Button variant="contained"
                            color="secondary"
                            onClick={handlePublishdesignSubmit}>
                        Publish
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained"
                            color="secondary"
                            onClick={handleCancelPublish}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
            </Grid>
        </Grid>
    )
}

export default PublishTool