import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Reducer } from "react";
import { RootState } from "../reducers/store";
import { fetchTranslations } from "./i18nApi";
import {
    defaultLang,
    supportedLangs
} from "./i18nConfig"

interface i18nState {
    status: string,
    lang: string,
    supportedLangs: any,
    translations: any,
}
const initialState: i18nState = {
    status: "loading",
    lang: defaultLang,
    supportedLangs: {...supportedLangs},
    translations: {},
}

export const setLangAsync = createAsyncThunk(
    "i18n/setLangAsync",
    async (lang: string, { getState, dispatch }) => {
      // Default to active locale if none is given.
      const state = getState() as RootState
      const resolvedLang = lang || state.i18n.lang;
      const translations = await fetchTranslations(
        resolvedLang,
      );
      dispatch(i18nSlice.actions.setLang(resolvedLang));
      return translations;
    },
  );

export const i18nSlice = createSlice({
    name: "i18n",
    initialState: initialState,
    reducers: {
        setLang: (state, action) => {
            state.lang = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setLangAsync.pending, (state) => {
          state.status = "loading";
        });
        builder.addCase(
          setLangAsync.fulfilled,
          (state, action) => {
            state.translations = action.payload;
            state.status = "idle";
          },
        );
    }
})

export const selectTranslations = (state: RootState) => state.i18n.translations
export default i18nSlice.reducer