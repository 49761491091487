import { applyMiddleware, createStore } from "redux";
import { configureStore } from "@reduxjs/toolkit"
import { persistReducer, persistStore } from "redux-persist";
import thunkMiddleware from "redux-thunk";
import loggerMiddleware from "redux-logger"
import createSagaMiddleware from "redux-saga"
import rootReducer from ".";
import { rootSaga } from ".";
import storage from "redux-persist/lib/storage";

const sagaMiddleware = createSagaMiddleware()
const persistConfig = {
    key: 'pixartee',
    storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer) 
export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunkMiddleware, loggerMiddleware, sagaMiddleware] as const,
})
sagaMiddleware.run(rootSaga)
export const persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
