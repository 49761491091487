import React, { useContext } from "react";
import { createContext, useMemo } from "react";
import { HtmlClassService } from "./HtmlClassService";
import { getInitLayoutConfig } from "./LayoutConfig";

type Props = {
    children: React.ReactNode
}
const LAYOUT_CONFIG_KEY = process.env.REACT_APP_LAYOUT_CONFIG_KEY || "LayoutConfig";

const HtmlClassServiceContext = createContext<HtmlClassService>(new HtmlClassService());
const getConfig = () => {
    const ls = localStorage.getItem(LAYOUT_CONFIG_KEY)
    if (ls) {
        try {
            return JSON.parse(ls);
        } catch (er) {
            console.log(er)
        }
    }
    return getInitLayoutConfig()
}

const setLayoutConfig = (config: any) => {
    localStorage.setItem(LAYOUT_CONFIG_KEY, JSON.stringify(config));
    window.location.reload();
  }

const useHtmlClassService = () => {
    return useContext(HtmlClassServiceContext);
}

const withHtmlClassService = (Component: any) => {
    class WithHtmlClassService extends React.Component {
        static displayName = `WithHtmlClassService(${Component.displayName ||
            Component.name})`;
        static contextType = HtmlClassServiceContext

        render() {
            return <Component {...this.props} htmlClassService={this.context} />
        }
    }

}
const HtmlClassServiceConsumer = HtmlClassServiceContext.Consumer

const LayOutProvider = ({children}:Props): JSX.Element => {
    const lc = useMemo(getConfig, [])
    const hcs = useMemo(() => {
        const service = new HtmlClassService()
        service.setConfig(lc)
        return service
    }, [lc])
    
    return (
        <HtmlClassServiceContext.Provider value={hcs}>
            {children}
        </HtmlClassServiceContext.Provider>
    )
}

export {
    setLayoutConfig,
    useHtmlClassService, 
    withHtmlClassService, 
    HtmlClassServiceConsumer, 
    LayOutProvider
}

