import { Autocomplete, Box, Button, Checkbox, Chip, Divider, FormControlLabel, Grid, IconButton, Paper, Stack, TextField, Typography } from '@mui/material';
import { resolve } from 'dns';
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';
import { debounce } from 'lodash';
import * as React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { addshippingaddrAPI, checkaddrexistsAPI, getshippingaddrAPI, updateshippingaddrAPI } from '../../../../reducers/func/addressFunc';
import { useAppSelector } from '../../../../reducers/hooks';
import { google_map_api_key, rest_endpoint } from '../../../../settings';
import { ShippingAddress } from '../../../../types';
import { toAbsoluteUrl } from '../../../../_helpers';
import { AddressLocator } from '../AddressLocator';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';

interface IShippingAddressModal {
    shippingaddress?: ShippingAddress | null
    onEditSuccess: () => void
    onSelect: (id: string) => void
    onClose: () => void
}

const EditShippingAddress: React.FC<IShippingAddressModal> = (props: IShippingAddressModal) => {
    const { shippingaddress, onEditSuccess, onSelect, onClose } = props
    const [addrname, setAddrName] = React.useState('')
    const [addr1, setAddr1] = React.useState('')
    const [addr2, setAddr2] = React.useState('')
    const [countries, setCountries] = React.useState<any>([])
    const [statehint, setStateHint] = React.useState<any>([])
    const [cityhint, setCityHint] = React.useState<any>([])
    const [countyhint, setCountyHint] = React.useState<any>([])
    const [postalhint, setPostalHint] = React.useState<any>([])
    const [country, setCountry] = React.useState<any | null>(null)
    const [st, setSt] = React.useState<any | null>(null)
    const [city, setCity] = React.useState<any | null>(null)
    const [county, setCounty] = React.useState<any | null>(null)
    const [postal, setPostal] = React.useState<any | null>(null)
    const [isdefault, setDefault] = React.useState<boolean>(false)
    const [location, setLocation] = React.useState<google.maps.LatLngLiteral>({ lat: 0, lng: 0 })
    const [zoom, setZoom] = React.useState(8)
    const [backenderror, setBackendError] = React.useState(false)
    const lang = useAppSelector((state) => state.i18n.lang)
    const form = useForm()

    const handleClickClose = () => {
        onClose()
    }

    const fetchCountries = async (url: string) => {
        const ct = await new Promise((resolve) => {
            fetch(url)
                .then((response) => response.json())
                .then((data) => resolve(data))
        })
        setCountries(ct)
    }

    const changeLocationInMap = (newpos: google.maps.LatLng) => {
        // use reverse geocoding api to convert coordinate into address
        const latlng = `${newpos.lat()},${newpos.lng()}`
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&language=${lang}&key=${google_map_api_key}`)
            .then((response) => response.json())
            .then((data) => {
                const { results } = data
                let result = results[0]
                for (let i=1; i < results.length; i++) {
                    if (results[i].address_components.length > result.address_components.length) result = results[i]
                }
                
                const components = result.address_components
                
                components.forEach((c: any) => {
                    if (c.types.includes("street_number")) {
                        setAddr1(c.long_name)
                    }
                    if (c.types.includes("route")) {
                        setAddr2(c.long_name)
                    }
                    if (c.types.includes("sublocality")) {
                        let county_name = c.long_name
                        if (lang === 'en') {
                            county_name = county_name.replace("Tambon ", "")
                            county_name = county_name.replace("Khwang ", "")
                        } else if (lang === 'th') {
                            county_name = county_name.replace("ตำบล", "")
                            county_name = county_name.replace("แขวง", "")
                        }
                        setCounty(county_name)
                    }
                    if (c.types.includes("administrative_area_level_2")) {
                        let city_name = c.long_name
                        if (lang === 'en') {
                            city_name = city_name.replace("Amphoe ", "")
                            city_name = city_name.replace("Khet ", "")
                        } else if (lang === 'th') {
                            city_name = city_name.replace("อำเภอ", "")
                            city_name = city_name.replace("เขต", "")
                        }
                        setCity(city_name)
                    }
                    if (c.types.includes("administrative_area_level_1")) {
                        let st_name = c.long_name
                        if (lang === 'en') {
                            st_name = st_name.replace("Chang Wat ", "")
                        } else if (lang === 'th') {
                            st_name = st_name.replace("จังหวัด", "")
                        }
                        setSt(st_name)
                    }
                    if (c.types.includes("country")) {
                        const country_code = c.short_name
                        const found_country = countries.find((c:any) => c.code === country_code)
                        if (found_country) setCountry(found_country)
                    }
                    if (c.types.includes("postal_code")) setPostal(c.long_name)
                    
                })

                
                
            })
            .catch((err) => console.log(err))
        
    }
    
    const onEditShippingAddrSubmit = async (data: any) => {
        const postdata: ShippingAddress = {
            id: shippingaddress?.id,
            address_name: data.address_name,
            addr1: data.address_1,
            addr2: data.address_2 !== undefined ? data.address_2:"",
            country: data.country,
            state: data.state,
            city: data.city,
            county: data.county,
            postal: data.postal,
            lat: location.lat,
            lng: location.lng,
            default: data.default !== undefined ? data.default:false
        }
        try {
            await updateshippingaddrAPI(postdata)
            onEditSuccess()
        } catch(e) {
            console.log("Error adding address")
            setBackendError(true)
        } 
    }

    

    React.useEffect(() => {

        const fetchAddressHint = async (query: string) => {
            const url = `${rest_endpoint}addresshints/getstates${query}`
            const hints = await new Promise((resolve) => {
                fetch(url)
                    .then((response) => response.json())
                    .then((data) => resolve(data))
            })
            setStateHint(hints)
        }

        if (country) {
            const query = `?country=${country.code}&lang=${lang}`
            fetchAddressHint(query)
            
        }
    }, [country])

    React.useEffect(() => {

        const fetchAddressHint = async (query: string) => {
            const url = `${rest_endpoint}addresshints/getcities${query}`
            const hints = await new Promise((resolve) => {
                fetch(url)
                    .then((response) => response.json())
                    .then((data) => resolve(data))
            })
            setCityHint(hints)
        }

        if (country && st) {
            const query = `?country=${country.code}&state=${st}&lang=${lang}`
            fetchAddressHint(query)
            
        }
    }, [st])

    React.useEffect(() => {

        const fetchAddressHint = async (query: string) => {
            const url = `${rest_endpoint}addresshints/getcounties${query}`
            const hints = await new Promise((resolve) => {
                fetch(url)
                    .then((response) => response.json())
                    .then((data) => resolve(data))
            })
            setCountyHint(hints)
        }

        if (country && st && city) {
            const query = `?country=${country.code}&state=${st}&city=${city}&lang=${lang}`
            fetchAddressHint(query)
            
        }
    }, [city])

    React.useEffect(() => {
        const fetchAddressHint = async (query: string) => {
            const url = `${rest_endpoint}addresshints/getpostal${query}`
            const hints = await new Promise((resolve) => {
                fetch(url)
                    .then((response) => response.json())
                    .then((data) => resolve(data))
            })
            setPostalHint(hints)
        }

        if (country && st && city && county) {
            const query = `?country=${country.code}&state=${st}&city=${city}&county=${county}&lang=${lang}`
            fetchAddressHint(query)
            
        }
    }, [county])

    React.useEffect(() => {
        // use geocoding api
        if (addr1 && country && st && city && county) {
            const address = encodeURIComponent(`${addr1}+${addr2}+${county}+${city}+${st}+${country.name}`)
            fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&region=${country.code.toLowerCase()}&language=${lang}&key=${google_map_api_key}`)
                .then((response) => response.json())
                .then((data) => {
                    const { results } = data
                    const result = results[0]
                    const geometry = result.geometry
                    setLocation({ lat: geometry.location.lat, lng: geometry.location.lng })
                    setZoom(15)
                })
                .catch((err) => console.log(err))

        }
    }, [addr1, addr2, country, st, city, county])


    if (countries.length <= 0) {
        fetchCountries(toAbsoluteUrl("/lang/phonecodes.json"))
    }

    React.useEffect(() => {
        if (shippingaddress && countries.length > 0) {
            setAddrName(shippingaddress.address_name)
            setAddr1(shippingaddress.addr1)
            setAddr2(shippingaddress.addr2)
            const ct = countries.find((c: any) => c.code === shippingaddress.country)
            if (ct) setCountry(ct)
            setSt(shippingaddress.state)
            setCity(shippingaddress.city)
            setCounty(shippingaddress.county)
            setPostal(shippingaddress.postal)
            setDefault(shippingaddress.default)
            setLocation({ lat: shippingaddress.lat, lng: shippingaddress.lng })
            setZoom(15)
        }

    }, [countries])
    

    // if (navigator.geolocation && location.lat == 0 && location.lng == 0) {
    //     navigator.geolocation.getCurrentPosition(
    //         (position: GeolocationPosition) => {
    //             const pos = {
    //                 lat: position.coords.latitude,
    //                 lng: position.coords.longitude
    //             }
    //             setLocation(pos)
    //             setZoom(15)
    //         }
    //     )
    // } 

    return (
        <>
        <Paper
            elevation={3}
            sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 768,
                pt: 2,
                pb: 3
            }}
        >
            <Stack spacing={1}>
            <Grid container direction='row' spacing={0} sx={{ width: 768 }}>
                <Grid item xs={5}>
                <Typography variant='h6' sx={{ ml: 2, pt: '4px' }}>
                    Edit Shipping Address
                </Typography>
                </Grid>
                <Grid item xs={6}>
                    {backenderror && (
                        <Chip icon={<ErrorIcon />} color='error' label="Backend Error. Please try again later." />
                    )}
                    
                </Grid>
                <Grid item sx={{ width: '40px', ml: 2 }}>
                    <IconButton
                        onClick={handleClickClose}
                    >
                        <CloseIcon sx={{ fontSize: 24 }} />
                    </IconButton>
                </Grid>
            </Grid>
            
            <Divider />
            <Grid container direction='column' spacing={1} sx={{ width: 768, marginTop: "0px !important" }}>
                <Grid item xs={12} sx={{ ml: 0, mr: 0, paddingLeft: "0px !important", paddingTop: "0px !important" }}>
                    <AddressLocator 
                    location={location}
                    zoom={zoom}
                    locationChanged={changeLocationInMap} />
                </Grid>
                <Grid item xs={12} sx={{ ml: 3, mr: 3, paddingLeft: "0px !important" }}>
                    <form>
                    <Stack spacing={1}>
                        <Controller
                            name='address_name'
                            control={form.control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Unique name for this address"
                                },
                                validate: debounce(async (value:any) => {
                                    const response = await checkaddrexistsAPI(value)
                                    const status = await response.status
                                    if (status===200) {
                                        return true
                                    } else {
                                        return false
                                    }
                                })
                            }}
                            render={({ field, fieldState, formState }) => 
                            <TextField
                                {...field}
                                value={addrname}
                                variant="standard"
                                label="Name this address e.g. Home, Office, etc."
                                color="secondary"
                                onChange={(event) => {
                                    field.onChange(event.target.value as string)
                                    setAddrName(event.target.value as string)
                                }}
                                error={fieldState.invalid}
                                helperText={fieldState.invalid ? 
                                    (fieldState.error?.type !== 'validate' ? fieldState.error?.message:'This shipping address exists with this name.'):''}
                            />}
                        />
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={6} sx={{ paddingLeft: "0px !important" }}>
                            <Controller
                                name='address_1'
                                control={form.control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Home or Building address number is required"
                                    }
                                }}
                                
                                render={({ field, fieldState, formState }) => 
                                <TextField
                                    {...field}
                                    value={addr1}
                                    variant="standard"
                                    label="Home or Building Address"
                                    color="secondary"
                                    sx={{ width: 350 }}
                                    onChange={(event) => {
                                        //field.onChange(event.target.value as string)
                                        setAddr1(event.target.value as string)
                                    }}
                                    error={fieldState.invalid}
                                    helperText={fieldState.invalid ? fieldState.error?.message:''}
                                />}
                            />
                            </Grid>
                            <Grid item xs={6} sx={{ paddingLeft: "0px !important" }}>
                            <Controller
                                name='address_2'
                                control={form.control}
                                rules={{
                                    required: {
                                        value: false,
                                        message: "Street address is not required"
                                    }
                                }}
                                render={({ field, fieldState, formState }) => 
                                <TextField
                                    {...field}
                                    value={addr2}
                                    variant="standard"
                                    label="Street Name"
                                    color="secondary"
                                    sx={{ width: 350 }}
                                    onChange={(event) => {
                                        //field.onChange(event.target.value as string)
                                        setAddr2(event.target.value as string)
                                    }}
                                    error={fieldState.invalid}
                                    helperText={fieldState.invalid ? fieldState.error?.message:''}
                                />}
                            />
                            </Grid>
                        </Grid>
                        
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={4} sx={{ paddingLeft: "0px !important" }}>
                            <Stack spacing={1}>
                            <Controller
                                name="country"
                                control={form.control}
                                rules={{ 
                                    required: {
                                        value: true,
                                        message: "Country is required."
                                    }
                                }}
                                render={({ field, fieldState, formState }) => 
                                <Autocomplete
                                {...field}
                                disablePortal
                                options={countries}
                                sx={{ width: 200 }}
                                value={country}
                                onChange={(event: any, newValue: any | null) => {
                                    field.onChange(newValue.code)
                                    setCountry(newValue);
                                }}
                                getOptionLabel={(option: any) => option.name}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    <img
                                        loading="lazy"
                                        width="20"
                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                        alt=""
                                    />
                                    {option.name} ({option.code})
                                    </Box>
                                )}
                                renderInput={(params: any) => 
                                    <TextField {...params} 
                                        label="Country" 
                                        variant="standard" 
                                        color="secondary"
                                    />}
                                //onChange={(_event, data) => field.onChange(data?.code ?? '')}
                                />}
                            />
                            <Controller
                                name="city"
                                control={form.control}
                                rules={{ 
                                    required: {
                                        value: true,
                                        message: "City or District is required"
                                    }
                                }}
                                render={({ field, fieldState, formState }) => 
                                <Autocomplete
                                {...field}
                                disablePortal
                                freeSolo
                                options={cityhint}
                                sx={{ width: 200 }}
                                value={city}
                                onChange={(event: any, newValue: any | null) => {
                                    //field.onChange(newValue)
                                    setCity(newValue);
                                }}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                    {option}
                                    </Box>
                                )}
                                renderInput={(params: any) => 
                                    <TextField {...params} 
                                        label="City/District" 
                                        variant="standard" 
                                        color="secondary"
                                    />}
                                //onChange={(_event, data) => field.onChange(data?.code ?? '')}
                                />}
                            />
                            <Controller
                                name="postal"
                                control={form.control}
                                rules={{ 
                                    required: {
                                        value: true,
                                        message: "City or District is required"
                                    }
                                }}
                                render={({ field, fieldState, formState }) => 
                                <Autocomplete
                                {...field}
                                disablePortal
                                freeSolo
                                options={postalhint}
                                sx={{ width: 200 }}
                                value={postal}
                                onChange={(event: any, newValue: any | null) => {
                                    //field.onChange(newValue)
                                    setPostal(newValue);
                                }}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                    {option}
                                    </Box>
                                )}
                                renderInput={(params: any) => 
                                    <TextField {...params} 
                                        label="Postal Code" 
                                        variant="standard" 
                                        color="secondary"
                                    />}
                                //onChange={(_event, data) => field.onChange(data?.code ?? '')}
                                />}
                            />
                            </Stack>
                            
                            </Grid>
                            <Grid item xs={4} sx={{ paddingLeft: "0px !important" }}>
                            <Stack spacing={1}>
                            <Controller
                                name="state"
                                control={form.control}
                                rules={{ 
                                    required: {
                                        value: true,
                                        message: "State or Province is required"
                                    }
                                }}
                                render={({ field, fieldState, formState }) => 
                                <Autocomplete
                                {...field}
                                disablePortal
                                freeSolo
                                options={statehint}
                                sx={{ width: 200 }}
                                value={st}
                                onChange={(event: any, newValue: any | null) => {
                                    //field.onChange(newValue)
                                    setSt(newValue);
                                }}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                    {option}
                                    </Box>
                                )}
                                renderInput={(params: any) => 
                                    <TextField {...params} 
                                        label="State/Province" 
                                        variant="standard" 
                                        color="secondary"
                                    />}
                                //onChange={(_event, data) => field.onChange(data?.code ?? '')}
                                />}
                            />
                            <Controller
                                name="county"
                                control={form.control}
                                rules={{ 
                                    required: {
                                        value: true,
                                        message: "County or Subdistrict is required"
                                    }
                                }}
                                render={({ field, fieldState, formState }) => 
                                <Autocomplete
                                {...field}
                                disablePortal
                                freeSolo
                                options={countyhint}
                                sx={{ width: 200 }}
                                value={county}
                                onChange={(event: any, newValue: any | null) => {
                                    //field.onChange(newValue)
                                    setCounty(newValue);
                                }}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                    {option}
                                    </Box>
                                )}
                                renderInput={(params: any) => 
                                    <TextField {...params} 
                                        label="County/Subdistrict" 
                                        variant="standard" 
                                        color="secondary"
                                    />}
                                //onChange={(_event, data) => field.onChange(data?.code ?? '')}
                                />}
                            />
                            <Controller
                                name='default'
                                control={form.control}
                                render={({ field, fieldState, formState }) => 
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...field}
                                            color="secondary"
                                            checked={isdefault}
                                            onChange={(e) => {
                                                field.onChange(e.target.checked)
                                                setDefault(e.target.checked)
                                            }}
                                        />
                                    }
                                    label="Set this address as default"
                                />
                                }
                            />
                            </Stack>
                            </Grid>
                            <Grid item xs={4} sx={{ paddingLeft: "0px !important" }}>
                            <Stack spacing={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    width: 230
                                }} 
                                onClick={() => onSelect(shippingaddress?.id as string)}
                            >
                                Select Address
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    width: 230
                                }} 
                                onClick={form.handleSubmit(onEditShippingAddrSubmit)}
                            >
                                Update Address
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    width: 230
                                }} 
                                
                            >
                                Delete Address
                            </Button>
                            </Stack>
                            
                            </Grid>
                        </Grid>

                       
                    </Stack>
                    </form>
                </Grid>
                
            </Grid>
            </Stack>
            
        </Paper>
        </>

    )
}


export const EditShippingAddressForm = React.forwardRef((props: IShippingAddressModal, ref: any) => {

    return (
        <div ref={ref}>
            <EditShippingAddress {...props} />
        </div>
    
    )

})