import * as React from 'react'
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { Marker } from './Marker';
import { Map } from './Map';
import { google_map_api_key } from '../../../settings';
import { Typography } from '@mui/material';

interface AddressLocatorProps {
    location: google.maps.LatLngLiteral
    zoom: number
    locationChanged: (location: google.maps.LatLng) => void
}

const render = (status: Status) => {
    return <Typography variant="h6">{status}</Typography>
}

export const AddressLocator: React.FC<AddressLocatorProps> = ({
    location,
    zoom,
    locationChanged
}) => {
  // const [zoom, setZoom] = React.useState(8);
  

  const onClick = (e: google.maps.MapMouseEvent) => {
    const position = e.latLng!
    locationChanged(position)
  }

  const onIdle = (m: google.maps.Map) => {
    console.log("onIdle");
    // setZoom(m.getZoom()!);
  }



  return (
      <div style={{ display: "flex", height: "300px" }}>
        <Wrapper apiKey={google_map_api_key} render={render}>
          <Map
            center={location}
            onClick={onClick}
            onIdle={onIdle}
            zoom={zoom}
            style={{ flexGrow: "1", height: "100%" }}
          >
            <Marker key="0" position={location} />
            
          </Map>
        </Wrapper>
      </div>
  );
}