export function guid(): string {
    var d = new Date().getTime()
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c: string) {
        var r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    })
}

export function generaterandomstr(len: number): string {
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let result = ''
    const charLen = characters.length
    for (let i=0; i < len; i++) {
        result += characters.charAt(Math.floor(Math.random()*charLen))
    }
    return result
}

export function generateorderId(prefix: string): string {
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    let randomstr = ''
    const charLen = characters.length
    for (let i=0; i < 6; i++) {
        randomstr += characters.charAt(Math.floor(Math.random()*charLen))
    }
    const today = new Date()
    const timecode = `${today.getFullYear()}${today.getMonth()+1}${today.getDate()}${today.getHours()}${today.getMinutes()}${today.getSeconds()}`
    return `${prefix}-${timecode}-${randomstr}`
}

