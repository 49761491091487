import axios from "axios"
import { rest_endpoint } from "../../settings"
import { Tag } from "../../types"

export const photouploadAPI = (file: File, onUploadProgress: any) => {
    let formData = new FormData()
    formData.append("uploadphoto", file)
    formData.append("uploadphotoname", file.name)
    let url = `${rest_endpoint}photos/uploadphoto/`
    return axios.post(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
    })
}

export const getyourphotoAPI = (tags: any, page: number, sortby: string) => {
    let tagsstr = tags.map((t: Tag) => (t.title)).join()
    let url = `${rest_endpoint}photos/yourphotos/?tags=${tagsstr}&page=${page}&ordering=${sortby}`
    return axios.get<any>(url)
}

export const getsharedphotoAPI = (tags: any, page: number, sortby: string) => {
    let tagsstr = tags.map((t: Tag) => (t.title)).join()
    let url = `${rest_endpoint}photos/sharedphotos/?tags=${tagsstr}&page=${page}&ordering=${sortby}`
    return axios.get<any>(url)
}

export const getpublicphotoAPI = (tags: any, page: number, sortby: string) => {
    let tagsstr = tags.map((t: Tag) => (t.title)).join()
    let url = `${rest_endpoint}photos/?tags=${tagsstr}&page=${page}&ordering=${sortby}`
    return axios.get<any>(url)
}

export const photopublishAPI = (data: string, name: string, onUploadProgress: any) => {
    let formData = new FormData()
    formData.append("publishdata", data)
    formData.append("publishname", name)
    let url = `${rest_endpoint}photos/publishphoto/`
    return axios.post<any>(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
    })
} 

export const deletephotoAPI = (id: string) => {
    let url = `${rest_endpoint}photos/${id}/deletephoto/`
    return axios.post<any>(url)
}

export const igmedialistAPI = (url: string) => {
    return axios.get<any>(url)
}

export const removebgAPI = (id: string) => {
    let url = `${rest_endpoint}photos/${id}/removebg/`
    return axios.post<any>(url)
}

export const applypresetAPI = (id: string, preset: string) => {
    let formData = new FormData()
    formData.append("filter", preset)
    let url = `${rest_endpoint}photos/${id}/applyfilter/`
    return axios.post<any>(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }

    })
}

export const presetlistAPI = () => {
    let url = `${rest_endpoint}filterpresets/`
    return axios.get<any>(url)
}
