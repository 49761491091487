import { toAbsoluteUrl } from "../_helpers";

export const supportedLangs = {
    "en": "English",
    "th": "ไทย",
}
const browserLang = window.navigator.language ? window.navigator.language.toLowerCase().split("-")[0]:"en";

const supportedKeys = Object.keys(supportedLangs)
export const defaultLang = supportedKeys.includes(browserLang) ? browserLang:"en";

export const langUrl = toAbsoluteUrl("/lang/{lang}.json")