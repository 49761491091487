import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import * as React from 'react'
import { toAbsoluteUrl } from '../../../_helpers'

interface IPaymentSelection {
    paymentmethod?: string | null
    onChangePaymentMethod: (value: string) => void
}
export const PaymentSelection: React.FC<IPaymentSelection> = (props: IPaymentSelection) => {
    const { paymentmethod, onChangePaymentMethod } = props

    const handlePaymentMethodChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChangePaymentMethod(event.target.value)
    }
    return (
        <>
        <FormControl component="fieldset">
            <RadioGroup row 
              aria-label="product" 
              name="product"
              value={paymentmethod}
              onChange={handlePaymentMethodChanged}>
              
                <FormControlLabel
                  value="credit_debit"
                  control={<Radio />}
                  label={<>
                  <Box>
                      <Stack alignItems="center" justifyContent="center">
                      <img src={toAbsoluteUrl('media/png/credit-cards.png')} width={64} />
                      <Typography variant='caption'>Credit/Debit</Typography>
                      </Stack>
                  </Box>
                    
                    </>}
                  labelPlacement="top"
                />

                <FormControlLabel
                  value="mobile_banking"
                  control={<Radio />}
                  label={<>
                  <Box>
                      <Stack alignItems="center" justifyContent="center">
                      <img src={toAbsoluteUrl('media/png/mobile-banking.png')} width={64} style={{paddingLeft: 12}} />
                      <Typography variant='caption'>Mobile Banking</Typography>
                      </Stack>
                  </Box>
                    
                    </>}
                  labelPlacement="top"
                />

                <FormControlLabel
                  value="internet_banking"
                  control={<Radio />}
                  label={<>
                  <Box>
                      <Stack alignItems="center" justifyContent="center">
                      <img src={toAbsoluteUrl('media/png/internet-banking.png')} width={64} />
                      <Typography variant='caption'>Internet Banking</Typography>
                      </Stack>
                  </Box>
                    
                    </>}
                  labelPlacement="top"
                />

                <FormControlLabel
                  value="ewallet"
                  control={<Radio />}
                  label={<>
                  <Box>
                      <Stack alignItems="center" justifyContent="center">
                      <img src={toAbsoluteUrl('media/png/wallet.png')} width={64} />
                      <Typography variant='caption'>e-Wallet</Typography>
                      </Stack>
                  </Box>
                    
                    </>}
                  labelPlacement="top"
                />

                <FormControlLabel
                  value="promptpay"
                  control={<Radio />}
                  label={<>
                  <Box>
                      <Stack alignItems="center" justifyContent="center">
                      <img src={toAbsoluteUrl('media/png/promptpay.png')} width={64} />
                      <Typography variant='caption'>PromptPay</Typography>
                      </Stack>
                  </Box>
                    
                    </>}
                  labelPlacement="top"
                />
              
            </RadioGroup>
        </FormControl>
        
        </>
    )
}