import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import * as React from 'react'
import { DesignLayer, DesignView } from '../../../../providers/DesignRefContext'
import { designtplsaveAPI, getyourdesigntplAPI } from '../../../../reducers/func/designtplFunc'
import { generaterandomstr } from '../../../../utilities/utilities'

interface SaveTemplateToolProps {
    opensavetemplatedialog: boolean
    exportstage: any
    view?: DesignView
    frameX: number
    frameY: number
    frameW: number
    frameH: number
    yourtemplatesviewset?: any
    setOpensavetemplatedialog: (value: boolean) => void
    setTemplatesViewset: (results: any, totalpages: number, page: number, searchtags: string, sortby: string) => void
}

const SaveTemplateTool = (props: SaveTemplateToolProps) => {
    const { opensavetemplatedialog, exportstage, view, 
        frameX, frameY, frameW, frameH, yourtemplatesviewset,
        setOpensavetemplatedialog, setTemplatesViewset } = props
    
    const [savetplprogress, setSavetplprogress] = React.useState(0)
    const [isSavingTpl, setIsSavingTpl] = React.useState(false) 

    const handleCloseSaveTemplateDialog = () => {
        setOpensavetemplatedialog(false)
    } 

    const handleOnConfirmSaveTemplate = () => {
        // generate base64 thumb image for template
        const config = {
            //mimeType: 'image/png',
            pixelRatio: 0.5
        }
        const exstg = exportstage.current
        const exlayer = exportstage.current.children.find((l: any) => l.attrs.id === `${view?.view}-export-layer`)
        const exportdata = exlayer?.toDataURL(config)
        setSavetplprogress(0)
        setIsSavingTpl(true)
        // read and process layers in current view
        if (view) {
            const processed_layers: any = []
            let idx = 1
            view.layers?.forEach((l: DesignLayer) => {
                if (l.props.type !== 'mockup-layer' && l.props.type !== 'printframe-layer') {
                    const normX = (l.props.shapeGrpAttr.x - frameX)/frameW
                    const normY = (l.props.shapeGrpAttr.y - frameY)/frameH
                    const normW = (l.props.shapeGrpAttr.width * l.props.shapeGrpAttr.scaleX)/frameW
                    const normH = (l.props.shapeGrpAttr.height * l.props.shapeGrpAttr.scaleY)/frameH
                    idx = idx + 1
                    processed_layers.push({
                        type: l.props.type,
                        src: l.props.itemId,
                        order: idx,
                        props: {
                            normX: normX,
                            normY: normY,
                            normW: normW,
                            normH: normH,
                            previewlayerAttr: {
                                ...l.props.previewlayerAttr
                            },
                            exportlayerAttr: {
                                ...l.props.exportlayerAttr
                            },
                            shapeGrpAttr: {
                                ...l.props.shapeGrpAttr,
                
                            },
                            shapeboxAttr: {
                                ...l.props.shapeboxAttr,
                            },
                            shapeAttr: {
                                ...l.props.shapeAttr
                            },
                            
                            exportshapeGrpAttr: {
                                ...l.props.exportshapeGrpAttr,
                            
                            },
                            exportshapeAttr: {
                                ...l.props.exportshapeAttr
                                
                            },
                            cropmaskAttr: {
                                ...l.props.cropmaskAttr,
                            
                            },
                        }
                    })
                }

            })

            // call save template api
            designtplsaveAPI(exportdata, JSON.stringify(processed_layers), false, (event: any) => {
                setSavetplprogress(Math.round((100 * event.loaded) / event.total))
            }).then((response) => {
                setIsSavingTpl(false)
                setOpensavetemplatedialog(false)
                return getyourdesigntplAPI(yourtemplatesviewset?.searchtags, 1, yourtemplatesviewset?.sortby as string)
            }).then((templates) => {
                setTemplatesViewset(templates.data.results, templates.data.total_pages, 1, yourtemplatesviewset?.searchtags, yourtemplatesviewset?.sortby as string)
            }).catch((e) => { 
                setIsSavingTpl(false)
                console.log("Error saving design: ", e.message)
            })
        }
        
    }

    return (
        <Dialog
            open={opensavetemplatedialog}
            onClose={handleCloseSaveTemplateDialog}
            aria-labelledby="save-template-dialog-title"
            aria-describedby="save-template-dialog-description"
        >
        <DialogTitle id="save-template-dialog-title">
            {`Saving design as template`}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="save-template-description">
            {`You are about to save the design as template for later uses. Are you sure?`}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseSaveTemplateDialog}>No</Button>
            <Button onClick={handleOnConfirmSaveTemplate} autoFocus>
            Yes
            </Button>
        </DialogActions>
        </Dialog>
    )
}

export default SaveTemplateTool