import * as React from 'react'
import { makeStyles } from '@mui/styles'
import { DesignLayerListItem } from './types'
import { Draggable } from 'react-beautiful-dnd'
import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { size } from 'lodash'

const useStyles = makeStyles((theme: any) => ({
    draggingListItem: {
        background: 'rgb(235,235,235)',
        top: 'auto !important',
        left: 'auto !important'
    }
}))

export interface DesignLayerListItemProps {
    item: DesignLayerListItem
    index: number
    size: number
    onMoveUp: (idx: number) => void
    onMoveDown: (idx: number) => void
    onRemove: (idx: number) => void
}
const DraggableDesignLayerListItem = ({item, index, size, onMoveUp, onMoveDown, onRemove}: DesignLayerListItemProps) => {
    const classes = useStyles()

    return (
        <Draggable 
            key={item.id} 
            draggableId={item.id} 
            index={index}
            >
            {(provided, snapshot) => (
                <ListItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={snapshot.isDragging ? classes.draggingListItem : ''}
                    secondaryAction={
                        <>
                        <IconButton edge='end' aria-label='moveup' disabled={index == 0} onClick={() => onMoveUp(index)}>
                            <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton edge='end' aria-label='movedown' disabled={index == size - 1} onClick={() => onMoveDown(index)}>
                            <ArrowDownwardIcon />
                        </IconButton>
                        <IconButton edge='end' aria-label='delete' onClick={() => onRemove(index)}>
                            <DeleteIcon />
                        </IconButton>
                        </>
                    }
                >
                    <ListItemAvatar>
                        
                            <Avatar 
                            sx={{ 
                                width: 48,
                                height: 48,
                                background: 'none'
                            }} 
                            variant='square'>
                                <img src={item.thumbpreview} width={48} />
                            </Avatar>
                        
                        
                    </ListItemAvatar>
                    <ListItemText 
                        primary={item.id}
                        secondary={item.type}
                    />

                </ListItem>
            )}
        </Draggable>
    )
}

export default DraggableDesignLayerListItem