import { Button, Grid } from '@mui/material'
import * as React from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { DesignView, DesignLayer } from '../../../../providers/DesignRefContext'
import DraggableDesignLayerList from '../lists/DraggableDesignLayerList'
import { DesignLayerListItem } from '../lists/types'


interface DesignLayerToolProps {
    view?: DesignView
    applylayers: (layers: DesignLayerListItem[]) => void
    canceledit: () => void
}

const DesignLayerTool = (props:DesignLayerToolProps) => {
    const { view, applylayers, canceledit } = props
    const [designlayerlist, setDesignLayerList] = React.useState<DesignLayerListItem[] | null>(null)

    React.useEffect(() => {
        if (view) {
            let dl: DesignLayerListItem[] = [] 
            view.layers?.forEach((l:DesignLayer) => {
                if (l.props.type !== 'mockup-layer' && l.props.type !== 'printframe-layer') {
                    const node = l.props.shapeRef.current
                    if (node) {
                        const previewdata = node.toDataURL({
                            pixelRatio: 0.5
                        })
                        // let thumbpreview = new window.Image()
                        // thumbpreview.src = previewdata
                        const item: DesignLayerListItem = {
                            id: l.id,
                            idx: l.props.idx,
                            type: l.props.type,
                            thumbpreview: previewdata
                        }
                        dl.push(item)
                    }
                    
                }
            })
            if (dl) {
                setDesignLayerList(dl.reverse())
            }
        }
    }, [view])

    const handleOnDragEnd = ({ destination, source }: DropResult) => {
        if (!destination) return
        if (designlayerlist) {
            const result = Array.from(designlayerlist)
            const [removed] = result.splice(destination.index, 1)
            result.splice(source.index, 0, removed)
            setDesignLayerList(result)
        }
    }

    const handleOnMoveup = (sourceidx: number) => {
        if (designlayerlist) {
            const result = Array.from(designlayerlist)
            const [removed] = result.splice(sourceidx-1, 1)
            result.splice(sourceidx, 0, removed)
            setDesignLayerList(result)
        }
    }

    const handleOnMovedown = (sourceidx: number) => {
        if (designlayerlist) {
            const result = Array.from(designlayerlist)
            const [removed] = result.splice(sourceidx+1, 1)
            result.splice(sourceidx, 0, removed)
            setDesignLayerList(result)
        }
    }

    const handleOnRemove = (sourceidx: number) => {
        if (designlayerlist) {
            const result = Array.from(designlayerlist)
            result.splice(sourceidx, 1)
            setDesignLayerList(result)
        }
    }

    const handleApplyLayersEdit = () => {
        if (applylayers && designlayerlist) applylayers(designlayerlist)
    }

    const handleCancelLayersEdit = () => {
        if (canceledit) canceledit()
    }

    return (
        <Grid container direction="column">
            <Grid item xs={12}>
            <DraggableDesignLayerList items={designlayerlist} onDragEnd={handleOnDragEnd} onMoveUp={handleOnMoveup} onMoveDown={handleOnMovedown} onRemove={handleOnRemove} />
            </Grid>
            <Grid item xs={12}>
            <Grid container direction="row" spacing={1} alignItems="center" justifyContent="center">
                <Grid item>
                    <Button variant="contained"
                            color="secondary"
                            onClick={handleApplyLayersEdit}>
                        Apply
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained"
                            color="secondary"
                            onClick={handleCancelLayersEdit}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
            </Grid>
        </Grid>
        
    )
}

export default DesignLayerTool