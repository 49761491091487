import { Button, Divider, Grid, IconButton, InputBase, Modal, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import * as React from 'react'
import { CartContext, ICartState, IStoreCartSummary } from '../../../providers/CartRefContext'
import { Link } from 'react-router-dom'
import DiscountIcon from '@mui/icons-material/Discount';
import { useAppSelector } from '../../../reducers/hooks';
// import { OfferModal } from './modals/OfferModal';

export const CartSummary: React.FC = () => {
    const { cartstate, cartdispatch, cartrefs } = React.useContext(CartContext)
    const { cartsummary } = cartstate as ICartState
    const currency = useAppSelector((state) => state.currency)
    const [opencoupons, setOpenCoupons] = React.useState(false)

    const handleOpenCoupons = () => {
        setOpenCoupons(true)
    }

    const handleCloseCoupons = () => {
        setOpenCoupons(false)
    }

    return (
        <>
        {cartsummary?.carts?.map((isc: IStoreCartSummary) => (
            <Paper sx={{
                margin: 2,
                mt: 0,
                width: {
                    md: 276,
                    lg: 360,
                    xl: 400
                }
            }}>
                <Stack spacing={1}>
                <Typography variant='subtitle1'>{isc.store}</Typography>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow key="subtotal">
                                <TableCell component='th' scope='row' align='left'>
                                    <Typography variant='subtitle1' sx={{ fontWeight: 700 }}>Subtotal</Typography>
                                </TableCell>
                                <TableCell align='left'>
                                    <Typography variant='subtitle1' sx={{ fontWeight: 400 }}>
                                    {currency.symbol}{isc.storecart_subtotal}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            {/* <TableRow key="coupon">
                                <TableCell component='th' scope='row' align='left'>
                                    <Typography variant='subtitle1' sx={{ fontWeight: 700 }}>Coupon Discounts</Typography>
                                </TableCell>
                                <TableCell align='left'>
                                    <Typography variant='subtitle1' sx={{ fontWeight: 400 }}>
                                    N/A
                                    </Typography>
                                </TableCell>
                            </TableRow> */}
                        </TableBody>
                    </Table>
                </TableContainer>
    
                <Grid container direction='column' spacing={2} alignItems='center' justifyContent='center'
                    sx={{
                        width: {
                            md: 276,
                            lg: 360,
                            xl: 400
                        }
                    }}
                >
                    {/* <Grid item xs={12} 
                        sx={{ 
                            padding: 2,
                            display: {
                                md: 'none',
                                lg: 'flex'
                            }
                        }}>
                        <Grid container direction='row' spacing={1} 
                            sx={{
                                width: {
                                    lg: 328,
                                    xl: 368
                                } 
                            }}>
                            <Grid item xs={8}>
                            <Paper
                                component="form"
                                sx={{ p: '2px 4px', display: 'flex', 
                                    alignItems: 'center', 
                                    border: '1px solid #ccc', 
                                    width: {
                                        lg: 200,
                                        xl: 240
                                    } 
                                }}
                                >
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Enter Coupon Code"
                                    inputProps={{ 'aria-label': 'subscribe' }}
                                />
                                <IconButton color="primary" sx={{ p: '10px' }} aria-label="search offers" onClick={handleOpenCoupons}>
                                    <DiscountIcon />
                                </IconButton>
                                <Modal
                                    open={opencoupons}
                                    onClose={(_, reason: any) => {
                                        if (reason !== 'backdropClick') {
                                            handleCloseCoupons()
                                        }
                                    }}
                                    aria-labelledby="findoffers-modal-title"
                                    aria-describedby="findoffers-modal-desc"
                                    sx={{ zIndex: 5000 }}
                                >
                                    <OfferModal onClose={handleCloseCoupons} />
                                </Modal>
                                
                            </Paper>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    sx={{
                                        // color: 'white',
                                        display: 'flex',
                                        width: '100%',
                                        height: 45
                                    }} 
    
                                >Apply</Button>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                    <Grid item xs={12} 
                        sx={{ 
                            padding: 2,
                            display: {
                                md: 'flex',
                                lg: 'none'
                            }
                        }}>
                        <Stack spacing={1}>
                            <Paper
                                component="form"
                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', border: '1px solid #ccc' }}
                                >
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Enter Coupon Code"
                                    inputProps={{ 'aria-label': 'subscribe' }}
                                />
                                <IconButton color="primary" sx={{ p: '10px' }} aria-label="search coupon">
                                    <DiscountIcon />
                                </IconButton>
                                
                            </Paper>
                            
                            <Button
                                variant="outlined"
                                color="primary"
                                sx={{
                                    // color: 'white',
                                    display: 'flex',
                                    width: '100%'
                                }} 
    
                            >Apply</Button>
                            
                        </Stack>
                        
                    </Grid> */}
                    <Grid item xs={12}
                        sx={{ 
                            padding: 2
                        }}
                    >
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            width: {
                                md: 244,
                                lg: 328,
                                xl: 368
                            }
                        }} 
    
                    >
                        <Link to="/checkout" style={{ color: 'white' }}>
                            Checkout Now
                        </Link>
                    </Button>
                    </Grid>
                </Grid>
                
                </Stack>
            </Paper>
        ))}
        
        
        </>
    )
}