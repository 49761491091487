import * as React from 'react'
import { bindActionCreators } from 'redux'
import compose from 'recompose/compose'
import { returntypeof } from 'react-redux-typescript'
import { setCurrentUri } from '../actions/userActions'
import { fetchProduct } from '../actions/designActions'
import { Content } from '../layouts'
import { connect } from 'react-redux'
import { Box, Button, Card, Divider, Grid, Paper, Icon, SvgIcon, AppBar, Toolbar, IconButton } from '@mui/material'
import { withStyles } from '@mui/styles';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import SellIcon from '@mui/icons-material/Sell'
import { toAbsoluteUrl } from '../_helpers'
import SVG from 'react-inlinesvg'
import { Stage, Layer } from 'react-konva'
import { DesignSimpleTools } from '../layouts/components/widgets/design/DesignSimpleTools'
import { DesignStage } from '../layouts/components/widgets/design/DesignStage'
import { DesignContext, DesignView } from '../providers/DesignRefContext'
import { DesignLayer } from '../providers/DesignRefContext'
import { CartCustomizedItem, CartItem, CartQuantity, Color, DesignItem, DesignTemplate, Font, IGPhoto, IGPhotoViewset, Mockup, Product, SharedGraphicViewset, SharedPhotoViewset, SharedTemplatesViewset, SizeChart, StoreCart, YourGraphic, YourGraphicViewset, YourPhoto, YourPhotoViewset, YourTemplatesViewset } from '../types'
import tinycolor from 'tinycolor2'
import { google_client_id, rest_endpoint } from '../settings'
import { fetchProductAPI } from '../reducers/func/productFunc'
import { fetchSizeAPI } from '../reducers/func/sizeFunc'
import { fetchMockupAPI } from '../reducers/func/mockupFunc'
import { getyourphotoAPI } from '../reducers/func/photoFunc'
import { DesignSidebar } from '../layouts/components/widgets/design/DesignSidebar'
import { DesignToolbar } from '../layouts/components/widgets/design/DesignToolbar'
import WebFont from 'webfontloader'
import { DesignLayerListItem } from '../layouts/components/widgets/lists/types'
import { addcartitem, updatecartitem } from '../actions/shoppingcartAction'
import { generateorderId } from '../utilities/utilities'
import { designtplsaveAPI, designtplupdateAPI } from '../reducers/func/designtplFunc'
import { addcartitemAPI, updatecartitemAPI } from '../reducers/func/cartFunc'



const styles = (theme: any) => ({
    workspace: {
        paddingLeft: theme.spacing(0)
    },
    tbutton: {
        margin: theme.spacing(1)
    },
    tb: {
        width: 'fit-content',
        //   border: `1px solid ${theme.palette.divider}`,
        //   borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        '& svg': {
            margin: theme.spacing(0.05),
        },
        '& hr': {
            margin: theme.spacing(0, 0.5),
        }
    },
    stage: {
        position: 'relative' as 'relative',
        paddingLeft: 0,
        paddingTop: 0
    },
    fab: {
        position: 'absolute' as 'absolute',
        top: theme.spacing(2),
        left: theme.spacing(2)
    }
})

class Component extends React.Component<any, {
    products?: Product[] | null
    sizes?: SizeChart[] | null
    mockups?: Mockup[] | null
    selproduct?: number | null
    selcolor?: number | null
    canvaswidth: number
    canvasheight: number
    // printwidth: number
    // printheight: number
    // exportwidth: number
    // exportheight: number
    // previewwidth: number
    // previewheight: number
    // mooffsetX: number
    // mooffsetY: number
    // curmockup?: Mockup | null
    curview?: string | null
    // curlayer?: string | null 
    views?: DesignView[] | null
    zoomlevel: number
    dragdesignitem?: DesignItem | null
    // curtool?: string | null
    yourtemplatesviewset?: YourTemplatesViewset | null
    sharedtemplatesviewset?: SharedTemplatesViewset | null
    yourphotosviewset?: YourPhotoViewset | null
    sharedphotosviewset?: SharedPhotoViewset | null
    yourgraphicsviewset?: YourGraphicViewset | null
    sharedgraphicsviewset?: SharedGraphicViewset | null
    igphotosviewset?: IGPhotoViewset | null
    fonts?: Font[] | null
    cartitem?: CartItem | null
}> {
    // moRef: React.RefObject<any>
    // pfRef: React.RefObject<any>
    stageRef: React.RefObject<any>
    exportstageRef: React.RefObject<any>
    previewstageRef: React.RefObject<any>

    constructor(props: any) {
        super(props)
        this.handleGSignInResponse = this.handleGSignInResponse.bind(this)
        this.addToShoppingCart = this.addToShoppingCart.bind(this)
        this.updateShoppingCart = this.updateShoppingCart.bind(this)
        //this.fetchProducts = this.fetchProducts.bind(this)
        // this.loadViews = this.loadViews.bind(this)
        // this.loadMockups = this.loadMockups.bind(this)
        //this.changeQuantity = this.changeQuantity.bind(this)
        // this.setMockup = this.setMockup.bind(this)
        // this.addBackgroundLayers = this.addBackgroundLayers.bind(this)
        this.setYourTemplatesViewset = this.setYourTemplatesViewset.bind(this)
        this.setYourPhotosViewset = this.setYourPhotosViewset.bind(this)
        this.setYourGraphicsViewset = this.setYourGraphicsViewset.bind(this)
        this.setSharedTemplatesViewset = this.setSharedTemplatesViewset.bind(this)
        this.setSharedPhotosViewset = this.setSharedPhotosViewset.bind(this)
        this.setSharedGraphicsViewset = this.setSharedGraphicsViewset.bind(this)
        this.setIGPhotosViewset = this.setIGPhotosViewset.bind(this)
        this.addPhotoLayer = this.addPhotoLayer.bind(this)
        this.addGraphicLayer = this.addGraphicLayer.bind(this)
        this.addTextLayer = this.addTextLayer.bind(this)
        this.zoomIn = this.zoomIn.bind(this)
        this.zoomOut = this.zoomOut.bind(this)
        this.setTemplateItem = this.setTemplateItem.bind(this)
        this.setPhotoItem = this.setPhotoItem.bind(this)
        this.setIGPhotoItem = this.setIGPhotoItem.bind(this)
        this.setGraphicItem = this.setGraphicItem.bind(this)
        this.selectLayer = this.selectLayer.bind(this)
        this.removeLayer = this.removeLayer.bind(this)
        this.removeAllLayers = this.removeAllLayers.bind(this)
        this.transformShape = this.transformShape.bind(this)
        this.transformCropMask = this.transformCropMask.bind(this)
        this.transformCropBox = this.transformCropBox.bind(this)
        this.expandKeepAspect = this.expandKeepAspect.bind(this)
        this.flipHorizontal = this.flipHorizontal.bind(this)
        this.flipVertical = this.flipVertical.bind(this)
        this.moveShape = this.moveShape.bind(this)
        this.moveCropMask = this.moveCropMask.bind(this)
        this.moveCropBox = this.moveCropBox.bind(this)
        this.moveCenter = this.moveCenter.bind(this)
        this.selecttool = this.selecttool.bind(this)
        this.adjustOpacity = this.adjustOpacity.bind(this)
        this.applyChange = this.applyChange.bind(this)
        this.resetChange = this.resetChange.bind(this)
        this.leaveDesignPage = this.leaveDesignPage.bind(this)
        this.setView = this.setView.bind(this)
        this.insertProps = this.insertProps.bind(this)
        this.undo = this.undo.bind(this)
        this.redo = this.redo.bind(this)
        this.bringToFront = this.bringToFront.bind(this)
        this.bringToBack = this.bringToBack.bind(this)
        this.updateText = this.updateText.bind(this)
        this.updateTextProps = this.updateTextProps.bind(this)
        this.updateShapeProps = this.updateShapeProps.bind(this)
        this.updateLayers = this.updateLayers.bind(this)
        this.selectProduct = this.selectProduct.bind(this)
        // this.updateCartItem = this.updateCartItem.bind(this)
        this.updateCartItemQuantity = this.updateCartItemQuantity.bind(this)
        //this.updateCartCustomizedItem = this.updateCartCustomizedItem.bind(this)
        let curitem: CartItem | null = null
        if (this.props.location.state) {
            if (this.props.location.state.isUpdating) {
                const { cart } = this.props
                let itemId = this.props.location.state.itemId
                cart.storecarts?.forEach((sc:StoreCart) => {
                    const item = sc.incart_items?.find((c: CartItem) => c.item_id === itemId)
                    if (item) {
                        curitem = item
                    }
                })
                

            }
        }
        this.state = {
            canvaswidth: 700,
            canvasheight: 700,
            
            // views: [
            //     {
            //         view: 'front',
            //         layers: [],
            //         curlayer: null,
            //         curtool: null,
            //         printwidth: 3900, //13in
            //         printheight: 4500, //15in
            //         exportwidth: 1300, //printwidth/3
            //         exportheight: 1500, // printheight/3
            //         previewwidth: 195, // printwidth/20
            //         previewheight: 225, // printheight/20
            //         mooffsetX: 0,
            //         mooffsetY: 25,
            //     },
            //     {
            //         view: 'back',
            //         layers: [],
            //         curlayer: null,
            //         curtool: null,
            //         printwidth: 3900, //13in
            //         printheight: 4500, //15in
            //         exportwidth: 1300, //printwidth/3
            //         exportheight: 1500, // printheight/3
            //         previewwidth: 195, // printwidth/20
            //         previewheight: 225, // printheight/20
            //         mooffsetX: -5,
            //         mooffsetY: 45,
            //     },
            //     {
            //         view: 'left',
            //         layers: [],
            //         curlayer: null,
            //         curtool: null,
            //         mooffsetX: 0,
            //         mooffsetY: 25,
            //     },
            //     {
            //         view: 'right',
            //         layers: [],
            //         curlayer: null,
            //         curtool: null,
            //         mooffsetX: 0,
            //         mooffsetY: 25,
            //     },
            // ],
            zoomlevel: 0,
            yourtemplatesviewset: {
                yourtemplates: null,
                totalpages: 0,
                page: 0,
                searchtags: [],
                sortby: '-created_dt'
            },
            yourphotosviewset: {
                yourphotos: null,
                totalpages: 0,
                page: 0,
                searchtags: [],
                sortby: '-upload_dt'
            },
            yourgraphicsviewset: {
                yourgraphics: null,
                totalpages: 0,
                page: 0,
                searchtags: [],
                sortby: '-upload_dt'
            },
            sharedtemplatesviewset: {
                sharedtemplates: null,
                totalpages: 0,
                page: 0,
                searchtags: [],
                sortby: '-created_dt'
            },
            sharedphotosviewset: {
                sharedphotos: null,
                totalpages: 0,
                page: 0,
                searchtags: [],
                sortby: '-upload_dt'
            },
            sharedgraphicsviewset: {
                sharedgraphics: null,
                totalpages: 0,
                page: 0,
                searchtags: [],
                sortby: '-upload_dt'
            },
            igphotosviewset: {
                igphotos: null
            },
            fonts: [
                {
                    name: "Alfa Slab One",
                    weights: [],
                    styles: [],
                    sample: "Sample Text"
                },
                {
                    name: "Amatic SC",
                    weights: [400, 700],
                    styles: [],
                    sample: "Sample Text"
                },
                {
                    name: "Bangers",
                    weights: [],
                    styles: [],
                    sample: "Sample Text"
                },
                {
                    name: "Bebas Neue",
                    weights: [],
                    styles: [],
                    sample: "Sample Text"
                },
                {
                    name: "Bungee",
                    weights: [],
                    styles: [],
                    sample: "Sample Text"
                },
                {
                    name: "Bungee Shade",
                    weights: [],
                    styles: [],
                    sample: "Sample Text"
                },
                {
                    name: "Charmonman",
                    weights: [400, 700],
                    styles: [],
                    sample: "ข้อความตัวอย่าง"
                },
                {
                    name: "Chonburi",
                    weights: [],
                    styles: [],
                    sample: "ข้อความตัวอย่าง"
                },
                {
                    name: "Comfortaa",
                    weights: [300,400,500,600,700],
                    styles: [],
                    sample: "Sample Text"
                },
                {
                    name: "Dokdo",
                    weights: [],
                    styles: [],
                    sample: "샘플 텍스트"
                },
                {
                    name: "Dela Gothic One",
                    weights: [],
                    styles: [],
                    sample: "サンプルテキスト"
                },
                {
                    name: "Faster One",
                    weights: [],
                    styles: [],
                    sample: "Sample Text"
                },
                {
                    name: "Fredericka the Great",
                    weights: [],
                    styles: [],
                    sample: "Sample Text"
                },
                {
                    name: "Itim",
                    weights: [],
                    styles: [],
                    sample: "ข้อความตัวอย่าง"
                },
                {
                    name: "Klee One",
                    weights: [],
                    styles: [],
                    sample: "サンプルテキスト"
                },
                {
                    name: "Lobster",
                    weights: [],
                    styles: [],
                    sample: "Sample Text"
                },
                {
                    name: "Long Cang",
                    weights: [],
                    styles: [],
                    sample: "示例文本"
                },
                {
                    name: "Luckiest Guy",
                    weights: [],
                    styles: [],
                    sample: "Sample Text"
                },
                {
                    name: "Monoton",
                    weights: [],
                    styles: [],
                    sample: "Sample Text"
                },
                {
                    name: "Passion One",
                    weights: [400, 700, 900],
                    styles: [],
                    sample: "Sample Text"
                },
                {
                    name: "Pattaya",
                    weights: [],
                    styles: [],
                    sample: "ข้อความตัวอย่าง"
                },
                {
                    name: "Poiret One",
                    weights: [],
                    styles: [],
                    sample: "Sample Text"
                },
                {
                    name: "Rampart One",
                    weights: [],
                    styles: [],
                    sample: "サンプルテキスト"
                },
                {
                    name: "Smokum",
                    weights: [],
                    styles: [],
                    sample: "Sample Text"
                },
                {
                    name: "Special Elite",
                    weights: [],
                    styles: [],
                    sample: "Sample Text"
                },
                {
                    name: "Sriracha",
                    weights: [],
                    styles: [],
                    sample: "ข้อความตัวอย่าง"
                },
                {
                    name: "Srisakdi",
                    weights: [400,700],
                    styles: [],
                    sample: "ข้อความตัวอย่าง"
                },
                {
                    name: "Tangerine",
                    weights: [400, 700],
                    styles: [],
                    sample: "Sample Text"
                },
            ],
            cartitem: curitem

        }
        // this.moRef = React.createRef()
        // this.pfRef = React.createRef()
        this.stageRef = React.createRef()
        this.exportstageRef = React.createRef()
        this.previewstageRef = React.createRef()
    }

    handleGSignInResponse(response: any) {
        const form = document.createElement('form');
        form.method = 'post';
        form.action = `${rest_endpoint}googlesignin/`;

        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = 'credential';
        hiddenField.value = response.credential;

        form.appendChild(hiddenField);
        document.body.appendChild(form);
        form.submit();
    }

    // async fetchProducts() {
    //     try {
    //         const { data } = await fetchProductAPI()
    //         this.setState({...this.state, products: data.result})
    //     } catch(e) {
    //         console.log("Error from fetching products: ", e)
    //     }
        
    // }
    // loadViews(producttype: string) {
    //     if (producttype === "Men Regular T-shirt") {
    //         const views = [
    //             {
    //                 view: 'front',
    //                 layers: [],
    //                 curlayer: null,
    //                 curtool: null,
    //                 printwidth: 3900, //13in
    //                 printheight: 4500, //15in
    //                 exportwidth: 1300, //printwidth/3
    //                 exportheight: 1500, // printheight/3
    //                 previewwidth: 195, // printwidth/20
    //                 previewheight: 225, // printheight/20
    //                 mooffsetX: 0,
    //                 mooffsetY: 25,
    //             },
    //             {
    //                 view: 'back',
    //                 layers: [],
    //                 curlayer: null,
    //                 curtool: null,
    //                 printwidth: 3900, //13in
    //                 printheight: 4500, //15in
    //                 exportwidth: 1300, //printwidth/3
    //                 exportheight: 1500, // printheight/3
    //                 previewwidth: 195, // printwidth/20
    //                 previewheight: 225, // printheight/20
    //                 mooffsetX: -5,
    //                 mooffsetY: 45,
    //             },
    //             {
    //                 view: 'left',
    //                 layers: [],
    //                 curlayer: null,
    //                 curtool: null,
    //                 mooffsetX: 0,
    //                 mooffsetY: 25,
    //             },
    //             {
    //                 view: 'right',
    //                 layers: [],
    //                 curlayer: null,
    //                 curtool: null,
    //                 mooffsetX: 0,
    //                 mooffsetY: 25,
    //             },
    //         ]
    //         this.setState({
    //             ...this.state, 
    //             views: views, 
    //             cartitem: {
    //                 product_name: producttype,
    //                 product_id: 0,
    //                 color_id: 0,
    //                 sizequantities: [],
    //                 customizeditems: []
    //             }
    //         })
    //     }
    // }

    // loadMockups(mockups: Mockup[]) {
    //     let defaultmockup = mockups?.find((m: Mockup) => m.isdefault);
    //     mockups?.forEach((mo: Mockup) => {
                 
    //         if (mo?.mockup !== undefined) {
    //             let mockupImg = new window.Image()
    //             mockupImg.crossOrigin = 'anonymous'
    //             mockupImg.src = mo.mockup
    //             mockupImg.onload = () => {
    //                 let view = this.state.views?.find((v: DesignView) => v.view===mo.view)
    //                 let mockupW = mo.mockup_w
    //                 let mockupH = mo.mockup_h
    //                 let moviewSize = this.state.canvaswidth as number
    //                 let moscale = (moviewSize/mockupW)*(1.1**this.state.zoomlevel)
    //                 let mostartX = (this.state.canvaswidth-moviewSize*(1.1**this.state.zoomlevel))/2+(view?.mooffsetX as number)*(1.1**this.state.zoomlevel)
    //                 let mostartY = (this.state.canvasheight-moviewSize*(1.1**this.state.zoomlevel))/2+(view?.mooffsetY as number)*(1.1**this.state.zoomlevel)
                    
    //                 const mocolor = tinycolor(mo.color)
    //                 const stroke = mocolor.getBrightness() <= 128 ? 'white':'black'
    //                 // check if layers has been added
    //                 var updated_layers: any = []
    //                 if (view?.layers?.length as number > 0) {
                        
    //                     updated_layers = view?.layers?.map((l: DesignLayer) =>
    //                         l.props.type === 'mockup-layer' ? {
    //                             ...l,
    //                             props: {
    //                                 ...l.props,
    //                                 mockup: mockupImg,
    //                                 x: mostartX,
    //                                 y: mostartY,
    //                                 w: mockupW,
    //                                 h: mockupH,
    //                                 scaleX: moscale,
    //                                 scaleY: moscale
    //                             }
    //                         }: l.props.type === 'printframe-layer' ? {
    //                             ...l,
    //                             props: {
    //                                 ...l.props,
    //                                 stroke: stroke
    //                             }
    //                         }: {
    //                             ...l,
    //                             selected: false
    //                         }
    //                     )
                        
                        
                        
    //                 }
    //                 else {
    //                     const moref = React.createRef<any>()
    //                     const pfref = React.createRef<any>()
    //                     let new_molayer: DesignLayer = {
    //                         id: `${view?.view}-mockup-layer`,
    //                         props: {
    //                             type: 'mockup-layer',
    //                             idx: 0,
    //                             moRef: moref,
    //                             mockup: mockupImg,
    //                             x: mostartX,
    //                             y: mostartY,
    //                             w: mockupW,
    //                             h: mockupH,
    //                             scaleX: moscale,
    //                             scaleY: moscale
    //                         }
    //                     }
    //                     view?.layers?.push(new_molayer)
    //                     if (mo.view === 'front' || mo.view === 'back') {
    //                         let pfW: number = view?.previewwidth as number
    //                         let pfH: number = view?.previewheight as number
    //                         let viewX: number = view?.previewwidth as number
    //                         let viewY: number = view?.previewheight as number
    //                         let pfscaleX = viewX/pfW
    //                         let pfscaleY = viewY/pfH
    //                         let pfstartX = (this.state.canvaswidth as number-viewX)/2
    //                         let pfstartY = (this.state.canvasheight as number-viewY)/2
    //                         let new_pflayer: DesignLayer = {
    //                             id: `${view?.view}-printframe-layer`,
    //                             props: { 
    //                                 type: 'printframe-layer',
    //                                 idx: 1,
    //                                 pfRef: pfref,
    //                                 x: pfstartX,
    //                                 y: pfstartY,
    //                                 w: pfW,
    //                                 h: pfH,
    //                                 scaleX: pfscaleX,
    //                                 scaleY: pfscaleY,
    //                                 stroke: stroke,
    //                                 dash: [5/pfscaleX, 5/pfscaleX],
    //                                 corner: 3/pfscaleX,
    //                                 strokeWidth: 0.5/pfscaleX,
    //                                 showDefaultText: true
    //                             }
    //                         }
    //                         view?.layers?.push(new_pflayer)
    //                     }
                        
    //                     updated_layers = view?.layers
                        
    //                 }
    //                 const update_views = this.state.views?.map((v: DesignView) => 
    //                         v.view === mo.view ? {
    //                             ...v,
    //                             thumb: mo.thumb,
    //                             layers: updated_layers
    //                         }:v
    //                     )
    //                 this.setState({...this.state, views: update_views})
                    
    //             }
    //         }
    //     })
    //     this.setState({
    //         ...this.state, 
    //         curview: defaultmockup?.view,
    //     })
    // }

    // updateCartItem(productname: string, product: number, color: number) {
    //     this.state.views?.forEach((v: DesignView) => {
            
    //     })
    //     this.setState({
    //         ...this.state, 
    //         cartitem: {
    //             ...this.state.cartitem,
    //             product_name: productname,
    //             product_id: product,
    //             color_id: color,
    //             item_type: 'customized',
    //             checkedout: false
    //         }
    //     })
    // }

    updateCartItemQuantity(size: number, sizename: string, quantity: number) {
        const newsizequans = this.state.sizes?.map((sc: SizeChart) => 
          sc.id == size ? 
          {
            ...sc,
            quantity: quantity
          }:sc
        ) 
        if (this.state.cartitem) {
            const found = this.state.cartitem.sizequantities?.find((q: CartQuantity) => q.size_id == size)
            let sizequan: CartQuantity[] | undefined = []
            if (found) {
                sizequan = this.state.cartitem.sizequantities?.map((q: CartQuantity) =>
                    q.size_id == size ? 
                    {
                        ...q,
                        quantity: quantity,
                        //prices: prices
                    }:q
                )
            } else {
                sizequan = this.state.cartitem.sizequantities?.concat({
                    size_id: size,
                    //color_id: color,
                    size_name: sizename,
                    quantity: quantity,
                    //prices: prices
                })
            }
            
            this.setState({
                ...this.state,
                sizes: newsizequans,
                cartitem: {
                    ...this.state.cartitem,
                    sizequantities: sizequan
                }
            })
        }
    }

    // updateCartCustomizedItem(src: number, method: string, position: string, prices: any) {
    //     if (this.state.cartitem) {
    //         const found = this.state.cartitem.customizeditems?.map((it: CartCustomizedItem) => it.src_id == src && it.position == position)
    //         let customizeditems: CartCustomizedItem[] | undefined = []
    //         if (found) {
    //             customizeditems = this.state.cartitem.customizeditems?.map((it: CartCustomizedItem) =>
    //             it.src_id == src && it.position == position  ? 
    //             {
    //                 ...it,
    //                 num_usages: it.num_usages + 1
    //             }:it
    //         )
    //         } else {
    //             customizeditems = this.state.cartitem.customizeditems?.concat({
    //                 src_id: src,
    //                 method: method,
    //                 position: position,
    //                 num_usages: 1,
    //                 prices: prices
    //             })
    //         }
    //         this.setState({
    //             ...this.state,
    //             cartitem: {
    //                 ...this.state.cartitem,
    //                 customizeditems: customizeditems
    //             }
    //         })
            
            
    //     }
    // }

    async selectProduct(product: number, color: number, resetview: boolean=false) {
        try {
            const prod = this.state.products?.find((p: Product) => p.id===product)
            const col = prod?.colors?.find((c: Color) => c.id === color)
            const sizedata = await fetchSizeAPI(product, color)
            const mockupdata = await fetchMockupAPI(product, color)
            const sizes = sizedata.data.result
            const mockups = mockupdata.data.result
            let defaultmockup = mockups?.find((m: Mockup) => m.isdefault);
            let views: DesignView[] | undefined | null = this.state.views
            let curview = this.state.curview
            if (resetview) {
                views = []
                curview = defaultmockup.view
            }
            mockups?.forEach((mo: Mockup) => {
                let view = this.state.views?.find((v: DesignView) => v.view===mo.view)
                if (resetview) {
                    view = {
                        view: mo.view,
                        thumb: mo.thumb,
                        layers: [],
                        curlayer: null,
                        curtool: null,
                        printwidth: mo.printwidth,
                        printheight: mo.printheight,
                        exportwidth: mo.exportwidth,
                        exportheight: mo.exportheight,
                        previewwidth: mo.previewwidth,
                        previewheight: mo.previewheight,
                        mooffsetX: mo.mooffsetX,
                        mooffsetY: mo.mooffsetY,
                        order: mo.vieworder
                    }
                    views?.push(view)
                    views?.sort((a,b) => a.order - b.order)
                } 

                if (mo?.mockup !== undefined) {
                    let mockupImg = new window.Image()
                    mockupImg.crossOrigin = 'anonymous'
                    mockupImg.src = mo.mockup
                    mockupImg.onload = () => {
                        
                        let mockupW = mo.mockup_w
                        let mockupH = mo.mockup_h
                        let moviewW = mo.moviewwidth //this.state.canvaswidth as number
                        let moviewH = mo.moviewheight
                        let moscaleX = (moviewW/mockupW)*(1.1**this.state.zoomlevel)
                        let moscaleY = (moviewH/mockupH)*(1.1**this.state.zoomlevel)
                        let mostartX = (this.state.canvaswidth-moviewW*(1.1**this.state.zoomlevel))/2+(view?.mooffsetX as number)*(1.1**this.state.zoomlevel)
                        let mostartY = (this.state.canvasheight-moviewH*(1.1**this.state.zoomlevel))/2+(view?.mooffsetY as number)*(1.1**this.state.zoomlevel)
                        
                        const mocolor = tinycolor(mo.color)
                        const stroke = mocolor.getBrightness() <= 128 ? 'white':'black'
                        // check if layers has been added
                        var updated_layers: any = []
                        if (view?.layers?.length as number > 0) {
                            
                            updated_layers = view?.layers?.map((l: DesignLayer) =>
                                l.props.type === 'mockup-layer' ? {
                                    ...l,
                                    props: {
                                        ...l.props,
                                        mockup: mockupImg,
                                        x: mostartX,
                                        y: mostartY,
                                        w: mockupW,
                                        h: mockupH,
                                        scaleX: moscaleX,
                                        scaleY: moscaleY
                                    }
                                }: l.props.type === 'printframe-layer' ? {
                                    ...l,
                                    props: {
                                        ...l.props,
                                        stroke: stroke
                                    }
                                }: {
                                    ...l,
                                    selected: false
                                }
                            )
                            
                            
                            
                        }
                        else {
                            const moref = React.createRef<any>()
                            const pfref = React.createRef<any>()
                            let new_molayer: DesignLayer = {
                                id: `${view?.view}-mockup-layer`,
                                props: {
                                    type: 'mockup-layer',
                                    idx: 0,
                                    moRef: moref,
                                    mockup: mockupImg,
                                    x: mostartX,
                                    y: mostartY,
                                    w: mockupW,
                                    h: mockupH,
                                    scaleX: moscaleX,
                                    scaleY: moscaleY
                                }
                            }
                            view?.layers?.push(new_molayer)
                            if (mo.customizable) {
                                let pfW: number = view?.previewwidth as number
                                let pfH: number = view?.previewheight as number
                                let viewX: number = view?.previewwidth as number
                                let viewY: number = view?.previewheight as number
                                let pfscaleX = viewX/pfW
                                let pfscaleY = viewY/pfH
                                let pfstartX = (this.state.canvaswidth as number-viewX)/2
                                let pfstartY = (this.state.canvasheight as number-viewY)/2
                                let new_pflayer: DesignLayer = {
                                    id: `${view?.view}-printframe-layer`,
                                    props: { 
                                        type: 'printframe-layer',
                                        idx: 1,
                                        pfRef: pfref,
                                        x: pfstartX,
                                        y: pfstartY,
                                        w: pfW,
                                        h: pfH,
                                        scaleX: pfscaleX,
                                        scaleY: pfscaleY,
                                        stroke: stroke,
                                        dash: [5/pfscaleX, 5/pfscaleX],
                                        corner: 3/pfscaleX,
                                        strokeWidth: 0.5/pfscaleX,
                                        showDefaultText: true
                                    }
                                }
                                view?.layers?.push(new_pflayer)
                            }
                            
                            updated_layers = view?.layers
                            
                        }
                        const update_views = this.state.views?.map((v: DesignView) => 
                                v.view === mo.view ? {
                                    ...v,
                                    thumb: mo.thumb,
                                    layers: updated_layers
                                }:v
                            )
                        this.setState({...this.state, views: update_views})
                        
                    }
                }
            });

            this.setState({
                ...this.state, 
                views: views,
                sizes: sizes,
                mockups: mockups,
                curview: curview,
                cartitem: {
                    // product_name: prod?.typename as string,
                    // product_id: product,
                    // color_id: color,
                    // prices: prod?.prices,
                    // discount: prod?.discount,
                    product: prod,
                    color: col,
                    sizequantities: [],
                    customizeditems: [],
                    checkingout: false,
                    // store: prod?.store,
                    // store_name: prod?.storename
                }
            })

            
            
            
        } catch(e) {
            console.log("Error from fetching sizes and mockups: ", e)
        }
    }

    // changeQuantity(sizeid: number, newquan: number, product: number, color: number) {
        // const newsc = this.state.sizes?.map(sc =>
        //     sc.id == sizeid ? {
        //         ...sc, 
        //         quantity: newquan >= 0 && newquan <= sc.availability ? newquan:sc.quantity,
        //         hasquanerr: newquan < 0 || newquan > sc.availability
        //       }
        //       : sc 
        // )
        // this.setState({...this.state, sizes: newsc})

        // return
    // }
    
    setYourTemplatesViewset(templates: any, totalpages: number, page: number, searchtags: any, sortby: string) {
        const templateviewset: YourTemplatesViewset = {
            ...this.state.yourtemplatesviewset,
            yourtemplates: templates,
            totalpages: totalpages,
            page: page,
            searchtags: searchtags,
            sortby: sortby
        }
        this.setState({...this.state, yourtemplatesviewset: templateviewset})
    }

    setSharedTemplatesViewset(templates: any, totalpages: number, page: number, searchtags: any, sortby: string) {
        const templateviewset: SharedTemplatesViewset = {
            ...this.state.sharedphotosviewset,
            sharedtemplates: templates,
            totalpages: totalpages,
            page: page,
            searchtags: searchtags,
            sortby: sortby
        }
        this.setState({...this.state, sharedtemplatesviewset: templateviewset})
    }

    setYourPhotosViewset(photos: any, totalpages: number, page: number, searchtags: any, sortby: string) {
        const photoviewset: YourPhotoViewset = {
            ...this.state.yourphotosviewset,
            yourphotos: photos,
            totalpages: totalpages,
            page: page,
            searchtags: searchtags,
            sortby: sortby
        }
        this.setState({...this.state, yourphotosviewset: photoviewset})
    }

    setSharedPhotosViewset(photos: any, totalpages: number, page: number, searchtags: any, sortby: string) {
        const photoviewset: SharedPhotoViewset = {
            ...this.state.sharedphotosviewset,
            sharedphotos: photos,
            totalpages: totalpages,
            page: page,
            searchtags: searchtags,
            sortby: sortby
        }
        this.setState({...this.state, sharedphotosviewset: photoviewset})
    }

    setYourGraphicsViewset(graphics: any, totalpages: number, page: number, searchtags: any, sortby: string) {
        const graphicviewset: YourGraphicViewset = {
            ...this.state.yourgraphicsviewset,
            yourgraphics: graphics,
            totalpages: totalpages,
            page: page,
            searchtags: searchtags,
            sortby: sortby
        }
        this.setState({...this.state, yourgraphicsviewset: graphicviewset})
    }

    setSharedGraphicsViewset(graphics: any, totalpages: number, page: number, searchtags: any, sortby: string) {
        const graphicviewset: SharedGraphicViewset = {
            ...this.state.sharedphotosviewset,
            sharedgraphics: graphics,
            totalpages: totalpages,
            page: page,
            searchtags: searchtags,
            sortby: sortby
        }
        this.setState({...this.state, sharedgraphicsviewset: graphicviewset})
    }

    setIGPhotosViewset(igphotos: any, next: string) {
        const igphotoviewset: IGPhotoViewset = {
            ...this.state.igphotosviewset,
            igphotos: igphotos,
            next: next
        }
        this.setState({...this.state, igphotosviewset: igphotoviewset})
    }

    setView(view: string) {
        this.setState({...this.state, curview: view})
    }

    addPhotoLayer(layers: DesignLayer[], replace: boolean = false) {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        let update_layers = view?.layers?.map((l: DesignLayer) =>
            l.props.type === 'printframe-layer' ? {
                ...l,
                props: {
                    ...l.props,
                    showDefaultText: false
                }
            }: l.props.type !== 'mockup-layer' ? {
                ...l,
                props: {
                    ...l.props,
                    selected: false
                }
            }: l
        )
        let customizeditems: CartCustomizedItem[] | undefined = []
        if (this.state.cartitem?.customizeditems) {
            customizeditems = [...this.state.cartitem.customizeditems]
        }
        if (replace) {
            update_layers = update_layers?.filter((l: DesignLayer) => l.props.type === 'mockup-layer' || l.props.type === 'printframe-layer')
            customizeditems = customizeditems?.filter((it: CartCustomizedItem) => it.position != this.state.curview)
        }
        var curlayer: any = null
        const previewlayerRef = React.createRef<any>()
        const exportlayerRef = React.createRef<any>()
        const shapeGrpRef = React.createRef<any>()
        const shapeRef = React.createRef<any>()
        const shapeboxRef = React.createRef<any>()
        const trRef = React.createRef<any>()
        const croptrRef = React.createRef<any>()
        const cropmaskRef = React.createRef<any>()
        const cropmasktrRef = React.createRef<any>()
        const exportshapeGrpRef = React.createRef<any>()
        const exportshapeRef = React.createRef<any>()
        
        
        
        layers.forEach((l: DesignLayer) => {
            if (l.props.selected) {
                curlayer = l.id
            }
            
            const props = {
                ...l.props,
                // currentsrc: 0,
                previewlayerRef: previewlayerRef,
                exportlayerRef: exportlayerRef,
                shapeGrpRef: shapeGrpRef,
                shapeRef: shapeRef,
                shapeboxRef: shapeboxRef,
                trRef: trRef,
                croptrRef: croptrRef,
                cropmaskRef: cropmaskRef,
                cropmasktrRef: cropmasktrRef,
                exportshapeGrpRef: exportshapeGrpRef,
                exportshapeRef: exportshapeRef,
            }
            const ll = {
                ...l,
                //selected: true,
                current: 0,
                props: props,
                propshistory: [props],
                // actualimghistory: [l.actual],
                // previewimghistory: [l.preview],
                // previewimgdatahistory: [l.previewdata]
            }
            update_layers?.push(ll)
            const found = customizeditems?.find((it: CartCustomizedItem) => it.src_id == l.props.itemId && it.position == this.state.curview)
                
            if (found) {
                customizeditems = customizeditems?.map((it: CartCustomizedItem) =>
                it.src_id == l.props.itemId && it.position == this.state.curview  ? 
                {
                    ...it,
                    num_usages: it.num_usages + 1
                }:it
            )
            } else {
                customizeditems = customizeditems?.concat({
                    item_id: l.id,
                    src_id: l.props.itemId,
                    src_type: l.props.type,
                    src_owner: l.props.itemowner,
                    method: 'printing',
                    position: this.state.curview as string,
                    num_usages: 1,
                    prices: l.props.usageprices,
                    discount: l.props.discount,
                    isfree: l.props.isfree
                })
            }
                
            
        })
        update_layers?.sort((a,b) => a.props.idx - b.props.idx)
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers,
                curlayer: curlayer,
                curtool: 'transform'
            }:v
        )
        if (this.state.cartitem) {
            this.setState({
                ...this.state, 
                views: update_views,
                cartitem: {
                    ...this.state.cartitem,
                    customizeditems: customizeditems
                }
            })
        } else {
            this.setState({
                ...this.state, 
                views: update_views
            })
        }
        
    }

    addGraphicLayer(layers: DesignLayer[], replace: boolean = false) {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        let update_layers = view?.layers?.map((l: DesignLayer) =>
            l.props.type === 'printframe-layer' ? {
                ...l,
                props: {
                    ...l.props,
                    showDefaultText: false
                }
            }: l.props.type !== 'mockup-layer' ? {
                ...l,
                props: {
                    ...l.props,
                    selected: false
                }
            }: l
        )
        let customizeditems: CartCustomizedItem[] | undefined = []
        if (this.state.cartitem?.customizeditems) {
            customizeditems = [...this.state.cartitem.customizeditems]
        }
        if (replace) {
            update_layers = update_layers?.filter((l: DesignLayer) => l.props.type === 'mockup-layer' || l.props.type === 'printframe-layer')
            customizeditems = customizeditems?.filter((it: CartCustomizedItem) => it.position != this.state.curview)
        }

        var curlayer: any = null
        const previewlayerRef = React.createRef<any>()
        const exportlayerRef = React.createRef<any>()
        const shapeGrpRef = React.createRef<any>()
        const shapeRef = React.createRef<any>()
        const shapeboxRef = React.createRef<any>()
        const trRef = React.createRef<any>()
        const exportshapeGrpRef = React.createRef<any>()
        const exportshapeRef = React.createRef<any>()
        
        layers.forEach((l: DesignLayer) => {
            if (l.props.selected) {
                curlayer = l.id
            }
            const props = {
                ...l.props,
                // currentsrc: 0,
                previewlayerRef: previewlayerRef,
                exportlayerRef: exportlayerRef,
                shapeGrpRef: shapeGrpRef,
                shapeRef: shapeRef,
                shapeboxRef: shapeboxRef,
                trRef: trRef,
                exportshapeGrpRef: exportshapeGrpRef,
                exportshapeRef: exportshapeRef,
            }
            const ll = {
                ...l,
                //selected: true,
                current: 0,
                props: props,
                propshistory: [props],
                // actualimghistory: [l.actual],
                // previewimghistory: [l.preview],
                // previewimgdatahistory: [l.previewdata]
            }
            update_layers?.push(ll)
            const found = customizeditems?.find((it: CartCustomizedItem) => it.src_id == l.props.itemId && it.position == this.state.curview)
                
            if (found) {
                customizeditems = customizeditems?.map((it: CartCustomizedItem) =>
                    it.src_id == l.props.itemId && it.position == this.state.curview  ? 
                    {
                        ...it,
                        num_usages: it.num_usages + 1
                    }:it
                )
            } else {
                customizeditems = customizeditems?.concat({
                    item_id: l.id,
                    src_id: l.props.itemId,
                    src_type: l.props.type,
                    src_owner: l.props.itemowner,
                    method: 'printing',
                    position: this.state.curview as string,
                    num_usages: 1,
                    prices: l.props.usageprices,
                    discount: l.props.discount,
                    isfree: l.props.isfree
                })
            }
        })
        update_layers?.sort((a,b) => a.props.idx - b.props.idx)
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers,
                curlayer: curlayer,
                curtool: 'transform'
            }:v
        )
        if (this.state.cartitem) {
            this.setState({
                ...this.state, 
                views: update_views,
                cartitem: {
                    ...this.state.cartitem,
                    customizeditems: customizeditems
                }
            })
        } else {
            this.setState({
                ...this.state, 
                views: update_views
            })
        }
    }

    addTextLayer(layers: DesignLayer[], replace: boolean = false) {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        let update_layers = view?.layers?.map((l: DesignLayer) =>
            l.props.type === 'printframe-layer' ? {
                ...l,
                props: {
                    ...l.props,
                    showDefaultText: false
                }
            }: l.props.type !== 'mockup-layer' ? {
                ...l,
                props: {
                    ...l.props,
                    selected: false
                }
            }: l
        )

        let customizeditems: CartCustomizedItem[] | undefined = []
        if (this.state.cartitem?.customizeditems) {
            customizeditems = [...this.state.cartitem.customizeditems]
        }
        if (replace) {
            update_layers = update_layers?.filter((l: DesignLayer) => l.props.type === 'mockup-layer' || l.props.type === 'printframe-layer')
            customizeditems = customizeditems?.filter((it: CartCustomizedItem) => it.position != this.state.curview)
        }
        var curlayer: any = null
        const previewlayerRef = React.createRef<any>()
        const exportlayerRef = React.createRef<any>()
        const shapeGrpRef = React.createRef<any>()
        const shapeRef = React.createRef<any>()
        const shapeboxRef = React.createRef<any>()
        const trRef = React.createRef<any>()
        const exportshapeGrpRef = React.createRef<any>()
        const exportshapeRef = React.createRef<any>()

        layers.forEach((l: DesignLayer) => {
            if (l.props.selected) {
                curlayer = l.id
            }
            const props = {
                ...l.props,
                // currentsrc: 0,
                previewlayerRef: previewlayerRef,
                exportlayerRef: exportlayerRef,
                shapeGrpRef: shapeGrpRef,
                shapeRef: shapeRef,
                shapeboxRef: shapeboxRef,
                trRef: trRef,
                exportshapeGrpRef: exportshapeGrpRef,
                exportshapeRef: exportshapeRef,
            }
            const ll = {
                ...l,
                //selected: true,
                current: 0,
                props: props,
                propshistory: [props],
                // actualimghistory: [l.actual],
                // previewimghistory: [l.preview],
                // previewimgdatahistory: [l.previewdata]
            }
            update_layers?.push(ll)
            const found = customizeditems?.find((it: CartCustomizedItem) => it.src_id == l.props.itemId && it.position == this.state.curview)
                
            if (found) {
                customizeditems = customizeditems?.map((it: CartCustomizedItem) =>
                    it.src_id == l.props.itemId && it.position == this.state.curview  ? 
                    {
                        ...it,
                        num_usages: it.num_usages + 1
                    }:it
                )
            } else {
                customizeditems = customizeditems?.concat({
                    item_id: l.id,
                    src_id: null,
                    src_type: l.props.type,
                    src_owner: null,
                    method: 'printing',
                    position: this.state.curview as string,
                    num_usages: 1,
                    prices: null,
                    discount: null,
                    isfree: true
                })
            }
        })
        update_layers?.sort((a,b) => a.props.idx - b.props.idx)
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers,
                curlayer: curlayer,
                curtool: 'transform'
            }:v
        )
        if (this.state.cartitem) {
            this.setState({
                ...this.state, 
                views: update_views,
                cartitem: {
                    ...this.state.cartitem,
                    customizeditems: customizeditems
                }
            })
        } else {
            this.setState({
                ...this.state, 
                views: update_views
            })
        }
    }

    updateText(newtext: string, newheight: number) {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        const layer = view?.layers?.find((l: DesignLayer) => l.id === view.curlayer)

        const props = {
            ...layer?.props,
            shapeGrpAttr: {
                ...layer?.props.shapeGrpAttr,
                height: newheight,
                clipHeight: newheight
            },
            shapeboxAttr: {
                ...layer?.props.shapeboxAttr,
                height: newheight,
            },
            shapeAttr: {
                ...layer?.props.shapeAttr,
                text: newtext,
                height: newheight,
            },
            exportshapeGrpAttr: {
                ...layer?.props.exportshapeGrpAttr,
                height: newheight*20/3,
                clipHeight: newheight*20/3
            },
            exportshapeAttr: {
                ...layer?.props.exportshapeAttr,
                text: newtext,
                height: newheight*20/3,
            },
        }
        layer?.propshistory.push(props)
        const update_layers = view?.layers?.map((l: DesignLayer) =>
            l.id === view.curlayer ? 
            {
                ...l,
                current: l.current as number + 1,
                propshistory: layer?.propshistory,
                props: props
            }: l
        )
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers
            }:v
        )
        this.setState({...this.state, views: update_views})

    }

    updateTextProps(newprops: any) {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        const layer = view?.layers?.find((l: DesignLayer) => l.id === view.curlayer)

        const props = {
            ...layer?.props,
            
            shapeAttr: {
                ...newprops.textattr,
            },
            exportshapeAttr: {
                ...newprops.exporttextattr,
            },
        }
        //layer?.propshistory.push(props)
        const update_layers = view?.layers?.map((l: DesignLayer) =>
            l.id === view.curlayer ? 
            {
                ...l,
                // current: l.current as number + 1,
                // propshistory: layer?.propshistory,
                props: props
            }: l
        )
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers
            }:v
        )
        this.setState({...this.state, views: update_views})

    }

    updateShapeProps(newprops: any) {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        const layer = view?.layers?.find((l: DesignLayer) => l.id === view.curlayer)

        const props = {
            ...layer?.props,
            
            shapeAttr: {
                ...newprops.shapeattr,
            },
            exportshapeAttr: {
                ...newprops.exportshapeattr,
            },
        }
        //layer?.propshistory.push(props)
        const update_layers = view?.layers?.map((l: DesignLayer) =>
            l.id === view.curlayer ? 
            {
                ...l,
                // current: l.current as number + 1,
                // propshistory: layer?.propshistory,
                props: props
            }: l
        )
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers
            }:v
        )
        this.setState({...this.state, views: update_views})

    }

    zoomIn() {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        const new_layers = view?.layers?.map((l: DesignLayer) => {
            if (l.props.type === 'mockup-layer') {
                return {
                    ...l,
                    props: {
                        ...l.props,
                        scaleX: l.props.scaleX*1.1,
                        scaleY: l.props.scaleY*1.1,
                        x: (this.state.canvaswidth-l.props.scaleX*l.props.w*1.1)/2+(view?.mooffsetX as number)*(1.1**(this.state.zoomlevel+1)),
                        y: (this.state.canvasheight-l.props.scaleY*l.props.h*1.1)/2+(view?.mooffsetY as number)*(1.1**(this.state.zoomlevel+1))
                    }
                }
            } 
            if (l.props.type === 'printframe-layer') {
                return {
                    ...l,
                    props: {
                        ...l.props,
                        scaleX: l.props.scaleX*1.1,
                        scaleY: l.props.scaleY*1.1,
                        x: (this.state.canvaswidth-l.props.scaleX*l.props.w*1.1)/2,
                        y: (this.state.canvasheight-l.props.scaleY*l.props.h*1.1)/2
                    }
                }
            }
            
            const shapegrpAttr = l.props.shapeGrpAttr
            const curframeX = (this.state.canvaswidth-(view?.previewwidth as number)*(1.1**this.state.zoomlevel))/2
            const curframeY = (this.state.canvasheight-(view?.previewheight as number)*(1.1**this.state.zoomlevel))/2
            const normX = (shapegrpAttr.x - curframeX)/((view?.previewwidth as number)*(1.1**this.state.zoomlevel))
            const normY = (shapegrpAttr.y - curframeY)/((view?.previewheight as number)*(1.1**this.state.zoomlevel))
        
            const newframeX = (this.state.canvaswidth-(view?.previewwidth as number)*(1.1**(this.state.zoomlevel+1)))/2
            const newframeY = (this.state.canvasheight-(view?.previewheight as number)*(1.1**(this.state.zoomlevel+1)))/2
            const shapegrpX = newframeX + normX*(view?.previewwidth as number)*(1.1**(this.state.zoomlevel+1))
            const shapegrpY = newframeY + normY*(view?.previewheight as number)*(1.1**(this.state.zoomlevel+1))
            
            if (view.curtool === "crop") {
                const cropmaskAttr = l.props.cropmaskAttr
                const cmnormX = (cropmaskAttr.x - curframeX)/((view?.previewwidth as number)*(1.1**this.state.zoomlevel))
                const cmnormY = (cropmaskAttr.y - curframeY)/((view?.previewheight as number)*(1.1**this.state.zoomlevel))
                const cmX = newframeX + cmnormX*(view?.previewwidth as number)*(1.1**(this.state.zoomlevel+1))
                const cmY = newframeY + cmnormY*(view?.previewheight as number)*(1.1**(this.state.zoomlevel+1))
                return {
                    ...l,
                    props: {
                        ...l.props,
                        shapeGrpAttr: { 
                            ...shapegrpAttr,
                            x: shapegrpX,
                            y: shapegrpY,
                            scaleX: shapegrpAttr.scaleX*1.1,
                            scaleY: shapegrpAttr.scaleY*1.1 
                        },
                        cropmaskAttr: { 
                            ...cropmaskAttr,
                            x: cmX,
                            y: cmY,
                            scaleX: cropmaskAttr.scaleX*1.1,
                            scaleY: cropmaskAttr.scaleY*1.1 
                        }
                    }
                }
            }
            return {
                ...l,
                props: {
                    ...l.props,
                    shapeGrpAttr: { 
                        ...shapegrpAttr,
                        x: shapegrpX,
                        y: shapegrpY,
                        scaleX: shapegrpAttr.scaleX*1.1,
                        scaleY: shapegrpAttr.scaleY*1.1 
                    }
                }
            }
        })
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: new_layers
            }:v
        )   
        this.setState({...this.state, views: update_views, zoomlevel: this.state.zoomlevel+1})
    }

    zoomOut() {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        const new_layers = view?.layers?.map((l: DesignLayer) => {
            if (l.props.type === 'mockup-layer') {
                return {
                    ...l,
                    props: {
                        ...l.props,
                        scaleX: l.props.scaleX/1.1,
                        scaleY: l.props.scaleY/1.1,
                        x: (this.state.canvaswidth-l.props.scaleX*l.props.w/1.1)/2+(view?.mooffsetX as number)*(1.1**(this.state.zoomlevel+1)),
                        y: (this.state.canvasheight-l.props.scaleY*l.props.h/1.1)/2+(view?.mooffsetY as number)*(1.1**(this.state.zoomlevel+1))
                    }
                }
            } 
            if (l.props.type === 'printframe-layer') {
                return {
                    ...l,
                    props: {
                        ...l.props,
                        scaleX: l.props.scaleX/1.1,
                        scaleY: l.props.scaleY/1.1,
                        x: (this.state.canvaswidth-l.props.scaleX*l.props.w/1.1)/2,
                        y: (this.state.canvasheight-l.props.scaleY*l.props.h/1.1)/2
                    }
                }
            }
            const shapegrpAttr = l.props.shapeGrpAttr
            const curframeX = (this.state.canvaswidth-(view?.previewwidth as number)*(1.1**this.state.zoomlevel))/2
            const curframeY = (this.state.canvasheight-(view?.previewheight as number)*(1.1**this.state.zoomlevel))/2
            const normX = (shapegrpAttr.x - curframeX)/((view?.previewwidth as number)*(1.1**this.state.zoomlevel))
            const normY = (shapegrpAttr.y - curframeY)/((view?.previewheight as number)*(1.1**this.state.zoomlevel))
            // const tf = shape.getTransform().copy()
            //tf.rotate(-l.props.rotation*Math.PI/180)
            // tf.scale(1.1,1.1)
            const newframeX = (this.state.canvaswidth-(view?.previewwidth as number)*(1.1**(this.state.zoomlevel-1)))/2
            const newframeY = (this.state.canvasheight-(view?.previewheight as number)*(1.1**(this.state.zoomlevel-1)))/2
            const shapegrpX = newframeX + normX*(view?.previewwidth as number)*(1.1**(this.state.zoomlevel-1))
            const shapegrpY = newframeY + normY*(view?.previewheight as number)*(1.1**(this.state.zoomlevel-1))
            
            if (view.curtool === "crop") {
                const cropmaskAttr = l.props.cropmaskAttr
                const cmnormX = (cropmaskAttr.x - curframeX)/((view?.previewwidth as number)*(1.1**this.state.zoomlevel))
                const cmnormY = (cropmaskAttr.y - curframeY)/((view?.previewheight as number)*(1.1**this.state.zoomlevel))
                const cmX = newframeX + cmnormX*(view?.previewwidth as number)*(1.1**(this.state.zoomlevel-1))
                const cmY = newframeY + cmnormY*(view?.previewheight as number)*(1.1**(this.state.zoomlevel-1))
                return {
                    ...l,
                    props: {
                        ...l.props,
                        shapeGrpAttr: { 
                            ...shapegrpAttr,
                            x: shapegrpX,
                            y: shapegrpY,
                            scaleX: shapegrpAttr.scaleX/1.1,
                            scaleY: shapegrpAttr.scaleY/1.1 
                        },
                        cropmaskAttr: { 
                            ...cropmaskAttr,
                            x: cmX,
                            y: cmY,
                            scaleX: cropmaskAttr.scaleX/1.1,
                            scaleY: cropmaskAttr.scaleY/1.1 
                        }
                    }
                }
            }
            return {
                ...l,
                props: {
                    ...l.props,
                    shapeGrpAttr: { 
                        ...shapegrpAttr,
                        x: shapegrpX,
                        y: shapegrpY,
                        scaleX: shapegrpAttr.scaleX/1.1,
                        scaleY: shapegrpAttr.scaleY/1.1 
                    }
                }
            }
        })
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: new_layers
            }:v
        )   
        this.setState({...this.state, views: update_views, zoomlevel: this.state.zoomlevel-1})
    }

    setTemplateItem(item: DesignTemplate) {
        this.setState({...this.state, dragdesignitem: { itemid: item.id, itemtype: 'designtpl', itemobj: item }})
    }

    setPhotoItem(item: YourPhoto) {
        this.setState({...this.state, dragdesignitem: { itemid: item.id, itemtype: 'yourphoto', itemobj: item }})
    }

    setIGPhotoItem(item: IGPhoto) {
        this.setState({...this.state, dragdesignitem: { itemid: item.id, itemtype: 'igphoto', itemobj: item }})
    }

    setGraphicItem(item: YourGraphic) {
        this.setState({...this.state, dragdesignitem: { itemid: item.id, itemtype: 'yourgraphic', itemobj: item }})
    }

    selectLayer(layerid: string) {
        var update_layers: any = null
        var tool = null
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        if (layerid !== null) {
            update_layers = view?.layers?.map((l: DesignLayer) =>
                l.props.type !== 'printframe-layer' && l.props.type !== 'mockup-layer' ? 
                    l.id === layerid ? { 
                        ...l,
                        props: {
                            ...l.props,
                            selected: true
                        }
                    }: {
                    ...l,
                    props: {
                        ...l.props,
                        selected: false
                    }
                }: l
            )
            // tool = 'transform'
        } else {
            update_layers = view?.layers?.map((l: DesignLayer) =>
                l.props.type !== 'printframe-layer' && l.props.type !== 'mockup-layer' ? 
                {
                    ...l,
                    props: {
                        ...l.props,
                        selected: false
                    }
                }: l
            )
        }
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers,
                curlayer: layerid,
                curtool: 'transform'
            }:v
        )
        this.setState({...this.state, views: update_views })
    }

    adjustOpacity(opacity: number) {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        const layer = view?.layers?.find((l: DesignLayer) => l.id === view.curlayer)

        // const props = {
        //     ...layer?.props,
        //     opacity: opacity
        // }
        
        const update_layers = view?.layers?.map((l: DesignLayer) =>
            l.id === layer?.id ? 
            {
                ...l,
                props: {
                    ...l.props,
                    previewlayerAttr: {
                        ...l.props.previewlayerAttr,
                        opacity: opacity
                    },
                    exportlayerAttr: {
                        ...l.props.exportlayerAttr,
                        opacity: opacity
                    }
                }
            }: l
        )
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers
            }:v
        )
        this.setState({...this.state, views: update_views})

    }

    transformShape(trX: number, trY: number, trSX: number, trSY: number, trRotation: number) {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        const layer = view?.layers?.find((l: DesignLayer) => l.id === view.curlayer)
        // const shapebox = layer?.props.shapeboxRef.current
        // //const shape = layer?.props.shapeRef.current
        
        // const shapeboxtr = shapebox.getTransform().copy()
        // //const shapetr = shape.getTransform().copy()
        
        // const shapeboxAttr = shapeboxtr.decompose()
       

        //get top-left coordinate of shapebox in cropbox
        const sbX = trX
        const sbY = trY
        //rotate shapebox point to original axis
        const sbX2 = sbX * Math.cos(-layer?.props.shapeGrpAttr.rotation*Math.PI/180) + sbY * Math.sin(-layer?.props.shapeGrpAttr.rotation*Math.PI/180)
        const sbY2 = -sbX * Math.sin(-layer?.props.shapeGrpAttr.rotation*Math.PI/180) + sbY * Math.cos(-layer?.props.shapeGrpAttr.rotation*Math.PI/180)
        //find norm
        let frameX = (this.state.canvaswidth - (view?.previewwidth as number)*(1.1**(this.state.zoomlevel as number)))/2
        let frameY = (this.state.canvasheight - (view?.previewheight as number)*(1.1**(this.state.zoomlevel as number)))/2
        let frameW = (view?.previewwidth as number)*(1.1**(this.state.zoomlevel as number))
        let frameH = (view?.previewheight as number)*(1.1**(this.state.zoomlevel as number))
        const normX = (layer?.props.shapeGrpAttr.x + sbX2*layer?.props.shapeGrpAttr.scaleX - frameX)/frameW
        const normY = (layer?.props.shapeGrpAttr.y + sbY2*layer?.props.shapeGrpAttr.scaleY - frameY)/frameH
        let props = {}
        if (layer?.props.type == 'photo-layer') {
            props = {
                ...layer?.props,
                shapeGrpAttr: { 
                    ...layer?.props.shapeGrpAttr,
                    x: layer?.props.shapeGrpAttr.x + sbX2*layer?.props.shapeGrpAttr.scaleX,
                    y: layer?.props.shapeGrpAttr.y + sbY2*layer?.props.shapeGrpAttr.scaleY,
                    scaleX: trSX*layer?.props.shapeGrpAttr.scaleX,
                    scaleY: trSY*layer?.props.shapeGrpAttr.scaleY,
                    rotation: layer?.props.shapeGrpAttr.rotation + trRotation
                },
                exportshapeGrpAttr: { 
                    ...layer?.props.exportshapeGrpAttr,
                    x: normX*(view?.exportwidth as number),
                    y: normY*(view?.exportheight as number),
                    scaleX: trSX*layer?.props.exportshapeGrpAttr.scaleX,
                    scaleY: trSY*layer?.props.exportshapeGrpAttr.scaleY,
                    rotation: layer?.props.exportshapeGrpAttr.rotation + trRotation 
                },
                cropmaskAttr: {
                    ...layer?.props.cropmaskAttr,
                    x: layer?.props.cropmaskAttr.x + sbX*layer?.props.cropmaskAttr.scaleX,
                    y: layer?.props.cropmaskAttr.y + sbY*layer?.props.cropmaskAttr.scaleY,
                    scaleX: layer?.props.cropmaskAttr.scaleX*trSX,
                    scaleY: layer?.props.cropmaskAttr.scaleY*trSY,
                    rotation: layer?.props.cropmaskAttr.rotation + trRotation
                }
            }
        } else {
            props = {
                ...layer?.props,
                shapeGrpAttr: { 
                    ...layer?.props.shapeGrpAttr,
                    x: layer?.props.shapeGrpAttr.x + sbX*layer?.props.shapeGrpAttr.scaleX,
                    y: layer?.props.shapeGrpAttr.y + sbY*layer?.props.shapeGrpAttr.scaleY,
                    scaleX: trSX*layer?.props.shapeGrpAttr.scaleX,
                    scaleY: trSY*layer?.props.shapeGrpAttr.scaleY,
                    rotation: layer?.props.shapeGrpAttr.rotation + trRotation
                },
                exportshapeGrpAttr: { 
                    ...layer?.props.exportshapeGrpAttr,
                    x: normX*(view?.exportwidth as number),
                    y: normY*(view?.exportheight as number),
                    scaleX: trSX*layer?.props.exportshapeGrpAttr.scaleX,
                    scaleY: trSY*layer?.props.exportshapeGrpAttr.scaleY,
                    rotation: layer?.props.exportshapeGrpAttr.rotation + trRotation 
                }
            }
        }
        
        layer?.propshistory.push(props)
        const update_layers = view?.layers?.map((l: DesignLayer) =>
            l.id === view.curlayer ? 
            {
                ...l,
                current: l.current as number + 1,
                propshistory: layer?.propshistory,
                props: props
            }: l
        )
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers
            }:v
        )
        this.setState({...this.state, views: update_views})
        

    }

    transformCropMask(trX: number, trY: number, trSX: number, trSY: number, trRotation: number) {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        const layer = view?.layers?.find((l: DesignLayer) => l.id === view.curlayer)
        //const cropmask = layer?.props.cropmaskRef.current
        const cropbox = layer?.props.shapeGrpRef.current
        const exportshapegrp = layer?.props.exportshapeGrpRef.current
        //const cropmasktr = cropmask.getTransform().copy()
        const cropboxtr = cropbox.getTransform().copy()
        const exportshapegrptr = exportshapegrp.getTransform().copy()
        //const cropmaskAttr = cropmasktr.decompose()
        const cropboxAttr = cropboxtr.decompose()
        const exportshapegrpAttr = exportshapegrptr.decompose()

        const x = cropboxAttr.x
        const y = cropboxAttr.y

        const x1 = trX
        const y1 = trY

        const x2 = (x1 - x)/(cropboxAttr.scaleX)
        const y2 = (y1 - y)/(cropboxAttr.scaleY)

        //rotate into cropbox axis
        const rx2 = x2 * Math.cos(cropboxAttr.rotation*Math.PI/180) + y2 * Math.sin(cropboxAttr.rotation*Math.PI/180)
        const ry2 = -x2 * Math.sin(cropboxAttr.rotation*Math.PI/180) + y2 * Math.cos(cropboxAttr.rotation*Math.PI/180)

        //find norm
        let frameW = (view?.previewwidth as number)*(1.1**(this.state.zoomlevel as number))
        let frameH = (view?.previewheight as number)*(1.1**(this.state.zoomlevel as number))
        const normX = (x1 - x)/frameW
        const normY = (y1 - y)/frameH
        const rnormX = normX * Math.cos(layer?.props.exportshapeGrpAttr.rotation*Math.PI/180) + normY * Math.sin(layer?.props.exportshapeGrpAttr.rotation*Math.PI/180)
        const rnormY = -normX * Math.sin(layer?.props.exportshapeGrpAttr.rotation*Math.PI/180) + normY * Math.cos(layer?.props.exportshapeGrpAttr.rotation*Math.PI/180)
        

        const update_layers = view?.layers?.map((l: DesignLayer) =>
            l.id === view.curlayer ? 
            {
                ...l,
                props: {
                    ...l.props,
                    shapeAttr: { 
                        ...layer?.props.shapeAttr,
                        x: rx2,
                        y: ry2,
                        // width: layer?.props.previewW, //*cropboxAttr.scaleX/cropmaskAttr.scaleX,
                        // height: layer?.props.previewH, //*cropboxAttr.scaleY/cropmaskAttr.scaleY,
                        scaleX: trSX/cropboxAttr.scaleX,
                        scaleY: trSY/cropboxAttr.scaleY,
                        rotation: trRotation-cropboxAttr.rotation,
                        // crop: {
                        //     ...layer?.props.shapeAttr.crop,
                        //     // x: cropX*cropboxAttr.scaleX/cropmaskAttr.scaleX,
                        //     // y: cropY*cropboxAttr.scaleY/cropmaskAttr.scaleY,
                        //     // width: (layer?.props.previewW)*cropboxAttr.scaleX/cropmaskAttr.scaleX,
                        //     // height: (layer?.props.previewH)*cropboxAttr.scaleY/cropmaskAttr.scaleY,
                        // }
                    },
                    exportshapeAttr: { 
                        ...layer?.props.exportshapeAttr,
                        x: rnormX*(view?.exportwidth as number)/layer?.props.exportshapeGrpAttr.scaleX,
                        y: rnormY*(view?.exportheight as number)/layer?.props.exportshapeGrpAttr.scaleY,
                        scaleX: trSX/cropboxAttr.scaleX,
                        scaleY: trSY/cropboxAttr.scaleY,
                        rotation: trRotation-cropboxAttr.rotation,
                        // crop: {
                        //     ...layer?.props.exportshapeAttr.crop,
                        //     x: export_cropX,
                        //     y: export_cropY
                        // }
                    },
                    cropmaskAttr: {
                        ...layer?.props.cropmaskAttr,
                        x: trX,
                        y: trY,
                        scaleX: trSX,
                        scaleY: trSY,
                        rotation: trRotation
                    }
                }
            }: l
        )

        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers
            }:v
        )
        this.setState({...this.state, views: update_views})
        

    }

    transformCropBox(trX: number, trY: number, trSX: number, trSY: number, trRotation: number) {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        const layer = view?.layers?.find((l: DesignLayer) => l.id === view.curlayer)
        //const shapebox = layer?.props.shapeboxRef.current
        const shape = layer?.props.shapeRef.current
        
        //const shapeboxtr = shapebox.getTransform().copy()
        const shapetr = shape.getTransform().copy()
        
        //const shapeboxAttr = shapeboxtr.decompose()
        const shapeAttr = shapetr.decompose()

        //get top-left coordinate of shapebox in cropbox
        const sbX = trX
        const sbY = trY
        //get top-left coordinate of shape in cropbox
        const sX = shapeAttr.x
        const sY = shapeAttr.y
        //translate top-left coordinate of shape to top-left coordinate of shapebox
        const sX1 = (sX - sbX)
        const sY1 = (sY - sbY)
        //rotate shapebox axis
        const sX2 = sX1 * Math.cos(trRotation*Math.PI/180) + sY1 * Math.sin(trRotation*Math.PI/180)
        const sY2 = -sX1 * Math.sin(trRotation*Math.PI/180) + sY1 * Math.cos(trRotation*Math.PI/180)

        //rotate shapebox point to original axis
        const sbX2 = sbX * Math.cos(-layer?.props.shapeGrpAttr.rotation*Math.PI/180) + sbY * Math.sin(-layer?.props.shapeGrpAttr.rotation*Math.PI/180)
        const sbY2 = -sbX * Math.sin(-layer?.props.shapeGrpAttr.rotation*Math.PI/180) + sbY * Math.cos(-layer?.props.shapeGrpAttr.rotation*Math.PI/180)

        //find norm
        let frameX = (this.state.canvaswidth - (view?.previewwidth as number)*(1.1**(this.state.zoomlevel as number)))/2
        let frameY = (this.state.canvasheight - (view?.previewheight as number)*(1.1**(this.state.zoomlevel as number)))/2
        let frameW = (view?.previewwidth as number)*(1.1**(this.state.zoomlevel as number))
        let frameH = (view?.previewheight as number)*(1.1**(this.state.zoomlevel as number))
        const grpnormX = (layer?.props.shapeGrpAttr.x + sbX2*layer?.props.shapeGrpAttr.scaleX - frameX)/frameW
        const grpnormY = (layer?.props.shapeGrpAttr.y + sbY2*layer?.props.shapeGrpAttr.scaleY - frameY)/frameH

        //rotate export shapebox
        const esbX = layer?.props.exportshapeAttr.x - sbX*layer?.props.exportshapeAttr.width/layer?.props.shapeAttr.width
        const esbY = layer?.props.exportshapeAttr.y - sbY*layer?.props.exportshapeAttr.height/layer?.props.shapeAttr.height
        const esbX2 = esbX * Math.cos(trRotation*Math.PI/180) + esbY * Math.sin(trRotation*Math.PI/180)
        const esbY2 = -esbX * Math.sin(trRotation*Math.PI/180) + esbY * Math.cos(trRotation*Math.PI/180)

        const update_layers = view?.layers?.map((l: DesignLayer) =>
            l.id === view.curlayer ? 
            {
                ...l,
                props: {
                    ...l.props,
                    shapeGrpAttr: { 
                        ...layer?.props.shapeGrpAttr,
                        x: layer?.props.shapeGrpAttr.x + sbX2*layer?.props.shapeGrpAttr.scaleX,
                        y: layer?.props.shapeGrpAttr.y + sbY2*layer?.props.shapeGrpAttr.scaleY,
                        scaleX: trSX*layer?.props.shapeGrpAttr.scaleX,
                        scaleY: trSY*layer?.props.shapeGrpAttr.scaleY,
                        rotation: layer?.props.shapeGrpAttr.rotation + trRotation
                    },
                    shapeAttr: {
                        ...layer?.props.shapeAttr,
                        x: sX2/trSX,
                        y: sY2/trSY,
                        scaleX: layer?.props.shapeAttr.scaleX/trSX,
                        scaleY: layer?.props.shapeAttr.scaleY/trSY,
                        rotation: layer?.props.shapeAttr.rotation - trRotation
                    },
                    exportshapeGrpAttr: { 
                        ...layer?.props.exportshapeGrpAttr,
                        x: grpnormX*(view?.exportwidth as number),
                        y: grpnormY*(view?.exportheight as number),
                        scaleX: trSX*layer?.props.exportshapeGrpAttr.scaleX,
                        scaleY: trSY*layer?.props.exportshapeGrpAttr.scaleY,
                        rotation: layer?.props.exportshapeGrpAttr.rotation + trRotation
                    },
                    exportshapeAttr: {
                        ...layer?.props.exportshapeAttr,
                        x: esbX2/trSX,
                        y: esbY2/trSY,
                        scaleX: layer?.props.exportshapeAttr.scaleX/trSX,
                        scaleY: layer?.props.exportshapeAttr.scaleY/trSY,
                        rotation: layer?.props.exportshapeAttr.rotation - trRotation
                    }
                    // normX: normX,
                    // normY: normY,
                    // normW: normW,
                    // normH: normH,
                    // exportshapeGrpAttr: { 
                    //     ...exportgrpAttr,
                    //     clipX: exportshapegrp.attrs.clipX,
                    //     clipY: exportshapegrp.attrs.clipY,
                    //     clipWidth: exportshapegrp.attrs.clipWidth,
                    //     clipHeight: exportshapegrp.attrs.clipHeight 
                    // },
                    // exportshapeAttr: { 
                    //     ...exportshapeAttr,
                    //     crop: {
                    //         ...layer?.props.exportshapeAttr.crop,
                    //         x: export_cropX,
                    //         y: export_cropY
                    //     }
                    // }
                }
            }: l
        )
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers
            }:v
        )
        this.setState({...this.state, views: update_views})
        

    }

    flipHorizontal() {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        const layer = view?.layers?.find((l: DesignLayer) => l.id === view.curlayer)
        let props = {}
        if (layer?.props.type === 'photo-layer') {
            props = {
                ...layer?.props,
                shapeGrpAttr: { 
                    ...layer?.props.shapeGrpAttr,
                    x: layer?.props.shapeGrpAttr.x+layer?.props.shapeGrpAttr.width*layer?.props.shapeGrpAttr.scaleX,
                    scaleX: -layer?.props.shapeGrpAttr.scaleX,
                },

                exportshapeGrpAttr: { 
                    ...layer?.props.exportshapeGrpAttr,
                    x: layer?.props.exportshapeGrpAttr.x+layer?.props.exportshapeGrpAttr.width*layer?.props.exportshapeGrpAttr.scaleX,
                    scaleX: -layer?.props.exportshapeGrpAttr.scaleX,
                },
                cropmaskAttr: {
                    ...layer?.props.cropmaskAttr,
                    x: layer?.props.cropmaskAttr.x + layer?.props.cropmaskAttr.width*layer?.props.cropmaskAttr.scaleX,
                    scaleX: -layer?.props.cropmaskAttr.scaleY,
                }
            
            }
        } else {
            props = {
                ...layer?.props,
                shapeGrpAttr: { 
                    ...layer?.props.shapeGrpAttr,
                    x: layer?.props.shapeGrpAttr.x+layer?.props.shapeGrpAttr.width*layer?.props.shapeGrpAttr.scaleX,
                    scaleX: -layer?.props.shapeGrpAttr.scaleX,
                },

                exportshapeGrpAttr: { 
                    ...layer?.props.exportshapeGrpAttr,
                    x: layer?.props.exportshapeGrpAttr.x+layer?.props.exportshapeGrpAttr.width*layer?.props.exportshapeGrpAttr.scaleX,
                    scaleX: -layer?.props.exportshapeGrpAttr.scaleX,
                },
            
            }
        }
        
        layer?.propshistory.push(props)
        const update_layers = view?.layers?.map((l: DesignLayer) =>
            l.id === view.curlayer ? 
            {
                ...l,
                current: l.current as number + 1,
                propshistory: layer?.propshistory,    
                props: props
            }: l
        )
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers
            }:v
        )
        this.setState({...this.state, views: update_views})
    }

    flipVertical() {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        const layer = view?.layers?.find((l: DesignLayer) => l.id === view.curlayer)
        let props = {}
        if (layer?.props.type === 'photo-layer') {
            props = {
                ...layer?.props,
                shapeGrpAttr: { 
                    ...layer?.props.shapeGrpAttr,
                    y: layer?.props.shapeGrpAttr.y+layer?.props.shapeGrpAttr.height*layer?.props.shapeGrpAttr.scaleY,
                    scaleY: -layer?.props.shapeGrpAttr.scaleY,
                },

                exportshapeGrpAttr: { 
                    ...layer?.props.exportshapeGrpAttr,
                    y: layer?.props.exportshapeGrpAttr.y+layer?.props.exportshapeGrpAttr.height*layer?.props.exportshapeGrpAttr.scaleY,
                    scaleY: -layer?.props.exportshapeGrpAttr.scaleY,
                },
                cropmaskAttr: {
                    ...layer?.props.cropmaskAttr,
                    y: layer?.props.cropmaskAttr.y + layer?.props.cropmaskAttr.height*layer?.props.cropmaskAttr.scaleY,
                    scaleY: -layer?.props.cropmaskAttr.scaleY,
                }
            
            }
        } else {
            props = {
                ...layer?.props,
                shapeGrpAttr: { 
                    ...layer?.props.shapeGrpAttr,
                    y: layer?.props.shapeGrpAttr.y+layer?.props.shapeGrpAttr.height*layer?.props.shapeGrpAttr.scaleY,
                    scaleY: -layer?.props.shapeGrpAttr.scaleY,
                },

                exportshapeGrpAttr: { 
                    ...layer?.props.exportshapeGrpAttr,
                    y: layer?.props.exportshapeGrpAttr.y+layer?.props.exportshapeGrpAttr.height*layer?.props.exportshapeGrpAttr.scaleY,
                    scaleY: -layer?.props.exportshapeGrpAttr.scaleY,
                },
            
            }
        }
        
        layer?.propshistory.push(props)
        const update_layers = view?.layers?.map((l: DesignLayer) =>
            l.id === view.curlayer ? 
            {
                ...l,
                current: l.current as number + 1,
                propshistory: layer?.propshistory,    
                props: props
            }: l
        )
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers
            }:v
        )
        this.setState({...this.state, views: update_views})
    }

    expandKeepAspect(layerid: string) {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        const layer = view?.layers?.find((l: DesignLayer) => l.id === view.curlayer)
        const actualW = layer?.props.actualW
        const actualH = layer?.props.actualH
        const previewW = layer?.props.previewW
        const previewH = layer?.props.previewH
        const cX = this.state.canvaswidth/2
        const cY = this.state.canvasheight/2
        const scale = (previewW >= previewH ? (view?.previewwidth as number)/previewW:(view?.previewheight as number)/previewH)*(1.1**this.state.zoomlevel)
        const shapegrpW = previewW*scale
        const shapegrpH = previewH*scale
        const shapegrpX = cX - shapegrpW/2
        const shapegrpY = cY - shapegrpH/2 
        const exportscale = (actualW >= actualH ? (view?.exportwidth as number)/actualW:(view?.exportheight as number)/actualH)
        const exportgrpW = actualW*scale
        const exportgrpH = actualH*scale
        const exportgrpX = (view?.exportwidth as number)/2 - exportgrpW/2
        const exportgrpY = (view?.exportheight as number)/2 - exportgrpH/2

        const props = {
            ...layer?.props,
            shapeGrpAttr: { 
                ...layer?.props.shapeGrpAttr,
                x: shapegrpX,
                y: shapegrpY,
                scaleX: scale,
                scaleY: scale,
                rotation: 0
            },
            exportshapeGrpAttr: { 
                ...layer?.props.exportshapeGrpAttr,
                x: exportgrpX,
                y: exportgrpY,
                scaleX: exportscale,
                scaleY: exportscale,
                rotation: 0 
            },
            cropmaskAttr: {
                ...layer?.props.cropmaskAttr,
                x: layer?.props.cropmaskAttr.x + (shapegrpX-layer?.props.shapeGrpAttr.x)*layer?.props.cropmaskAttr.scaleX/layer?.props.shapeGrpAttr.scaleX,
                y: layer?.props.cropmaskAttr.y + (shapegrpY-layer?.props.shapeGrpAttr.y)*layer?.props.cropmaskAttr.scaleY/layer?.props.shapeGrpAttr.scaleY,
                scaleX: layer?.props.cropmaskAttr.scaleX*scale/layer?.props.shapeGrpAttr.scaleX,
                scaleY: layer?.props.cropmaskAttr.scaleY*scale/layer?.props.shapeGrpAttr.scaleY,
                rotation: layer?.props.cropmaskAttr.rotation - layer?.props.shapeGrpAttr.rotation
            }
        }
        layer?.propshistory.push(props)
        const update_layers = view?.layers?.map((l: DesignLayer) =>
            l.id === layerid ? 
            {
                ...l,
                current: l.current as number + 1,
                propshistory: layer?.propshistory,
                props: props
            }: l
        )
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers
            }:v
        )
        this.setState({...this.state, views: update_views})

    }

    moveShape(targetX: number, targetY: number) {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        const layer = view?.layers?.find((l: DesignLayer) => l.id === view.curlayer)
        const shapebox = layer?.props.shapeGrpRef.current
        
        const shapeboxtr = shapebox.getTransform().copy()
        
        const shapeboxAttr = shapeboxtr.decompose()

        //get top-left coordinate of shapebox in cropbox
        const sbX = targetX
        const sbY = targetY
        //find norm
        let frameX = (this.state.canvaswidth - (view?.previewwidth as number)*(1.1**(this.state.zoomlevel as number)))/2
        let frameY = (this.state.canvasheight - (view?.previewheight as number)*(1.1**(this.state.zoomlevel as number)))/2
        let frameW = (view?.previewwidth as number)*(1.1**(this.state.zoomlevel as number))
        let frameH = (view?.previewheight as number)*(1.1**(this.state.zoomlevel as number))
        const normX = (layer?.props.shapeGrpAttr.x + sbX*layer?.props.shapeGrpAttr.scaleX - frameX)/frameW
        const normY = (layer?.props.shapeGrpAttr.y + sbY*layer?.props.shapeGrpAttr.scaleY - frameY)/frameH
        let props = {}
        if (layer?.props.type === 'photo-layer') {
            props = {
                ...layer?.props,
                shapeGrpAttr: { 
                    ...layer?.props.shapeGrpAttr,
                    x: layer?.props.shapeGrpAttr.x + sbX*layer?.props.shapeGrpAttr.scaleX,
                    y: layer?.props.shapeGrpAttr.y + sbY*layer?.props.shapeGrpAttr.scaleY,
                },

                exportshapeGrpAttr: { 
                    ...layer?.props.exportshapeGrpAttr,
                    x: normX*(view?.exportwidth as number),
                    y: normY*(view?.exportheight as number),
                },
                cropmaskAttr: {
                    ...layer?.props.cropmaskAttr,
                    x: layer?.props.cropmaskAttr.x + sbX*layer?.props.cropmaskAttr.scaleX,
                    y: layer?.props.cropmaskAttr.y + sbY*layer?.props.cropmaskAttr.scaleY,
                }
            
            }
        } else {
            props = {
                ...layer?.props,
                shapeGrpAttr: { 
                    ...layer?.props.shapeGrpAttr,
                    x: layer?.props.shapeGrpAttr.x + sbX*layer?.props.shapeGrpAttr.scaleX,
                    y: layer?.props.shapeGrpAttr.y + sbY*layer?.props.shapeGrpAttr.scaleY,
                },
                exportshapeGrpAttr: { 
                    ...layer?.props.exportshapeGrpAttr,
                    x: normX*(view?.exportwidth as number),
                    y: normY*(view?.exportheight as number),
                }
            
            }
        }
        
        layer?.propshistory.push(props)
        const update_layers = view?.layers?.map((l: DesignLayer) =>
            l.id === view.curlayer ? 
            {
                ...l,
                current: l.current as number + 1,
                propshistory: layer?.propshistory,    
                props: props
            }: l
        )
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers
            }:v
        )
        this.setState({...this.state, views: update_views})
    }

    moveCropMask(targetX: number, targetY: number) {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        const layer = view?.layers?.find((l: DesignLayer) => l.id === view.curlayer)
        const cropbox = layer?.props.shapeGrpRef.current
        const cropboxtr = cropbox.getTransform().copy()
        const cropboxAttr = cropboxtr.decompose()
        // const cropmask = layer?.props.cropmaskRef.current
        // const cropmasktr = cropmask.getTransform().copy()
        // const cropmaskAttr = cropmasktr.decompose()

        const x = cropboxAttr.x
        const y = cropboxAttr.y

        const x1 = targetX
        const y1 = targetY

        const x2 = (x1 - x)/(cropboxAttr.scaleX)
        const y2 = (y1 - y)/(cropboxAttr.scaleY)

        //rotate into cropbox axis
        const rx2 = x2 * Math.cos(cropboxAttr.rotation*Math.PI/180) + y2 * Math.sin(cropboxAttr.rotation*Math.PI/180)
        const ry2 = -x2 * Math.sin(cropboxAttr.rotation*Math.PI/180) + y2 * Math.cos(cropboxAttr.rotation*Math.PI/180)

        //find norm
        let frameW = (view?.previewwidth as number)*(1.1**(this.state.zoomlevel as number))
        let frameH = (view?.previewheight as number)*(1.1**(this.state.zoomlevel as number))
        const normX = (x1 - x)/frameW
        const normY = (y1 - y)/frameH
        const rnormX = normX * Math.cos(layer?.props.exportshapeGrpAttr.rotation*Math.PI/180) + normY * Math.sin(layer?.props.exportshapeGrpAttr.rotation*Math.PI/180)
        const rnormY = -normX * Math.sin(layer?.props.exportshapeGrpAttr.rotation*Math.PI/180) + normY * Math.cos(layer?.props.exportshapeGrpAttr.rotation*Math.PI/180)
        

        const update_layers = view?.layers?.map((l: DesignLayer) =>
            l.id === view.curlayer ? 
            {
                ...l,
                props: {
                    ...l.props,
                    shapeAttr: { 
                        ...layer?.props.shapeAttr,
                        x: rx2,
                        y: ry2
                        
                    },
                    exportshapeAttr: { 
                        ...layer?.props.exportshapeAttr,
                        x: rnormX*(view?.exportwidth as number)/layer?.props.exportshapeGrpAttr.scaleX,
                        y: rnormY*(view?.exportheight as number)/layer?.props.exportshapeGrpAttr.scaleY
                    },
                    cropmaskAttr: {
                        ...layer?.props.cropmaskAttr,
                        x: targetX,
                        y: targetY
                    }
                
                }
            }: l
        )
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers
            }:v
        )
        this.setState({...this.state, views: update_views})
    }

    moveCropBox(targetX: number, targetY: number) {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        const layer = view?.layers?.find((l: DesignLayer) => l.id === view.curlayer)
        const shapebox = layer?.props.shapeboxRef.current
        const shape = layer?.props.shapeRef.current
        
        const shapeboxtr = shapebox.getTransform().copy()
        const shapetr = shape.getTransform().copy()
        
        const shapeboxAttr = shapeboxtr.decompose()
        const shapeAttr = shapetr.decompose()

        //get top-left coordinate of shapebox in cropbox
        const sbX = targetX
        const sbY = targetY
        //get top-left coordinate of shape in cropbox
        const sX = shapeAttr.x
        const sY = shapeAttr.y
        //translate top-left coordinate of shape to top-left coordinate of shapebox
        const sX1 = (sX - sbX)
        const sY1 = (sY - sbY)
        //rotate shapebox axis
        const sX2 = sX1 * Math.cos(shapeboxAttr.rotation*Math.PI/180) + sY1 * Math.sin(shapeboxAttr.rotation*Math.PI/180)
        const sY2 = -sX1 * Math.sin(shapeboxAttr.rotation*Math.PI/180) + sY1 * Math.cos(shapeboxAttr.rotation*Math.PI/180)
 
        //rotate shapebox point to original axis
        const sbX2 = sbX * Math.cos(-layer?.props.shapeGrpAttr.rotation*Math.PI/180) + sbY * Math.sin(-layer?.props.shapeGrpAttr.rotation*Math.PI/180)
        const sbY2 = -sbX * Math.sin(-layer?.props.shapeGrpAttr.rotation*Math.PI/180) + sbY * Math.cos(-layer?.props.shapeGrpAttr.rotation*Math.PI/180)

        //find norm
        let frameX = (this.state.canvaswidth - (view?.previewwidth as number)*(1.1**(this.state.zoomlevel as number)))/2
        let frameY = (this.state.canvasheight - (view?.previewheight as number)*(1.1**(this.state.zoomlevel as number)))/2
        let frameW = (view?.previewwidth as number)*(1.1**(this.state.zoomlevel as number))
        let frameH = (view?.previewheight as number)*(1.1**(this.state.zoomlevel as number))
        
        const grpnormX = (layer?.props.shapeGrpAttr.x + sbX2*layer?.props.shapeGrpAttr.scaleX - frameX)/frameW
        const grpnormY = (layer?.props.shapeGrpAttr.y + sbY2*layer?.props.shapeGrpAttr.scaleY - frameY)/frameH

        // //rotate export shapebox
        // const esbX = layer?.props.exportshapeAttr.x - sbX*layer?.props.exportshapeAttr.width/layer?.props.shapeAttr.width
        // const esbY = layer?.props.exportshapeAttr.y - sbY*layer?.props.exportshapeAttr.height/layer?.props.shapeAttr.height
        // const esbX2 = esbX * Math.cos(shapeboxAttr.rotation*Math.PI/180) + esbY * Math.sin(shapeboxAttr.rotation*Math.PI/180)
        // const esbY2 = -esbX * Math.sin(shapeboxAttr.rotation*Math.PI/180) + esbY * Math.cos(shapeboxAttr.rotation*Math.PI/180)

        const update_layers = view?.layers?.map((l: DesignLayer) =>
            l.id === view.curlayer ? 
            {
                ...l,
                props: {
                    ...l.props,
                    shapeGrpAttr: { 
                        ...layer?.props.shapeGrpAttr,
                        x: layer?.props.shapeGrpAttr.x + sbX2*layer?.props.shapeGrpAttr.scaleX,
                        y: layer?.props.shapeGrpAttr.y + sbY2*layer?.props.shapeGrpAttr.scaleY 
                    },
                    shapeAttr: { 
                        ...layer?.props.shapeAttr,
                        x: sX2/shapeboxAttr.scaleX,
                        y: sY2/shapeboxAttr.scaleY
                    },
                    exportshapeAttr: { 
                        ...layer?.props.exportshapeAttr,
                        x: layer?.props.exportshapeAttr.x - targetX*layer?.props.exportshapeAttr.width/layer?.props.shapeAttr.width,
                        y: layer?.props.exportshapeAttr.y - targetY*layer?.props.exportshapeAttr.height/layer?.props.shapeAttr.height
                    },
                    exportshapeGrpAttr: { 
                        ...layer?.props.exportshapeGrpAttr,
                        x: grpnormX*(view?.exportwidth as number),
                        y: grpnormY*(view?.exportheight as number)
                    }
                    
                
                }
            }: l
        )
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers
            }:v
        )
        this.setState({...this.state, views: update_views})
    }

    moveCenter(layerid: string) {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        const layer = view?.layers?.find((l: DesignLayer) => l.id === view.curlayer)
        
        const shapegrpW = layer?.props.shapeGrpAttr.width * layer?.props.shapeGrpAttr.scaleX
        const shapegrpH = layer?.props.shapeGrpAttr.height * layer?.props.shapeGrpAttr.scaleY
        
        // find center of shapegrp
        const shapegrpCX = layer?.props.shapeGrpAttr.x + (shapegrpW/2)*Math.cos(layer?.props.shapeGrpAttr.rotation*Math.PI/180) - (shapegrpH/2)*Math.sin(layer?.props.shapeGrpAttr.rotation*Math.PI/180) 
        const shapegrpCY = layer?.props.shapeGrpAttr.y + (shapegrpW/2)*Math.sin(layer?.props.shapeGrpAttr.rotation*Math.PI/180) + (shapegrpH/2)*Math.cos(layer?.props.shapeGrpAttr.rotation*Math.PI/180)
        const cvCX = this.state.canvaswidth/2
        const cvCY = this.state.canvasheight/2
        // translate center of shapegrp to canvas center
        const transX = (cvCX-shapegrpCX)/layer?.props.shapeGrpAttr.scaleX
        const transY = (cvCY-shapegrpCY)/layer?.props.shapeGrpAttr.scaleY

        let props = {}
        if (layer?.props.type === 'photo-layer') {
            props = {
                ...layer?.props,
                shapeGrpAttr: { 
                    ...layer?.props.shapeGrpAttr,
                    x: layer?.props.shapeGrpAttr.x + transX*layer?.props.shapeGrpAttr.scaleX,
                    y: layer?.props.shapeGrpAttr.y + transY*layer?.props.shapeGrpAttr.scaleY,
                },
                exportshapeGrpAttr: { 
                    ...layer?.props.exportshapeGrpAttr,
                    x: layer?.props.exportshapeGrpAttr.x + transX*layer?.props.exportshapeGrpAttr.scaleX,
                    y: layer?.props.exportshapeGrpAttr.y + transY*layer?.props.exportshapeGrpAttr.scaleY,
                },
                cropmaskAttr: {
                    ...layer?.props.cropmaskAttr,
                    x: layer?.props.cropmaskAttr.x + transX*layer?.props.cropmaskAttr.scaleX,
                    y: layer?.props.cropmaskAttr.y + transY*layer?.props.cropmaskAttr.scaleY,
                }
            
            }
        } else {
            props = {
                ...layer?.props,
                shapeGrpAttr: { 
                    ...layer?.props.shapeGrpAttr,
                    x: layer?.props.shapeGrpAttr.x + transX*layer?.props.shapeGrpAttr.scaleX,
                    y: layer?.props.shapeGrpAttr.y + transY*layer?.props.shapeGrpAttr.scaleY,
                },
                exportshapeGrpAttr: { 
                    ...layer?.props.exportshapeGrpAttr,
                    x: layer?.props.exportshapeGrpAttr.x + transX*layer?.props.exportshapeGrpAttr.scaleX,
                    y: layer?.props.exportshapeGrpAttr.y + transY*layer?.props.exportshapeGrpAttr.scaleY,
                },
            
            }
        }
        layer?.propshistory.push(props)
        const update_layers = view?.layers?.map((l: DesignLayer) =>
            l.id === layerid ? 
            {
                ...l,
                current: l.current as number + 1,
                propshistory: layer?.propshistory,    
                props: props
            }: l
        )
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers
            }:v
        )
        this.setState({...this.state, views: update_views})
    }

    selecttool(tool: string) {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                curtool: tool
            }:v
        )
        this.setState({...this.state, views: update_views })
    }

    applyChange() {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        const layer = view?.layers?.find((l: DesignLayer) => l.id === view.curlayer)
        
        layer?.propshistory.push(layer?.props)
        const update_layers = view?.layers?.map((l: DesignLayer) =>
            l.id === layer?.id ? 
            {
                ...l,
                current: l.current as number + 1,
                propshistory: layer?.propshistory
            }:l
        )
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers,
                curtool: 'transform'
            }:v
        )
        this.setState({...this.state, views: update_views})
    }

    resetChange() {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        const layer = view?.layers?.find((l: DesignLayer) => l.id === view.curlayer)
        
        const update_layers = view?.layers?.map((l: DesignLayer) =>
            l.id === layer?.id ? 
            {
                ...l,
                props: l.propshistory[l.current as number]
            }:l
        )
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers,
                curtool: 'transform'
            }:v
        )
        this.setState({...this.state, views: update_views})
    }

    

    undo() {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        const layer = view?.layers?.find((l: DesignLayer) => l.id === view.curlayer)
        
        const update_layers = view?.layers?.map((l: DesignLayer) =>
            l.id === layer?.id ? 
            {
                ...l,
                current: l.current as number - 1,
                props: l.propshistory[l.current as number - 1]
            }:l
        )
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers
            }:v
        )
        this.setState({...this.state, views: update_views})
    }

    redo() {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        const layer = view?.layers?.find((l: DesignLayer) => l.id === view.curlayer)
        
        const update_layers = view?.layers?.map((l: DesignLayer) =>
            l.id === layer?.id ? 
            {
                ...l,
                current: l.current as number + 1,
                props: l.propshistory[l.current as number + 1]
            }:l
        )
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers
            }:v
        )
        this.setState({...this.state, views: update_views})
    }

    bringToFront() {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        const layer = view?.layers?.find((l: DesignLayer) => l.id === view.curlayer)
        

        const update_layers = view?.layers?.map((l: DesignLayer) =>
            l.id === layer?.id ? 
            {
                ...l,
                // current: l.current as number + 1,
                // propshistory: layer?.propshistory.push({ 
                //     ...l.props,
                //     idx: (view?.layers?.length as number)-1
                // }),
                props: {
                    ...l.props,
                    idx: (view?.layers?.length as number)-1
                }
            }:
            l.props.idx > (layer?.props.idx as number) && l.props.idx > 1 ?
            {
                ...l,
                // current: l.current as number + 1,
                // propshistory: layer?.propshistory.push({ 
                //     ...l.props,
                //     idx: l.props.idx - 1
                // }),
                props: {
                    ...l.props,
                    idx: l.props.idx - 1
                }
            }:l
        )

        update_layers?.sort((a,b) => a.props.idx - b.props.idx)

        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers
            }:v
        )
        this.setState({...this.state, views: update_views})
    }

    bringToBack() {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        const layer = view?.layers?.find((l: DesignLayer) => l.id === view.curlayer)
        
        const update_layers = view?.layers?.map((l: DesignLayer) =>
            l.id === layer?.id ? 
            {
                ...l,
                // current: l.current as number + 1,
                // propshistory: layer?.propshistory.push({ 
                //     ...l.props,
                //     idx: 2
                // }),
                props: {
                    ...l.props,
                    idx: 2
                }
            }:
            l.props.idx < (layer?.props.idx as number) && l.props.idx > 1 ?
            {
                ...l,
                // current: l.current as number + 1,
                // propshistory: layer?.propshistory.push({ 
                //     ...l.props,
                //     idx: l.props.idx + 1
                // }),
                props: {
                    ...l.props,
                    idx: l.props.idx + 1
                }
            }:l
        )

        update_layers?.sort((a,b) => a.props.idx - b.props.idx)
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers
            }:v
        )
        this.setState({...this.state, views: update_views})
    }

    updateLayers(layers: DesignLayerListItem[]) {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        const newlayers: DesignLayer[] | undefined = view?.layers?.slice(0,2)
        let layerIdx = 1
        layers.forEach((layer: DesignLayerListItem) => {
            const foundlayer = view?.layers?.find((l:DesignLayer) => l.id === layer.id)
            if (foundlayer) {
                layerIdx = layerIdx + 1
                newlayers?.push({
                    ...foundlayer,
                    props: {
                        ...foundlayer.props,
                        idx: layerIdx
                    }
                })
            }
        })

        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: newlayers
            }:v
        )
        this.setState({...this.state, views: update_views})
    }

    removeLayer() {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        const layer = view?.layers?.find((l: DesignLayer) => l.id === view.curlayer)
        if (layer !== undefined) {
            const layerIdx = view?.layers?.indexOf(layer) as number
            if (layerIdx > -1) {
                view?.layers?.splice(layerIdx,1)
            }
        }
        const cnt_designlayers = view?.layers?.filter((l: DesignLayer) => {
            return l.props.type !== 'printframe-layer' && l.props.type !== 'mockup-layer'
        }).length
        let customizeditems: CartCustomizedItem[] | undefined = []
        if (this.state.cartitem?.customizeditems) {
            customizeditems = [...this.state.cartitem.customizeditems]
        }
        const update_layers = view?.layers?.map((l: DesignLayer) =>
            l.props.type === 'printframe-layer' ? {
                ...l,
                props: {
                    ...l.props,
                    showDefaultText: cnt_designlayers !== undefined && cnt_designlayers > 0 ? false:true
                }
            }: l.props.type !== 'mockup-layer' ? {
                ...l,
                selected: false
            }: l
        )

        customizeditems = customizeditems?.filter((it: CartCustomizedItem) => it.item_id != layer?.id)

        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers,
                curlayer: null
            }:v
        )
        if (this.state.cartitem) {
            this.setState({
                ...this.state, 
                views: update_views,
                cartitem: {
                    ...this.state.cartitem,
                    customizeditems: customizeditems
                }
            })
        } else {
            this.setState({
                ...this.state, 
                views: update_views
            })
        }
    }

    removeAllLayers() {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        let update_layers: DesignLayer[] = []
        let customizeditems: CartCustomizedItem[] | undefined = []
        if (this.state.cartitem?.customizeditems) {
            customizeditems = [...this.state.cartitem.customizeditems]
        }
        view?.layers?.forEach((l: DesignLayer) => {
            if (l.props.type === 'printframe-layer') {
                update_layers.push({
                    ...l,
                    props: {
                        ...l.props,
                        showDefaultText: true
                    }
                })
            }
            if (l.props.type === 'mockup-layer') {
                update_layers.push(l)
            }
            
        })
        customizeditems = customizeditems?.filter((it: CartCustomizedItem) => it.position != this.state.curview)
        
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers,
                curlayer: null,
                curtool: null
            }:v
        )
        if (this.state.cartitem) {
            this.setState({
                ...this.state, 
                views: update_views,
                cartitem: {
                    ...this.state.cartitem,
                    customizeditems: customizeditems
                }
            })
        } else {
            this.setState({
                ...this.state, 
                views: update_views
            })
        }

    }

    insertProps(newprops: any) {
        const view = this.state.views?.find((v: DesignView) => v.view===this.state.curview)
        const layer = view?.layers?.find((l: DesignLayer) => l.id === view.curlayer)
        
        layer?.propshistory.push(newprops)
        const update_layers = view?.layers?.map((l: DesignLayer) =>
            l.id === layer?.id ? 
            {
                ...l,
                current: l.current as number + 1,
                props: newprops,
                propshistory: layer?.propshistory
            }:l
        )
        const update_views = this.state.views?.map((v: DesignView) => 
            v.view === view?.view ? {
                ...v,
                layers: update_layers,
                curtool: 'transform'
            }:v
        )
        this.setState({...this.state, views: update_views})
    }


    async addToShoppingCart() {
        //generate order id
        const orderid = generateorderId('CTM')
        
        let previewthumb = []
        let exportcustomizeddesign = []
        let templates = []
        const previewstage = this.previewstageRef.current
        const exportstage = this.exportstageRef.current
        if (this.state.views) {
        for (const v of this.state.views) {
            //export preview and printable
            const l = previewstage.children.find((l:any) => l.attrs.id === `${v.view}-preview-publish-layer`)
            const preview = l.toDataURL({
                pixelRatio: 0.8
            })
            const exl = exportstage.children.find((l:any) => l.attrs.id === `${v.view}-export-layer`)
            
            previewthumb.push({
                view: v.view,
                thumb: preview
            })
            

            //save tmp templates
            const processed_layers: any = []
            let idx = 1
            let frameX = (this.state.canvaswidth as number-(v.previewwidth as number)*(1.1**(this.state.zoomlevel as number)))/2
            let frameY = (this.state.canvasheight as number-(v.previewheight as number)*(1.1**(this.state.zoomlevel as number)))/2
            let frameW = (v.previewwidth as number)*(1.1**(this.state.zoomlevel as number))
            let frameH = (v.previewheight as number)*(1.1**(this.state.zoomlevel as number))
            v.layers?.forEach((l: DesignLayer) => {
                if (l.props.type !== 'mockup-layer' && l.props.type !== 'printframe-layer') {
                    const normX = (l.props.shapeGrpAttr.x - frameX)/frameW
                    const normY = (l.props.shapeGrpAttr.y - frameY)/frameH
                    const normW = (l.props.shapeGrpAttr.width * l.props.shapeGrpAttr.scaleX)/frameW
                    const normH = (l.props.shapeGrpAttr.height * l.props.shapeGrpAttr.scaleY)/frameH
                    idx = idx + 1
                    processed_layers.push({
                        type: l.props.type,
                        src: l.props.itemId,
                        order: idx,
                        props: {
                            normX: normX,
                            normY: normY,
                            normW: normW,
                            normH: normH,
                            previewlayerAttr: {
                                ...l.props.previewlayerAttr
                            },
                            exportlayerAttr: {
                                ...l.props.exportlayerAttr
                            },
                            shapeGrpAttr: {
                                ...l.props.shapeGrpAttr,
                
                            },
                            shapeboxAttr: {
                                ...l.props.shapeboxAttr,
                            },
                            shapeAttr: {
                                ...l.props.shapeAttr
                            },
                            
                            exportshapeGrpAttr: {
                                ...l.props.exportshapeGrpAttr,
                            
                            },
                            exportshapeAttr: {
                                ...l.props.exportshapeAttr
                                
                            },
                            cropmaskAttr: {
                                ...l.props.cropmaskAttr,
                            
                            },
                        }
                    })
                }

            })
            try {
                if (exl.children.length > 0) {
                    const exportdesign = exl.toDataURL({
                        pixelRatio: 1.0
                    })
                    exportcustomizeddesign.push({
                        view: v.view,
                        exportdesign: exportdesign,
                        exportwidth: v.exportwidth,
                        exportheight: v.exportheight
                    })
                    const tplsnapshot = exl.toDataURL({
                        pixelRatio: 0.5
                    })
                
                    const res = await designtplsaveAPI(tplsnapshot, JSON.stringify(processed_layers), true, null)
                    templates.push({
                        view: v.view,
                        template: res.data.tpl_id
                    })
                }
            }
            catch(e) {
                console.log(e)
            }
        }
        }

        const item: CartItem = {
            ...this.state.cartitem,
            item_id: orderid,
            previewthumb: previewthumb,
            exportcustomizeddesign: exportcustomizeddesign,
            templates: templates,
            checkingout: false
        }

        // if logged in, add item to user cart
        const now = (new Date()).getTime() / 1000
        const prod = this.state.cartitem?.product
        if (this.props.user.isLoggedIn && this.props.user.tokenExpireOn > now) {
            try {
                await addcartitemAPI(prod?.store as unknown as string, item)
            }
            catch(e) {
                console.log(e)
            }
        }
        this.setState({
            ...this.state,
            cartitem: item
        }, () => {
            if (this.state.cartitem) this.props.addcartitem(prod?.store as unknown as string, this.state.cartitem)
        })
        
    }

    async updateShoppingCart() {
        let previewthumb = []
        let exportcustomizeddesign = []
        let templates = []
        const previewstage = this.previewstageRef.current
        const exportstage = this.exportstageRef.current
        if (this.state.views) {
        for (const v of this.state.views) {
            //export preview and printable
            const l = previewstage.children.find((l:any) => l.attrs.id === `${v.view}-preview-publish-layer`)
            const preview = l.toDataURL({
                pixelRatio: 0.8
            })
            const exl = exportstage.children.find((l:any) => l.attrs.id === `${v.view}-export-layer`)
            
            previewthumb.push({
                view: v.view,
                thumb: preview
            })
            

            //save tmp templates
            const processed_layers: any = []
            let idx = 1
            let frameX = (this.state.canvaswidth as number-(v.previewwidth as number)*(1.1**(this.state.zoomlevel as number)))/2
            let frameY = (this.state.canvasheight as number-(v.previewheight as number)*(1.1**(this.state.zoomlevel as number)))/2
            let frameW = (v.previewwidth as number)*(1.1**(this.state.zoomlevel as number))
            let frameH = (v.previewheight as number)*(1.1**(this.state.zoomlevel as number))
            v.layers?.forEach((l: DesignLayer) => {
                if (l.props.type !== 'mockup-layer' && l.props.type !== 'printframe-layer') {
                    const normX = (l.props.shapeGrpAttr.x - frameX)/frameW
                    const normY = (l.props.shapeGrpAttr.y - frameY)/frameH
                    const normW = (l.props.shapeGrpAttr.width * l.props.shapeGrpAttr.scaleX)/frameW
                    const normH = (l.props.shapeGrpAttr.height * l.props.shapeGrpAttr.scaleY)/frameH
                    idx = idx + 1
                    processed_layers.push({
                        type: l.props.type,
                        src: l.props.itemId,
                        order: idx,
                        props: {
                            normX: normX,
                            normY: normY,
                            normW: normW,
                            normH: normH,
                            previewlayerAttr: {
                                ...l.props.previewlayerAttr
                            },
                            exportlayerAttr: {
                                ...l.props.exportlayerAttr
                            },
                            shapeGrpAttr: {
                                ...l.props.shapeGrpAttr,
                
                            },
                            shapeboxAttr: {
                                ...l.props.shapeboxAttr,
                            },
                            shapeAttr: {
                                ...l.props.shapeAttr
                            },
                            
                            exportshapeGrpAttr: {
                                ...l.props.exportshapeGrpAttr,
                            
                            },
                            exportshapeAttr: {
                                ...l.props.exportshapeAttr
                                
                            },
                            cropmaskAttr: {
                                ...l.props.cropmaskAttr,
                            
                            },
                        }
                    })
                }

            })
            try {
                if (exl.children.length > 0) {
                    const exportdesign = exl.toDataURL({
                        pixelRatio: 1.0
                    })
                    exportcustomizeddesign.push({
                        view: v.view,
                        exportdesign: exportdesign
                    })
                    const tplsnapshot = exl.toDataURL({
                        pixelRatio: 0.5
                    })
                    //get template at view v
                    const tpl = this.state.cartitem?.templates?.filter((t: any) => t.view === v.view)
                    const res = await designtplupdateAPI(tpl.template, tplsnapshot, JSON.stringify(processed_layers), null)
                    templates.push({
                        view: v.view,
                        template: res.data.tpl_id
                    })
                }
            }
            catch(e) {
                console.log(e)
            }
        }
        }
        const item: CartItem = {
            ...this.state.cartitem,
            previewthumb: previewthumb,
            exportcustomizeddesign: exportcustomizeddesign,
            templates: templates,
            checkingout: this.state.cartitem ? this.state.cartitem.checkingout:false
        }
        // if logged in, update item in user cart
        const now = (new Date()).getTime() / 1000
        if (this.props.user.isLoggedIn && this.props.user.tokenExpireOn > now) {
            try {
                await updatecartitemAPI(item)
            }
            catch(e) {
                console.log(e)
            }
        }
        this.setState({
            ...this.state,
            cartitem: item
        }, () => {
            if (this.state.cartitem) this.props.updatecartitem(item.item_id, item)
        })
    }

    leaveDesignPage(path: string) {
        this.props.history.push(path, {})
    }
    
    componentDidMount() {
        const { user, setCurrentUri } = this.props
        const now = (new Date()).getTime() / 1000
        if (!user.isLoggedIn || (user.isLoggedIn && user.tokenExpireOn <= now)) {
            const google = (window as any).google
            if (google) {
                google.accounts.id.initialize({
                    client_id: google_client_id,
                    callback: this.handleGSignInResponse
                })
                google.accounts.id.prompt()
            }


        }
        const recaptchaEl = document.getElementsByClassName('grecaptcha-badge') as HTMLCollectionOf<HTMLElement>
        if (recaptchaEl !== null && recaptchaEl !== undefined && recaptchaEl.length > 0) {
            // document.head.removeChild(script)
            recaptchaEl[0].style.visibility = 'hidden'
        }

        

        const fontspec = this.state.fonts!.map((font:Font) => {
            let s = `${font.name}`
            if (font.weights.length > 0) {
                s = `${s}:${font.weights.toString()}`
            }
            if (font.styles.length > 0) {
                s = `${s}:${font.styles.toString()}`
            }
            return s
        })
        
        WebFont.load({
            google: {
                families: fontspec
            }
        })
    
        //fetch products
        fetchProductAPI()
        .then((response) => {
            // const products = response.data.result
            // let defaultProduct: Product | undefined = products?.find((p: Product) => p.isdefault);
            // let defaultColor: Color | undefined = defaultProduct?.colors?.find((c: Color) => c.isdefault) as Color
            // if (defaultProduct && defaultColor) {
            //     let selproduct = defaultProduct.id
            //     let selcolor = defaultColor.id
            //     if (this.state.cartitem) {
            //         selproduct = this.state.cartitem.product_id
            //         selcolor = this.state.cartitem.color_id
            //     }
            // }
            
            this.setState({
                ...this.state,
                products: response.data.result
            })
        })
        .catch((error) => {
            console.log("Error fetching products: ", error)
        })

        setCurrentUri('/design')
        
    }

    componentDidUpdate() {
        // const { user } = this.props
        // const now = (new Date()).getTime() / 1000
        // if (!user.isLoggedIn || (user.isLoggedIn && user.tokenExpireOn <= now)) {
        //     const google = (window as any).google
        //     if (google) {
        //         google.accounts.id.initialize({
        //             client_id: google_client_id,
        //             callback: this.handleGSignInResponse
        //         })
        //         google.accounts.id.prompt()
        //     }


        // }

    }

    render() {
        const { user, classes } = this.props
        return (
            <DesignContext.Provider value={{
                designstate: {...this.state},
                designdispatch: { 
                    //fetchProduct: this.fetchProducts,
                    // loadViews: this.loadViews,
                    // loadMockups: this.loadMockups,
                    //changeQuantity: this.changeQuantity,
                    //setMockup: this.setMockup,
                    //addBackgroundLayers: this.addBackgroundLayers,
                    setYourTemplatesViewset: this.setYourTemplatesViewset,
                    setYourPhotosViewset: this.setYourPhotosViewset,
                    setYourGraphicsViewset: this.setYourGraphicsViewset,
                    setSharedTemplatesViewset: this.setSharedTemplatesViewset,
                    setSharedPhotosViewset: this.setSharedPhotosViewset,
                    setSharedGraphicsViewset: this.setSharedGraphicsViewset,
                    setIGPhotosViewset: this.setIGPhotosViewset,
                    addPhotoLayer: this.addPhotoLayer,
                    addGraphicLayer: this.addGraphicLayer,
                    addTextLayer: this.addTextLayer,
                    zoomIn: this.zoomIn,
                    zoomOut: this.zoomOut,
                    setTemplateItem: this.setTemplateItem,
                    setPhotoItem: this.setPhotoItem,
                    setIGPhotoItem: this.setIGPhotoItem,
                    setGraphicItem: this.setGraphicItem,
                    selectLayer: this.selectLayer,
                    removeLayer: this.removeLayer,
                    removeAllLayers: this.removeAllLayers,
                    transformShape: this.transformShape,
                    transformCropMask: this.transformCropMask,
                    transformCropBox: this.transformCropBox,
                    expandKeepAspect: this.expandKeepAspect,
                    flipHorizontal: this.flipHorizontal,
                    flipVertical: this.flipVertical,
                    moveShape: this.moveShape,
                    moveCropMask: this.moveCropMask,
                    moveCropBox: this.moveCropBox,
                    moveCenter: this.moveCenter,
                    selecttool: this.selecttool,
                    adjustOpacity: this.adjustOpacity,
                    applyChange: this.applyChange,
                    resetChange: this.resetChange,
                    leaveDesignPage: this.leaveDesignPage,
                    setView: this.setView,
                    insertProps: this.insertProps,
                    undo: this.undo,
                    redo: this.redo,
                    bringToFront: this.bringToFront,
                    bringToBack: this.bringToBack,
                    updateText: this.updateText,
                    updateTextProps: this.updateTextProps,
                    updateShapeProps: this.updateShapeProps,
                    updateLayers: this.updateLayers,
                    selectProduct: this.selectProduct,
                    // updateCartItem: this.updateCartItem,
                    updateCartItemQuantity: this.updateCartItemQuantity,
                    addToShoppingCart: this.addToShoppingCart,
                    updateShoppingCart: this.updateShoppingCart
                    //updateCartCustomizedItem: this.updateCartCustomizedItem
                    //publishLayer: this.publishLayer
                },
                designrefs: { 
                    stage: this.stageRef,
                    exportstage: this.exportstageRef,
                    previewstage: this.previewstageRef
                }
            }}>
            <>
            
                
            <Content>
                <div style={{
                    paddingTop: '55px'
                }}>
                
                <DesignSidebar />
                <DesignToolbar />
                           
                <Grid container spacing={1} sx={{
                    display: {
                        xs: 'none',
                        md: 'none',
                        lg: 'flex'
                    }
                }}>
                    <Grid item lg={7}>
                        <DesignStage />
                    </Grid>
                    <Grid item lg={5} sx={{
                        display: {
                            xs: 'none',
                            lg: 'flex'
                        }
                    }}>
                        <DesignSimpleTools />
                    </Grid>
                </Grid>
                <Box alignItems="center" justifyContent="content" sx={{
                    width: 700,
                    margin: 'auto',
                    display: {
                        xs: 'flex',
                        md: 'flex',
                        lg: 'none'
                    }
                }}>
                <DesignStage />
                </Box>
                </div>
                
            </Content>
            </>    
            </DesignContext.Provider>    
                
            
        )
    }
        
    
}

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        setCurrentUri, addcartitem, updatecartitem
    }, dispatch)
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user,
        cart: state.cart
    }
}
const stateProps = returntypeof(mapStateToProps)
const dispatchProps = returntypeof(mapDispatchToProps)
export default compose(
    withStyles(styles),
    connect<typeof stateProps, typeof dispatchProps, {}>(mapStateToProps, mapDispatchToProps)
)(Component);