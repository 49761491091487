import { AsyncThunkAction } from "@reduxjs/toolkit";
import objectPath from "object-path";
import React, { useEffect, useMemo } from "react"
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setLangAsync } from "../../i18n/i18nSlice";
import useTranslations from "../../i18n/useTranslation";
import { checkIsActive } from "../../_helpers";

import { useHtmlClassService } from "../core/LayOut"
import { Footer } from "./Footer";
import { Header } from "./Header";
// import { HeaderMobile } from "./HeaderMobile";
import { LayoutInit } from "./LayoutInit";
import { MainAppbar } from "./MainAppbar";
import { DesignSidebar } from "./widgets/design/DesignSidebar";
import { DesignToolbar } from "./widgets/design/DesignToolbar";

type IProps = {
    children: React.ReactNode
}

export const Layout: React.FC<IProps> = ({children}:IProps): JSX.Element => {
    const uiService = useHtmlClassService();
    // Layout settings (cssClasses/cssAttributes)
    const layoutProps = useMemo(() => {
        return {
            layoutConfig: uiService.config,
            desktopHeaderDisplay: objectPath.get(
                uiService.config,
                "header.self.fixed.desktop"
            ),
            contentCssClasses: uiService.getClasses("content", true),
            contentContainerClasses: uiService.getClasses("content_container", true)
        };
    }, [uiService]);
    
    const {init, status: i18nstatus} = useTranslations()
    useEffect(() => { init() }, [])
    const location = useLocation()
    return (
        <>
        {i18nstatus !== "loading" && (
           <>
           <div className="d-flex flex-column flex-root">
                {/*begin::Page*/}
                <div className="d-flex flex-row flex-column-fluid page">
                    {/*begin::Wrapper*/}
                    <div className="d-flex flex-column flex-row-fluid" id="app_wrapper">
                        {/* <Header/> */}
                        <MainAppbar/>
                        {/*begin::Content*/}
                        <div
                            id="app_content"
                            className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
                        >
                            {/*begin::Entry*/}
                            <div className="d-flex flex-column-fluid">
                                {/*begin::Container*/}
                                <div className={layoutProps.contentContainerClasses}>
                                    {children}
                                </div>
                                {/*end::Container*/}
                            </div>
                            {/*end::Entry*/}
                        </div>
                        {/*end::Content*/} 
                        <Footer />
                        </div>
                    {/*end::Wrapper*/}
                </div>
                {/*end::Page*/}
            </div>
            
            <LayoutInit />
            </>
        )}
        </>
    )
} 
