import React, {useLayoutEffect} from "react";
import { KTUtil } from "../../_assets/js/components/util";
import KTLayoutContent from "../../_assets/js/layout/base/content";
import KTLayoutHeader from "../../_assets/js/layout/base/header";
import KTLayoutHeaderMenu from "../../_assets/js/layout/base/header-menu";
import KTLayoutHeaderTopbar from "../../_assets/js/layout/base/header-topbar";

export const LayoutInit: React.FC = () => {
    useLayoutEffect(() => {
        KTUtil.ready(function () {
            KTLayoutHeader.init('app_header');
            // Init Header Menu
            KTLayoutHeaderMenu.init('app_header_menu', 'app_header_menu_wrapper');
            // Init Header Topbar For Mobile Mode
            KTLayoutHeaderTopbar.init('app_header_mobile_topbar_toggle');

            KTLayoutContent.init('app_content');
        })
    }, [])
    return (
        <>
        </>
    );
}