import * as React from 'react'
import { Dropdown, Button } from "react-bootstrap"
import { useAppDispatch, useAppSelector } from "../../../reducers/hooks"
import { toAbsoluteUrl } from "../../../_helpers"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { UserMenuDropdown } from "./dropdowns/UserMenuDropDown"
import { useDropdownToggle } from "react-overlays"
import { DropdownTopbarItemToggler } from "../../../_partials/dropdowns";
import { setLangAsync } from "../../../i18n/i18nSlice";
import { useDispatch } from "react-redux";
import { Avatar, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { currencySet } from '../../../actions/currencyAction';


export const CurrencyToggler: React.FC = () => {
    const currency = useAppSelector((state) => state.currency.isocode)
    const currencySymbol = useAppSelector((state) => state.currency.symbol)
    const supportedCurrencies = useAppSelector((state) => state.currency.supportedCurrencies)
    // console.log(supportedLangs[lang])
    const dispatch = useDispatch()

    const [anchorElCurrency, setAnchorElCurrency] = React.useState<null | HTMLElement>(null);
    
    const handleOpenCurrencyMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElCurrency(event.currentTarget);
    }

    const handleCloseCurrencyMenu = () => {
        setAnchorElCurrency(null);
        
    }

    const handleSelectCurrency = (name: string, symbol: string, code: string) => {
        setAnchorElCurrency(null)
        dispatch(currencySet(name, symbol,code))
    }
    return (
        <>
        <IconButton
            size="large"
            aria-label="app currency"
            aria-controls="menu-currencybar"
            aria-haspopup="true"
            color="secondary"
            onClick={handleOpenCurrencyMenu}
            sx={{
                width: '55px',
                height: '55px'
            }}
        >
            <Avatar 
                children={`${currencySymbol}`} 
                variant="rounded" 
                sx={{ width: 32, height: 32, bgcolor: '#222222' }} />
        </IconButton>
        <Menu
            id="menu-currencybar"
            anchorEl={anchorElCurrency}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            marginThreshold={60}
            open={Boolean(anchorElCurrency)}
            onClose={handleCloseCurrencyMenu}
            PaperProps={{  
                style: {  
                    width: 150,  
                },  
            }} 
        >
            {supportedCurrencies.map(
                    (c: any) => (
                    <MenuItem key={c.isocode} onClick={() => handleSelectCurrency(c.name, c.symbol, c.isocode)}>
                        <Avatar 
                            children={`${c.symbol}`} 
                            variant="square" 
                            sx={{ width: 16, height: 16, bgcolor: '#222222', fontSize: 10, mr: 1 }} />
                        <Typography textAlign="left" variant="button"><>{c.name}</></Typography>
                    </MenuItem>
                    )
            )}
                
        </Menu>
        
        
        </>
    )

}