import { IconButton, ImageList, ImageListItem, ImageListItemBar } from '@mui/material'
import * as React from 'react'
import { DesignTemplate } from '../../../../types'
import AddIcon from '@mui/icons-material/Add'
import InfoIcon from '@mui/icons-material/Info'


interface ISharedTemplateListProps {
    templates?: DesignTemplate[] | null
    onScrollList: (event: any) => void
    onDragItem: (item: DesignTemplate) => void
    onAddTemplateItembarClick: (item: DesignTemplate) => void
    onViewTemplateItembarClick: (item: DesignTemplate) => void
  }
  
  

const SharedTemplateList = (props: ISharedTemplateListProps) => {
    const { templates, onScrollList, onDragItem, 
        onAddTemplateItembarClick,
        onViewTemplateItembarClick } = props
    return (
        <ImageList
        variant="quilted"
        onScroll={onScrollList} 
        sx={{ height: 180 }} cols={3} rowHeight={100}>
        <>
        {templates?.map((item: DesignTemplate) => (
            <ImageListItem key={item.id}>
            <img
                src={`${item.snapshot}`}
                srcSet={`${item.snapshot}`}
                loading="lazy"
                draggable="true"
                onDragStart={(e) => { onDragItem(item) }}
            />
            <ImageListItemBar
                actionIcon={
                <>
                <IconButton
                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                    aria-label={`Add this template to design`}
                    onClick={() => { onAddTemplateItembarClick(item) }}
                >
                    <AddIcon />
                </IconButton>
                <IconButton
                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                    aria-label={`View info of this template`}
                    onClick={() => { onViewTemplateItembarClick(item) }}
                >
                    <InfoIcon />
                </IconButton>
                
                </>
                
                }
            />
            </ImageListItem>
        ))}
        </>
        </ImageList>
    );
}

export default SharedTemplateList