import React, { useState } from "react"
import Dropdown from "react-bootstrap/Dropdown"
import { useAppSelector } from "../../../reducers/hooks"
import { toAbsoluteUrl } from "../../../_helpers"
import IconButton from '@mui/material/IconButton'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonIcon from '@mui/icons-material/Person';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CollectionsIcon from '@mui/icons-material/Collections';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { UserMenuDropdown } from "./dropdowns/UserMenuDropDown"
import { useDropdownToggle } from "react-overlays"
import { DropdownTopbarItemToggler } from "../../../_partials/dropdowns";
import { Avatar, Box, Button, Card, CardActions, CardContent, CardMedia, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Popover, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { stringAvatar } from "./AvatarUtils"


export const UserToggler: React.FC = () => {
    const userInfo = useAppSelector((state) => state.user)
    const [userInfoPopoverAnchorEl, setUserInfoPopoverAnchorEl] = React.useState<HTMLElement | null>(null);
    const now = (new Date()).getTime() / 1000
    const handleUserInfoPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setUserInfoPopoverAnchorEl(event.currentTarget);
    };

    const handleUserInfoPopoverClose = () => {
        setUserInfoPopoverAnchorEl(null);
    };

    const openUserInfoPopover = Boolean(userInfoPopoverAnchorEl)
      
    return (
        <>
        <IconButton 
            aria-owns={openUserInfoPopover ? 'userinfo-popover' : undefined}
            aria-haspopup="true"
            onClick={handleUserInfoPopoverOpen}
            sx={{
                width: '55px',
                height: '55px'
            }}
            >
        {userInfo.isLoggedIn && userInfo.tokenExpireOn > now ? (
            userInfo.avatar !== null && userInfo.avatar !== undefined ? (
                <Avatar alt={userInfo.name as string} src={userInfo.avatar} 
                sx={{
                    width: '48px',
                    height: '48px'
                }} />
            ):(
                <Avatar {...stringAvatar(userInfo.name as string)} />
            )
        ):(
            <Avatar>
            <PersonIcon />
            </Avatar>
        )}
        </IconButton>
        <Popover
            id="userinfo-popover"
            open={openUserInfoPopover}
            anchorEl={userInfoPopoverAnchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            marginThreshold={60}
            onClose={handleUserInfoPopoverClose}
            disableRestoreFocus
            PaperProps={{
                elevation: 2,
                style: {
                width: '400px'
                }
            }}
        >
            <Card sx={{ width: '400px' }}>
                <CardMedia
                    component="img"
                    height="140"
                    image={toAbsoluteUrl(
                        "/media/misc/default-user-bg.png"
                      )}
                    alt="green iguana"
                />
                <CardContent sx={{ paddingTop: '5px' }}>
                    
                    {userInfo.isLoggedIn && userInfo.tokenExpireOn > now ? (
                        userInfo.avatar !== null && userInfo.avatar !== undefined ? (
                            <Grid container direction="row" alignItems="center" spacing={2}>
                            <Grid item xs={3}>
                                <Box sx={{ position:"relative" }}>
                                <Avatar 
                                    alt={userInfo.name as string} 
                                    src={userInfo.avatar} 
                                    sx={{ width: 72, height: 72, position:"absolute", top: -56 }}
                                    />
                                </Box>
                                </Grid>

                            <Grid item xs={9}>
                                
                                <Typography gutterBottom variant="h5" component="div">
                                {userInfo.name}
                                </Typography>
                                
                            </Grid>
                            </Grid>
                        ):(
                            <Grid container direction="row" alignItems="center">
                            <Grid item>
                            <Avatar {...stringAvatar(userInfo.name as string)} />
                            </Grid>
                            <Grid item>
                                <Typography gutterBottom variant="h5" component="div">
                                {userInfo.name}
                                </Typography>
                            </Grid>
                            </Grid>
                        )
                    ):(
                        <Grid container direction="row" alignItems="center" spacing={2}>
                        <Grid item>
                        <Avatar>
                        <PersonIcon />
                        </Avatar>
                        </Grid>
                        <Grid item>
                            <Typography gutterBottom variant="h5" component="div">
                            You're not signed in.
                            </Typography>
                        </Grid>
                        </Grid>
                    )}
                    {userInfo.isLoggedIn && userInfo.tokenExpireOn > now ? (
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', paddingTop: '10px' }}>
                        <ListItemButton>
                            <ListItemAvatar>
                            <Avatar>
                                <ManageAccountsIcon />
                            </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Manage Account" secondary="Edit your account detail e.g. shipping address, payment options" />
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemAvatar>
                            <Avatar>
                                <CollectionsIcon />
                            </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Manage Portfolio" secondary="Edit and share your saved templates, photos, and graphics. Share and Earn money from your collections" />
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemAvatar>
                            <Avatar>
                                <BookmarkIcon />
                            </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Manage Bookmarks" secondary="Edit your favorite and wishlist items." />
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemAvatar>
                            <Avatar>
                                <LoyaltyIcon />
                            </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Manage Promotions and Coupons" secondary="View and edit your current offers." />
                        </ListItemButton>
                        </List>
                    ):(
                        <div style={{paddingTop: '10px'}}>
                        <Typography variant="body2" color="text.secondary">
                        Sign In or Sign Up to access all PixarTee's features.
                        </Typography>
                        </div>
                    )}
                </CardContent>
                <CardActions>
                {userInfo.isLoggedIn && userInfo.tokenExpireOn > now ? (
                    <>
                    <Button 
                        size="small"
                        sx={{
                            width: 400
                        }} 
                    >
                        <Link to="/signout">
                        Sign Out
                        </Link>
                    </Button>
                    </>
                ):(
                    <>
                    <Button size="small">
                        <Link to="/signin">
                        Sign In
                        </Link>
                    </Button>
                    <Button size="small">
                        <Link to="/signup">
                        Sign Up
                        </Link>
                    </Button>
                    </>
                )}
                </CardActions>
            </Card>
        </Popover>
        {/* <Dropdown drop="down" alignRight>
            <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="dropdown-toggle-user-profile"
            >
                <div
                    className={
                        "d-flex align-items-center"
                    }
                >
                    {userInfo.isLoggedIn && userInfo.tokenExpireOn > now ? 
                    <span className="symbol symbol-circle symbol-35 symbol-primary">
                        <span className="symbol-label font-size-h5 font-weight-bold">
                        {userInfo.name != null ? userInfo.name[0]:""}
                        </span>
                    </span>:
                    <IconButton>
                        <AccountCircleIcon fontSize="large" />
                    </IconButton>
                    } 
                </div>
            </Dropdown.Toggle>
            <UserMenuDropdown />
        </Dropdown> */}
        {/* <OverlayTrigger 
            placement="bottom" 
            overlay={<Tooltip id="quick-user-tooltip">View user</Tooltip>}>
            <div className="topbar-item">
                <div
                    className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                    id="quick_user_toggle"
                >
                 {userInfo.isLoggedIn ? 
                 <span className="symbol symbol-circle symbol-35 symbol-light-success">
                    <span className="symbol-label font-size-h5 font-weight-bold">
                    {userInfo.name != null ? userInfo.name[0]:""}
                    </span>
                 </span>:
                 <span className="symbol symbol-circle symbol-35 symbol-light-success">
                     <AccountCircleIcon />
                 </span>
                 }   
                </div>
            </div>
        </OverlayTrigger>
        <UserMenuDropdown/> */}
        
        </>
    )

}