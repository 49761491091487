import axios from "axios"
import { client_id, client_secret, rest_endpoint } from "../../settings"

export const registerUserAPI = (
    reqdata: any) => {
    let url = `${rest_endpoint}registeruser/`

    return axios.post(url, {
        ...reqdata
    })
}

export const signout = (token: string) => {
    let url = `${rest_endpoint}o/revoke_token/`

    return axios.post(url, `token=${token}&token_type_hint=access_token&client_id=${client_id}&client_secret=${client_secret}`)
}

export const attachcardAPI = (token: string, isdefault: boolean) => {
    let url = `${rest_endpoint}profiles/attachpaymentcard/`

    return axios.post(url, `token=${token}&isdefault=${isdefault}`)
}

export const listcardAPI = () => {
    let url = `${rest_endpoint}profiles/listpaymentcards/`

    return axios.get<any>(url)
}

export const deletecardAPI = (token: string) => {
    let url = `${rest_endpoint}profiles/deletepaymentcard/`

    return axios.post(url, `token=${token}`)
}

export const getdefaultcardAPI = () => {
    let url = `${rest_endpoint}profiles/getdefaultpaymentcard/`

    return axios.get<any>(url)
}

export const setdefaultcardAPI = (token: string) => {
    let url = `${rest_endpoint}profiles/setdefaultpaymentcard/`

    return axios.post(url, `token=${token}`)
}
