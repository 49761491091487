import * as React from 'react'
import { bindActionCreators } from 'redux'
import compose from 'recompose/compose'
import { returntypeof } from 'react-redux-typescript'
import { setCurrentUri } from '../actions/userActions'
import { Content } from '../layouts'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Grid } from '@mui/material'
import { toAbsoluteUrl } from '../_helpers'
import { google_client_id, rest_endpoint } from '../settings'


class Component extends React.Component<any, {}> {

    constructor(props: any) {
        super(props)
        this.handleGSignInResponse = this.handleGSignInResponse.bind(this)
        
    }

    handleGSignInResponse(response: any) {
        const form = document.createElement('form');
        form.method = 'post';
        form.action = `${rest_endpoint}googlesignin/`;

        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = 'credential';
        hiddenField.value = response.credential;

        form.appendChild(hiddenField);
        document.body.appendChild(form);
        form.submit();
    }
    
    componentDidMount() {
        const { user, setCurrentUri } = this.props
        const now = (new Date()).getTime() / 1000
        if (!user.isLoggedIn || (user.isLoggedIn && user.tokenExpireOn <= now)) {
            const google = (window as any).google
            if (google) {
                google.accounts.id.initialize({
                    client_id: google_client_id,
                    callback: this.handleGSignInResponse
                })
                google.accounts.id.prompt()
            }
        }
        // const script = document.getElementById('recaptcha-script')
        const recaptchaEl = document.getElementsByClassName('grecaptcha-badge') as HTMLCollectionOf<HTMLElement>
        if (recaptchaEl !== null && recaptchaEl !== undefined && recaptchaEl.length > 0) {
            // document.head.removeChild(script)
            recaptchaEl[0].style.visibility = 'hidden'
        }
        setCurrentUri('/home')
    }

    componentDidUpdate() {
        // const { user } = this.props
        // const now = (new Date()).getTime() / 1000
        // if (!user.isLoggedIn || (user.isLoggedIn && user.tokenExpireOn <= now)) {
        //     const google = (window as any).google
        //     if (google) {
        //         google.accounts.id.initialize({
        //             client_id: google_client_id,
        //             callback: this.handleGSignInResponse
        //         })
        //         google.accounts.id.prompt()
        //     }
        // }
    }

    render() {
        return (
            <Content>
                <div style={{
                    paddingTop: '55px'
                }}>
                <Grid container spacing={1}>
                    {/* Start Headline banners */}
                    <Grid item xs={12}>
                    <img 
                        src={toAbsoluteUrl("/media/template/Video.png")} 
                        alt="banner1" 
                        style={{
                            maxWidth: "100%"
                        }}
                    />
                    </Grid>
                    <Grid item xs={12}>
                    <img 
                        src={toAbsoluteUrl("/media/template/Video.png")} 
                        alt="banner1" 
                        style={{
                            maxWidth: "100%"
                        }}
                    />
                    </Grid>
                    {/* End Headline banners */}
                </Grid>
                </div>
            </Content>
        )
    }
        
    
}

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        setCurrentUri
    }, dispatch)
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user
    }
}
const stateProps = returntypeof(mapStateToProps)
const dispatchProps = returntypeof(mapDispatchToProps)
export default compose(
    connect<typeof stateProps, typeof dispatchProps, {}>(mapStateToProps, mapDispatchToProps)
)(Component);