import { Grid, Paper, Typography } from '@mui/material'
import * as React from 'react'
import { connect } from 'react-redux'
import { returntypeof } from 'react-redux-typescript'
import compose from 'recompose/compose'
import { bindActionCreators } from 'redux'
import { setCurrentUri } from '../actions/userActions'
import { Content } from '../layouts'
import { CheckOutInfo } from '../layouts/components/widgets/CheckOutInfo'
import { ICartState } from '../providers/CartRefContext'
import { CheckOutContext } from '../providers/CheckOutRefContext'
import { google_client_id, omise_test_pubkey } from '../settings'


class Component extends React.Component<any, 
    {
        shippingaddr?: string | null,
        paymentmethod?: string | null,
        selectedcard: string | null,
        carrier?: string | null,
        cart: ICartState | null
    }> {
    constructor(props: any) {
        super(props)
        this.selectShippingAddr = this.selectShippingAddr.bind(this)
        this.selectPaymentMethod = this.selectPaymentMethod.bind(this)
        this.selectPaymentCard = this.selectPaymentCard.bind(this)
        this.state = {
            shippingaddr: null,
            paymentmethod: 'credit_debit',
            selectedcard: null,
            carrier: null,
            cart: props.cart
        }
    }

    selectShippingAddr(item: string) {
        this.setState({ ...this.state, shippingaddr: item })
    }

    selectPaymentMethod(item: string) {
        this.setState({ ...this.state, paymentmethod: item })
    }

    selectPaymentCard(item: string) {
        this.setState({...this.state, selectedcard: item })
    }

    componentDidMount() {
        const Omise = (window as any).Omise
        Omise.setPublicKey(omise_test_pubkey)

    }

    componentDidUpdate() {

    }

    render() {
        return (
            <CheckOutContext.Provider value={{
                checkoutstate: {...this.state},
                checkoutdispatch: {
                    selectShippingAddr: this.selectShippingAddr,
                    selectPaymentMethod: this.selectPaymentMethod,
                    selectPaymentCard: this.selectPaymentCard
                },
                checkoutrefs: {}
            }}>
            <Content>
                <div style={{
                    paddingTop: '68px',
                    paddingBottom: '20px',
                    minHeight: '600px'
                }}>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                    <Typography variant='h4'>Checkout</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" spacing={1}
                            sx={{
                                display: {
                                    xs: 'none',
                                    md: 'flex'
                                }
                            }}
                        >
                            <Grid item xs={8}>
                                <CheckOutInfo />
                            </Grid>
                            <Grid item xs={4}>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                </div>
            </Content>
            </CheckOutContext.Provider>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        setCurrentUri
    }, dispatch)
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user,
        cart: state.cart
    }
}
const stateProps = returntypeof(mapStateToProps)
const dispatchProps = returntypeof(mapDispatchToProps)
export default compose(
    connect<typeof stateProps, typeof dispatchProps, {}>(mapStateToProps, mapDispatchToProps)
)(Component);