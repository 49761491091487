import React from 'react'
import { CartItem, DesignItem, Font, IGPhotoViewset, Mockup, Product, SharedGraphicViewset, SharedPhotoViewset, SharedTemplatesViewset, SizeChart, YourGraphicViewset, YourPhoto, YourPhotoViewset, YourTemplatesViewset } from '../types'

export interface DesignLayer {
    id: string
    // idx: number
    // type: string
    // itemId?: string
    // itemname?: string
    // itemowner?: string
    // actualW?: number
    // actualH?: number
    // actual?: string
    // previewW?: number
    // previewH?: number
    // preview?: string
    // previewdata?: any
    selected?: boolean
    current?: number
    props?: any
    propshistory?: any
    // actualimghistory?: any
    // previewimghistory?: any
    // previewimgdatahistory?: any
}

export interface DesignView {
    view: string
    thumb?: string
    curtpl?: number | null
    curlayer?: string | null
    curtool?: string | null
    layers?: DesignLayer[] | null
    printwidth?: number
    printheight?: number
    exportwidth?: number
    exportheight?: number
    previewwidth?: number
    previewheight?: number
    mooffsetX?: number
    mooffsetY?: number
    customizable?: boolean
    order: number
}


export interface DesignState {
    products?: Product[] | null
    sizes?: SizeChart[] | null
    mockups?: Mockup[] | null
    // selproduct?: number | null
    // selcolor?: number | null
    canvaswidth?: number
    canvasheight?: number
    // printwidth?: number
    // printheight?: number
    // exportwidth?: number
    // exportheight?: number
    // previewwidth?: number
    // previewheight?: number
    // mooffsetX?: number
    // mooffsetY?: number
    // curmockup?: any
    // curlayer?: string | null 
    curview?: string | null 
    views?: DesignView[] | null
    zoomlevel?: number
    dragdesignitem?: DesignItem | null
    cntlayers?: number
    // curtool?: string | null
    yourtemplatesviewset?: YourTemplatesViewset | null
    sharedtemplatesviewset?: SharedTemplatesViewset | null
    sharedphotosviewset?: SharedPhotoViewset | null
    yourphotosviewset?: YourPhotoViewset | null
    sharedgraphicsviewset?: SharedGraphicViewset | null
    yourgraphicsviewset?: YourGraphicViewset | null
    igphotosviewset?: IGPhotoViewset | null
    fonts?: Font[] | null
    cartitem?: CartItem | null
}

export interface DesignRefs {
    stage?: any | null
    exportstage?: any | null
    previewstage?: any | null
}
export interface DesignContextInterface {
    designstate: DesignState | null
    designdispatch: any
    designrefs: DesignRefs | null
}
const designContext: DesignContextInterface = {
    designstate: {},
    designdispatch: {},
    designrefs: {}
}

export const DesignContext = React.createContext(designContext)