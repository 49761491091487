import * as React from 'react'
import { Box, Button, CircularProgress, Grid, Paper, Stack, Typography } from '@mui/material'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { bindActionCreators } from 'redux'
import { loginFulfilled, logout, logoutFulfilled } from '../actions/userActions'
import { returntypeof } from 'react-redux-typescript'
import compose from 'recompose/compose'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { toAbsoluteUrl } from '../_helpers'
import { IdToken } from '../providers/djangoOAuth2'
import { withRouter } from 'react-router-dom'
import { signout } from '../reducers/func/userFunc'

const styles = (theme: any) => ({
    box: {
        margin: 'auto'
    },
    paper: {
        width: 400,
        height: 400,
        marginTop: 50,
        paddingTop: 10,
        alignItems: 'top',
        justifyContent: 'center',
    }
})


class Component extends React.Component<any, 
{
    loading: boolean,
    success: boolean
}> {
    accessToken: string | null
    idToken: string | null
    decodedIdToken: IdToken | null | undefined
    completed: boolean

    constructor(props: any) {
        super(props)
        this.handleRetry = this.handleRetry.bind(this)
        this.handleBack = this.handleBack.bind(this)
        this.accessToken = null!
        this.idToken = null!
        this.decodedIdToken = null!
        this.completed = false
        this.state = {
            loading: true,
            success: false
        }
    }

    handleRetry() {
        this.props.history.push('/signout')
    }

    handleBack() {
        const { user } = this.props
        this.props.history.push(user.currentUri)
    }

    logoutwithFB() {
        const FB = (window as any).FB
        FB.logout((response: any) => {
            // revoke pixartee token
            this.revoketoken()
        })
    }

    async revoketoken() {
        const { user, logoutFulfilled } = this.props
        await signout(user.access_token)
        logoutFulfilled()
    }

    componentDidMount() {
        
        const FB = (window as any).FB
        FB.getLoginStatus((response: any) => {
            if (response.status === 'connected') {
                this.logoutwithFB()
            }
            this.revoketoken()
        })

    }

    componentDidUpdate() {
        
    }

    render() {
        const { classes } = this.props
        return (
            <>
            {/* <Box className={classes.box}>
                <Paper className={classes.paper}> */}
            <Box sx={{margin: 'auto'}}>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh', marginTop: '50px' }}
                >
                <Grid item xs={3}>
                <Paper
                  sx={{
                    width: 400,
                    height: 400,
                    alignItems: 'top',
                    justifyContent: 'center',
                    padding: '15px',
                    paddingTop: '20px'
                  }}
                >
                    <Grid container 
                        spacing={2}
                        direction="column"
                        alignItems="center"
                        justifyContent="center">
                        <Grid item xs={12} sx={{ justifyContent: 'center' }}>
                        <img src={toAbsoluteUrl('/media/logos/pixartee-logo.png')} width={150} />
                        </Grid>
                        <Grid item xs={12}>
                        <Typography variant="h6">Signing Out</Typography>
                        </Grid>
                    </Grid>

                        {this.state.loading ? (
                            <>
                            <Grid container 
                                spacing={2}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                paddingTop={10}
                                >
                            <Grid item xs={12} sx={{justifyContent: "center"}}>
                            <Box sx={{position: 'relative', display: 'inline-flex', width: 84}}>
                            <CircularProgress
                            size={84}
                            color='primary'
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                zIndex: 1,
                            }}
                            />
                            
                            <LockIcon 
                            sx={{ 
                                fontSize: 56,
                                top: 14,
                                left: 14,
                                position: 'absolute',
                                color: '#444444'
                            }}
                            />
                            
                            </Box>
                            </Grid>
                            </Grid>
                            </>
                        ):(
                            <>
                            {(!this.state.success && !this.state.loading) && (
                                <Stack spacing={2} sx={{ paddingTop: '20px' }}>
                                <Typography variant="body1" color="error">Access Denied. You are not authorized.</Typography>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={this.handleRetry}>
                                    Retry
                                </Button>
                                <Button 
                                    variant="outlined" 
                                    color="primary" 
                                    onClick={this.handleBack}>
                                    Go Back
                                </Button>
                                </Stack>
                            )}
                            </>
                        )}
                            
                        
                    
                </Paper>
                </Grid>
            </Grid>
            </Box>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        logoutFulfilled
    }, dispatch)
}
const mapStateToProps = (state: any) => {
    return {
        user: state.user
    }
}
const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

export default compose(
    withRouter,
    withStyles(styles),
   connect<typeof stateProps, typeof dispatchProps, {}>(mapStateToProps, mapDispatchToProps)
)(Component);
