import * as React from 'react';
import compose from 'recompose/compose';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';
import { Autocomplete, Avatar, Button, Divider, FormControl, FormHelperText, Grid, Icon, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import { authorize_url, client_id, redirect_uri, redirect_iframe_uri, google_client_id, public_url, rest_endpoint, grecaptcha_site_key } from '../settings';
import { guid } from '../utilities/utilities';
import { withFormHook } from '../hooks/FormValidationHook';
import { Controller } from 'react-hook-form';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { toAbsoluteUrl } from '../_helpers';
import debounce from 'lodash/debounce';
import { withTranslationHook } from '../hooks/TranslationHook';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LanguageToggler } from '../layouts/components/widgets/LanguageToggler';
import { Link, withRouter } from 'react-router-dom';
import { resolve } from 'dns';
import { registerUserAPI } from '../reducers/func/userFunc';
import { returntypeof } from 'react-redux-typescript';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { setCurrentUri } from '../actions/userActions';


const styles = (theme: any) => ({
    paper: {
        height: 600,
        marginTop: 10,
        marginBottom: 50,
        padding: 20,
        alignItems: 'top',
        justifyContent: 'center',
    },
    paperbody: {
        justifyContent: 'center',
    },
    button1: {
        backgroundColor: '#fb0d1d'
    },
    button2: {
        backgroundColor: '#444444'
    },
    button3: {
        backgroundColor: '#227aee'
    }
})

interface SigninTabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface Country {
    name: string;
    dial_code: string;
    code: string;
}

function SigninTabPanel(props: SigninTabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`signin-tabpanel-${index}`}
        aria-labelledby={`signin-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
}

function signinTabProps(index: number) {
    return {
      id: `signin-tab-${index}`,
      'aria-controls': `signin-tabpanel-${index}`,
    };
}

class Component extends React.Component<any, {
    // signintab: number,
    // showpwd: boolean,
    // phonecodes: any,
    // emaillang: string,
    // regsubmit?: boolean,
    // regsuccess?: boolean
}> {
    googleSignInRef: React.RefObject<any>
    // regpwdRef: React.RefObject<any>

    constructor(props: any) {
        super(props)
        this.handleSignInClick = this.handleSignInClick.bind(this)
        // this.handleSignInTabChange = this.handleSignInTabChange.bind(this)
        // this.onRegistrationSubmit = this.onRegistrationSubmit.bind(this)
        // this.handleClickShowPassword = this.handleClickShowPassword.bind(this)
        // this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this)
        // this.checkExisting = this.checkExisting.bind(this)
        this.handleBack = this.handleBack.bind(this)
        // this.fetchPhoneCodes = this.fetchPhoneCodes.bind(this)
        // this.handleEmailLanguageChange = this.handleEmailLanguageChange.bind(this)
        //let search = window.location.search
        //let params = new URLSearchParams(search);
        //let action = params.get('action');
        //this.state = {
            //signintab: action !== null && action === 'register' ? 1:0,
        //     showpwd: false,
        //     phonecodes: [],
        //     emaillang: props.lang
        // }
        this.googleSignInRef = React.createRef()
        // this.regpwdRef = React.createRef()
        // this.fetchPhoneCodes(toAbsoluteUrl("/lang/phonecodes.json"))
    }
    // async fetchPhoneCodes(url: string) {
    //     const codes = await new Promise((resolve) => {
    //         fetch(url)
    //             .then((response) => response.json())
    //             .then((data) => resolve(data))
    //     })
    //     this.setState({ ...this.state, phonecodes: codes})
    // }
    handleSignInClick(value: string, provider: string) {
        let authorizer: string = '';
        let origin: string = encodeURIComponent(`${public_url}/signin`)
        if (provider==='internal') {
            authorizer = `${value}?response_type=id_token%20token&scope=openid%20read%20write&client_id=${client_id}&redirect_uri=${redirect_uri}&origin=${origin}&state=${guid()}&nonce=${guid()}`
        }
        window.location.assign(authorizer)
    }
    // handleSignInTabChange(event: React.SyntheticEvent, newValue: number) {
    //     this.setState({ ...this.state, signintab: newValue})
    // }
    // onRegistrationSubmit(data: any) {
    //     console.log(data) 
    //     const grecaptcha = (window as any).grecaptcha
    //     if (grecaptcha) {
    //         grecaptcha.ready(() => {
    //             grecaptcha.execute(grecaptcha_site_key, {action: 'submit'}).then((token: any) => {
    //                 // Add your logic to submit to your backend server here.
    //                 // console.log(token)
    //                 var country: Country | null = null 
    //                 if (data.countrycode !== '' && data.countrycode !== null && data.countrycode !== undefined) {
    //                     country = this.state.phonecodes.find((c: Country) => c.code === data.countrycode)
    //                 }
    //                 var mobileno = ''
    //                 if (data.mobileno !== '' && data.mobileno !== null && data.mobileno !== undefined) {
    //                     if (country !== null) {
    //                         mobileno = country.dial_code + (data.mobileno.charAt(0)==='0' ? data.mobileno.substring(1):data.mobileno)
    //                     } else {
    //                         mobileno = data.mobileno
    //                     }
    //                 }
                         
    //                 const reqdata = {
    //                     token: token,
    //                     username: data.email,
    //                     email: data.email,
    //                     first_name: data.firstname,
    //                     last_name: data.lastname,
    //                     password: data.password,
    //                     display_name: data.displayname,
    //                     role: 2,
    //                     mobile: mobileno,
    //                     subscribed: true,
    //                     preflang: data.emaillang
    //                 }
    //                 this.registerUser(reqdata)
    //             })
    //         })
    //     }
    // }
    // async registerUser(reqdata: any) {
    //     try {
    //         const { data } = await registerUserAPI(reqdata)
    //         this.setState({...this.state, regsubmit: true, regsuccess: true})
    //     } catch(e) {
    //         console.log("Registration failed")
    //         this.setState({...this.state, regsubmit: true, regsuccess: false})
    //     }
        
    // }
    // handleClickShowPassword() {
    //     this.setState({ ...this.state, showpwd: !this.state.showpwd })
    // }
    
    // handleMouseDownPassword(event: React.MouseEvent<HTMLButtonElement>) {
    //     event.preventDefault()
    // }

    handleBack() {
        this.props.history.push(this.props.user.currentUri)
    }

    // handleEmailLanguageChange(event: SelectChangeEvent) {
    //     this.setState({ ...this.state, emaillang: event.target.value as string });
    // }

    // checkExisting(value: any) {
        
    //     return new Promise((resolve) => { 
    //         fetch(`${rest_endpoint}checkexistinguser/`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({
    //                 'username': value
    //             }) 
    //         })
    //         .then(response => {
    //             if (response.status===200) {
    //                 resolve(true)
    //             } else {
    //                 resolve(false)
    //             }
    //         })
    //         .catch((error) => {
    //             console.error('Error:', error)
    //             resolve(false)
    //         })
    //     })

    // }

    handleGSignInResponse(response: any) {
        const form = document.createElement('form');
        form.method = 'post';
        form.action = `${rest_endpoint}googlesignin/`;

        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = 'credential';
        hiddenField.value = response.credential;

        form.appendChild(hiddenField);
        document.body.appendChild(form);
        form.submit();
    }

    handleFBSignInResponse(response: any) {
        const form = document.createElement('form');
        form.method = 'post';
        form.action = `${rest_endpoint}facebooksignin/`;

        const idField = document.createElement('input');
        idField.type = 'hidden';
        idField.name = 'fbId';
        idField.value = response.id;
        form.appendChild(idField);
        const nameField = document.createElement('input');
        nameField.type = 'hidden';
        nameField.name = 'name';
        nameField.value = response.name;
        form.appendChild(nameField);
        const fnameField = document.createElement('input');
        fnameField.type = 'hidden';
        fnameField.name = 'first_name';
        fnameField.value = response.first_name;
        form.appendChild(fnameField);
        const lnameField = document.createElement('input');
        lnameField.type = 'hidden';
        lnameField.name = 'last_name';
        lnameField.value = response.last_name;
        form.appendChild(lnameField);
        const pictureField = document.createElement('input');
        pictureField.type = 'hidden';
        pictureField.name = 'picture';
        pictureField.value = response.picture.data.url;
        form.appendChild(pictureField);
        const emailField = document.createElement('input');
        emailField.type = 'hidden';
        emailField.name = 'email';
        emailField.value = response.picture.data.url;
        form.appendChild(emailField);
        
        document.body.appendChild(form);
        form.submit();
    }

    getFBprofileandsignin() {
        const FB = (window as any).FB
        FB.api(
            '/me',
            'GET',
            {"fields":"id,name,first_name,last_name,picture,email"},
            (response: any) => {
                // Submit fb profile to sign in
                this.handleFBSignInResponse(response)
            }
          );
    }

    handleFacebookSignInClick() {
        const FB = (window as any).FB
        FB.login((response: any) => {
            if (response.status === 'connected') {
                // Get fb profile and use fb profile to sign in.
                this.getFBprofileandsignin()
            } else {
            // The person is not logged into your webpage or we are unable to tell. 
            console.log("Cannot sign in via Facebook")
            }
        }, {scope: 'public_profile,email'})
    }

    

    componentDidMount() {
        if (this.googleSignInRef.current !== null) {
            const google = (window as any).google
            if (google) {
                google.accounts.id.initialize({
                    client_id: google_client_id,
                    ux_mode: 'popup',
                    callback: this.handleGSignInResponse
                    //login_uri: `${rest_endpoint}googlesignin`
                })
                // google.accounts.id.prompt()
                google.accounts.id.renderButton(this.googleSignInRef.current, {
                theme: 'outline',
                size: 'large',
                width: 335
                })
            }
        }
        
        // const FB = (window as any).FB
        // const script = document.createElement("script");
        // script.id = "recaptcha-script"
        // script.src = "https://www.google.com/recaptcha/api.js?render=6LeFHPQdAAAAAKPodnHr7DqCtpSlB31I5rQvatPy";
        // script.async = true;

        // document.head.appendChild(script);
        const recaptchaEl = document.getElementsByClassName('grecaptcha-badge') as HTMLCollectionOf<HTMLElement>
        if (recaptchaEl !== null && recaptchaEl !== undefined && recaptchaEl.length > 0) {
            // document.head.removeChild(script)
            recaptchaEl[0].style.visibility = 'hidden'
        }
        const locationHelper = locationHelperBuilder({})
        const redirectpath = locationHelper.getRedirectQueryParam(this.props)
        if (redirectpath) this.props.setCurrentUri(redirectpath)
    }
    componentDidUpdate() {
        // if (this.googleSignInRef.current !== null) {
        //     const google = (window as any).google
        //     if (google) {
        //         google.accounts.id.initialize({
        //             client_id: google_client_id,
        //             ux_mode: 'redirect',
        //             login_uri: `${rest_endpoint}googlesignin`
        //         })
        //         // google.accounts.id.prompt()
        //         google.accounts.id.renderButton(this.googleSignInRef.current, {
        //         theme: 'outline',
        //         size: 'large',
        //         width: 350
        //         })
        //     }
        // }
    }

    render() {
        const { classes, handleSubmit, control, watch, t, supportedLangs } = this.props
        return (
            <>
            <Grid container sx={{display: 'flex' }}>
                <Grid item xs={6} md={6}>
                <Grid container justifyContent="flex-start">
                    <IconButton
                        onClick={this.handleBack}
                        size="large"
                        sx={{
                            marginLeft: '7px',
                            marginTop: '7px'
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                <Grid container justifyContent="flex-end">
                    <LanguageToggler />
                </Grid>
                </Grid>
            </Grid>
            <Box sx={{margin: 'auto'}}>
                <Paper
                  className={classes.paper}
                >
                    {/* <Tabs value={this.state !== undefined ? this.state.signintab:0} onChange={this.handleSignInTabChange} centered>
                        <Tab label={t.signin !== undefined ? t.signin.signintab:'Sign in'} {...signinTabProps(0)} />
                        <Tab label={t.signin !== undefined ? t.signin.registertab:'Register'} {...signinTabProps(1)} />
                    </Tabs>
                    <SigninTabPanel value={this.state.signintab} index={0}> */}
                    <Stack direction='row' spacing={2}>

                    <Box sx={{
                        width: 335
                    }}>
                    <Grid container 
                        spacing={2}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        
                        >
                        <Grid item xs={12} sx={{ justifyContent: 'center' }}>
                        <img src={toAbsoluteUrl('/media/logos/pixartee-logo.png')} width={150} />
                        </Grid>
                        <Grid item xs={12}>
                        <Typography variant="h6">Choose Sign In Method</Typography>
                        </Grid>
                    </Grid>
                    <Stack spacing={2} sx={{ paddingTop: '15px' }} textAlign='center'>
                        <Button 
                            variant="outlined" 
                            color="primary"
                            onClick={()=>{ this.handleSignInClick(authorize_url, 'internal') }}
                            startIcon={<Icon><Avatar src={toAbsoluteUrl('/media/logos/shirt-logo-32.png')} sx={{ width: 20, height: 20 }} /></Icon>}
                            sx={{ width: '100%' }}
                        >
                            Sign In with PixarTee
                        </Button>
                        <Typography variant='body1'>Not yet a member? <Link to="/signup">Sign Up</Link></Typography>
                        <Divider>Or</Divider>
                        <div ref={this.googleSignInRef} style={{ width: '100%' }}></div>
                        <Button variant="contained" color="secondary" onClick={this.handleFacebookSignInClick}>
                            Sign In with Facebook
                        </Button>
                        {/* <div className="fb-login-button" data-width="355" data-size="large" data-button-type="continue_with" data-layout="default" data-auto-logout-link="false" data-use-continue-as="true"></div> */}
                    </Stack> 
                    </Box>
                    <Box sx={{
                        width: 500,
                        display: {
                            xs: 'none',
                            sm: 'none',
                            md: 'none'
                        }
                    }}>
                        Member benefits
                    </Box>
                    </Stack>
                    
                      
                        
                    
                    
                    
                </Paper>
            </Box>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        setCurrentUri
    }, dispatch)
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user,
        //product: state.product
    }
}
const stateProps = returntypeof(mapStateToProps)
const dispatchProps = returntypeof(mapDispatchToProps)
export default compose(
    withRouter,
    withFormHook,
    withTranslationHook,
    withStyles(styles),
    connect<typeof stateProps, typeof dispatchProps, {}>(mapStateToProps, mapDispatchToProps)
)(Component)