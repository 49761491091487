import { Box, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar } from '@mui/material'
import * as React from 'react'
import { DesignTemplate } from '../../../../types'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

interface IYourTemplateListProps {
    templates?: DesignTemplate[] | null,
    onScrollList: (event: any) => void
    onDragItem: (item: DesignTemplate) => void
    onAddTemplateItembarClick: (item: DesignTemplate) => void
    onEditTemplateItembarClick: (item: DesignTemplate) => void
    onDeleteTemplateItembarClick: (itemId: string) => void
  }

const YourTemplateList = (props: IYourTemplateListProps) => {
    const { templates, onScrollList, onDragItem,
      onAddTemplateItembarClick,
      onEditTemplateItembarClick,
      onDeleteTemplateItembarClick } = props
       

    return (
      <>
      <ImageList
        variant="quilted"
        onScroll={onScrollList} 
        sx={{ height: 180 }} cols={3} rowHeight={100}>
        <>
        {templates?.map((item: DesignTemplate) => (
          <ImageListItem key={item.id}>
            <img
              src={`${item.snapshot}`}
              srcSet={`${item.snapshot}`}
              loading="lazy"
              draggable="true"
              onDragStart={(e) => { onDragItem(item) }}
            />
            <ImageListItemBar
              actionIcon={
                <>
                <IconButton
                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  aria-label={`Add this template to design`}
                  onClick={() => { onAddTemplateItembarClick(item)}}
                >
                  <AddIcon />
                </IconButton>
                <IconButton
                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  aria-label={`Edit info of this template`}
                  onClick={() => { onEditTemplateItembarClick(item)}}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  aria-label={`Remove this template from your storage`}
                  onClick={() => { onDeleteTemplateItembarClick(item.id)}}
                >
                  <DeleteIcon />
                </IconButton>
                </>
                
              }
            />
          </ImageListItem>
        ))}
        </>
      </ImageList>
      
      </>
    );
}

export default YourTemplateList