import axios from "axios"
import { rest_endpoint } from "../../settings"

export const getoffersAPI = (name: string | null, store: string | null, topup: boolean, page: number) => {
    let url = `${rest_endpoint}offers/?${name ? `&name=${name}`:''}${topup ? `&topup=true`:''}${store ? `&store=${store}`:''}&page=${page}`
    return axios.get<any>(url)
}

export const collectofferAPI = (offer: string) => {
    let formData = new FormData()
    formData.append("offer", offer)
    let url = `${rest_endpoint}offers/${offer}/collect/`
    return axios.post<any>(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

export const getcouponsAPI = (name: string | null, store: string | null, topup: boolean, page: number) => {
    let url = `${rest_endpoint}coupons/?${name ? `&name=${name}`:''}&topup=${topup}${store ? `&store=${store}`:''}&page=${page}`
    return axios.get<any>(url)
}