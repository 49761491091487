
// export const authorize_url = 'http://localhost:8080/o/authorize';
// export const client_id = 'hmE6K9s81f7MYmeLbcbN2KHxJPTXzUX0GCvYdIgd';
// export const client_secret = 'OCmEsln54ehngC44YhexO04uJaKMqekkUz5dedcD64uDxKjh587sVUTMmntCLquPTu9YHixeC8UZwTRLK0bdq5tIdkpA6EPjC5fpbqfms06SkSa8i0B6tRCd0cEQglSr';
export const authorize_url = 'https://api.pixartee.com/o/authorize';
export const client_id = '9oF6b2r5YyGIRz14hX251IAf94XQvK5LYLdvhOpo';
export const client_secret = 'F8ppHHsN1IDx8rgNmHrKj8j3i3Nmck8xXfzar3k2anUaW4oSCmgCGeeApSsPK3L8fCHxPG8etz9mI43nURYPnv7dCvlTfxVL4U23YC4Nj9ikMZRJoXQf73a5DUPWouyw';
export const redirect_uri = encodeURIComponent(`${window.location.origin}/redirectafterlogin/`);
export const redirect_iframe_uri = encodeURIComponent(`${window.location.origin}/redirect-iframe.html`);
export const rest_endpoint = 'https://api.pixartee.com/';
export const public_url = 'https://www.pixartee.com'
export const google_client_id = '748541429508-2ucj4ichc32mbscgqct4g37a2irve3p3.apps.googleusercontent.com'
export const google_client_secret = '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'
export const grecaptcha_site_key = '6LeFHPQdAAAAAKPodnHr7DqCtpSlB31I5rQvatPy'
export const ig_authorize_url = 'https://api.instagram.com/oauth/authorize';
export const ig_app_client_id = '905079576850637'
export const ig_app_redirect_uri = encodeURIComponent('https://api.pixartee.com/auth_ig/')
export const ig_graph_url = 'https://graph.instagram.com/'
export const google_fonts_api_key = 'AIzaSyC8BRJiaz7kcxvmCqiK5pI8SHx4L5g8Pj4'
export const google_map_api_key = 'AIzaSyDEqp8VzL2f5hZ7xhH8mqQff7qicRZVu_w'
export const omise_test_pubkey = 'pkey_test_5rxkvo7jy47q2klkiam'
export const omise_test_seckey = 'skey_test_5rxkvo8rfo1bv2g1eq3'