import React from 'react'
import { ICartState } from "./CartRefContext"


export interface ICheckOutState {
    shippingaddr?: string | null
    paymentmethod?: string | null
    selectedcard: string | null
    carrier?: string | null
    cart: ICartState | null
}

export interface ICheckOutContext {
    checkoutstate: ICheckOutState | null,
    checkoutdispatch: any,
    checkoutrefs: any
}

const checkoutContext: ICheckOutContext = {
    checkoutstate: null,
    checkoutdispatch: null,
    checkoutrefs: null
}

export const CheckOutContext = React.createContext(checkoutContext)