import { ActionObject, CurrencyState } from '../types'
import AT from '../types/ActionTypes';


const supportedCurrencies = [
    {
        name: 'Thai Baht',
        symbol: '฿',
        isocode: 'THB'
    },
    {
        name: 'US Dollar',
        symbol: '$',
        isocode: 'USD'
    },
]

const browserLang = window.navigator.language ? window.navigator.language.toLowerCase().split("-")[0]:"en";

const initialState: CurrencyState = {
    name: 'Thai Baht',
    symbol: '฿',
    isocode: 'THB',
    supportedCurrencies: supportedCurrencies
}

const currencyReducer = (state: CurrencyState = initialState, action: ActionObject) => {
    switch (action.type) {
        case AT.CURRENCY_SET:
            return {
                ...state,
                name: action.name,
                symbol: action.symbol,
                isocode: action.isocode
            }
        default:
            return state
    }
}

export default currencyReducer