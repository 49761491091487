import React from "react"
import { returntypeof } from "react-redux-typescript"
import { Route, Switch } from "react-router-dom"
import { bindActionCreators } from "redux"
import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper";
import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";
import { Layout } from "../layouts"
import { UserState } from "../types";
import Base from "./Base"
import RedirectAfterLogin from "./RedirectAfterLogin";
import RedirectAfterIGLogin from "./RedirectAfterIGLogin";
import Signin from "./Signin"
import Signup from "./Signup";

const locationHelper = locationHelperBuilder({})
const now = (new Date()).getTime() / 1000
const userIsNotAuthenticated = connectedRouterRedirect<any, any>({
    // This sends the user either to the query param route if we have one, or to the landing page if none is specified and the user is already logged in
    redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || state.user.currentUri,
    // This prevents us from adding the query parameter when we send the user away from the login page
    allowRedirectBack: false,
    // This prevents us from adding the query parameter when we send the user away from the login page
    // Determine if the user is authenticated or not
    authenticatedSelector: state => !state.user.isLoggedIn || (state.user.tokenExpireOn <= now),
    // A nice display name for this check
    wrapperDisplayName: 'UserIsNotAuthenticated'
})

const RedirectedLogin = userIsNotAuthenticated(Signin)
const RedirectedSignUp = userIsNotAuthenticated(Signup)
const RedirectedRedirectAfterLogin = userIsNotAuthenticated(RedirectAfterLogin)
class Component extends React.Component {
    render() {
        return (
            <Switch>
                
                <Route exact path="/signin" component={RedirectedLogin}/>
                <Route exact path="/signup" component={RedirectedSignUp}/>
                <Route exact path="/redirectafterlogin" component={RedirectedRedirectAfterLogin}/>
                <Route exact path="/redirectafteriglogin" component={RedirectAfterIGLogin}/>
                <Route 
                  render={() => {
                      return (
                        <Layout>
                            <Base />
                        </Layout>
                      )
                  }}
                />
            </Switch>
            
        )
    }
}

export default Component