import * as React from 'react'
import { Layer, Group, Image } from 'react-konva'


interface MockupLayerProps {
    id: string
    mockup?: any
    x?: number
    y?: number
    w?: number
    h?: number
    scaleX?: number
    scaleY?: number
    moRef?: any
    visible: boolean
}

const MockupLayer = (props: MockupLayerProps) => {
    //console.log(props)
    //const [image, status] = useImage(props.mockup as string, 'anonymous')
    return (
    <Group
        id={props.id}
        ref={props.moRef}
    >
        {props.mockup !== undefined && (
            <Image 
            
            id={`${props.id}-img`}
            image={props.mockup}
            x={props.x}
            y={props.y}
            width={props.w}
            height={props.h}
            scaleX={props.scaleX}
            scaleY={props.scaleY}
          />
        )}
        
    </Group>
)}

export default MockupLayer