import * as React from 'react'
import { Group, Layer, Rect, Text } from 'react-konva'

interface PrintframeProps {
    id: string
    visible: boolean
    x?: number
    y?: number
    w?: number
    h?: number
    scaleX?: number
    scaleY?: number
    stroke?: string
    dash?: number[]
    corner?: number
    strokeWidth?: number
    showDefaultText: boolean
    pfRef?: any
    canvaswidth: number
    canvasheight: number
}

const PrintframeLayer = (props: PrintframeProps) => (
        
    <Group
        id={props.id}
        visible={props.visible}
        ref={props.pfRef}
    >
        <Rect 
            
            id={`${props.id}-rect`}
            x={props.x}
            y={props.y}
            width={props.w}
            height={props.h}
            scaleX={props.scaleX}
            scaleY={props.scaleY}
            dash={props.dash}
            stroke={props.stroke}
            strokeWidth={props.strokeWidth}
            fillEnabled={false}
            cornerRadius={props.corner}
          />
        {props.showDefaultText && (
        <Text
            id={`${props.id}-defaulttext`}
            x={(props.canvaswidth as number-190)/2}
            y={(props.canvasheight as number-220)/2}
            width={190}
            height={220}
            text={"Create your design here"}
            fontSize={36}
            fontFamily={"Poppins"}
            fill={props.stroke}
            opacity={0.8}
        />
        )}
    </Group>
    
)

export default PrintframeLayer