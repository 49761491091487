import * as React from 'react'
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Badge, Box, ButtonBase, Divider, Grid, IconButton, Modal, Paper, Stack, Typography } from '@mui/material';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import AddCardIcon from '@mui/icons-material/AddCard';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckIcon from '@mui/icons-material/Check';
import { AddShippingAddressForm } from './forms/AddShippingAddress';
import { ShippingAddress } from '../../../types';
import { CheckOutContext, ICheckOutState } from '../../../providers/CheckOutRefContext';
import { getshippingaddrAPI } from '../../../reducers/func/addressFunc';
import { EditShippingAddressForm } from './forms/EditShippingAddress';
import { PaymentSelection } from './PaymentSelection';
import { ManageCreditDebit } from './payments/ManageCreditDebit';
import GppGoodIcon from '@mui/icons-material/GppGood';
import { toAbsoluteUrl } from '../../../_helpers';
import SVG from 'react-inlinesvg'


const Accordion = styled((props: AccordionProps) => (
<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
border: `1px solid ${theme.palette.divider}`,
'&:not(:last-child)': {
    borderBottom: 0,
},
'&:before': {
    display: 'none',
},
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
<MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: '#ffffff' }} />}
    {...props}
/>
))(({ theme }) => ({

backgroundColor: theme.palette.secondary.main,
color: theme.palette.primary.contrastText,
flexDirection: 'row-reverse',
'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
},
'& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
},
// '& .Mui-expanded': {
//   backgroundColor: theme.palette.primary.main,
//   color: theme.palette.primary.contrastText
// }

}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
padding: theme.spacing(2),
borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const CheckOutInfo: React.FC = () => {
    const [expanded, setExpanded] = React.useState<string | false>('shippingaddress')
    const [openaddlocation, setOpenaddlocation] = React.useState(false)
    const [openeditlocation, setOpeneditlocation] = React.useState(false)
    const [shippingaddresses, setShippingAddrList] = React.useState<ShippingAddress[] | null>(null)
    const { checkoutstate, checkoutdispatch, checkoutrefs } = React.useContext(CheckOutContext)
    const { shippingaddr, paymentmethod, selectedcard, cart, carrier } = checkoutstate as ICheckOutState
    const { selectShippingAddr, selectPaymentMethod, selectPaymentCard } = checkoutdispatch

    const handleAccordionChange =
      (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleOpenAddLocation = () => {
        setOpenaddlocation(true)
    }

    const handleCloseAddLocation = () => {
        setOpenaddlocation(false)
    }

    const handleOpenEditLocation = () => {
        setOpeneditlocation(true)
    }

    const handleCloseEditLocation = () => {
        setOpeneditlocation(false)
    }

    const handleAfterAddLocationSuccess = () => {
        setOpenaddlocation(false)
        getshippingaddrAPI()
            .then((response) => {
                setShippingAddrList(response.data)
                // select default address if no address is selected
                if (!shippingaddr) {
                    const defaultaddr: ShippingAddress = response.data.find((a: ShippingAddress) => a.default)
                
                    selectShippingAddr(defaultaddr.id)
                } 
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const handleAfterEditLocationSuccess = () => {
        setOpeneditlocation(false)
        getshippingaddrAPI()
            .then((response) => {
                setShippingAddrList(response.data)
                // select default address if no address is selected
                if (!shippingaddr) {
                    const defaultaddr: ShippingAddress = response.data.find((a: ShippingAddress) => a.default)
                
                    selectShippingAddr(defaultaddr.id)
                } 
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const handleSelectShippingAddress = (addrId: string) => {
        setOpeneditlocation(false)
        selectShippingAddr(addrId)
    }

    const handleSelectPaymentMethod = (method: string) => {
        selectPaymentMethod(method)
    }

    const handleSelectPaymentCard = (card: string) => {
        selectPaymentCard(card)
    }

    React.useEffect(() => {
        if (!shippingaddresses) {
            getshippingaddrAPI()
            .then((response) => {
                setShippingAddrList(response.data)
                // select default address
                const defaultaddr: ShippingAddress = response.data.find((a: ShippingAddress) => a.default)
                
                selectShippingAddr(defaultaddr.id)
            })
            .catch((e) => {
                console.log(e)
            })
        }
        
    }, [])

    

    

    return (
        <>
        <Accordion expanded={expanded === 'shippingaddress'} onChange={handleAccordionChange('shippingaddress')}>
            <AccordionSummary
                aria-controls="shippingaddress-content"
                id="shippingaddress-header"
            >
                <Typography>Shipping Address</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2} justifyContent="flex-start">
                    <Grid item key={0}>
                        <Paper
                        elevation={2}
                            sx={{
                                height: 120,
                                width: 120,
                                display: 'flex',
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                                verticalAlign: "middle"
                            }}>
                            <ButtonBase onClick={handleOpenAddLocation}>
                            <Stack sx={{
                                height: 120,
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                            }}>
                                
                                <AddLocationIcon sx={{ fontSize: 40 }} />
                                
                                <Typography variant="caption">Add New Shipping Address</Typography>
                            </Stack>
                            </ButtonBase>
                            <Modal
                                open={openaddlocation}
                                onClose={(_, reason: any) => {
                                    if (reason !== 'backdropClick') {
                                        handleCloseAddLocation()
                                    }
                                }}
                                aria-labelledby="addlocation-modal-title"
                                aria-describedby="addlocation-modal-desc"
                                sx={{ zIndex: 5000 }}
                            >
                                <AddShippingAddressForm onClose={handleCloseAddLocation} onAddSuccess={handleAfterAddLocationSuccess} />
                            </Modal>
                        </Paper>
                    </Grid>
                    {shippingaddresses?.map((addr: ShippingAddress) => (
                    <Grid item key={addr.id}>
                        <Paper
                        elevation={2}
                            sx={{
                                height: 120,
                                width: 120,
                                display: 'flex',
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                                verticalAlign: "middle"
                            }}>
                            <ButtonBase onClick={handleOpenEditLocation}>
                            <Stack sx={{
                                height: 120,
                                width:120,
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                            }}>
                                <Badge color="info" badgeContent={<CheckIcon sx={{ fontSize: 8 }} />} overlap="circular" invisible={addr.id !== shippingaddr} >
                                
                                <LocationOnIcon sx={{ fontSize: 40 }} />
                                </Badge> 
                                <Typography variant="caption">{addr.address_name}</Typography>
                            </Stack>
                            </ButtonBase>
                            <Modal
                                open={openeditlocation}
                                onClose={(_, reason: any) => {
                                    if (reason !== 'backdropClick') {
                                        handleCloseEditLocation()
                                    }
                                }}
                                aria-labelledby="editlocation-modal-title"
                                aria-describedby="editlocation-modal-desc"
                                sx={{ zIndex: 5000 }}
                            >
                                <EditShippingAddressForm 
                                    shippingaddress={addr} 
                                    onClose={handleCloseEditLocation}
                                    onSelect={handleSelectShippingAddress}
                                    onEditSuccess={handleAfterEditLocationSuccess} />
                            </Modal>
                        </Paper>
                    </Grid>
                    ))}


                </Grid>
            </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'carrier'} onChange={handleAccordionChange('carrier')}>
            <AccordionSummary
                aria-controls="carrier-content"
                id="carrier-header"
            >
                <Typography>Shipping Carrier</Typography>
            </AccordionSummary>
            <AccordionDetails>
                
            </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'paymentmethod'} onChange={handleAccordionChange('paymentmethod')}>
            <AccordionSummary
                aria-controls="payment-content"
                id="payment-header"
            >
                <Typography>Payment Method</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction="row" spacing={0}>
                    <Grid item xs={8}>
                    <Stack>
                    <PaymentSelection 
                        paymentmethod={paymentmethod}
                        onChangePaymentMethod={handleSelectPaymentMethod} />
                    <div
                        hidden={paymentmethod !== 'credit_debit'}
                    >
                        <ManageCreditDebit selectedCard={selectedcard} onSelectCard={handleSelectPaymentCard} />
                    </div>
                    
                    </Stack>
                    </Grid>
                    <Grid item xs={4}>
                        <Stack>
                        <Stack direction={'row'}>
                            <GppGoodIcon fontSize='large' />
                            <Typography variant='h6'>Secure Checkout</Typography>
                        </Stack>
                        <Typography variant='caption' sx={{pl: 2}}>You can shop at pixartee.com with confidence. We have partnered with <a href='https://www.omise.co/'>Omise</a>, 
                        a leading payment gateway in ASEAN, to accept credit cards and other forms of electronic payments safely and securely for our customers.
                        The Omise Payment Gateway manages the complex routing of sensitive customer information through the secured payment processing networks. 
                        See <a href='https://www.omise.co/docs'>the documentation</a> to see how it works. The company adheres to strict industry standards for payment processing, including:</Typography>
                        <Typography variant='caption' sx={{pl: 1, ml: 4, display: 'list-item'}}>128-bit Secure Sockets Layer (SSL) technology for secure Internet Protocol (IP) transactions.</Typography>
                        <Typography variant='caption' sx={{pl: 1, ml: 4, display: 'list-item'}}>Industry leading encryption hardware and software methods and security protocols to protect customer information.</Typography>
                        <Typography variant='caption' sx={{pl: 1, ml: 4, display: 'list-item'}}>Compliance with the Payment Card Industry Data Security Standard (PCI DSS).</Typography>
                        <Typography variant='caption' sx={{pl: 2}}>For additional information regarding the privacy of your sensitive cardholder data, please read <a href="https://www.omise.co/en/privacy/international">the Omise Privacy Policy</a>.</Typography>
                        <br />
                        <Typography variant='caption' sx={{pl: 2}}>pixartee.com is registered with</Typography>
                        <SVG
                            src={toAbsoluteUrl("/media/svg/omise.svg")}
                            width={120}
                            style={{ paddingLeft: '20px' }}
                        />
                        </Stack>
                    </Grid>
                </Grid>
                
                
                {/* <Grid container spacing={2} justifyContent="flex-start">
                    <Grid item key={0}>
                        <Paper
                            elevation={2}
                            sx={{
                                height: 120,
                                width: 120,
                                display: 'flex',
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                                verticalAlign: "middle"
                            }}>
                            <ButtonBase>
                            <Stack sx={{
                                height: 120,
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                            }}>
                                
                                <AddCardIcon sx={{ fontSize: 40 }} />
                                
                                <Typography variant="caption">Add New Payment Method</Typography>
                            </Stack>
                            </ButtonBase>
                            
                        </Paper>
                    </Grid>
                </Grid> */}
            </AccordionDetails>
        </Accordion>
        </>
    )
}