import { Paper, ListItem, IconButton, ListItemAvatar, Avatar, ListItemText, Stack, Typography, Grid, Collapse, List, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, ListItemIcon, Checkbox } from '@mui/material'
import * as React from 'react'
import { useHistory } from 'react-router'
import { removecartitem } from '../../../../actions/shoppingcartAction'
import { useAppSelector, useAppDispatch } from '../../../../reducers/hooks'
import { CartAssetUsagePrice, CartCustomizedItem, CartItem, CartItemProductPrice, CartQuantity, Coupon, ProductPrice, Store } from '../../../../types'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface ICartListItemProps {
    store: Store
    item: CartItem
    coupons?: Coupon[] | null
    onCheckItem: (item: string, checked: boolean) => void
}

interface ICartDetailProps {
    id: number
    desc: string
    unitprice: number | string
    quantity: number
    discount: number
    subtotal: number | string
}

interface IAppliedCouponProps {
    id: string
    coupon_name: string
    discount: number
}

const CartDetailListItem = (props: ICartListItemProps) => {
    const { store, item, coupons, onCheckItem } = props
    const [open, setOpen] = React.useState(false)
    const history = useHistory()
    const user = useAppSelector((state) => state.user)
    const currency = useAppSelector((state) => state.currency)
    const dispatch = useAppDispatch()
    const handleUpdateCartItem = (id: string) => {
        const path = item.item_type && item.item_type !== 'customized' ? '/store':'/design'
        history.push({
            pathname: path,
            state: {
                isUpdating: true,
                orderId: id
            }
        })
    }

    const handleRemoveCartItem = (store_id: string, id: string) => {
        dispatch(removecartitem(store_id, id))
    }

    let cur_totalquantity = 0
    let cur_totalprice = 0.00
    let customizationfee = 0.00
    let itemusagefee = 0.00
    let unitcustomizationfee = currency.isocode.toLowerCase() === 'usd' ? 1.50:50
    let customizationdiscount = 0
    const uniqueviews = new Array(item.customizeditems?.map(it => [it.position,it.method]))
    let ci_productprice = item?.product?.prices?.find((p:ProductPrice) => p.currency === currency.isocode.toLowerCase())
    let productprice = 0.00
    if (ci_productprice) {
        productprice = ci_productprice.price
    }
    if (item) {
        
        uniqueviews?.forEach(() => 
        {
            customizationfee = customizationfee + unitcustomizationfee
            // if (currency.isocode.toLowerCase() === 'usd') {
            //     customizationfee = customizationfee + 1.50
            // } else {
            //     customizationfee = customizationfee + 50
            // }
                
        })

        

        if (item.customizeditems) {
            item.customizeditems.forEach((cc: CartCustomizedItem) => {
                if (cc.src_id && cc.src_owner !== parseInt(user.id as string) && cc.prices && !cc.isfree && cc.discount != null) {
                    let cc_usageprices = cc.prices.find((p: CartAssetUsagePrice) => p.currency === currency.isocode.toLowerCase())
                    // itemusagefee = itemusagefee + cc.prices[currency.isocode.toLowerCase()] - cc.prices[currency.isocode.toLowerCase()]*cc.discount/100
                    if (cc_usageprices) {
                        itemusagefee = itemusagefee + cc_usageprices.price - cc_usageprices?.price*cc.discount/100
                    }
                }
            })
        }
        
        cur_totalprice = item?.sizequantities?.map(it => it.quantity*(productprice - productprice*(item.product?.discount as number)/100 + customizationfee + itemusagefee)).reduce((p,c)=>p+c,0) as number
        cur_totalquantity = item?.sizequantities?.map(it => it.quantity).reduce((p,c)=>p+c,0) as number
    }

    

    let cartitemdetails: ICartDetailProps[] = []
    let k = 1
    item.sizequantities?.forEach((q: CartQuantity) => {
        cartitemdetails.push({
            id: k,
            desc: `${item.product?.typename} ${q.size_name ? `(${q.size_name})`:''}`,
            unitprice: productprice,
            quantity: q.quantity,
            discount: item.product?.discount as number,
            subtotal: q.quantity*(productprice-productprice*(item.product?.discount as number)/100)
        })
        k = k+1
    }) 

    uniqueviews?.forEach((el: any) => {
        cartitemdetails.push({
            id: k, 
            desc:`${el[0][1]} fee (${el[0][0]})`,
            unitprice: unitcustomizationfee,
            quantity: cur_totalquantity,
            discount: customizationdiscount,
            subtotal: cur_totalquantity*(unitcustomizationfee-unitcustomizationfee*customizationdiscount/100)
        })
        k = k + 1
    }) 

    item.customizeditems?.forEach((q: CartCustomizedItem) => {
        let q_usageprices = q.prices?.find((p: CartAssetUsagePrice) => p.currency === currency.isocode.toLowerCase())
        cartitemdetails.push({
            id: k,
            desc: `Digital asset usage fee (Item #${q.src_id} in ${q.position} position)`,
            unitprice: q.isfree || q.src_owner == user.id ? 'free':(q_usageprices ? q_usageprices.price:'N/A'),
            quantity: q.num_usages as number,
            discount: q.discount as number,
            subtotal: q.isfree || q.src_owner == user.id ? 'free':(q_usageprices ? q_usageprices.price-q_usageprices.price*(q.discount as number)/100:'N/A') 
        })
        k = k + 1
    })


    // determine applicable coupons for this item product and size
    let appliedcoupons: IAppliedCouponProps[] = []
    
    const handleOpenOrderDetail = () => {
        setOpen(!open)
    }

    return (
        <List disablePadding>
        <ListItem
            secondaryAction={
                <>
                <IconButton edge='end' aria-label='edit' onClick={() => { if (item.item_id) handleUpdateCartItem(item.item_id)}}>
                    <EditIcon sx={{ fontSize: 30 }} />
                </IconButton>
                <IconButton edge='end' aria-label='remove' onClick={() => { if (item.item_id) handleRemoveCartItem(store.id, item.item_id)}}>
                    <DeleteIcon sx={{ fontSize: 30 }} />
                </IconButton>
                <IconButton edge='end' aria-label='showdetail' onClick={handleOpenOrderDetail}>
                    {open ? <ExpandLess sx={{ fontSize: 30 }} />:<ExpandMore sx={{ fontSize: 30 }} />}
                    
                </IconButton>
                </>
            }
        >
        <ListItemIcon>
            <Checkbox
            edge="start"
            checked={item.checkingout}
            tabIndex={-1}
            disableRipple
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleIcon />}
            onChange={(e) => onCheckItem(item.item_id as string, item.checkingout)}
            />
        </ListItemIcon>
        <ListItemAvatar>
            <Avatar 
                src={item.previewthumb[0].thumb}
                sx={{ 
                    width: 64,
                    height: 64,
                    background: 'none'
                }} 
                variant='square' />
                
        </ListItemAvatar>
        <ListItemText
            primary={item.product?.typename}
            secondary={
                <React.Fragment>
                    <Stack>
                    <Typography variant='caption'>
                        Item Id: {item.item_id}
                    </Typography>
                    <Grid container direction="row" spacing={1}>
                        <Grid item>
                            <Typography variant='caption'>
                            Qty: {cur_totalquantity}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='caption'>
                            Item Subtotal: {currency.symbol}{cur_totalprice}
                            </Typography>
                        </Grid>
                    </Grid>
                    </Stack>
                    
                </React.Fragment>
                
            }
        />
        
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell align='right'>Unit Price</TableCell>
                            <TableCell align='right'>Qty.</TableCell>
                            <TableCell align='right'>Discount</TableCell>
                            <TableCell align='right'>Subtotal</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cartitemdetails.map((r: ICartDetailProps) => (
                            <TableRow key={r.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component={'th'} scope='row'>
                                    {r.desc}
                                </TableCell>
                                <TableCell align="right">{((r.unitprice as unknown as string) !== 'free' && (r.unitprice as unknown as string) !== 'N/A') ? `${currency.symbol}${r.unitprice}`:`${r.unitprice}`}</TableCell>
                                <TableCell align="right">{r.quantity}</TableCell>
                                <TableCell align="right">{r.discount}%</TableCell>
                                <TableCell align="right">{((r.subtotal as unknown as string) !== 'free' && (r.subtotal as unknown as string) !== 'N/A') ? `${currency.symbol}${r.subtotal}`:`${r.subtotal}`}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Collapse>
        {/* Collapse show coupons applied to this item */}
        </List>
    )
}

export default CartDetailListItem