import { combineReducers } from 'redux'
import userReducer, { userLogInSaga } from './userReducer'
import i18nReducer from '../i18n/i18nSlice'
import { all } from 'redux-saga/effects'
import productReducer from './productReducer'
import { productsaga } from './productReducer'
import sizeReducer from './sizeReducer'
import { sizesaga } from './sizeReducer'
import mockupReducer, { mockupsaga } from './mockupReducer'
import currencyReducer from './currencyReducer'
import shoppingcartReducer, { cartSaga } from './shoppingcartReducer'
//import designReducer, { designsaga } from './designReducer'


// const rootReducer = combineReducers({
//     user: userReducer,
// })

const rootReducer = combineReducers({
    user: userReducer,
    i18n: i18nReducer,
    currency: currencyReducer,
    cart: shoppingcartReducer
    // product: productReducer,
    // size: sizeReducer,
    // mockup: mockupReducer,
    // design: designReducer
})

export default rootReducer
export function* rootSaga() {
    yield all([
        userLogInSaga(),
        cartSaga()
    ])
}