import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { replacecart } from "../actions/shoppingcartAction";
import { loginFulfilled } from "../actions/userActions";
import authservice from "../providers/AuthInterface";
import { djangoOAuth2Provider } from "../providers/djangoOAuth2";
import { ActionObject, UserState, UserLoginAction, ShoppingCartState, CartItem, StoreCart } from "../types";
import AT from "../types/ActionTypes";
import { addcartitemAPI, getcartAPI } from "./func/cartFunc";
import { useAppSelector } from "./hooks";
import { RootState } from "./store";

const unauthenticatedState: UserState = {
    isLoggedIn: false,
    id: null,
    uid: null,
    name: null,
    email: null,
    accessToken: null,
    tokenExpireOn: -1,
    isError: false,
    errMsg: null,
    currentUri: '/',
    igid: null,
    igtoken: null,
    igtokenExpireOn: -1,
    iguser: null,
    fbtoken: null
}

const initialState = { ...unauthenticatedState }

// const session = authservice.restoreSession(djangoOAuth2Provider)
// if (session) {
//     const expiration = session.decodedIdToken.iat + session.expireDurationSeconds
//     initialState.isLoggedIn = true
//     initialState.id = session.decodedIdToken.sub
//     initialState.name = session.decodedIdToken.fullname
//     initialState.email = session.decodedIdToken.email
//     initialState.avatar = session.decodedIdToken.avatar
//     initialState.accessToken = session.accessToken
//     initialState.tokenExpireOn = expiration
// }
//  persistReducer<UserState, any>(
//    { storage, key: "ott-user" },
    
const userReducer = (state: UserState = initialState, action: ActionObject) => {
        switch (action.type) {
            case AT.USER_LOGIN_FULFILLED:
                return {
                    ...state,
                    id: action.id,
                    uid: action.uid,
                    isLoggedIn: true,
                    name: action.name,
                    email: action.email,
                    avatar: action.avatar,
                    omise_cust_id: action.omise_cust_id,
                    accessToken: action.token,
                    tokenExpireOn: action.expiration,
                    isError: false,
                    errMsg: null
                }
            case AT.USER_LOGOUT_FULFILLED:
                // authservice.invalidateSession()
                return { ...unauthenticatedState }
            case AT.USER_SET_CURRENT_URI:
                return {
                    ...state,
                    currentUri: action.currentUri
                }
            case AT.IG_LOGIN_FULFILLED:
                return {
                    ...state,
                    igid: action.igid,
                    iguser: action.iguser,
                    igtoken: action.igtoken,
                    igtokenExpireOn: action.igtokenExpireOn
                }
            default:
                return state
        }
    }

const getCart = (state: RootState) => state.cart
function* AddRemoteCartItem(store: string, items: CartItem[]) {
    try {
        yield all(items.map((ci: CartItem) => call(addcartitemAPI, store, ci)))
    }
    catch(e) {
        console.log("Error from function call.", e)
    }
}

export function* userLogInSaga() {
    // const additem = async (it: CartItem) => {
    //     await addcartitemAPI(it)
    // }
    yield takeLatest<UserLoginAction>(AT.USER_LOGIN, function* loginAction(arg: UserLoginAction) {
        try {
            let expiration = new Date().getTime() / 1000
            if (arg.decodedIdToken) {
                expiration = arg.decodedIdToken.exp
            } else {
                expiration = expiration + 3600
            }
            yield put(loginFulfilled(arg.decodedIdToken.sub, 
                                    arg.decodedIdToken.uid, 
                                    arg.decodedIdToken.fullname, 
                                    arg.decodedIdToken?.email, 
                                    arg.decodedIdToken?.avatar, 
                                    arg.decodedIdToken?.omise_cust_id,
                                    arg.accessToken, 
                                    expiration))
            const cart: ReturnType<typeof getCart> = yield select(getCart)
            if (cart.storecarts) {
                if (cart.storecarts.length > 0) {
                    // save cart to backend
                    // cart.cartitems.forEach((ci: CartItem) => {
                    //     AddRemoteCartItem(ci)
                    // })
                    yield all(cart.storecarts.map((sc: StoreCart) => 
                        sc.incart_items?.forEach((ci: CartItem) => {
                            call(addcartitemAPI, sc.store.id, ci)
                        })
                    ))
                }
            }
            const { data } = yield call(getcartAPI)
            yield put(replacecart(data))
        } 
        catch(e) {
            console.log("Error from function call.", e)
        }
        
    })
}

export default userReducer