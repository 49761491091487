import axios from "axios"
import { rest_endpoint } from "../../settings"
import { Tag } from "../../types"


export const designtplsaveAPI = (data: string, layers: string, istmp: boolean = false, onUploadProgress: any) => {
    let formData = new FormData()
    formData.append("snapshot", data)
    formData.append("layers", layers)
    formData.append("istmp", istmp.toString())
    let url = `${rest_endpoint}designtemplates/savetemplate/`
    return axios.post<any>(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
    })
} 

export const designtplupdateAPI = (id: number, data: string, layers: string, onUploadProgress: any) => {
    let formData = new FormData()
    formData.append("snapshot", data)
    formData.append("layers", layers)
    let url = `${rest_endpoint}designtemplates/${id}/updatetemplate/`
    return axios.post<any>(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
    })
} 

export const getpublicdesigntplAPI = (tags: any, page: number, sortby: string) => {
    let tagsstr = tags.map((t: Tag) => (t.title)).join()
    let url = `${rest_endpoint}designtemplates/?tags=${tagsstr}&page=${page}&ordering=${sortby}`
    return axios.get<any>(url)
}

export const getyourdesigntplAPI = (tags: any, page: number, sortby: string) => {
    let tagsstr = tags.map((t: Tag) => (t.title)).join()
    let url = `${rest_endpoint}designtemplates/yourtemplates/?tags=${tagsstr}&page=${page}&ordering=${sortby}`
    return axios.get<any>(url)
}

export const getshareddesigntplAPI = (tags: any, page: number, sortby: string) => {
    let tagsstr = tags.map((t: Tag) => (t.title)).join()
    let url = `${rest_endpoint}designtemplates/sharedtemplates/?tags=${tagsstr}&page=${page}&ordering=${sortby}`
    return axios.get<any>(url)
}

export const deletedesigntplAPI = (id: string) => {
    let url = `${rest_endpoint}designtemplates/${id}/deletetemplate/`
    return axios.post<any>(url)
}
