import React from 'react';
import ReactDOM from 'react-dom';
import App from './routes/App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import axios from 'axios';
import setupAxios from './reducers/setupAxios';
import { store } from './reducers/store';
import { persistor } from './reducers/store';
import {
  LayOutProvider,
  SplashscreenProvider,
  LayoutSplashScreen
} from './layouts'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import './index.scss';

setupAxios(axios, store)

ReactDOM.render(
  <LayOutProvider>
    <SplashscreenProvider>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={<LayoutSplashScreen/>}>
          <React.Suspense fallback={<LayoutSplashScreen />}>
            <App />
          </React.Suspense>
        </PersistGate>
      </Provider>
    </SplashscreenProvider>
  </LayOutProvider>
  ,
  document.getElementById('root') as HTMLElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
