import { Button, Divider, Grid, Paper, Stack, Typography } from '@mui/material'
import * as React from 'react'
import { connect } from 'react-redux'
import { returntypeof } from 'react-redux-typescript'
import compose from 'recompose/compose'
import { bindActionCreators } from 'redux'
import { applycoupon, deselectcartitemcheckout, selectcartitemcheckout } from '../actions/shoppingcartAction'
import { setCurrentUri } from '../actions/userActions'
import { Content } from '../layouts'
import { CartSummary } from '../layouts/components/widgets/CartSummary'
import { StoreOfferCollaspsible } from '../layouts/components/widgets/collaspsibles/StoreOfferCollaspsibles'
import CartDetailListItem from '../layouts/components/widgets/lists/CartDetailListItem'
import { ICartSummary, IAppliedCoupon, IItemSummary, CartContext, IStoreCartSummary } from '../providers/CartRefContext'
import { google_client_id } from '../settings'
import { CartAssetUsagePrice, CartCustomizedItem, CartItem, CartItemProductPrice, Coupon, ProductPrice, Store, StoreCart } from '../types'


class Component extends React.Component<any, 
    {
        cartsummary: ICartSummary | null
        // coupons: IAppliedCoupon[] | null
    }> {
    
    //itemsgroupbystore: any
    storesofferopen: any
    constructor(props: any) {
        super(props)
        this.changeSelectCartItem = this.changeSelectCartItem.bind(this)
        this.handleApplyCoupon = this.handleApplyCoupon.bind(this)
        const { user, cart, currency } = props
        // this.itemsgroupbystore = cart.cartitems?.reduce((group: any, item: CartItem) => {
        //     const { store_name } = item
        //     group[store_name as string] = group[store_name as string] ?? []
        //     group[store_name as string].push(item)
        //     return group
        // },{})
        

        this.state = {
            cartsummary: {
                merchandise_subtotal: 0.00,
                carts: []
                // items: []
            },
            // coupons: []
        }
        
    }

    changeSelectCartItem(item: string, ck: boolean) {
        if (!ck) {
            const { selectcartitemcheckout } = this.props
            selectcartitemcheckout(item)
        } else {
            const { deselectcartitemcheckout } = this.props
            deselectcartitemcheckout(item)
        }
    }

    updateCheckOutItems(props: any) {
        const { user, cart, currency } = props
        // this.itemsgroupbystore = cart.cartitems?.reduce((group: any, item: CartItem) => {
        //     const { store_name } = item
        //     group[store_name as string] = group[store_name as string] ?? []
        //     group[store_name as string].push(item)
        //     return group
        // },{})
        let carts: IStoreCartSummary[] = []
        let merchandise_subtotal = 0.00 
        cart.storecarts?.forEach((sc: StoreCart) => {
            let items: IItemSummary[] = []
            let storecart_subtotal = 0.00
            sc.incart_items?.forEach((c: CartItem) => {
                if (c.checkingout) {
                    let customizationfee = 0.00
                    let itemusagefee = 0.00
                    let unitcustomizationfee = currency.isocode.toLowerCase() === 'usd' ? 1.50:50
                    let customizationdiscount = 0
                    const uniqueviews = new Array(c.customizeditems?.map(it => [it.position,it.method]))
                    uniqueviews?.forEach(() => 
                    {
                        customizationfee = customizationfee + unitcustomizationfee
                    })
                    if (c.customizeditems) {
                        c.customizeditems.forEach((cc: CartCustomizedItem) => {
                            if (cc.src_id && cc.src_owner !== parseInt(user.id as string) && cc.prices && !cc.isfree && cc.discount != null) {
                                let cc_usageprices = cc.prices.find((p: CartAssetUsagePrice) => p.currency === currency.isocode.toLowerCase())
                                // itemusagefee = itemusagefee + cc.prices[currency.isocode.toLowerCase()] - cc.prices[currency.isocode.toLowerCase()]*cc.discount/100
                                if (cc_usageprices) {
                                    itemusagefee = itemusagefee + cc_usageprices.price - cc_usageprices?.price*cc.discount/100
                                }
                            }
                        })
                    }
                    let ci_productprice = c.product?.prices?.find((p: ProductPrice) => p.currency === currency.isocode.toLowerCase())
                    let productprice = 0.00
                    if (ci_productprice) {
                        productprice = ci_productprice.price
                    }
                    const quantity = c?.sizequantities?.map(it => it.quantity).reduce((p,c)=>p+c,0) as number
                    const item_subtotal = c?.sizequantities?.map(it => it.quantity*(productprice - productprice*(c.product?.discount as number)/100 + customizationfee + itemusagefee)).reduce((p,c)=>p+c,0) as number
                    // determine coupon discount for this item
                    // ?????
                    const item: IItemSummary = {
                        item_id: c.item_id as string,
                        quantity: quantity,
                        item_subtotal: item_subtotal
                    }
                    storecart_subtotal += item_subtotal
                    items.push(item)

                }
            })
            carts.push({
                store: sc.store.store_name,
                storecart_subtotal: storecart_subtotal,
                items: items,
                coupons: sc.coupons
            })
            merchandise_subtotal += storecart_subtotal
        })
        

        

        this.setState({
            ...this.state,
            cartsummary: {
                merchandise_subtotal: merchandise_subtotal,
                carts: carts
            }
        })
            
    }

    handleApplyCoupon(store: Store, coupon: Coupon) {
        const { applycoupon } = this.props
        applycoupon(store.id as string, coupon)
    }

    componentDidMount() {
        
        const recaptchaEl = document.getElementsByClassName('grecaptcha-badge') as HTMLCollectionOf<HTMLElement>
        if (recaptchaEl !== null && recaptchaEl !== undefined && recaptchaEl.length > 0) {
            // document.head.removeChild(script)
            recaptchaEl[0].style.visibility = 'hidden'
        }
        this.updateCheckOutItems(this.props)

    }

    componentDidUpdate() {
        
    }

    componentWillReceiveProps(nextProps:any) {
        this.updateCheckOutItems(nextProps)
    }

    render() {
        const { cart } = this.props
        return (
            <CartContext.Provider value={{
                cartstate: {...this.state},
                cartdispatch: {
                    changeSelectCartItem: this.changeSelectCartItem,
                    handleApplyCoupon: this.handleApplyCoupon
                },
                cartrefs: {}
            }}>
            <Content>
                <div style={{
                    paddingTop: '68px',
                    minHeight: '600px'
                }}>
                <Grid container 
                    direction="column" 
                    spacing={1}
                    sx={{
                        ml: {
                            lg: 2,
                            xl: 4
                        },
                        mr: {
                            lg: 2,
                            xl: 4
                        },
                        width: {
                            lg: 'calc(100% - 16px)'
                        }
                    }}
                    >
                    <Grid item>
                    <Typography variant='h4'>Cart</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" spacing={1}
                            sx={{
                                display: {
                                    xs: 'none',
                                    md: 'flex'
                                }
                            }}
                        >
                            <Grid item xs={7}>
                                <Stack spacing={1}>

                                
                                {/* {Object.entries(this.itemsgroupbystore).map(([key, value]) => ( */}
                                {cart.storecarts && cart.storecarts.map((sc: StoreCart) => (
                                    <Paper
                                        sx={{
                                            marginBottom: 2,
                                            p: 3
                                        }}
                                    >
                                    <Typography variant='h5'>{`${sc.store.store_name} (${sc.incart_items?.length} ${sc.incart_items && sc.incart_items.length > 1 ? 'items':'item'})`}</Typography>
                                    <StoreOfferCollaspsible store={sc.store} appliedcoupons={sc.coupons} />
                                    {sc.incart_items?.map((item: CartItem) => (
                                        <>
                                        <CartDetailListItem store={sc.store} item={item} coupons={sc.coupons} onCheckItem={this.changeSelectCartItem} />
                                        <Divider />
                                        </>
                                    ))}
                                    </Paper>
                                ))}
                                </Stack>
                                
                            
                            </Grid>
                            <Grid item xs={5}>
                                <CartSummary />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                </div>
            </Content>
            </CartContext.Provider>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        setCurrentUri, selectcartitemcheckout, deselectcartitemcheckout, applycoupon
    }, dispatch)
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user,
        cart: state.cart,
        currency: state.currency
    }
}
const stateProps = returntypeof(mapStateToProps)
const dispatchProps = returntypeof(mapDispatchToProps)
export default compose(
    connect<typeof stateProps, typeof dispatchProps, {}>(mapStateToProps, mapDispatchToProps)
)(Component);