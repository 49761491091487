import { Button, Grid, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import * as React from 'react'
import { Coupon, Store } from '../../../../types'

interface ICouponListItemProps {
    item: Coupon
    store: Store
    onApply: (store: Store, coupon: Coupon) => void
}

const CouponListItem = (props: ICouponListItemProps) => {
    const { item, store, onApply } = props

    let desc = ""
    if (item.offer.scheme === 0) {
        desc = `${item.offer.discount_percent}% discount of your total purchase`
    } 
    else if (item.offer.scheme === 1) {
        desc = `${item.offer.discount_amount} ${item.offer.discount_currency?.toUpperCase()} discount of your total purchase`
    } 
    else if (item.offer.scheme === 2) {
        desc = `Buy ${item.offer.min_buy} Get ${item.offer.amt_free} Free`
    } 
    return (
        <ListItem
            secondaryAction={
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                        width: 100
                    }} 
                    onClick = {() => onApply(store, item)}
                >
                    Apply
                </Button>
            }
        >
            <ListItemText
                primary={item.offer.offer_name}
                secondary={
                    <React.Fragment>
                        <Stack>
                        <Typography variant='caption'>
                            {desc}
                        </Typography>
                        <Grid container direction="row" spacing={1}>
                        <Grid item>
                            <Typography variant='caption'>
                            Valid from: {item.offer.start_on}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='caption'>
                            to: {item.offer.expired_on}
                            </Typography>
                        </Grid>
                    </Grid>
                        </Stack>
                    </React.Fragment>
                }>

            </ListItemText>
        </ListItem>
    )
}

export default CouponListItem