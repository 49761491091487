import * as React from 'react'
import { Dropdown, Button } from "react-bootstrap"
import { useAppDispatch, useAppSelector } from "../../../reducers/hooks"
import { toAbsoluteUrl } from "../../../_helpers"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { UserMenuDropdown } from "./dropdowns/UserMenuDropDown"
import { useDropdownToggle } from "react-overlays"
import { DropdownTopbarItemToggler } from "../../../_partials/dropdowns";
import { setLangAsync } from "../../../i18n/i18nSlice";
import { useDispatch } from "react-redux";
import { Avatar, IconButton, Menu, MenuItem, Typography } from "@mui/material";


export const LanguageToggler: React.FC = () => {
    const lang = useAppSelector((state) => state.i18n.lang)
    const supportedLangs = useAppSelector((state) => state.i18n.supportedLangs)
    // console.log(supportedLangs[lang])
    const dispatch = useDispatch()

    const [anchorElLang, setAnchorElLang] = React.useState<null | HTMLElement>(null);
    
    const handleOpenLangMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElLang(event.currentTarget);
    }

    const handleCloseLangMenu = () => {
        setAnchorElLang(null);
        
    }

    const handleSelectLang = (code: string) => {
        setAnchorElLang(null)
        dispatch(setLangAsync(code))
    }
    return (
        <>
        <IconButton
            size="large"
            aria-label="app language"
            aria-controls="menu-langbar"
            aria-haspopup="true"
            color="secondary"
            onClick={handleOpenLangMenu}
            sx={{
                width: '55px',
                height: '55px'
            }}
        >
            <Avatar 
                children={`${lang.toUpperCase()}`} 
                variant="rounded" 
                sx={{ width: 32, height: 32, bgcolor: '#222222' }} />
        </IconButton>
        <Menu
            id="menu-langbar"
            anchorEl={anchorElLang}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            marginThreshold={60}
            open={Boolean(anchorElLang)}
            onClose={handleCloseLangMenu}
            PaperProps={{  
                style: {  
                    width: 150,
                },  
            }}
        >
            {Object.entries(supportedLangs).map(
                    ([code, name]) => (
                    <MenuItem key={code} onClick={() => handleSelectLang(code)}>
                        <Avatar 
                            children={`${code.toUpperCase()}`} 
                            variant="square" 
                            sx={{ width: 16, height: 16, bgcolor: '#222222', fontSize: 10, mr: 1 }} />
                        <Typography textAlign="left" variant="button"><>{name}</></Typography>
                    </MenuItem>
                    )
            )}
                
        </Menu>
        {/* <Dropdown drop="down" alignRight>
            <Dropdown.Toggle
                as={DropdownTopbarItemToggler}
                id="dropdown-toggle-lang"
            >
                <div
                    className={
                        "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                    }
                >
                    <Button variant="outline-dark" className="btn-fixwidth100">
                    {supportedLangs[lang]}
                    </Button>
                </div>
                
                
                
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {Object.entries(supportedLangs).map(
                    ([code, name]) => (
                    <Dropdown.Item key={code} onClick={() => dispatch(setLangAsync(code))}>
                        <>{name}</>
                    </Dropdown.Item>
                    )
                )}
            </Dropdown.Menu>
        </Dropdown> */}
        
        </>
    )

}