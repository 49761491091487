import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme(
    {
        breakpoints: {
            // mobile < md
            // md < tablet  < lg
            // lg < laptop < md
            // desktop > xl
            // custom breakpoint
            values: {
                // xs: 0,
                // sm: 750,
                // md: 990,
                // lg: 1536,
                // xl: 2048,
                xs: 0,
                sm: 576,   
                md: 768,  
                lg: 1024,  
                xl: 1400
            },
        },
        typography: {
            fontFamily: ["Poppins", "Prompt", "sans-serif"].join(","),
            fontWeightLight: 200,
            fontWeightRegular: 300,
            fontWeightMedium: 400,
            fontWeightBold: 600
        },
        palette: {
            primary: {
                main: "#f02b3b",
                contrastText: "#ffffff"
            },
            secondary: {
                main: "#222222",
                contrastText: "#ffffff"
            },
            error: {
                main: "#f50057"
            }
        },
        components: {
            MuiPopover: {
                defaultProps: {
                    elevation: 2
                }
            },
            MuiButtonBase: {
                defaultProps: {
                    disableRipple: false
                }
            },
            MuiInput: {
                styleOverrides: {
                    underline: {
                        '&:focus:not($focused):before': {
                            borderBottom: '1px solid #000000'
                        },
                        '&:active:not($focused):before': {
                            borderBottom: '1px solid #000000'
                        },
                        '&:hover:not($disabled):before': {
                            borderBottom: '1px solid #000000'
                        }
                    }
                }
            }
        }
    }
);

export function MaterialUIThemeProvider(props: { children: any; }) {
    const { children } = props;
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}