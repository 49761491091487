import React, { createContext, useState, Dispatch, SetStateAction, useEffect, useContext, useCallback } from "react";

type Props = {
    children: React.ReactNode
}

interface SplashscreenContextData {
    count: number
    updateCount?: (cnt: number) => void 
}

const initialSplashscreen: SplashscreenContextData = {
    count: 0
}

const SplashscreenContext = createContext<SplashscreenContextData>(initialSplashscreen)

const SplashscreenProvider = ({children}:Props): JSX.Element => {
    const [count, setCount] = useState<number>(0)
    let visible = count > 0

    const updateCount = useCallback((cnt: number) => {
        setCount(cnt)
    }, [setCount])

    useEffect(() => {
        const splashScreen = document.getElementById("splash-screen");
    
        // Show SplashScreen
        if (splashScreen && visible) {
          splashScreen.classList.remove("hidden");
    
          return () => {
            splashScreen.classList.add("hidden");
          };
        }
    
        // Hide SplashScreen
        let timeout: any;
        if (splashScreen && !visible) {
          timeout = setTimeout(() => {
            splashScreen.classList.add("hidden");
          }, 3000);
        }
    
        return () => {
          clearTimeout(timeout);
        };
      }, [visible]);
      
    return (
        <SplashscreenContext.Provider value={{count, updateCount}}>
            {children}
        </SplashscreenContext.Provider>
    )
}

const LayoutSplashScreen = ({ visible = true }) => {
    // Everything are ready - remove splashscreen
    const {count, updateCount} = useContext(SplashscreenContext);
  
    useEffect(() => {
      if (!visible) {
        return;
      }
      if (updateCount) {
        updateCount(count + 1)

        return () => {
            updateCount(count - 1)
        }

      }
      
      return
      
    }, [updateCount, visible]);
  
    return null;
}

export {
    SplashscreenProvider,
    LayoutSplashScreen
}