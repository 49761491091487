import { Box, Chip, Collapse, Divider, IconButton, List, ListItem, ListItemText, Stack, Tab, Tabs, Typography } from '@mui/material'
import * as React from 'react'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { collectofferAPI, getcouponsAPI, getoffersAPI } from '../../../../reducers/func/offerFunc';
import { Coupon, Offer, Store } from '../../../../types';
import OfferListItem from '../lists/OfferListItem';
import CouponListItem from '../lists/CouponListItem';
import { CartContext } from '../../../../providers/CartRefContext';
import { useAppSelector } from '../../../../reducers/hooks';

interface IStoreOfferProps {
    store: Store
    appliedcoupons?: Coupon[] | null
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 0 }}>
            {children}
            </Box>
        )}
        </div>
    );
}

export const StoreOfferCollaspsible: React.FC<IStoreOfferProps> = (props:IStoreOfferProps) => {
    const { store, appliedcoupons } = props
    const [expand, setExpand] = React.useState(false)
    const [tab, setTab] = React.useState(0)
    const [offerlist, setOfferList] = React.useState<any | null>(null)
    const [offerlistpage, setOfferListPage] = React.useState(1)
    const [offerlisttotalpages, setOfferListTotalPages] = React.useState(0)
    const [couponlist, setCouponList] = React.useState<any | null>(null)
    const [couponlistpage, setCouponListPage] = React.useState(1)
    const [couponlisttotalpages, setCouponListTotalPages] = React.useState(0)
    const { cartstate, cartdispatch, cartrefs } = React.useContext(CartContext)
    const { handleApplyCoupon } = cartdispatch
    const cart = useAppSelector((state) => state.cart)
    
    const handleExpandOffer = () => {
        setExpand(!expand)
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue)
    }

    const handleRemoveAppliedCoupon = () => {
        console.info("Remove applied coupon")
    }

    const handleCollectOffer = (offer: string) => {
        collectofferAPI(offer)
        .then((response) => {
            return getoffersAPI(null, store.id, false, 1)
        })
        .then((response) => {
            setOfferList(response.data.results)
            setOfferListPage(1)
            setOfferListTotalPages(response.data.total_pages)
        })
        .catch((e) => {
            console.log(e)
        })
    }

    const handleScrollOfferList = (event: any) => {
        if (event.currentTarget.scrollHeight - event.currentTarget.scrollTop == 240) {
            if (offerlistpage + 1 <= offerlisttotalpages) {
                getoffersAPI(null, store.id, false, offerlistpage+1)
                .then((response) => {
                    setOfferList(response.data.results)
                    setOfferListPage(offerlistpage+1)
                    
                })
                .catch((e) => {
                    console.log(e)
                })
            }
            
        }
    }

    const handleSearchCoupons = () => {
        getcouponsAPI(null, store.id, false, 1)
        .then((response) => {
            setCouponList(response.data.results)
            setCouponListPage(1)
            setCouponListTotalPages(response.data.total_pages)
        })
        .catch((e) => {
            console.log(e)
        })
    }

    const handleScrollCouponList = (event: any) => {
        if (event.currentTarget.scrollHeight - event.currentTarget.scrollTop == 240) {
            if (couponlistpage + 1 <= couponlisttotalpages) {
                getcouponsAPI(null, store.id, false, couponlistpage+1)
                .then((response) => {
                    setCouponList(response.data.results)
                    setCouponListPage(couponlistpage+1)
                    
                })
                .catch((e) => {
                    console.log(e)
                })
            }
            
        }
    }

    const handleApplyStoreCoupon = (store: Store, coupon: Coupon) => {
        handleApplyCoupon(store, coupon)
    }

    if (!offerlist) {
        getoffersAPI(null, store.id, false, 1)
        .then((response) => {
            setOfferList(response.data.results)
            setOfferListPage(1)
            setOfferListTotalPages(response.data.total_pages)
        })
        .catch((e) => {
            console.log(e)
        })
    }

    if (!couponlist) {
        getcouponsAPI(null, store.id, false, 1)
        .then((response) => {
            setCouponList(response.data.results)
            setCouponListPage(1)
            setCouponListTotalPages(response.data.total_pages)
        })
        .catch((e) => {
            console.log(e)
        })
    }

    return (
        <List 
            sx={{ 
                border: 1,
                borderColor:'grey.400',
                borderRadius: 3,
                mt: 1
            }} 
            disablePadding>
            <ListItem
                key={`${store.store_name}-offers`}
                secondaryAction={
                    <IconButton edge='end' aria-label='show-offer' onClick={handleExpandOffer}>
                        {expand ? <ExpandLess />:<ExpandMore />}
                    </IconButton>
                }
            >
            <ListItemText 
                primary={`${store.store_name}'s Offers`}
                secondary={
                    <React.Fragment>
                        <Stack direction="row" flexWrap="wrap" spacing={1}>
                        {appliedcoupons && appliedcoupons.map((coupon: Coupon) => (
                            <Chip 
                            label={coupon.offer.offer_name}
                            onDelete={handleRemoveAppliedCoupon}
                            
                            />
                        ))}
                        
                        
                        </Stack>
                    </React.Fragment>
                }
            />

            </ListItem>
            <Collapse in={expand} timeout="auto" unmountOnExit>
                <Stack spacing={1}>
                    <Tabs value={tab} onChange={handleTabChange}>
                        <Tab label="Offers" id="tab-0" aria-controls="tab-0" />
                        <Tab label="Collected Coupons" id="tab-1" aria-controls="tab-1" />
                    </Tabs>
                    <TabPanel value={tab} index={0}>
                        <List
                            sx={{
                                width: '100%',
                                height: 240,
                                overflow: 'auto',
                                pl: 2,
                                pr: 2 
                            }}
                            onScroll={handleScrollOfferList}
                        >
                            {offerlist && offerlist.map((offer:Offer) => (
                                <>
                                <OfferListItem item={offer} onCollect={handleCollectOffer} />
                                <Divider />
                                </>
                            ))}
                        </List>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <List
                            sx={{
                                width: '100%',
                                height: 240,
                                overflow: 'auto',
                                pl: 2,
                                pr: 2 
                            }}
                            onScroll={handleScrollOfferList}
                        >
                            {couponlist && couponlist.map((coupon:Coupon) => (
                                <>
                                <CouponListItem item={coupon} store={store} onApply={handleApplyStoreCoupon} />
                                </>
                            ))}
                        </List>
                    </TabPanel>
                </Stack>
            
            </Collapse>
        </List>
    )
}