import axios from "axios"
import { rest_endpoint } from "../../settings"
import { Tag } from "../../types"

export const graphicuploadAPI = (file: File, onUploadProgress: any) => {
    let formData = new FormData()
    formData.append("uploadgraphic", file)
    formData.append("graphicname", file.name)
    formData.append("graphictype", file.type)
    let url = `${rest_endpoint}graphics/uploadgraphic/`
    return axios.post(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
    })
}

export const getyourgraphicAPI = (tags: any, page: number, sortby: string) => {
    let tagsstr = tags.map((t: Tag) => (t.title)).join()
    let url = `${rest_endpoint}graphics/yourgraphics/?tags=${tagsstr}&page=${page}&ordering=${sortby}`
    return axios.get<any>(url)
}

export const getsharedgraphicAPI = (tags: any, page: number, sortby: string) => {
    let tagsstr = tags.map((t: Tag) => (t.title)).join()
    let url = `${rest_endpoint}graphics/sharedgraphics/?tags=${tagsstr}&page=${page}&ordering=${sortby}`
    return axios.get<any>(url)
}

export const getpublicgraphicAPI = (tags: any, page: number, sortby: string) => {
    let tagsstr = tags.map((t: Tag) => (t.title)).join()
    let url = `${rest_endpoint}graphics/?tags=${tagsstr}&page=${page}&ordering=${sortby}`
    return axios.get<any>(url)
}

export const graphicpublishAPI = (data: string, name: string, onUploadProgress: any) => {
    let formData = new FormData()
    formData.append("publishdata", data)
    formData.append("publishname", name)
    let url = `${rest_endpoint}graphics/publishgraphic/`
    return axios.post<any>(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
    })
} 

export const deletegraphicAPI = (id: string) => {
    let url = `${rest_endpoint}graphics/${id}/deletegraphic/`
    return axios.post<any>(url)
}