import * as React from 'react'
import { toAbsoluteUrl } from '../../../../_helpers'
import SVG from 'react-inlinesvg'
import { makeStyles } from '@mui/styles';
import { DesignContext, DesignLayer, DesignRefs, DesignState, DesignView } from '../../../../providers/DesignRefContext';
import { DesignLayerListItem } from '../lists/types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, Popper } from '@mui/material';
import { DropResult } from 'react-beautiful-dnd';
import DraggableDesignLayerList from '../lists/DraggableDesignLayerList';
import DesignLayerTool from '../sidebartools/DesignLayerTool';
import PublishTool from '../sidebartools/PublishTool';
import { generaterandomstr } from '../../../../utilities/utilities';
import SaveTemplateTool from '../sidebartools/SaveTemplate';

const useStyles = makeStyles((theme: any) => ({
    sidebar: {
        position: 'fixed' as 'fixed',
        backgroundColor: theme.palette.secondary.main,
        paddingTop: theme.spacing(10),
        paddingLeft: theme.spacing(1),
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            visibility: 'hidden'
        }
    },
}))



export const DesignSidebar: React.FC = () => {
    const classes = useStyles()
    const { designstate, designdispatch, designrefs } = React.useContext(DesignContext)
    const { views, curview, canvaswidth, canvasheight, zoomlevel,
        yourphotosviewset, yourgraphicsviewset, yourtemplatesviewset } = designstate as DesignState
    const { updateLayers, setYourPhotosViewset, setYourGraphicsViewset, setYourTemplatesViewset, leaveDesignPage } = designdispatch
    const { exportstage } = designrefs as DesignRefs
    const [publishToolMenuAnchorEl, setPublishToolMenuAnchorEl] = React.useState<HTMLElement | null>(null)
    const [publishBtnCls, setPublishBtnCls] = React.useState('')
    const [layerToolMenuAnchorEl, setLayerToolMenuAnchorEl] = React.useState<HTMLElement | null>(null)
    const [layerBtnCls, setLayerBtnCls] = React.useState('')
    const [opensavetemplatedialog, setOpensavetemplatedialog] = React.useState(false)
    
    const view = views?.find((v: DesignView) => v.view===curview)
    
    let frameX = (canvaswidth as number-(view?.previewwidth as number)*(1.1**(zoomlevel as number)))/2
    let frameY = (canvasheight as number-(view?.previewheight as number)*(1.1**(zoomlevel as number)))/2
    let frameW = (view?.previewwidth as number)*(1.1**(zoomlevel as number))
    let frameH = (view?.previewheight as number)*(1.1**(zoomlevel as number))

    const openPublishToolMenu = Boolean(publishToolMenuAnchorEl)
    const publishToolMenuId = openPublishToolMenu ? 'publish-tool-popper' : undefined

    const openLayerToolMenu = Boolean(layerToolMenuAnchorEl)
    const layerToolMenuId = openLayerToolMenu ? 'layer-tool-popper' : undefined

    const handleLayerToolClicked = (event: React.MouseEvent<HTMLElement>) => {
        if (layerBtnCls === '') {
            setLayerBtnCls('active')
        } else {
            setLayerBtnCls('')
        }

        setLayerToolMenuAnchorEl(layerToolMenuAnchorEl ? null:event.currentTarget)
        

    }

    const handleApplyLayersEdit = (layers: DesignLayerListItem[]) => {
        setLayerToolMenuAnchorEl(null)
        setLayerBtnCls('')
        updateLayers(layers.reverse())
    }

    const handleCancelLayersEdit = () => {
        setLayerToolMenuAnchorEl(null)
        setLayerBtnCls('')
    }

    const handleOpenSaveTemplateDialog = () => {
        setOpensavetemplatedialog(true)
    } 

    const handlePublishToolClicked = (event: React.MouseEvent<HTMLElement>) => {
        if (publishBtnCls === '') {
            setPublishBtnCls('active')
        } else {
            setPublishBtnCls('')
        }

        setPublishToolMenuAnchorEl(publishToolMenuAnchorEl ? null:event.currentTarget)
        

    }

    const handleRefreshDesign = () => {
        window.location.reload()
    }

    // const handleCloseSaveTemplateDialog = () => {
    //     setOpensavetemplatedialog(false)
    // } 

    // const handleOnConfirmSaveTemplate = () => {
    //     // generate base64 thumb image for template
    //     const config = {
    //         mimeType: 'image/png',
    //         pixelRatio: 0.5
    //     }
    //     const exportdata = exportstage.current.toDataURL(config)
    //     const exportname = `${generaterandomstr(6)}`
    // }

    return (
        <>
        <div className={classes.sidebar} style={{
            top: 0,
            left:0,
            zIndex: 1200,
            width: '64px',
            height: '100%'}} >

                <div
                    className="btn btn-icon btn-sidebar btn-lg mr-1"
                    onClick={handleRefreshDesign}
                >
                    <span className={`svg-icon svg-icon-xxl`}>
                
                    <SVG
                        src={toAbsoluteUrl("/media/svg/flaticon/refresh.svg")}
                    />
                    </span>
                    
                </div> 
                <div
                    className="btn btn-icon btn-sidebar btn-lg mr-1"
                    onClick={handleOpenSaveTemplateDialog}
                >
                    <span className={`svg-icon svg-icon-xxl`}>
                
                    <SVG
                        src={toAbsoluteUrl("/media/svg/flaticon/floppy-disk.svg")}
                    />
                    </span>
                    
                </div>    
                <div
                    className={`btn btn-icon btn-sidebar btn-lg mr-1 ${publishBtnCls}`}
                    onClick={handlePublishToolClicked}
                >
                    <span className={`svg-icon svg-icon-xxl`}>
                
                    <SVG
                        src={toAbsoluteUrl("/media/svg/flaticon/upload.svg")}
                    />
                    </span>
                    
                </div>  
                <div
                    className="btn btn-icon btn-sidebar btn-lg mr-1"
                >
                    <span className={`svg-icon svg-icon-xxl`}>
                
                    <SVG
                        src={toAbsoluteUrl("/media/svg/flaticon/shapes.svg")}
                    />
                    </span>
                    
                </div>

                <div
                    className="btn btn-icon btn-sidebar btn-lg mr-1"
                >
                    <span className={`svg-icon svg-icon-xxl`}>
                
                    <SVG 
                        src={toAbsoluteUrl("/media/svg/flaticon/line.svg")}
                    />
                    </span>
                    
                </div>

                <div
                    className="btn btn-icon btn-sidebar btn-lg mr-1"
                >
                    <span className={`svg-icon svg-icon-xxl`}>
                
                    <SVG 
                        src={toAbsoluteUrl("/media/svg/flaticon/paint-brush.svg")}
                    />
                    </span>
                    
                </div>

                <div
                    className={`btn btn-icon btn-sidebar btn-lg mr-1 ${layerBtnCls}`}
                    onClick={handleLayerToolClicked}
                >
                    <span className={`svg-icon svg-icon-xxl`}>
                
                    <SVG 
                        src={toAbsoluteUrl("/media/svg/flaticon/layer.svg")}
                    />
                    </span>
                    
                </div>
                

        </div>
        {/* <Dialog
            open={opensavetemplatedialog}
            onClose={handleCloseSaveTemplateDialog}
            aria-labelledby="save-template-dialog-title"
            aria-describedby="save-template-dialog-description"
        >
        <DialogTitle id="save-template-dialog-title">
            {`Saving design as template`}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="save-template-description">
            {`You are about to save the design as template for later uses. Are you sure?`}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseSaveTemplateDialog}>No</Button>
            <Button onClick={handleOnConfirmSaveTemplate} autoFocus>
            Yes
            </Button>
        </DialogActions>
        </Dialog> */}
        <SaveTemplateTool
            opensavetemplatedialog={opensavetemplatedialog}
            exportstage={exportstage}
            view={view}
            frameX={frameX}
            frameY={frameY}
            frameW={frameW}
            frameH={frameH}
            yourtemplatesviewset={yourtemplatesviewset}
            setTemplatesViewset={setYourTemplatesViewset}
            setOpensavetemplatedialog={setOpensavetemplatedialog} />
        <Popper
            id={publishToolMenuId}
            open={openPublishToolMenu}
            anchorEl={publishToolMenuAnchorEl}
            placement='right-start'
        >
            <Paper 
                elevation={6} 
                sx={{
                    padding: '15px',
                    ml: 2
                }}>
                <PublishTool 
                    exportstage={exportstage}
                    yourphotosviewset={yourphotosviewset}
                    yourgraphicsviewset={yourgraphicsviewset}
                    setAnchorEl={setPublishToolMenuAnchorEl}
                    setBtnCls={setPublishBtnCls}
                    setPhotosViewset={setYourPhotosViewset}
                    setGraphicsViewset={setYourGraphicsViewset}
                />
                
                
            </Paper>
        </Popper>
        <Popper
            id={layerToolMenuId}
            open={openLayerToolMenu}
            anchorEl={layerToolMenuAnchorEl}
            placement='right-start'
        >
            <Paper 
                elevation={6} 
                sx={{
                    padding: '15px',
                    ml: 2
                }}>
                <DesignLayerTool 
                    view={view} 
                    applylayers={handleApplyLayersEdit}
                    canceledit={handleCancelLayersEdit} />
                
                
            </Paper>
        </Popper>
        </>
    )
}