import React, { Suspense } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect"
import { LayoutSplashScreen } from "../layouts"
import Design from "./Design"
import Home from "./Home"
import Privacy from "./Privacy"
import Terms from "./Terms"
import CheckOut from "./CheckOut"
import Cart from "./Cart"
import Signout from "./Signout"

const now = (new Date()).getTime() / 1000
const userIsAuthenticated = connectedRouterRedirect<any, any>({
    // The url to redirect user to if they fail
    redirectPath: state => state.user.currentUri === '/' ? '/':'/signin',
    // Determine if the user is authenticated or not
    authenticatedSelector: state => state.user.isLoggedIn && (state.user.tokenExpireOn > now),
    // A nice display name for this check
    wrapperDisplayName: 'UserIsAuthenticated'
})

const ProtectedCheckOut = userIsAuthenticated(CheckOut)
const ProtectedCart = userIsAuthenticated(Cart)
const ProtectedSignOut = userIsAuthenticated(Signout)

class Component extends React.Component {
    render() {
        return (
            <Suspense fallback={<LayoutSplashScreen />}>
                <Switch>
                    {
                        <Redirect exact from="/" to="/home" />
                    }
                    <Route exact path="/home" component={Home} />
                    <Route exact path="/design" component={Design} />
                    <Route exact path="/privacy" component={Privacy} />
                    <Route exact path="/terms" component={Terms} />
                    <Route exact path="/cart" component={ProtectedCart} />
                    <Route exact path="/checkout" component={ProtectedCheckOut} />
                    <Route exact path="/signout" component={ProtectedSignOut} />
                </Switch>
            </Suspense>
        )
    }
}

export default Component