import { Box } from '@mui/material';
import * as React from 'react'

interface TemplateTabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

export const TemplateTabPanel = (props: TemplateTabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`template-tabpanel-${index}`}
        aria-labelledby={`photo-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 1 , height: 250}}>
            {children}
            </Box>
        )}
        </div>
    );
}

export const templateTabProps = (index: number) => {
    return {
        id: `template-tab-${index}`,
        'aria-controls': `template-tabpanel-${index}`,
    };
}

