import React from 'react'
import { Coupon } from '../types'

export interface IItemSummary {
    item_id: string
    quantity: number
    item_subtotal: number
}

export interface IStoreCartSummary {
    store: string
    storecart_subtotal: number
    items: IItemSummary[] | null
    coupons?: Coupon[] | null
}
export interface ICartSummary {
    merchandise_subtotal: number
    carts: IStoreCartSummary[] | null
    // items: IItemSummary[] | null
}

export interface IAppliedCoupon {
    code: string
    discount: number
}

export interface ICartState {
    cartsummary: ICartSummary | null
    // coupons: IAppliedCoupon[] | null
}

export interface ICartContext {
    cartstate: ICartState | null
    cartdispatch: any
    cartrefs: any
}

const cartContext: ICartContext = {
    cartstate: null,
    cartdispatch: null,
    cartrefs: null
}

export const CartContext = React.createContext(cartContext)