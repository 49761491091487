import { useDispatch } from "react-redux";
import { useAppSelector } from "../reducers/hooks";
import { setLangAsync } from "./i18nSlice";

const useTranslations = () => {
  const dispatch = useDispatch();

  const t = useAppSelector((state) => state.i18n.translations);
  const lang = useAppSelector((state) => state.i18n.lang);
  const supportedLangs = useAppSelector(
    (state) => state.i18n.supportedLangs,
  );
  const status = useAppSelector((state) => state.i18n.status);
  const setLang = () => dispatch(setLangAsync(lang));
  return {
    t,
    lang,
    setLang,
    init: setLang,
    supportedLangs,
    status,
  };
}

export default useTranslations