import * as React from 'react'
import { CartAssetUsagePrice, CartCustomizedItem, CartItem, CartItemProductPrice, Coupon, ProductPrice } from '../../../../types'
import { useHistory } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../../../reducers/hooks'
import { removecartitem } from '../../../../actions/shoppingcartAction'
import { Avatar, Grid, IconButton, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

interface IShoppingCartListItemProps {
    item: CartItem
}

const ShoppingCartListItem = (props: IShoppingCartListItemProps) => {
    const { item } = props
    const history = useHistory()
    const user = useAppSelector((state) => state.user)
    const currency = useAppSelector((state) => state.currency)
    // const dispatch = useAppDispatch()
    // const handleUpdateCartItem = (id: string) => {
    //     history.push({
    //         pathname: '/design',
    //         state: {
    //             isUpdating: true,
    //             orderId: id
    //         }
    //     })
    // }

    // const handleRemoveCartItem = (id: string) => {
    //     dispatch(removecartitem(id))
    // }
    let cur_totalquantity = 0
    let cur_totalprice = 0.00
    let customizationfee = 0.00
    let itemusagefee = 0.00
    let unitcustomizationfee = currency.isocode.toLowerCase() === 'usd' ? 1.50:50
    let ci_productprice = item?.product?.prices?.find((p:ProductPrice) => p.currency === currency.isocode.toLowerCase())
    let productprice = 0.00
    if (ci_productprice) {
        productprice = ci_productprice.price as number
    }
    if (item) {
        const uniqueviews = new Set(item.customizeditems?.map(it => it.position))
        uniqueviews?.forEach((v) => 
        {
            customizationfee = customizationfee + unitcustomizationfee
                
        })

        

        if (item.customizeditems) {
            item.customizeditems.forEach((cc: CartCustomizedItem) => {
                if (cc.src_id && cc.src_owner !== parseInt(user.id as string) && cc.prices && !cc.isfree && cc.discount != null) {
                    let cc_usageprices = cc.prices.find((p: CartAssetUsagePrice) => p.currency === currency.isocode.toLowerCase())
                    // itemusagefee = itemusagefee + cc.prices[currency.isocode.toLowerCase()] - cc.prices[currency.isocode.toLowerCase()]*cc.discount/100
                    if (cc_usageprices) {
                        itemusagefee = itemusagefee + cc_usageprices.price - cc_usageprices?.price*cc.discount/100
                    }
                }
            })
        }
        
        cur_totalprice = item?.sizequantities?.map(it => it.quantity*(productprice - productprice*(item.product?.discount as number)/100 + customizationfee + itemusagefee)).reduce((p,c)=>p+c,0) as number
        cur_totalquantity = item?.sizequantities?.map(it => it.quantity).reduce((p,c)=>p+c,0) as number
    }

    return (
        <ListItem
            // secondaryAction={
            //     <>
            //     <IconButton edge='end' aria-label='edit' onClick={() => { if (item.order_id) handleUpdateCartItem(item.order_id)}}>
            //         <EditIcon />
            //     </IconButton>
            //     <IconButton edge='end' aria-label='remove' onClick={() => { if (item.order_id) handleRemoveCartItem(item.order_id)}}>
            //         <DeleteIcon />
            //     </IconButton>
            //     </>
            // }
        >
        <ListItemAvatar>
            <Avatar 
                src={item.previewthumb[0].thumb}
                sx={{ 
                    width: 64,
                    height: 64,
                    background: 'none'
                }} 
                variant='square' />
                
        </ListItemAvatar>
        <ListItemText
            primary={item.product?.typename}
            secondary={
                <React.Fragment>
                    <Stack>
                    <Typography variant='caption'>
                        Item Id: {item.item_id}
                    </Typography>
                    <Grid container direction="row" spacing={1}>
                        <Grid item>
                            <Typography variant='caption'>
                            Qty: {cur_totalquantity}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='caption'>
                            Price: {currency.symbol}{cur_totalprice}
                            </Typography>
                        </Grid>
                    </Grid>
                    </Stack>
                    
                </React.Fragment>
                
            }
        />
        </ListItem>
    )
}

export default ShoppingCartListItem