import * as React from 'react'
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import CircleIcon from '@mui/icons-material/Circle'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import HelpIcon from '@mui/icons-material/Help'
import AddIcon from '@mui/icons-material/Add'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import InfoIcon from '@mui/icons-material/Info'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useAppSelector, useAppDispatch } from '../../../../reducers/hooks';
import { Product, Color, SizeChart, SizeList, YourPhoto, Tag, YourGraphic, IGPhoto, Mockup, DesignTemplate, DesignLayerTemplate, CartItem } from '../../../../types';
import { Autocomplete, Avatar, Badge, Box, Button, ButtonGroup, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, Input, InputAdornment, InputLabel, LinearProgress, MenuItem, Paper, Popover, Radio, RadioGroup, Select, SelectChangeEvent, Stack, Tab, Tabs, TextareaAutosize, TextField, Tooltip } from '@mui/material';
import SVG from 'react-inlinesvg'
import { DesignContext, DesignLayer, DesignState, DesignView } from '../../../../providers/DesignRefContext';
import { makeStyles } from '@mui/styles';
import { fontFamily, height } from '@mui/system';
import { deletephotoAPI, getpublicphotoAPI, getsharedphotoAPI, getyourphotoAPI, igmedialistAPI, photouploadAPI } from '../../../../reducers/func/photoFunc';
import { fetchTagsAPI } from '../../../../reducers/func/tagFunc';
import { deletegraphicAPI, getpublicgraphicAPI, getsharedgraphicAPI, getyourgraphicAPI, graphicuploadAPI } from '../../../../reducers/func/graphicFunc';
import { Link } from 'react-router-dom';
import { ig_authorize_url, ig_app_client_id, ig_app_redirect_uri, ig_graph_url } from '../../../../settings';
import ColorPicker from 'material-ui-color-picker';
import { SliderPicker, SketchPicker } from 'react-color'
import html2canvas from 'html2canvas'
import { fetchProductAPI } from '../../../../reducers/func/productFunc';
import { fetchSizeAPI } from '../../../../reducers/func/sizeFunc';
import { fetchMockupAPI } from '../../../../reducers/func/mockupFunc';
import { TemplateTabPanel, templateTabProps } from '../tabs/DesignTemplateTabPanel';
import { deletedesigntplAPI, getpublicdesigntplAPI, getshareddesigntplAPI, getyourdesigntplAPI } from '../../../../reducers/func/designtplFunc';
import YourTemplateList from '../lists/YourTemplateList';
import SharedTemplateList from '../lists/SharedTemplateList';

  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: '#ffffff' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
    // '& .Mui-expanded': {
    //   backgroundColor: theme.palette.primary.main,
    //   color: theme.palette.primary.contrastText
    // }
    
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

  const useStyles = makeStyles((theme: any) => ({
    accordionexpanded: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    }
  }))

  interface PhotoTabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function PhotoTabPanel(props: PhotoTabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`photo-tabpanel-${index}`}
        aria-labelledby={`photo-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 , height: 250}}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  function photoTabProps(index: number) {
    return {
      id: `photo-tab-${index}`,
      'aria-controls': `photo-tabpanel-${index}`,
    };
  }

  interface IYourPhotoListProps {
    photos?: YourPhoto[] | null,
    currentUploadPhotos?: FileList | null, 
    isUploading: boolean,
    uploadprogress: number,
    onAddPhotoClick: () => void,
    onUploadClick: () => void,
    onScrollList: (event: any) => void
    onDragItem: (item: YourPhoto) => void
    onAddPhotoItembarClick: (item: YourPhoto) => void
    onEditPhotoItembarClick: (item: YourPhoto) => void
    onDeletePhotoItembarClick: (itemId: string, itemName: string) => void
  }

  interface ISharedPhotoListProps {
    photos?: YourPhoto[] | null,
    onScrollList: (event: any) => void
    onDragItem: (item: YourPhoto) => void
    onAddPhotoItembarClick: (item: YourPhoto) => void
    onViewPhotoItembarClick: (item: YourPhoto) => void
  }
  
  function YourPhotoList(props: IYourPhotoListProps) {
    const { photos, currentUploadPhotos, isUploading, uploadprogress, 
      onAddPhotoClick, onUploadClick, onScrollList, onDragItem,
      onAddPhotoItembarClick,
      onEditPhotoItembarClick,
      onDeletePhotoItembarClick } = props
       

    return (
      <>
      <ImageList
        variant="quilted"
        onScroll={onScrollList} 
        sx={{ height: 180 }} cols={3} rowHeight={100}>
        <Box border={1} sx={{ height: 100, borderColor: '#C5C5C5', borderRadius: 1 }}>
        <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ height: 100, marginLeft: '3px', marginRight: '3px' }} >
          {currentUploadPhotos !== null && currentUploadPhotos !== undefined ? (
            <>
            {isUploading ? (
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Box sx={{ width: '90%' }}>
                  <LinearProgress variant='determinate' value={uploadprogress} />
                </Box>
                <Typography variant='caption'>Uploading {currentUploadPhotos[0].name}</Typography>
              </Grid>
            ):(
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <IconButton
                  onClick={onUploadClick}
                >
                <CloudUploadIcon sx={{ fontSize: 40 }}/>
                </IconButton>
                <Typography variant='caption'>{currentUploadPhotos[0].name}</Typography>
              </Grid>
            )}
            </>
            
          ):(
          <IconButton
            onClick={onAddPhotoClick}
          >
          <AddIcon sx={{ fontSize: 40 }}/>
          </IconButton>
          )}
        </Grid>
        </Box>
        {photos?.map((item: YourPhoto) => (
          <ImageListItem key={item.id}>
            <img
              src={`${item.thumbnail}`}
              srcSet={`${item.thumbnail}`}
              alt={item.photo_name}
              loading="lazy"
              draggable="true"
              onDragStart={(e) => { onDragItem(item) }}
            />
            <ImageListItemBar
              actionIcon={
                <>
                <IconButton
                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  aria-label={`Add this photo to design`}
                  onClick={() => { onAddPhotoItembarClick(item)}}
                >
                  <AddIcon />
                </IconButton>
                <IconButton
                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  aria-label={`Edit info of this photo`}
                  onClick={() => { onEditPhotoItembarClick(item)}}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  aria-label={`Remove this photo from your storage`}
                  onClick={() => { onDeletePhotoItembarClick(item.id, item.photo_name)}}
                >
                  <DeleteIcon />
                </IconButton>
                </>
                
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
      
      </>
    );
  }

  function SharedPhotoList(props: ISharedPhotoListProps) {
    const { photos, onScrollList, onDragItem, 
      onAddPhotoItembarClick,
      onViewPhotoItembarClick } = props
    return (
      <ImageList
        variant="quilted"
        onScroll={onScrollList} 
        sx={{ height: 180 }} cols={3} rowHeight={100}>
        <>
        {photos?.map((item: YourPhoto) => (
          <ImageListItem key={item.id}>
            <img
              src={`${item.thumbnail}`}
              srcSet={`${item.thumbnail}`}
              alt={item.photo_name}
              loading="lazy"
              draggable="true"
              onDragStart={(e) => { onDragItem(item) }}
            />
            <ImageListItemBar
              actionIcon={
                <>
                <IconButton
                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  aria-label={`Add this photo to design`}
                  onClick={() => { onAddPhotoItembarClick(item) }}
                >
                  <AddIcon />
                </IconButton>
                <IconButton
                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  aria-label={`View info of this photo`}
                  onClick={() => { onViewPhotoItembarClick(item) }}
                >
                  <InfoIcon />
                </IconButton>
                
                </>
                
              }
            />
          </ImageListItem>
        ))}
        </>
      </ImageList>
    );
  }

  interface IIGPhotoListProps {
    photos?: IGPhoto[] | null,
    onScrollList: (event: any) => void
    onDragItem: (item: IGPhoto) => void
    onAddPhotoItembarClick: (item: IGPhoto) => void
    onViewPhotoItembarClick: (item: IGPhoto) => void
  }

  function IGPhotoList(props: IIGPhotoListProps) {
    const { photos, onScrollList, onDragItem, 
      onAddPhotoItembarClick,
      onViewPhotoItembarClick } = props
    return (
      <ImageList
        variant="quilted"
        onScroll={onScrollList} 
        sx={{ height: 180 }} cols={3} rowHeight={100}>
        <>
        {photos?.map((item: IGPhoto) => (
          <ImageListItem key={item.id}>
            <img
              src={`${item.media_url}`}
              srcSet={`${item.media_url}`}
              alt={item.caption}
              loading="lazy"
              draggable="true"
              onDragStart={(e) => { onDragItem(item) }}
            />
            <ImageListItemBar
              actionIcon={
                <>
                <IconButton
                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  aria-label={`Add this photo to design`}
                  onClick={() => { onAddPhotoItembarClick(item) }}
                >
                  <AddIcon />
                </IconButton>
                <IconButton
                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  aria-label={`View info of this photo`}
                  onClick={() => { onViewPhotoItembarClick(item) }}
                >
                  <InfoIcon />
                </IconButton>
                
                </>
                
              }
            />
          </ImageListItem>
        ))}
        </>
      </ImageList>
    );
  }


  interface GraphicTabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function GraphicTabPanel(props: GraphicTabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`graphic-tabpanel-${index}`}
        aria-labelledby={`graphic-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 , height: 250}}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  function graphicTabProps(index: number) {
    return {
      id: `graphic-tab-${index}`,
      'aria-controls': `graphic-tabpanel-${index}`,
    };
  }

  interface IYourGraphicListProps {
    graphics?: YourGraphic[] | null,
    currentUploadGraphics?: FileList | null, 
    isUploading: boolean,
    uploadprogress: number,
    onAddGraphicClick: () => void,
    onUploadClick: () => void,
    onScrollList: (event: any) => void
    onDragItem: (item: YourGraphic) => void
    onAddGraphicItembarClick: (item: YourGraphic) => void
    onEditGraphicItembarClick: (item: YourGraphic) => void
    onDeleteGraphicItembarClick: (itemId: string, itemName: string) => void
  }

  interface ISharedGraphicListProps {
    graphics?: YourGraphic[] | null,
    onScrollList: (event: any) => void
    onDragItem: (item: YourGraphic) => void
    onAddGraphicItembarClick: (item: YourGraphic) => void
    onViewGraphicItembarClick: (item: YourGraphic) => void
  }
  
  function YourGraphicList(props: IYourGraphicListProps) {
    const { graphics, currentUploadGraphics, isUploading, uploadprogress, 
      onAddGraphicClick, onUploadClick, onScrollList, onDragItem,
      onAddGraphicItembarClick,
      onEditGraphicItembarClick,
      onDeleteGraphicItembarClick } = props
    
    return (
      <>
      <ImageList
        variant="quilted"
        onScroll={onScrollList} 
        sx={{ height: 180 }} cols={3} rowHeight={100}>
        <Box border={1} sx={{ height: 100, borderColor: '#C5C5C5', borderRadius: 1 }}>
        <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ height: 100, marginLeft: '3px', marginRight: '3px' }} >
          {currentUploadGraphics !== null && currentUploadGraphics !== undefined ? (
            <>
            {isUploading ? (
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Box sx={{ width: '90%' }}>
                  <LinearProgress variant='determinate' value={uploadprogress} />
                </Box>
                <Typography variant='caption'>Uploading {currentUploadGraphics[0].name}</Typography>
              </Grid>
            ):(
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <IconButton
                  onClick={onUploadClick}
                >
                <CloudUploadIcon sx={{ fontSize: 40 }}/>
                </IconButton>
                <Typography variant='caption'>{currentUploadGraphics[0].name}</Typography>
              </Grid>
            )}
            </>
            
          ):(
          <IconButton
            onClick={onAddGraphicClick}
          >
          <AddIcon sx={{ fontSize: 40 }}/>
          </IconButton>
          )}
        </Grid>
        </Box>
        {graphics?.map((item: YourGraphic) => (
         
          <ImageListItem key={item.id}>
            <img
              src={`${item.thumbnail}`}
              srcSet={`${item.thumbnail}`}
              alt={item.graphic_name}
              loading="lazy"
              draggable="true"
              onDragStart={(e) => { onDragItem(item) }}
            />
            <ImageListItemBar
              actionIcon={
                <>
                <IconButton
                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  aria-label={`Add this graphic to design`}
                  onClick={() => { onAddGraphicItembarClick(item)}}
                >
                  <AddIcon />
                </IconButton>
                <IconButton
                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  aria-label={`Edit info of this graphic`}
                  onClick={() => { onEditGraphicItembarClick(item)}}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  aria-label={`Remove this graphic from your storage`}
                  onClick={() => { onDeleteGraphicItembarClick(item.id, item.graphic_name)}}
                >
                  <DeleteIcon />
                </IconButton>
                </>
                
              }
            />
          </ImageListItem>
          
        ))}
      </ImageList>
      
      </>
    );
  }

  function SharedGraphicList(props: ISharedGraphicListProps) {
    const { graphics, onScrollList, onDragItem, 
      onAddGraphicItembarClick,
      onViewGraphicItembarClick } = props
    return (
      <ImageList
        variant="quilted"
        onScroll={onScrollList} 
        sx={{ height: 180 }} cols={3} rowHeight={100}>
        <>
        {graphics?.map((item: YourGraphic) => (
          <ImageListItem key={item.id}>
            <img
              src={`${item.thumbnail}`}
              srcSet={`${item.thumbnail}`}
              alt={item.graphic_name}
              loading="lazy"
              draggable="true"
              onDragStart={(e) => { onDragItem(item) }}
            />
            <ImageListItemBar
              actionIcon={
                <>
                <IconButton
                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  aria-label={`Add this photo to design`}
                  onClick={() => { onAddGraphicItembarClick(item) }}
                >
                  <AddIcon />
                </IconButton>
                <IconButton
                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  aria-label={`View info of this photo`}
                  onClick={() => { onViewGraphicItembarClick(item) }}
                >
                  <InfoIcon />
                </IconButton>
                
                </>
                
              }
            />
          </ImageListItem>
        ))}
        </>
      </ImageList>
    );
  }
  
  const captureHTMLEl = async (el: HTMLElement) => {
        return await html2canvas(el)
  }
  
  export const DesignSimpleTools: React.FC = () => {
      const classes = useStyles()
      const [expanded, setExpanded] = React.useState<string | false>('chooseproduct')

      const [templatetab, setTemplatetab] = React.useState(0)
      const [helpYourTemplateAnchorEl, setHelpYourTemplateAnchorEl] = React.useState<HTMLElement | null>(null);
      const [helpSharedTemplateAnchorEl, setHelpSharedTemplateAnchorEl] = React.useState<HTMLElement | null>(null);
      const [opendeleteyourtemplatedialog, setOpendeleteyourtemplatedialog] = React.useState(false)
      const [deleteyourtemplateitemid, setDeleteyourtemplateitemid] = React.useState('')
      
      const [phototab, setPhototab] = React.useState(0)
      const [selectedUploadPhotos, setSelectedUploadPhotos] = React.useState<FileList | null>(null)
      const [uploadphotoprogress, setUploadphotoprogress] = React.useState(0)
      const [isUploadingPhoto, setIsUploadingPhoto] = React.useState(false)
      const [helpYourPhotoAnchorEl, setHelpYourPhotoAnchorEl] = React.useState<HTMLElement | null>(null);
      const [helpSharedPhotoAnchorEl, setHelpSharedPhotoAnchorEl] = React.useState<HTMLElement | null>(null);
      const [opendeleteyourphotodialog, setOpendeleteyourphotodialog] = React.useState(false)
      const [deleteyourphotoitemid, setDeleteyourphotoitemid] = React.useState('')
      const [deleteyourphotoitemname, setDeleteyourphotoitemname] = React.useState('')
    
      const [graphictab, setGraphictab] = React.useState(0)
      const [selectedUploadGraphics, setSelectedUploadGraphics] = React.useState<FileList | null>(null)
      const [uploadgraphicprogress, setUploadgraphicprogress] = React.useState(0)
      const [isUploadingGraphic, setIsUploadingGraphic] = React.useState(false)
      const [helpYourGraphicAnchorEl, setHelpYourGraphicAnchorEl] = React.useState<HTMLElement | null>(null);
      const [helpSharedGraphicAnchorEl, setHelpSharedGraphicAnchorEl] = React.useState<HTMLElement | null>(null);
      const [opendeleteyourgraphicdialog, setOpendeleteyourgraphicdialog] = React.useState(false)
      const [deleteyourgraphicitemid, setDeleteyourgraphicitemid] = React.useState('')
      const [deleteyourgraphicitemname, setDeleteyourgraphicitemname] = React.useState('')

      const [inputtext, setInputtext] = React.useState('Enter text')
      const [fontfamily, setFontFamily] = React.useState('inherit')
      const [fontsize, setFontSize] = React.useState('24')
      const [fontcolorpickerAnchorEl, setFontcolorpickerAnchorEl] = React.useState<HTMLElement | null>(null);
      const [fontcolor, setFontColor] = React.useState('#000000')

      // const [products, setProducts] = React.useState<Product[] | null>(null)
      // const [sizes, setSizes] = React.useState<SizeChart[] | null>(null)
      // const [mockups, setMockups] = React.useState<Mockup[] | null>(null)
      // const [selproduct, setSelProduct] = React.useState<number | undefined>(undefined)
      // const [selcolor, setSelColor] = React.useState<number | undefined>(undefined)
      
      const [tags, setTags] = React.useState<Tag[] | null>(null)
      const { designstate, designdispatch, designrefs } = React.useContext(DesignContext)
      const { 
              products, sizes, // selproduct, selcolor, 
              canvaswidth, canvasheight, 
              views, curview, zoomlevel,
              yourphotosviewset, yourgraphicsviewset, yourtemplatesviewset,
              sharedphotosviewset, sharedgraphicsviewset, sharedtemplatesviewset,
              igphotosviewset, fonts, cartitem } = designstate as DesignState
      const { 
              // fetchProduct, 
              selectProduct, 
              // loadViews,
              // loadMockups,
              setYourTemplatesViewset,
              setYourPhotosViewset,
              setYourGraphicsViewset,
              setSharedTemplatesViewset,
              setSharedPhotosViewset,
              setSharedGraphicsViewset,
              setIGPhotosViewset,
              setTemplateItem, setPhotoItem, setGraphicItem, setIGPhotoItem,
              addPhotoLayer, addGraphicLayer, addTextLayer, removeAllLayers,
              //updateCartItem, 
              updateCartItemQuantity,
              leaveDesignPage } = designdispatch

      const { isLoggedIn, tokenExpireOn, 
              igid, igtoken, igtokenExpireOn } = useAppSelector((state) => state.user)
      // const currency = useAppSelector((state) => state.currency)
      
      const yourphotoFile = React.useRef<any>(null)
      const yourgraphicFile = React.useRef<any>(null)

      const view = views?.find((v: DesignView) => v.view===curview)
      
      

      const handleChange =
      (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
      };
      const handleProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let sel_p: Product | undefined = products?.find((p: Product) => p.id == Number.parseInt(event.target.value)); 
        if (sel_p) {
          let defaultColor: Color = sel_p.colors?.find((c: Color) => c.isdefault) as Color
          selectProduct(sel_p.id, defaultColor.id, true)
          //selectProduct(sel_p.id)
        }

      };
      const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (cartitem) selectProduct(cartitem.product?.id as number, Number.parseInt(event.target.value))
        //selectColor(Number.parseInt(event.target.value))
      };

      const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>, item: number) => {
        let newquan = Number.parseInt(event.target.value)
        // const newsizequans = sizes?.map((sc: SizeChart) => 
        //   sc.id == item ? 
        //   {
        //     ...sc,
        //     quantity: newquan
        //   }:sc
        // ) 
        const sizequan = sizes?.find((sc: SizeChart) => sc.id == item)
        // let price = 0.00
        // if (currency.isocode.toLowerCase() === 'usd') {
        //   price = sizequan?.usd_price as number
        // }
        // else {
        //   price = sizequan?.thb_price as number
        // }
        // if (newsizequans) setSizes(newsizequans)
        updateCartItemQuantity(item, sizequan?.sizename, newquan)
      }

      const handlePhotoTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setPhototab(newValue);
      };

      const handleTemplateTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTemplatetab(newValue);
      };

      const handleAccountLoginClick = () => {
        leaveDesignPage('/signin')
      }

      /* Start YourTemplates functions */

      const handleYourTemplateHelpPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setHelpYourTemplateAnchorEl(event.currentTarget);
      };
    
      const handleYourTemplateHelpPopoverClose = () => {
        setHelpYourTemplateAnchorEl(null);
      };

      const handleYourTemplateScrollEnd = (event: any) => {
        if (event.currentTarget.scrollHeight - event.currentTarget.scrollTop == 180) {
          if ((yourtemplatesviewset?.page as number)+1 <= (yourtemplatesviewset?.totalpages as number)) {
            getyourdesigntplAPI(yourtemplatesviewset?.searchtags, (yourtemplatesviewset?.page as number)+1, yourtemplatesviewset?.sortby as string)
            .then((response) => {
              if (yourtemplatesviewset?.yourtemplates !== null) {
                setYourTemplatesViewset(yourtemplatesviewset?.yourtemplates?.concat(response.data.results), response.data.total_pages, (yourtemplatesviewset?.page as number)+1, yourtemplatesviewset?.searchtags, yourtemplatesviewset?.sortby as string)
              } else {
                setYourTemplatesViewset(response.data.results, response.data.total_pages, 1, yourtemplatesviewset?.searchtags, yourtemplatesviewset?.sortby as string)
              }
              
            })
            .catch((error) => {
              console.log("Error fetching your templates: ", error)
            })
          }
        }
      }

      const handleYourTemplateSortbyChange = (event: SelectChangeEvent) => {
        
        getyourdesigntplAPI(yourtemplatesviewset?.searchtags, 1, event.target.value)
            .then((response) => {
              setYourTemplatesViewset(response.data.results, response.data.total_pages, 1, yourtemplatesviewset?.searchtags, event.target.value)
            })
            .catch((error) => {
              console.log("Error fetching your templates: ", error)
            })
      }

      const handleYourTemplateTagChange = (event: React.SyntheticEvent, value: any) => {
        
        getyourdesigntplAPI(value, 1, yourtemplatesviewset?.sortby as string)
            .then((response) => {
              setYourTemplatesViewset(response.data.results, response.data.total_pages, 1, value, yourtemplatesviewset?.sortby)
            })
            .catch((error) => {
              console.log("Error fetching your templates: ", error)
            })
      }

      const handleOnDragTemplateStart = (item: DesignTemplate) => {
        setTemplateItem(item)
      }

      const handleOnAddTemplateItembarClick = (item: DesignTemplate) => {
        let frameX = (canvaswidth as number-(view?.previewwidth as number)*(1.1**(zoomlevel as number)))/2
        let frameY = (canvasheight as number-(view?.previewheight as number)*(1.1**(zoomlevel as number)))/2
        let frameW = (view?.previewwidth as number)*(1.1**(zoomlevel as number))
        let frameH = (view?.previewheight as number)*(1.1**(zoomlevel as number))
        let textlayers: DesignLayer [] = []

        item.layers?.forEach((l: DesignLayerTemplate) => {
          const jsonstr = l.layerprops.replace(/'/g,"\"")
          const layerprops = JSON.parse(jsonstr)
          
          if (l.layertypename === "photo") {
            let it = l.src as YourPhoto
            if (it.preview) {
              let previewphoto = new window.Image()
              previewphoto.crossOrigin = 'anonymous'
              previewphoto.src = it.preview
              previewphoto.onload = () => {
                let previewW = it.preview_w
                let previewH = it.preview_h
                let previewscaleX = layerprops.normW*(view?.previewwidth as number)*(1.1**(zoomlevel as number))/previewW
                let previewscaleY = layerprops.normH*(view?.previewheight as number)*(1.1**(zoomlevel as number))/previewH
                let previewX = frameX + layerprops.normX*frameW
                let previewY = frameY + layerprops.normY*frameH
                
                let actualW = it.actual_w
                let actualH = it.actual_h
                let exportscaleX = layerprops.normW*(view?.exportwidth as number)/actualW
                let exportscaleY = layerprops.normH*(view?.exportheight as number)/actualH
                let exportX = layerprops.normX*(view?.exportwidth as number)
                let exportY = layerprops.normY*(view?.exportheight as number)
                //const layerIdx = view?.layers?.length as number
                const layer: DesignLayer = {
                    id: `${view?.view}-layer-${l.orderidx}`,
                    props: {
                        idx: l.orderidx,
                        itemId: it.id,
                        itemname: it.photo_name,
                        itemowner: item.owner,
                        type: 'photo-layer',
                        usageprices: it.prices,
                        discount: it.discount,
                        isfree: it.is_free,
                        actualW: actualW,
                        actualH: actualH,
                        actual: it.actual,
                        previewW: previewW,
                        previewH: previewH,
                        preview: it.preview,
                        previewdata: previewphoto,
                        selected: true,
                        previewlayerAttr: {
                          ...layerprops.previewlayerAttr
                        },
                        exportlayerAttr: {
                          ...layerprops.exportlayerAttr
                        },
                        shapeGrpAttr: {
                          ...layerprops.shapeGrpAttr,
                          x: previewX,
                          y: previewY,
                          scaleX: previewscaleX,
                          scaleY: previewscaleY,
                            
                        },
                        shapeboxAttr: {
                          ...layerprops.shapeboxAttr,
                        },
                        shapeAttr: {
                          ...layerprops.shapeAttr
                        },
                        
                        exportshapeGrpAttr: {
                            ...layerprops.exportshapeGrpAttr,
                            x: exportX,
                            y: exportY,
                            scaleX: exportscaleX,
                            scaleY: exportscaleY,
                            
                        },
                        exportshapeAttr: {
                          ...layerprops.exportshapeAttr
                            
                        },
                        cropmaskAttr: {
                          ...layerprops.cropmaskAttr,
                            x: previewX,
                            y: previewY,
                            scaleX: previewscaleX,
                            scaleY: previewscaleY,
                            
                        },
                        
                        
                    }
    
                }
                addPhotoLayer([layer])
    
              }
            }
          }
          if (l.layertypename == "graphic") {
            let it = l.src as YourGraphic
            if (it.preview) {
              let previewgraphic = new window.Image()
              previewgraphic.crossOrigin = 'anonymous'
              previewgraphic.src = it.preview
              previewgraphic.onload = () => {
                let previewW = it.preview_w
                let previewH = it.preview_h
                let previewscaleX = layerprops.normW*(view?.previewwidth as number)*(1.1**(zoomlevel as number))/previewW
                let previewscaleY = layerprops.normH*(view?.previewheight as number)*(1.1**(zoomlevel as number))/previewH
                let previewX = frameX + layerprops.normX*frameW
                let previewY = frameY + layerprops.normY*frameH
                
                let actualW = it.actual_w
                let actualH = it.actual_h
                let exportscaleX = layerprops.normW*(view?.exportwidth as number)/actualW
                let exportscaleY = layerprops.normH*(view?.exportheight as number)/actualH
                let exportX = layerprops.normX*(view?.exportwidth as number)
                let exportY = layerprops.normY*(view?.exportheight as number)
                //const layerIdx = view?.layers?.length as number
                const layer: DesignLayer = {
                    id: `${view?.view}-layer-${l.orderidx}`,
                    props: {
                        idx: l.orderidx,
                        itemId: it.id,
                        itemname: it.graphic_name,
                        itemowner: it.owner,
                        type: 'graphic-layer',
                        usageprices: it.prices,
                        discount: it.discount,
                        isfree: it.is_free,
                        actualW: actualW,
                        actualH: actualH,
                        actual: it.actual,
                        previewW: previewW,
                        previewH: previewH,
                        preview: it.preview,
                        previewdata: previewgraphic,
                        selected: true,
                        previewlayerAttr: {
                          ...layerprops.previewlayerAttr
                        },
                        exportlayerAttr: {
                          ...layerprops.exportlayerAttr
                        },
                        shapeGrpAttr: {
                          ...layerprops.shapeGrpAttr,
                          x: previewX,
                          y: previewY,
                          scaleX: previewscaleX,
                          scaleY: previewscaleY,
                            
                        },
                        shapeboxAttr: {
                          ...layerprops.shapeboxAttr,
                        },
                        shapeAttr: {
                          ...layerprops.shapeAttr
                        },
                        
                        exportshapeGrpAttr: {
                            ...layerprops.exportshapeGrpAttr,
                            x: exportX,
                            y: exportY,
                            scaleX: exportscaleX,
                            scaleY: exportscaleY,
                            
                        },
                        exportshapeAttr: {
                          ...layerprops.exportshapeAttr
                            
                        }
                        
                    }
    
                }
                addGraphicLayer([layer])
    
              }
            }
          }
          if (l.layertypename == "text") {
            let previewW = layerprops.shapeGrpAttr.width
            let previewH = layerprops.shapeGrpAttr.height
            let previewscaleX = layerprops.normW*(view?.previewwidth as number)*(1.1**(zoomlevel as number))/previewW
            let previewscaleY = layerprops.normH*(view?.previewheight as number)*(1.1**(zoomlevel as number))/previewH
            let previewX = frameX + layerprops.normX*frameW
            let previewY = frameY + layerprops.normY*frameH
                
            let actualW = layerprops.exportshapeGrpAttr.width
            let actualH = layerprops.exportshapeGrpAttr.height
            let exportscaleX = layerprops.normW*(view?.exportwidth as number)/actualW
            let exportscaleY = layerprops.normH*(view?.exportheight as number)/actualH
            let exportX = layerprops.normX*(view?.exportwidth as number)
            let exportY = layerprops.normY*(view?.exportheight as number)

            //const layerIdx = view?.layers?.length as number
            const layer: DesignLayer = {
                id: `${view?.view}-layer-${l.orderidx}`,
                props: {
                  idx: l.orderidx,
                  type: 'text-layer',
                  pathpreset: 'horizontal',
                  actualW: actualW,
                  actualH: actualH,
                  //actualdata: actual,
                  previewW: previewW,
                  previewH: previewH,
                  //previewdata: preview,
                  selected: true,
                  previewlayerAttr: {
                    ...layerprops.previewlayerAttr
                  },
                  exportlayerAttr: {
                    ...layerprops.exportlayerAttr
                  },
                  shapeGrpAttr: {
                    ...layerprops.shapeGrpAttr,
                    x: previewX,
                    y: previewY,
                    scaleX: previewscaleX,
                    scaleY: previewscaleY,
                      
                  },
                  shapeboxAttr: {
                    ...layerprops.shapeboxAttr,
                  },
                  shapeAttr: {
                    ...layerprops.shapeAttr
                  },
                  
                  exportshapeGrpAttr: {
                      ...layerprops.exportshapeGrpAttr,
                      x: exportX,
                      y: exportY,
                      scaleX: exportscaleX,
                      scaleY: exportscaleY,
                      
                  },
                  exportshapeAttr: {
                    ...layerprops.exportshapeAttr
                      
                  }
                }
            }
            //addTextLayer([layer])
            textlayers.push(layer)
          }
        })


        //removeAllLayers()
        addTextLayer(textlayers, true)
        

      } 

      const handleOnEditTemplateItembarClick = (item: DesignTemplate) => {
        console.log(item)
      } 

      

      const handleOnDeleteTemplateItembarClick = (itemId: string) => {
        console.log(itemId)
        
        deletedesigntplAPI(itemId)
        .then((response) => {
          return getyourdesigntplAPI(yourtemplatesviewset?.searchtags, 1, yourtemplatesviewset?.sortby as string)
        }).then((templates) => {
          handleCloseDeleteYourtemplateDialog()
          setYourTemplatesViewset(templates.data.results, templates.data.total_pages, 1, yourtemplatesviewset?.searchtags, yourtemplatesviewset?.sortby as string)
        }).catch((e) => { 
          console.log("Error delete template: ", e)
        })
      } 

      const handleOpenDeleteYourtemplateDialog = (itemid: string) => {
        setDeleteyourtemplateitemid(itemid)
        setOpendeleteyourtemplatedialog(true)
      }
  
      const handleCloseDeleteYourtemplateDialog = () => {
        setDeleteyourtemplateitemid('')
        setOpendeleteyourtemplatedialog(false)
      } 

      const openYourTemplateHelpPopover = Boolean(helpYourTemplateAnchorEl)

      /* End YourTemplates functions */
      
      /* Start SharedTemplates functions */

      const handleSharedTemplateHelpPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setHelpSharedTemplateAnchorEl(event.currentTarget);
      };
    
      const handleSharedTemplateHelpPopoverClose = () => {
        setHelpSharedTemplateAnchorEl(null);
      };

      const handleSharedTemplateScrollEnd = (event: any) => {
        if (event.currentTarget.scrollHeight - event.currentTarget.scrollTop == 180) {
          if ((sharedtemplatesviewset?.page as number)+1 <= (sharedtemplatesviewset?.totalpages as number)) {
            getshareddesigntplAPI(sharedtemplatesviewset?.searchtags, (sharedtemplatesviewset?.page as number)+1, sharedtemplatesviewset?.sortby as string)
            .then((response) => {
              if (sharedtemplatesviewset?.sharedtemplates !== null) {
                setSharedTemplatesViewset(sharedtemplatesviewset?.sharedtemplates?.concat(response.data.results), response.data.total_pages, (sharedtemplatesviewset?.page as number)+1, sharedtemplatesviewset?.searchtags, sharedtemplatesviewset?.sortby as string)
              } else {
                setSharedTemplatesViewset(response.data.results, response.data.total_pages, 1, sharedtemplatesviewset?.searchtags, sharedtemplatesviewset?.sortby as string)
              }
              
            })
            .catch((error) => {
              console.log("Error fetching shared templates: ", error)
            })
          }
        }
      }

      const handleSharedTemplateSortbyChange = (event: SelectChangeEvent) => {
        
        getshareddesigntplAPI(sharedtemplatesviewset?.searchtags, 1, event.target.value)
            .then((response) => {
              setSharedTemplatesViewset(response.data.results, response.data.total_pages, 1, sharedtemplatesviewset?.searchtags, event.target.value)
            })
            .catch((error) => {
              console.log("Error fetching shared templates: ", error)
            })
      }

      const handleSharedTemplateTagChange = (event: React.SyntheticEvent, value: any) => {
        
        getshareddesigntplAPI(value, 1, sharedtemplatesviewset?.sortby as string)
            .then((response) => {
              setSharedTemplatesViewset(response.data.results, response.data.total_pages, 1, value, sharedtemplatesviewset?.sortby)
            })
            .catch((error) => {
              console.log("Error fetching shared templates: ", error)
            })
      }

      const openSharedTemplateHelpPopover = Boolean(helpSharedTemplateAnchorEl)

      /* End SharedTemplates functions */
      
      /* Start YourPhotos functions */
      
      

      const handleYourPhotoHelpPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setHelpYourPhotoAnchorEl(event.currentTarget);
      };
    
      const handleYourPhotoHelpPopoverClose = () => {
        setHelpYourPhotoAnchorEl(null);
      };

      const handleAddYourPhotoClick = () => {
        if (yourphotoFile.current !== null) {
          yourphotoFile.current.click()
        }
      } 

      const handleYourPhotoSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation()
        event.preventDefault()
        setSelectedUploadPhotos(event.target.files as FileList)
      }

      const uploadPhoto = () => {
        if (selectedUploadPhotos !== null) {
          let currentFile = selectedUploadPhotos[0]
          // setYourphotopage(1)
          // setYourphotosearchtags([])
          setIsUploadingPhoto(true)
          setUploadphotoprogress(0)
          photouploadAPI(currentFile, (event: any) => {
            setUploadphotoprogress(Math.round((100 * event.loaded) / event.total))
          }).then((response) => {
            setIsUploadingPhoto(false)
            setSelectedUploadPhotos(null)
            // return getyourphotoAPI(yourphotosearchtags, 1, yourphotosortby)
            return getyourphotoAPI(yourphotosviewset?.searchtags, 1, yourphotosviewset?.sortby as string)
          }).then((photos) => {
            // if (photos.data.total_pages !== undefined) {
            //   setYourphotototalpages(photos.data.total_pages)
            // }
            // setYourphotopage(1)
            // setYourphotos(photos.data.results)
            setYourPhotosViewset(photos.data.results, photos.data.total_pages, 1, yourphotosviewset?.searchtags, yourphotosviewset?.sortby as string)
          }).catch((e) => { 
            setIsUploadingPhoto(false)
            console.log("Error upload photo: ", e)
          })
        }

      }

      const handleYourPhotoScrollEnd = (event: any) => {
        if (event.currentTarget.scrollHeight - event.currentTarget.scrollTop == 180) {
          console.log("scroll end")
          if ((yourphotosviewset?.page as number)+1 <= (yourphotosviewset?.totalpages as number)) {
            getyourphotoAPI(yourphotosviewset?.searchtags, (yourphotosviewset?.page as number)+1, yourphotosviewset?.sortby as string)
            .then((response) => {
              // if (response.data.total_pages !== undefined) {
              //   setYourphotototalpages(response.data.total_pages)
              // }
              // setYourphotopage(yourphotopage+1)
              if (yourphotosviewset?.yourphotos !== null) {
                //setYourphotos(yourphotos.concat(response.data.results))
                setYourPhotosViewset(yourphotosviewset?.yourphotos?.concat(response.data.results), response.data.total_pages, (yourphotosviewset?.page as number)+1, yourphotosviewset?.searchtags, yourphotosviewset?.sortby as string)
              } else {
                //setYourphotos(response.data.results)
                setYourPhotosViewset(response.data.results, response.data.total_pages, 1, yourphotosviewset?.searchtags, yourphotosviewset?.sortby as string)
              }
              
            })
            .catch((error) => {
              console.log("Error fetching your photos: ", error)
            })
          }
        }
      }

      const handleYourPhotoSortbyChange = (event: SelectChangeEvent) => {
        
        getyourphotoAPI(yourphotosviewset?.searchtags, 1, event.target.value)
            .then((response) => {
              // if (response.data.total_pages !== undefined) {
              //   setYourphotototalpages(response.data.total_pages)
              // }
              // setYourphotopage(1)
              // setYourphotosortby(event.target.value)
              // setYourphotos(response.data.results)
              setYourPhotosViewset(response.data.results, response.data.total_pages, 1, yourphotosviewset?.searchtags, event.target.value)
            })
            .catch((error) => {
              console.log("Error fetching your photos: ", error)
            })
      }

      const handleYourPhotoTagChange = (event: React.SyntheticEvent, value: any) => {
        
        getyourphotoAPI(value, 1, yourphotosviewset?.sortby as string)
            .then((response) => {
              // if (response.data.total_pages !== undefined) {
              //   setYourphotototalpages(response.data.total_pages)
              // }
              // setYourphotopage(1)
              // setYourphotosearchtags(value)
              // setYourphotos(response.data.results)
              setYourPhotosViewset(response.data.results, response.data.total_pages, 1, value, yourphotosviewset?.sortby)
            })
            .catch((error) => {
              console.log("Error fetching your photos: ", error)
            })
      }

      const handleOnDragYourPhotoStart = (item: YourPhoto) => {
        setPhotoItem(item)
      }

      const handleOnAddPhotoItembarClick = (item: YourPhoto) => {
        if (item.preview !== undefined) {
          let previewphoto = new window.Image()
          previewphoto.crossOrigin = 'anonymous'
          previewphoto.src = item.preview
          previewphoto.onload = () => {
            let previewW = item.preview_w
            let previewH = item.preview_h
            let previewscale = (previewW >= previewH ? (view?.previewwidth as number)/previewW:(view?.previewheight as number)/previewH)*(1.1**(zoomlevel as number))
            let viewW = previewW*previewscale
            let viewH = previewH*previewscale
            let previewX = (canvaswidth as number-viewW*(1.1**(zoomlevel as number)))/2
            let previewY = (canvasheight as number-viewH*(1.1**(zoomlevel as number)))/2
              // let actualphoto = new window.Image()
              // actualphoto.src = item.actual
              // actualphoto.onload = () => {
            let actualW = item.actual_w
            let actualH = item.actual_h
            let exportscale = (actualW >= actualH ? (view?.exportwidth as number)/actualW:(view?.exportheight as number)/actualH)
            let exportW = actualW*exportscale
            let exportH = actualH*exportscale
          
            let exportX = ((view?.exportwidth as number) - exportW)/2
            let exportY = ((view?.exportheight as number) - exportH)/2
            const layerIdx = view?.layers?.length as number
            const layer: DesignLayer = {
                id: `${view?.view}-layer-${layerIdx}`,
                props: {
                    idx: layerIdx,
                    itemId: item.id,
                    itemname: item.photo_name,
                    itemowner: item.owner,
                    type: 'photo-layer',
                    usageprices: item.prices,
                    discount: item.discount,
                    isfree: item.is_free,
                    actualW: actualW,
                    actualH: actualH,
                    actual: item.actual,
                    previewW: previewW,
                    previewH: previewH,
                    preview: item.preview,
                    previewdata: previewphoto,
                    selected: true,
                    previewlayerAttr: {
                        opacity: 1,
                    },
                    exportlayerAttr: {
                        opacity: 1,
                    },
                    shapeGrpAttr: {
                        x: previewX,
                        y: previewY,
                        width: previewW,
                        height: previewH,
                        scaleX: previewscale,
                        scaleY: previewscale,
                        rotation: 0,
                        clipX: 0,
                        clipY: 0,
                        clipWidth: previewW,
                        clipHeight: previewH
                    },
                    shapeboxAttr: {
                        x: 0,
                        y: 0,
                        width: previewW,
                        height: previewH,
                        scaleX: 1,
                        scaleY: 1,
                        rotation: 0
                    },
                    shapeAttr: {
                        x: 0,
                        y: 0,
                        width: previewW,
                        height: previewH,
                        scaleX: 1,
                        scaleY: 1,
                        rotation: 0,
                        offsetX: 0,
                        offsetY: 0,
                        crop: {
                            x: 0,
                            y: 0,
                            width: previewW,
                            height: previewH
                        }
                    },
                    cropmaskAttr: {
                        x: previewX,
                        y: previewY,
                        width: previewW,
                        height: previewH,
                        scaleX: previewscale,
                        scaleY: previewscale,
                        rotation: 0
                    },
                    exportshapeGrpAttr: {
                        x: exportX,
                        y: exportY,
                        width: actualW,
                        height: actualH,
                        scaleX: exportscale,
                        scaleY: exportscale,
                        rotation: 0,
                        clipX: 0,
                        clipY: 0,
                        clipWidth: actualW,
                        clipHeight: actualH
                    },
                    exportshapeAttr: {
                        x: 0,
                        y: 0,
                        width: actualW,
                        height: actualH,
                        scaleX: 1,
                        scaleY: 1,
                        rotation: 0,
                        crop: {
                            x: 0,
                            y: 0,
                            width: actualW,
                            height: actualH
                        }
                    }
                    
                }

            }
            addPhotoLayer([layer])
          //     }

          }
        }

      } 

      const handleOnEditPhotoItembarClick = (item: YourPhoto) => {
        console.log(item)
      } 

      const handleOnViewPhotoItembarClick = (item: YourPhoto) => {
        console.log(item)
      } 

      const handleOnDeletePhotoItembarClick = (itemId: string) => {
        console.log(itemId)
        
        deletephotoAPI(itemId)
        .then((response) => {
          return getyourphotoAPI(yourphotosviewset?.searchtags, 1, yourphotosviewset?.sortby as string)
        }).then((photos) => {
          handleCloseDeleteYourphotoDialog()
          setYourPhotosViewset(photos.data.results, photos.data.total_pages, 1, yourphotosviewset?.searchtags, yourphotosviewset?.sortby as string)
        }).catch((e) => { 
          console.log("Error delete photo: ", e)
        })
      } 

      const handleOpenDeleteYourphotoDialog = (itemid: string, itemname: string) => {
        setDeleteyourphotoitemid(itemid)
        setDeleteyourphotoitemname(itemname)
        setOpendeleteyourphotodialog(true)
      }
  
      const handleCloseDeleteYourphotoDialog = () => {
        setDeleteyourphotoitemid('')
        setDeleteyourphotoitemname('')
        setOpendeleteyourphotodialog(false)
      } 

      const openYourPhotoHelpPopover = Boolean(helpYourPhotoAnchorEl)
      
      /* End YourPhotos functions */

      /* Start Shared Photos function */
      const handleSharedPhotoHelpPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setHelpSharedPhotoAnchorEl(event.currentTarget);
      };
    
      const handleSharedPhotoHelpPopoverClose = () => {
        setHelpSharedPhotoAnchorEl(null);
      };

      const handleSharedPhotoScrollEnd = (event: any) => {
        if (event.currentTarget.scrollHeight - event.currentTarget.scrollTop == 180) {
          console.log("scroll end")
          if ((sharedphotosviewset?.page as number)+1 <= (sharedphotosviewset?.totalpages as number)) {
            getsharedphotoAPI(sharedphotosviewset?.searchtags, (sharedphotosviewset?.page as number)+1, sharedphotosviewset?.sortby as string)
            .then((response) => {
              // if (response.data.total_pages !== undefined) {
              //   setYourphotototalpages(response.data.total_pages)
              // }
              // setYourphotopage(yourphotopage+1)
              if (sharedphotosviewset?.sharedphotos !== null) {
                //setYourphotos(yourphotos.concat(response.data.results))
                setSharedPhotosViewset(sharedphotosviewset?.sharedphotos?.concat(response.data.results), response.data.total_pages, (sharedphotosviewset?.page as number)+1, sharedphotosviewset?.searchtags, sharedphotosviewset?.sortby as string)
              } else {
                //setYourphotos(response.data.results)
                setSharedPhotosViewset(response.data.results, response.data.total_pages, 1, sharedphotosviewset?.searchtags, sharedphotosviewset?.sortby as string)
              }
              
            })
            .catch((error) => {
              console.log("Error fetching shared photos: ", error)
            })
          }
        }
      }

      const handleSharedPhotoSortbyChange = (event: SelectChangeEvent) => {
        
        getsharedphotoAPI(sharedphotosviewset?.searchtags, 1, event.target.value)
            .then((response) => {
              // if (response.data.total_pages !== undefined) {
              //   setYourphotototalpages(response.data.total_pages)
              // }
              // setYourphotopage(1)
              // setYourphotosortby(event.target.value)
              // setYourphotos(response.data.results)
              setSharedPhotosViewset(response.data.results, response.data.total_pages, 1, sharedphotosviewset?.searchtags, event.target.value)
            })
            .catch((error) => {
              console.log("Error fetching shared photos: ", error)
            })
      }

      const handleSharedPhotoTagChange = (event: React.SyntheticEvent, value: any) => {
        
        getsharedphotoAPI(value, 1, sharedphotosviewset?.sortby as string)
            .then((response) => {
              // if (response.data.total_pages !== undefined) {
              //   setYourphotototalpages(response.data.total_pages)
              // }
              // setYourphotopage(1)
              // setYourphotosearchtags(value)
              // setYourphotos(response.data.results)
              setSharedPhotosViewset(response.data.results, response.data.total_pages, 1, value, yourphotosviewset?.sortby)
            })
            .catch((error) => {
              console.log("Error fetching your photos: ", error)
            })
      }
      const openSharedPhotoHelpPopover = Boolean(helpSharedPhotoAnchorEl)
      /* End Shared Photos function */

      const handleGraphicTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setGraphictab(newValue);
      };
      /* Start YourGraphics functions */
      const handleYourGraphicHelpPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setHelpYourGraphicAnchorEl(event.currentTarget);
      };
    
      const handleYourGraphicHelpPopoverClose = () => {
        setHelpYourGraphicAnchorEl(null);
      };

      const handleAddYourGraphicClick = () => {
        if (yourgraphicFile.current !== null) {
          yourgraphicFile.current.click()
        }
      } 

      const handleYourGraphicSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation()
        event.preventDefault()
        setSelectedUploadGraphics(event.target.files as FileList)
      }

      const uploadGraphic = () => {
        if (selectedUploadGraphics !== null) {
          let currentFile = selectedUploadGraphics[0]
          // setYourphotopage(1)
          // setYourphotosearchtags([])
          setIsUploadingGraphic(true)
          setUploadgraphicprogress(0)
          graphicuploadAPI(currentFile, (event: any) => {
            setUploadgraphicprogress(Math.round((100 * event.loaded) / event.total))
          }).then((response) => {
            setIsUploadingGraphic(false)
            setSelectedUploadGraphics(null)
            // return getyourphotoAPI(yourphotosearchtags, 1, yourphotosortby)
            return getyourgraphicAPI(yourgraphicsviewset?.searchtags, 1, yourgraphicsviewset?.sortby as string)
          }).then((graphics) => {
            // if (photos.data.total_pages !== undefined) {
            //   setYourphotototalpages(photos.data.total_pages)
            // }
            // setYourphotopage(1)
            // setYourphotos(photos.data.results)
            setYourGraphicsViewset(graphics.data.results, graphics.data.total_pages, 1, yourgraphicsviewset?.searchtags, yourgraphicsviewset?.sortby as string)
          }).catch((e) => { 
            setIsUploadingPhoto(false)
            console.log("Error upload graphic: ", e)
          })
        }

      }

      const handleYourGraphicScrollEnd = (event: any) => {
        if (event.currentTarget.scrollHeight - event.currentTarget.scrollTop == 180) {
          console.log("scroll end")
          if ((yourgraphicsviewset?.page as number)+1 <= (yourgraphicsviewset?.totalpages as number)) {
            getyourgraphicAPI(yourgraphicsviewset?.searchtags, (yourgraphicsviewset?.page as number)+1, yourgraphicsviewset?.sortby as string)
            .then((response) => {
              // if (response.data.total_pages !== undefined) {
              //   setYourphotototalpages(response.data.total_pages)
              // }
              // setYourphotopage(yourphotopage+1)
              if (yourgraphicsviewset?.yourgraphics !== null) {
                //setYourphotos(yourphotos.concat(response.data.results))
                setYourGraphicsViewset(yourgraphicsviewset?.yourgraphics?.concat(response.data.results), response.data.total_pages, (yourgraphicsviewset?.page as number)+1, yourgraphicsviewset?.searchtags, yourgraphicsviewset?.sortby as string)
              } else {
                //setYourphotos(response.data.results)
                setYourGraphicsViewset(response.data.results, response.data.total_pages, 1, yourgraphicsviewset?.searchtags, yourgraphicsviewset?.sortby as string)
              }
              
            })
            .catch((error) => {
              console.log("Error fetching your graphics: ", error)
            })
          }
        }
      }

      const handleYourGraphicSortbyChange = (event: SelectChangeEvent) => {
        
        getyourgraphicAPI(yourgraphicsviewset?.searchtags, 1, event.target.value)
            .then((response) => {
              // if (response.data.total_pages !== undefined) {
              //   setYourphotototalpages(response.data.total_pages)
              // }
              // setYourphotopage(1)
              // setYourphotosortby(event.target.value)
              // setYourphotos(response.data.results)
              setYourGraphicsViewset(response.data.results, response.data.total_pages, 1, yourgraphicsviewset?.searchtags, event.target.value)
            })
            .catch((error) => {
              console.log("Error fetching your graphics: ", error)
            })
      }

      const handleYourGraphicTagChange = (event: React.SyntheticEvent, value: any) => {
        
        getyourgraphicAPI(value, 1, yourgraphicsviewset?.sortby as string)
            .then((response) => {
              // if (response.data.total_pages !== undefined) {
              //   setYourphotototalpages(response.data.total_pages)
              // }
              // setYourphotopage(1)
              // setYourphotosearchtags(value)
              // setYourphotos(response.data.results)
              setYourGraphicsViewset(response.data.results, response.data.total_pages, 1, value, yourgraphicsviewset?.sortby)
            })
            .catch((error) => {
              console.log("Error fetching your graphics: ", error)
            })
      }

      const handleOnDragYourGraphicStart = (item: YourGraphic) => {
        setGraphicItem(item)
      }

      const handleOnAddGraphicItembarClick = (item: YourGraphic) => {
        if (item.preview !== undefined) {
          let previewgraphic = new window.Image()
          previewgraphic.crossOrigin = 'anonymous'
          previewgraphic.src = item.preview
          previewgraphic.onload = () => {
            let previewW = item.preview_w
            let previewH = item.preview_h
            let previewscale = (previewW >= previewH ? (view?.previewwidth as number)/previewW:(view?.previewheight as number)/previewH)*(1.1**(zoomlevel as number))
            let viewW = previewW*previewscale
            let viewH = previewH*previewscale
            let previewX = (canvaswidth as number-viewW*(1.1**(zoomlevel as number)))/2
            let previewY = (canvasheight as number-viewH*(1.1**(zoomlevel as number)))/2
              // let actualgraphic = new window.Image()
              // actualgraphic.src = item.actual
              // actualgraphic.onload = () => {
            let actualW = item.actual_w
            let actualH = item.actual_h
            let exportscale = (actualW >= actualH ? (view?.exportwidth as number)/actualW:(view?.exportheight as number)/actualH)
            let exportW = actualW*exportscale
            let exportH = actualH*exportscale
            
            let exportX = ((view?.exportwidth as number) - exportW)/2
            let exportY = ((view?.exportheight as number) - exportH)/2
            const layerIdx = view?.layers?.length as number
            const layer: DesignLayer = {
                id: `${view?.view}-layer-${layerIdx}`,
                props: {
                    idx: layerIdx,
                    itemId: item.id,
                    itemname: item.graphic_name,
                    itemowner: item.owner,
                    type: 'graphic-layer',
                    usageprices: item.prices,
                    discount: item.discount,
                    isfree: item.is_free,
                    actualW: actualW,
                    actualH: actualH,
                    actual: item.actual,
                    previewW: previewW,
                    previewH: previewH,
                    preview: item.preview,
                    previewdata: previewgraphic,
                    selected: true,
                    previewlayerAttr: {
                        opacity: 1,
                    },
                    exportlayerAttr: {
                        opacity: 1,
                    },
                    shapeGrpAttr: {
                        x: previewX,
                        y: previewY,
                        width: previewW,
                        height: previewH,
                        scaleX: previewscale,
                        scaleY: previewscale,
                        rotation: 0,
                        clipX: 0,
                        clipY: 0,
                        clipWidth: previewW,
                        clipHeight: previewH
                    },
                    shapeboxAttr: {
                        x: 0,
                        y: 0,
                        width: previewW,
                        height: previewH,
                        scaleX: 1,
                        scaleY: 1,
                        rotation: 0
                    },
                    shapeAttr: {
                        x: 0,
                        y: 0,
                        width: previewW,
                        height: previewH,
                        scaleX: 1,
                        scaleY: 1,
                        rotation: 0,
                        offsetX: 0,
                        offsetY: 0,
                        crop: {
                            x: 0,
                            y: 0,
                            width: previewW,
                            height: previewH
                        }
                    },
                  
                    exportshapeGrpAttr: {
                        x: exportX,
                        y: exportY,
                        width: actualW,
                        height: actualH,
                        scaleX: exportscale,
                        scaleY: exportscale,
                        rotation: 0,
                        clipX: 0,
                        clipY: 0,
                        clipWidth: actualW,
                        clipHeight: actualH
                    },
                    exportshapeAttr: {
                        x: 0,
                        y: 0,
                        width: actualW,
                        height: actualH,
                        scaleX: 1,
                        scaleY: 1,
                        rotation: 0,
                        crop: {
                            x: 0,
                            y: 0,
                            width: actualW,
                            height: actualH
                        }
                    }
                    
                }

            }
            addGraphicLayer([layer])
          //     }

          }
        }
      } 

      const handleOnEditGraphicItembarClick = (item: YourGraphic) => {
        console.log(item)
      } 

      const handleOnViewGraphicItembarClick = (item: YourGraphic) => {
        console.log(item)
      } 

      const handleOnDeleteGraphicItembarClick = (itemId: string) => {
        console.log(itemId)
        deletegraphicAPI(itemId)
        .then((response) => {
          return getyourgraphicAPI(yourgraphicsviewset?.searchtags, 1, yourgraphicsviewset?.sortby as string)
        }).then((graphics) => {
          handleCloseDeleteYourgraphicDialog()
          setYourGraphicsViewset(graphics.data.results, graphics.data.total_pages, 1, yourgraphicsviewset?.searchtags, yourgraphicsviewset?.searchtags)
        }).catch((e) => { 
          console.log("Error delete graphic: ", e)
        })
      } 

      const handleOpenDeleteYourgraphicDialog = (itemid: string, itemname: string) => {
        setDeleteyourgraphicitemid(itemid)
        setDeleteyourgraphicitemname(itemname)
        setOpendeleteyourgraphicdialog(true)
      }
  
      const handleCloseDeleteYourgraphicDialog = () => {
        setDeleteyourgraphicitemid('')
        setDeleteyourgraphicitemname('')
        setOpendeleteyourgraphicdialog(false)
      }

      const openYourGraphicHelpPopover = Boolean(helpYourGraphicAnchorEl)
      /* End YourGraphics functions */

      /* Start Shared Photos function */
      const handleSharedGraphicHelpPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setHelpSharedGraphicAnchorEl(event.currentTarget);
      };
    
      const handleSharedGraphicHelpPopoverClose = () => {
        setHelpSharedGraphicAnchorEl(null);
      };

      const handleSharedGraphicScrollEnd = (event: any) => {
        if (event.currentTarget.scrollHeight - event.currentTarget.scrollTop == 180) {
          console.log("scroll end")
          if ((sharedgraphicsviewset?.page as number)+1 <= (sharedgraphicsviewset?.totalpages as number)) {
            getsharedphotoAPI(sharedgraphicsviewset?.searchtags, (sharedgraphicsviewset?.page as number)+1, sharedgraphicsviewset?.sortby as string)
            .then((response) => {
              // if (response.data.total_pages !== undefined) {
              //   setYourphotototalpages(response.data.total_pages)
              // }
              // setYourphotopage(yourphotopage+1)
              if (sharedgraphicsviewset?.sharedgraphics !== null) {
                //setYourphotos(yourphotos.concat(response.data.results))
                setSharedGraphicsViewset(sharedgraphicsviewset?.sharedgraphics?.concat(response.data.results), response.data.total_pages, (sharedgraphicsviewset?.page as number)+1, sharedgraphicsviewset?.searchtags, sharedgraphicsviewset?.sortby as string)
              } else {
                //setYourphotos(response.data.results)
                setSharedGraphicsViewset(response.data.results, response.data.total_pages, 1, sharedgraphicsviewset?.searchtags, sharedgraphicsviewset?.sortby as string)
              }
              
            })
            .catch((error) => {
              console.log("Error fetching shared graphics: ", error)
            })
          }
        }
      }

      const handleSharedGraphicSortbyChange = (event: SelectChangeEvent) => {
        
        getsharedgraphicAPI(sharedgraphicsviewset?.searchtags, 1, event.target.value)
            .then((response) => {
              // if (response.data.total_pages !== undefined) {
              //   setYourphotototalpages(response.data.total_pages)
              // }
              // setYourphotopage(1)
              // setYourphotosortby(event.target.value)
              // setYourphotos(response.data.results)
              setSharedPhotosViewset(response.data.results, response.data.total_pages, 1, sharedphotosviewset?.searchtags, event.target.value)
            })
            .catch((error) => {
              console.log("Error fetching shared graphics: ", error)
            })
      }

      const handleSharedGraphicTagChange = (event: React.SyntheticEvent, value: any) => {
        
        getsharedgraphicAPI(value, 1, sharedgraphicsviewset?.sortby as string)
            .then((response) => {
              // if (response.data.total_pages !== undefined) {
              //   setYourphotototalpages(response.data.total_pages)
              // }
              // setYourphotopage(1)
              // setYourphotosearchtags(value)
              // setYourphotos(response.data.results)
              setSharedGraphicsViewset(response.data.results, response.data.total_pages, 1, value, sharedgraphicsviewset?.sortby)
            })
            .catch((error) => {
              console.log("Error fetching shared graphics: ", error)
            })
      }
      const openSharedGraphicHelpPopover = Boolean(helpSharedGraphicAnchorEl)
      /* End Shared Photos function */

      /* Start IG functions */
      const handleIGLoginClick = () => {
        let authorizer: string = `${ig_authorize_url}?client_id=${ig_app_client_id}&redirect_uri=${ig_app_redirect_uri}&scope=user_profile%2Cuser_media&response_type=code`
        window.location.assign(authorizer)
      }

      const handleIGPhotoScrollEnd = (event: any) => {
        if (event.currentTarget.scrollHeight - event.currentTarget.scrollTop == 180) {
          console.log("scroll end")
          if (igphotosviewset?.next !== null && igphotosviewset?.next !== undefined) {
            igmedialistAPI(igphotosviewset?.next)
            .then((response) => {
              setIGPhotosViewset(response.data.data,response.data.paging.next)
            })
            .catch((error) => {
              console.log("Error fetching ig media: ", error)
            });
          }
        }
      }

      const handleOnDragIGPhotoStart = (item: IGPhoto) => {
        setIGPhotoItem(item)
      }

      const handleOnAddIGPhotoItembarClick = (item: IGPhoto) => {
        console.log(item)
      } 

      const handleOnViewIGPhotoItembarClick = (item: IGPhoto) => {
        console.log(item)
      } 

      /* End IG functions */

      /* Start text functions */
      const openFontcolorpickerPopover = Boolean(fontcolorpickerAnchorEl)

      const handleFontColorPickerPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setFontcolorpickerAnchorEl(event.currentTarget);
      };
  
      const handleFontColorPickerPopoverClose = () => {
        setFontcolorpickerAnchorEl(null);
      };

      const handleFontcolorChangeComplete = (color: any) => {
        setFontColor(color.hex)
      }

      const handleInputTextChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputtext(event.target.value)
      }

      const handleFontSizeChange = (event: SelectChangeEvent) => {
        
        setFontSize(event.target.value)
      }

      const handleFontFamilyChange = (event: React.SyntheticEvent, value: any) => {
        if (value) {
          setFontFamily(value.name)
        } else {
          setFontFamily('inherit')
        }
      }

      

      const handleOnAddText = () => {

        const textEl = document.querySelector('#inputtext-preview') as HTMLElement
        if (textEl) {
          //create canvas element for preview and export
          // html2canvas(textEl, {
          //   backgroundColor: 'rgba(0,0,0,0)'
          // }).then((preview) => {
            let previewW = textEl.offsetWidth
            let previewH = textEl.offsetHeight
            let previewscale = (previewW >= previewH ? (view?.previewwidth as number)/previewW:(view?.previewheight as number)/previewH)*(1.1**(zoomlevel as number))
            let viewW = previewW*previewscale
            let viewH = previewH*previewscale
            let previewX = (canvaswidth as number-viewW*(1.1**(zoomlevel as number)))/2
            let previewY = (canvasheight as number-viewH*(1.1**(zoomlevel as number)))/2
            // html2canvas(textEl, {
            //   backgroundColor: 'rgba(0,0,0,0)'
            // }).then((actual) => {
            let actualW = previewW*20/3
            let actualH = previewH*20/3
            let exportscale = (actualW >= actualH ? (view?.exportwidth as number)/actualW:(view?.exportheight as number)/actualH)
            let exportW = actualW*exportscale
            let exportH = actualH*exportscale
                                
            let exportX = ((view?.exportwidth as number) - exportW)/2
            let exportY = ((view?.exportheight as number) - exportH)/2
            
            //let previewpath = `M0,${previewH/2} H${previewW}`
            //let exportpath = `M0,${actualH/2} H${actualW}`
            const layerIdx = view?.layers?.length as number
            const layer: DesignLayer = {
                id: `${view?.view}-layer-${layerIdx}`,
                props: {
                  idx: layerIdx,
                  type: 'text-layer',
                  pathpreset: 'horizontal',
                  actualW: actualW,
                  actualH: actualH,
                  //actualdata: actual,
                  previewW: previewW,
                  previewH: previewH,
                  //previewdata: preview,
                  selected: true,
                  previewlayerAttr: {
                      opacity: 1,
                  },
                  exportlayerAttr: {
                      opacity: 1,
                  },
                  shapeGrpAttr: {
                      x: previewX,
                      y: previewY,
                      width: previewW,
                      height: previewH,
                      scaleX: previewscale,
                      scaleY: previewscale,
                      rotation: 0,
                      clipX: 0,
                      clipY: 0,
                      clipWidth: previewW,
                      clipHeight: previewH
                  },
                  shapeboxAttr: {
                      x: 0,
                      y: 0,
                      width: previewW,
                      height: previewH,
                      scaleX: 1,
                      scaleY: 1,
                      rotation: 0
                  },
                  shapeAttr: {
                      x: 0,
                      y: 0,
                      width: previewW,
                      height: previewH,
                      scaleX: 1,
                      scaleY: 1,
                      rotation: 0,
                      offsetX: 0,
                      offsetY: 0,
                      text: inputtext,
                      fontFamily: fontfamily,
                      fontSize: +fontsize,
                      fill: fontcolor,
                      
                      //data: previewpath
                  },
                  
                  exportshapeGrpAttr: {
                      x: exportX,
                      y: exportY,
                      width: actualW,
                      height: actualH,
                      scaleX: exportscale,
                      scaleY: exportscale,
                      rotation: 0,
                      clipX: 0,
                      clipY: 0,
                      clipWidth: actualW,
                      clipHeight: actualH
                  },
                  exportshapeAttr: {
                      x: 0,
                      y: 0,
                      width: actualW,
                      height: actualH,
                      scaleX: 1,
                      scaleY: 1,
                      rotation: 0,
                      text: inputtext,
                      fontFamily: fontfamily,
                      fontSize: (+fontsize)*20/3,
                      fill: fontcolor,
                      //data: exportpath
                  }
                }
              }
            addTextLayer([layer])
          //   })
            
          // })
        }
      }
      /* End text functions */

      /* Start fetching function */
      // const fetchProducts = async () => {
      //   try {
      //       const { data } = await fetchProductAPI()
      //       setProducts(data.result)
      //   } catch(e) {
      //       console.log("Error from fetching products: ", e)
      //   }
        
      // }

      // const fetchSizes = async (product: number, color: number) => {
      //   try {
      //       const { data } = await fetchSizeAPI(product, color)
      //       setSizes(data.result)
      //   } catch(e) {
      //       console.log("Error from fetching products: ", e)
      //   }
        
      // }

      // const fetchMockups = async (product: number, color: number) => {
      //   try {
      //       const { data } = await fetchMockupAPI(product, color)
      //       setMockups(data.result)
      //   } catch(e) {
      //       console.log("Error from fetching products: ", e)
      //   }
        
      // }

      /* End fetching function */

      // const selectProduct = (product: number) => {
      //   //fetchSizes(product, color)
      //   //fetchMockups(product, color)
      //   setSelProduct(product)
      //   setSelColor(undefined)
      //   let prod = products?.find((p: Product) => p.id == product)
      //   loadViews(prod?.typename)
      // }

      // const selectColor = (color: number) => {
      //   if (selproduct) {
      //     fetchSizes(selproduct, color)
      //     fetchMockups(selproduct, color)
      //     setSelColor(color)
      //     let pd: Product | undefined = products?.find((p: Product) => p.isdefault);
      //     let co: Color | undefined = pd?.colors?.find((c: Color) => c.isdefault) as Color
      //     const productname = `${pd?.typename} (${co.colorname})` 
      //     updateCartItem(productname, selproduct, color)
      //   }
      // }

      React.useEffect(() => {
      //   if (!products) {
      //     fetchProducts()
      //   }
      //   if (!selproduct && products) {
      //     let defaultProduct: Product | undefined = products?.find((p: Product) => p.isdefault);
      //     let defaultColor: Color | undefined = defaultProduct?.colors?.find((c: Color) => c.isdefault) as Color
      //     if (defaultProduct && defaultColor) {
            
      //       selectProduct(defaultProduct.id)
      //     } 
          
      //   } 
      //   if (selproduct && !selcolor && products) {
      //     let product = products?.find((p: Product) => p.id == selproduct)
      //     let defaultColor: Color | undefined = product?.colors?.find((c: Color) => c.isdefault) as Color
      //     selectColor(defaultColor.id)
      //   }

      //   if (selproduct && selcolor) {
      //     updateCartItem(selproduct, selcolor)
      //   }

      //   if (mockups) {
      //     loadMockups(mockups)
      //   }
      if (products) {
        let defaultProduct: Product | undefined = products?.find((p: Product) => p.isdefault);
        let defaultColor: Color | undefined = defaultProduct?.colors?.find((c: Color) => c.isdefault) as Color
        if (defaultProduct && defaultColor) {
            let selproduct = defaultProduct.id
            let selcolor = defaultColor.id
            if (cartitem) {
                selproduct = cartitem.product?.id as number
                selcolor = cartitem.color?.id as number
            }
            selectProduct(selproduct, selcolor, true)
        }
      }

      }, [products])
      

     

      var product: Product | undefined = undefined
      if (cartitem && cartitem.product?.id && products) {
        product = products?.find((p: Product) => p.id == cartitem.product?.id)
      }
      
      const now = (new Date()).getTime() / 1000

      if (isLoggedIn && tokenExpireOn > now && yourtemplatesviewset?.yourtemplates === null ) {
        getyourdesigntplAPI(yourtemplatesviewset?.searchtags, 1, yourtemplatesviewset?.sortby as string)
        .then((response) => {
          setYourTemplatesViewset(response.data.results, response.data.total_pages, 1, yourtemplatesviewset?.searchtags, yourtemplatesviewset?.sortby)
        })
        .catch((error) => {
          console.log("Error fetching your templates: ", error)
        })
      }

      if (isLoggedIn && tokenExpireOn > now && sharedtemplatesviewset?.sharedtemplates === null ) {
        getshareddesigntplAPI(sharedtemplatesviewset?.searchtags, 1, sharedtemplatesviewset?.sortby as string)
        .then((response) => {
          setSharedTemplatesViewset(response.data.results, response.data.total_pages, 1, sharedtemplatesviewset?.searchtags, sharedtemplatesviewset?.sortby)
        })
        .catch((error) => {
          console.log("Error fetching shared templates: ", error)
        })
      }

      if (!isLoggedIn && sharedtemplatesviewset?.sharedtemplates === null ) {
        getpublicdesigntplAPI(sharedtemplatesviewset?.searchtags, 1, sharedtemplatesviewset?.sortby as string)
        .then((response) => {
          setSharedTemplatesViewset(response.data.results, response.data.total_pages, 1, sharedtemplatesviewset?.searchtags, sharedtemplatesviewset?.sortby)
        })
        .catch((error) => {
          console.log("Error fetching shared templates: ", error)
        })
      }

      if (isLoggedIn && tokenExpireOn > now && yourphotosviewset?.yourphotos === null ) {
        getyourphotoAPI(yourphotosviewset?.searchtags, 1, yourphotosviewset?.sortby as string)
        .then((response) => {
          setYourPhotosViewset(response.data.results, response.data.total_pages, 1, yourphotosviewset?.searchtags, yourphotosviewset?.sortby)
        })
        .catch((error) => {
          console.log("Error fetching your photos: ", error)
        })
      }

      if (isLoggedIn && tokenExpireOn > now && sharedphotosviewset?.sharedphotos === null ) {
        getsharedphotoAPI(sharedphotosviewset?.searchtags, 1, sharedphotosviewset?.sortby as string)
        .then((response) => {
          setSharedPhotosViewset(response.data.results, response.data.total_pages, 1, sharedphotosviewset?.searchtags, sharedphotosviewset?.sortby)
        })
        .catch((error) => {
          console.log("Error fetching shared photos: ", error)
        })
      }

      if (!isLoggedIn && sharedphotosviewset?.sharedphotos === null ) {
        getpublicphotoAPI(sharedphotosviewset?.searchtags, 1, sharedphotosviewset?.sortby as string)
        .then((response) => {
          setSharedPhotosViewset(response.data.results, response.data.total_pages, 1, sharedphotosviewset?.searchtags, sharedphotosviewset?.sortby)
        })
        .catch((error) => {
          console.log("Error fetching shared photos: ", error)
        })
      }

      if (isLoggedIn && tokenExpireOn > now && yourgraphicsviewset?.yourgraphics === null ) {
        getyourgraphicAPI(yourgraphicsviewset?.searchtags, 1, yourgraphicsviewset?.sortby as string)
        .then((response) => {
          setYourGraphicsViewset(response.data.results, response.data.total_pages, 1, yourphotosviewset?.searchtags, yourphotosviewset?.sortby)
        })
        .catch((error) => {
          console.log("Error fetching your graphics: ", error)
        })
      }

      if (isLoggedIn && tokenExpireOn > now && sharedgraphicsviewset?.sharedgraphics === null ) {
        getsharedgraphicAPI(sharedgraphicsviewset?.searchtags, 1, sharedgraphicsviewset?.sortby as string)
        .then((response) => {
          setSharedGraphicsViewset(response.data.results, response.data.total_pages, 1, sharedgraphicsviewset?.searchtags, sharedgraphicsviewset?.sortby)
        })
        .catch((error) => {
          console.log("Error fetching shared graphics: ", error)
        })
      }

      if (!isLoggedIn && sharedgraphicsviewset?.sharedgraphics === null ) {
        getpublicgraphicAPI(sharedgraphicsviewset?.searchtags, 1, sharedgraphicsviewset?.sortby as string)
        .then((response) => {
          setSharedGraphicsViewset(response.data.results, response.data.total_pages, 1, sharedgraphicsviewset?.searchtags, sharedgraphicsviewset?.sortby)
        })
        .catch((error) => {
          console.log("Error fetching shared graphics: ", error)
        })
      }

      if (igid !== null && igtoken !== null && igtokenExpireOn > now && igphotosviewset?.igphotos === null) {
        let media_fields = encodeURIComponent('id,caption,media_type,media_url,timestamp')
        let ig_media_url = `${ig_graph_url}me/media?fields=${media_fields}&access_token=${igtoken}`
        igmedialistAPI(ig_media_url)
        .then((response) => {
          setIGPhotosViewset(response.data.data,response.data.paging.next)
        })
        .catch((error) => {
          console.log("Error fetching ig media: ", error)
        });
        
      }

      if (tags === null) {
        fetchTagsAPI()
          .then((response) => {
            setTags(response.data)
          })
          .catch((error) => {
            console.log("Error fetching tags: ", error)
          })
      }
      return (
        <div style={{
          paddingTop: 56
        }}>
        <Accordion expanded={expanded === 'chooseproduct'} onChange={handleChange('chooseproduct')}>
          <AccordionSummary aria-controls="panel1-content" id="panel1-header">
            <Typography variant="h6">Choose product</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
              malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
              sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
              sit amet blandit leo lobortis eget.
            </Typography> */}
            <FormControl component="fieldset">
            <RadioGroup row 
              aria-label="product" 
              name="product"
              value={cartitem && cartitem.product?.id ? cartitem.product?.id:1}
              onChange={handleProductChange}>
              {products && products?.map((product: Product) => (
                <Tooltip title={product.typename as string} arrow>
                <FormControlLabel
                  value={product.id}
                  control={<Radio />}
                  label={<Stack sx={{ width: 64 }}>
                    
                    <SVG src={product.icon!} width={64} height={64} />
                    
                    <Typography noWrap variant='subtitle1'>{product.typename}</Typography>
                    </Stack>}
                  labelPlacement="top"
                />
                </Tooltip>
              ))}
            </RadioGroup>
            </FormControl>
            
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'choosecolor'} onChange={handleChange('choosecolor')}>
          <AccordionSummary aria-controls="panel2-content" id="panel2-header" 
          //classes={{ expanded: classes.accordionexpanded }}
          >
            <Typography variant="h6">Choose Color</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <div>
            
            {product?.colors !== null && product?.colors?.map((color: Color) => (
              <Radio
                checked={cartitem?.color?.id === color.id}
                onChange={handleColorChange}
                value={color.id}
                name={color.colorname as string}
                inputProps={{ 'aria-label': color.colorname as string }}
                sx={{
                  color: color.colorcode as string,
                  '&.Mui-checked': {
                    color: color.colorcode as string,
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: 28,
                  },
                }}
                disableRipple
                checkedIcon={<CheckCircleIcon />}
                icon={<CircleIcon />}
              />
            ))}
          
          </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'designtpl'} onChange={handleChange('designtpl')}>
          <AccordionSummary aria-controls="designtpl-content" id="designtpl-header" 
          //classes={{ expanded: classes.accordionexpanded }}
          >
            <Typography variant="h6">Choose Design Template</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <Grid container spacing={1}>
            <Grid item xs={12}>
              <Tabs value={templatetab} onChange={handleTemplateTabChange} centered>
                <Tab label="Your Templates" {...templateTabProps(0)} />
                <Tab label="Shared Templates" {...templateTabProps(1)} />
              </Tabs>
              <TemplateTabPanel value={templatetab} index={0}>
              {isLoggedIn && tokenExpireOn > now ? (
                <Stack spacing={1} sx={{ width: '100%'}}>
                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <Autocomplete
                        multiple
                        id="template-searchbox"
                        options={tags !== null ? tags:[]}
                        getOptionLabel={(option) => option.title}
                        defaultValue={yourtemplatesviewset?.searchtags}
                        onChange={handleYourTemplateTagChange}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            color="secondary"
                            label="Search Your Templates"
                            placeholder="Enter template tags"
                          />
                        )}
                        
                      />
                    </Grid>
                    <Grid item xs={3}>
                    <FormControl variant="standard" color="secondary">
                      <InputLabel id="yourtemplate-sortby">Sort by</InputLabel>
                      <Select
                        labelId="yourtemplate-sortby"
                        value={yourtemplatesviewset?.sortby as string}
                        onChange={handleYourTemplateSortbyChange}
                        label="Sort by"
                      >
                        <MenuItem value={'-created_dt'}>Newest</MenuItem>
                        <MenuItem value={'created_dt'}>Oldest</MenuItem>
                      </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton 
                        color="secondary"
                        aria-owns={openYourTemplateHelpPopover ? 'yourtemplate-help-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handleYourTemplateHelpPopoverOpen}
                        onMouseLeave={handleYourTemplateHelpPopoverClose}
                      >
                        <HelpIcon />
                      </IconButton>
                      <Popover
                        id="yourtemplate-help-popover"
                        sx={{
                          pointerEvents: 'none',
                        }}
                        open={openYourTemplateHelpPopover}
                        anchorEl={helpYourTemplateAnchorEl}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        onClose={handleYourTemplateHelpPopoverClose}
                        disableRestoreFocus
                        PaperProps={{
                          style: {
                            width: '400px', height: '250px', padding: '10px'
                          }
                        }}
                      >
                      <div>
                        <Typography variant="subtitle2" component="div">Help for Your Templates</Typography>
                        <Typography variant="caption">
                        <ul>
                          <li>You're required to sign in before using "Your Templates" feature.</li>
                          <li>Enter template tags in the searchbox to search for your templates stored in your account.</li>
                          <li>Manage your template in "Account" page to share.</li>
                        </ul>
                        </Typography>
                        <Typography variant="subtitle2" component="div">Important!!! Please read PixarTee's terms and conditions before using templates.</Typography>
                      </div>
                      </Popover>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <YourTemplateList 
                        templates={yourtemplatesviewset?.yourtemplates} 
                        onScrollList={handleYourTemplateScrollEnd} 
                        onDragItem={handleOnDragTemplateStart} 
                        onAddTemplateItembarClick={handleOnAddTemplateItembarClick}
                        onEditTemplateItembarClick={handleOnEditTemplateItembarClick}
                        onDeleteTemplateItembarClick={handleOpenDeleteYourtemplateDialog} />
                      <Dialog
                        open={opendeleteyourphotodialog}
                        onClose={handleCloseDeleteYourphotoDialog}
                        aria-labelledby="delete-yourphoto-dialog-title"
                        aria-describedby="delete-dialog-description"
                      >
                        <DialogTitle id="delete-yourphoto-dialog-title">
                          {`Deleting photo ${deleteyourphotoitemname}`}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="delete-yourphoto-dialog-description">
                            {`You are about to delete ${deleteyourphotoitemname}. Are you sure?`}
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseDeleteYourphotoDialog}>No</Button>
                          <Button onClick={() => { handleOnDeletePhotoItembarClick(deleteyourphotoitemid) }} autoFocus>
                            Yes
                          </Button>
                        </DialogActions>
                      </Dialog>
                      
                    </Grid>
                  </Grid>
                  
                </Stack>
              ):(
                <Button
                        variant="contained"
                        color="primary"  
                        onClick={handleAccountLoginClick}           
                    >
                      
                      Sign In to use Your Templates
                        
                    </Button>
              )}
              </TemplateTabPanel>
              <TemplateTabPanel value={templatetab} index={1}>
                <Stack spacing={1} sx={{ width: '100%'}}>
                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <Autocomplete
                        multiple
                        id="sharedtemplate-searchbox"
                        options={tags !== null ? tags:[]}
                        getOptionLabel={(option) => option.title}
                        defaultValue={sharedtemplatesviewset?.searchtags}
                        onChange={handleSharedTemplateTagChange}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            color="secondary"
                            label="Search Shared Templates"
                            placeholder="Enter template tags"
                          />
                        )}
                        
                      />
                    </Grid>
                    <Grid item xs={3}>
                    <FormControl variant="standard" color="secondary">
                      <InputLabel id="sharedtemplate-sortby">Sort by</InputLabel>
                      <Select
                        labelId="sharedtemplate-sortby"
                        value={sharedtemplatesviewset?.sortby as string}
                        onChange={handleSharedTemplateSortbyChange}
                        label="Sort by"
                      >
                        <MenuItem value={'-created_dt'}>Newest</MenuItem>
                        <MenuItem value={'created_dt'}>Oldest</MenuItem>
                      </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton 
                        color="secondary"
                        aria-owns={openSharedTemplateHelpPopover ? 'yourtemplate-help-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handleSharedTemplateHelpPopoverOpen}
                        onMouseLeave={handleSharedTemplateHelpPopoverClose}
                      >
                        <HelpIcon />
                      </IconButton>
                      <Popover
                        id="sharedtemplate-help-popover"
                        sx={{
                          pointerEvents: 'none',
                        }}
                        open={openSharedTemplateHelpPopover}
                        anchorEl={helpSharedTemplateAnchorEl}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        onClose={handleSharedTemplateHelpPopoverClose}
                        disableRestoreFocus
                        PaperProps={{
                          style: {
                            width: '400px', height: '250px', padding: '10px'
                          }
                        }}
                      >
                      <div>
                        <Typography variant="subtitle2" component="div">Help for Shared Templates</Typography>
                        <Typography variant="caption">
                        <ul>
                          <li>Enter template tags in the searchbox to search for shared templates.</li>
                        </ul>
                        </Typography>
                        <Typography variant="subtitle2" component="div">Important!!! Please read PixarTee's terms and conditions before using templates.</Typography>
                      </div>
                      </Popover>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <SharedTemplateList 
                        templates={sharedtemplatesviewset?.sharedtemplates} 
                        onScrollList={handleSharedTemplateScrollEnd} 
                        onDragItem={handleOnDragTemplateStart} 
                        onAddTemplateItembarClick={handleOnAddTemplateItembarClick}
                        onViewTemplateItembarClick={handleOnEditTemplateItembarClick}
                      />
                      
                    </Grid>
                  </Grid>
                  
                </Stack>
              </TemplateTabPanel>
            </Grid>
            </Grid>
            </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'placephoto'} onChange={handleChange('placephoto')}>
          <AccordionSummary aria-controls="placephoto-content" id="placephoto-header" 
          //classes={{ expanded: classes.accordionexpanded }}
          >
            <Typography variant="h6">Place Photo</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <Grid container spacing={1}>
            <Grid item xs={12}>
              <Tabs value={phototab} onChange={handlePhotoTabChange} centered>
                <Tab label="Your Photos" {...photoTabProps(0)} />
                <Tab label="Shared Photos" {...photoTabProps(1)} />
                <Tab label="Instagram" {...photoTabProps(2)} />
                {/* <Tab label="Unsplash" {...photoTabProps(3)} /> */}
              </Tabs>
              <PhotoTabPanel value={phototab} index={0}>
                {isLoggedIn && tokenExpireOn > now ? (
                  <Stack spacing={1} sx={{ width: '100%'}}>
                    <Grid container spacing={1}>
                      <Grid item xs={8}>
                        <Autocomplete
                          multiple
                          id="photo-searchbox"
                          options={tags !== null ? tags:[]}
                          getOptionLabel={(option) => option.title}
                          defaultValue={yourphotosviewset?.searchtags}
                          onChange={handleYourPhotoTagChange}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              color="secondary"
                              label="Search Your Photos"
                              placeholder="Enter photo tags"
                            />
                          )}
                          
                        />
                      </Grid>
                      <Grid item xs={3}>
                      <FormControl variant="standard" color="secondary">
                        <InputLabel id="yourphoto-sortby">Sort by</InputLabel>
                        <Select
                          labelId="yourphoto-sortby"
                          value={yourphotosviewset?.sortby as string}
                          onChange={handleYourPhotoSortbyChange}
                          label="Sort by"
                        >
                          <MenuItem value={'-upload_dt'}>Newest</MenuItem>
                          <MenuItem value={'upload_dt'}>Oldest</MenuItem>
                        </Select>
                      </FormControl>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton 
                          color="secondary"
                          aria-owns={openYourPhotoHelpPopover ? 'yourimg-help-popover' : undefined}
                          aria-haspopup="true"
                          onMouseEnter={handleYourPhotoHelpPopoverOpen}
                          onMouseLeave={handleYourPhotoHelpPopoverClose}
                        >
                          <HelpIcon />
                        </IconButton>
                        <Popover
                          id="yourimg-help-popover"
                          sx={{
                            pointerEvents: 'none',
                          }}
                          open={openYourPhotoHelpPopover}
                          anchorEl={helpYourPhotoAnchorEl}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          onClose={handleYourPhotoHelpPopoverClose}
                          disableRestoreFocus
                          PaperProps={{
                            style: {
                              width: '400px', height: '250px', padding: '10px'
                            }
                          }}
                        >
                        <div>
                          <Typography variant="subtitle2" component="div">Help for Your Photos</Typography>
                          <Typography variant="caption">
                          <ul>
                            <li>You're required to sign in before using "Your Photos" feature.</li>
                            <li>Click the item with the "+" sign to browse for photos in your computer and upload to your Pixartee's personal storage. The storage is limited to 10GB.</li>
                            <li>The upload file cannot exceed 20 MB. We recommend a photo to be at least 1280x960 pixels (4:3).</li>
                            <li>Enter photo tags in the searchbox to search for your images stored in your account.</li>
                            <li>Manage your photos in "Account" page to share and earn money by sharing your photos.</li>
                          </ul>
                          </Typography>
                          <Typography variant="subtitle2" component="div">Important!!! Please read PixarTee's terms and conditions before uploading your photos.</Typography>
                        </div>
                        </Popover>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <YourPhotoList 
                          photos={yourphotosviewset?.yourphotos} 
                          currentUploadPhotos={selectedUploadPhotos} 
                          isUploading={isUploadingPhoto}
                          uploadprogress={uploadphotoprogress}
                          onAddPhotoClick={handleAddYourPhotoClick} 
                          onUploadClick={uploadPhoto}
                          onScrollList={handleYourPhotoScrollEnd} 
                          onDragItem={handleOnDragYourPhotoStart} 
                          onAddPhotoItembarClick={handleOnAddPhotoItembarClick}
                          onEditPhotoItembarClick={handleOnEditPhotoItembarClick}
                          onDeletePhotoItembarClick={handleOpenDeleteYourphotoDialog} />
                        <Dialog
                          open={opendeleteyourphotodialog}
                          onClose={handleCloseDeleteYourphotoDialog}
                          aria-labelledby="delete-yourphoto-dialog-title"
                          aria-describedby="delete-dialog-description"
                        >
                          <DialogTitle id="delete-yourphoto-dialog-title">
                            {`Deleting photo ${deleteyourphotoitemname}`}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="delete-yourphoto-dialog-description">
                              {`You are about to delete ${deleteyourphotoitemname}. Are you sure?`}
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleCloseDeleteYourphotoDialog}>No</Button>
                            <Button onClick={() => { handleOnDeletePhotoItembarClick(deleteyourphotoitemid) }} autoFocus>
                              Yes
                            </Button>
                          </DialogActions>
                        </Dialog>
                        <input type="file" id="yourphoto" 
                          ref={yourphotoFile} 
                          style={{display: 'none'}} 
                          accept="image/jpg,image/jpeg"
                          onChange={handleYourPhotoSelected} />
                      </Grid>
                    </Grid>
                    
                  </Stack>
                  ):(
                    <Button
                        variant="contained"
                        color="primary"  
                        onClick={handleAccountLoginClick}           
                    >
                      
                      Sign In to use Your Photos
                        
                    </Button>
                  )}
              </PhotoTabPanel>
              <PhotoTabPanel value={phototab} index={1}>
              <Stack spacing={1} sx={{ width: '100%'}}>
                    <Grid container spacing={1}>
                      <Grid item xs={8}>
                        <Autocomplete
                          multiple
                          id="sharedphoto-searchbox"
                          options={tags !== null ? tags:[]}
                          getOptionLabel={(option) => option.title}
                          defaultValue={sharedphotosviewset?.searchtags}
                          onChange={handleSharedPhotoTagChange}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              color="secondary"
                              label="Search Shared Photos"
                              placeholder="Enter photo tags"
                            />
                          )}
                          
                        />
                      </Grid>
                      <Grid item xs={3}>
                      <FormControl variant="standard" color="secondary">
                        <InputLabel id="sharedphoto-sortby">Sort by</InputLabel>
                        <Select
                          labelId="sharedphoto-sortby"
                          value={sharedphotosviewset?.sortby as string}
                          onChange={handleSharedPhotoSortbyChange}
                          label="Sort by"
                        >
                          <MenuItem value={'-upload_dt'}>Newest</MenuItem>
                          <MenuItem value={'upload_dt'}>Oldest</MenuItem>
                        </Select>
                      </FormControl>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton 
                          color="secondary"
                          aria-owns={openSharedPhotoHelpPopover ? 'sharedimg-help-popover' : undefined}
                          aria-haspopup="true"
                          onMouseEnter={handleSharedPhotoHelpPopoverOpen}
                          onMouseLeave={handleSharedPhotoHelpPopoverClose}
                        >
                          <HelpIcon />
                        </IconButton>
                        <Popover
                          id="sharedimg-help-popover"
                          sx={{
                            pointerEvents: 'none',
                          }}
                          open={openSharedPhotoHelpPopover}
                          anchorEl={helpSharedPhotoAnchorEl}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          onClose={handleSharedPhotoHelpPopoverClose}
                          disableRestoreFocus
                          PaperProps={{
                            style: {
                              width: '400px', height: '250px', padding: '10px'
                            }
                          }}
                        >
                        <div>
                          <Typography variant="subtitle2" component="div">Help for Shared Photos</Typography>
                          <Typography variant="caption">
                          <ul>
                            <li>You may use shared photos in your design. A shared photo may be included muliple times in one design.</li>
                            <li>The cost of using a shared photo is determined based on the number of prints. You may include the same shared photo several times in one design, and pay for each printed design.</li>
                            <li>Look for free and discounted photos where we update occasionally</li>
                            <li>Enter photo tags in the searchbox to search for shared photos.</li>
                          </ul>
                          </Typography>
                          <Typography variant="subtitle2" component="div">Important!!! Please read PixarTee's terms and conditions before uploading your photos.</Typography>
                        </div>
                        </Popover>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <SharedPhotoList 
                          photos={sharedphotosviewset?.sharedphotos} 
                          onScrollList={handleSharedPhotoScrollEnd} 
                          onDragItem={handleOnDragYourPhotoStart} 
                          onAddPhotoItembarClick={handleOnAddPhotoItembarClick}
                          onViewPhotoItembarClick={handleOnViewPhotoItembarClick}
                        />
                    
                      </Grid>
                    </Grid>
                    
                  </Stack>
              </PhotoTabPanel>
              <PhotoTabPanel value={phototab} index={2}>
                {igid !== null && igtoken !== null && igtokenExpireOn > now ? (
                  <Stack spacing={1} sx={{ width: '100%'}}>
                    <Grid container>
                      <Grid item xs={12}>
                        <IGPhotoList
                          photos={igphotosviewset?.igphotos}
                          onScrollList={handleIGPhotoScrollEnd}
                          onDragItem={handleOnDragIGPhotoStart}
                          onAddPhotoItembarClick={handleOnAddIGPhotoItembarClick}
                          onViewPhotoItembarClick={handleOnViewIGPhotoItembarClick}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                ):(
                  <Button
                        variant="contained"
                        color="primary" 
                        onClick={handleIGLoginClick}            
                    >
                  
                      Sign In to use Your Instagram
                        
                  </Button>
                )}
              
              </PhotoTabPanel>
              {/* <PhotoTabPanel value={phototab} index={3}>
                Item Four
              </PhotoTabPanel> */}
            </Grid>
            </Grid>
            </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'placegraphic'} onChange={handleChange('placegraphic')}>
          <AccordionSummary aria-controls="placegraphic-content" id="placegraphic-header" 
          //classes={{ expanded: classes.accordionexpanded }}
          >
            <Typography variant="h6">Place Graphic</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <Grid container spacing={1}>
            <Grid item xs={12}>
            <Tabs value={graphictab} onChange={handleGraphicTabChange} centered>
                <Tab label="Your Graphics" {...graphicTabProps(0)} />
                <Tab label="Shared Graphics" {...graphicTabProps(1)} />
              </Tabs>
              <GraphicTabPanel value={graphictab} index={0}>
                {isLoggedIn && tokenExpireOn > now ? (
                  <Stack spacing={1} sx={{ width: '100%'}}>
                    <Grid container spacing={1}>
                      <Grid item xs={8}>
                        <Autocomplete
                          multiple
                          id="graphic-searchbox"
                          options={tags !== null ? tags:[]}
                          getOptionLabel={(option) => option.title}
                          defaultValue={yourgraphicsviewset?.searchtags}
                          onChange={handleYourGraphicTagChange}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              color="secondary"
                              label="Search Your Graphics"
                              placeholder="Enter graphic tags"
                            />
                          )}
                          
                        />
                      </Grid>
                      <Grid item xs={3}>
                      <FormControl variant="standard" color="secondary">
                        <InputLabel id="yourgraphic-sortby">Sort by</InputLabel>
                        <Select
                          labelId="yourgraphic-sortby"
                          value={yourgraphicsviewset?.sortby as string}
                          onChange={handleYourGraphicSortbyChange}
                          label="Sort by"
                        >
                          <MenuItem value={'-upload_dt'}>Newest</MenuItem>
                          <MenuItem value={'upload_dt'}>Oldest</MenuItem>
                        </Select>
                      </FormControl>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton 
                          color="secondary"
                          aria-owns={openYourGraphicHelpPopover ? 'yourgraphic-help-popover' : undefined}
                          aria-haspopup="true"
                          onMouseEnter={handleYourGraphicHelpPopoverOpen}
                          onMouseLeave={handleYourGraphicHelpPopoverClose}
                        >
                          <HelpIcon />
                        </IconButton>
                        <Popover
                          id="yourgraphic-help-popover"
                          sx={{
                            pointerEvents: 'none',
                          }}
                          open={openYourGraphicHelpPopover}
                          anchorEl={helpYourGraphicAnchorEl}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          onClose={handleYourGraphicHelpPopoverClose}
                          disableRestoreFocus
                          PaperProps={{
                            style: {
                              width: '400px', height: '250px', padding: '10px'
                            }
                          }}
                        >
                        <div>
                          <Typography variant="subtitle2" component="div">Help for Your Graphics</Typography>
                          <Typography variant="caption">
                          <ul>
                            <li>You're required to sign in before using "Your Graphics" feature.</li>
                            <li>Click the item with the "+" sign to browse for PNG or SVG graphics in your computer and upload to your Pixartee's personal storage. The storage is limited to 10GB.</li>
                            <li>The upload file cannot exceed 20 MB.</li>
                            <li>Enter graphic tags in the searchbox to search for your graphics stored in your account.</li>
                            <li>Manage your graphics in "Account" page to share and earn money by sharing your graphics.</li>
                          </ul>
                          </Typography>
                          <Typography variant="subtitle2" component="div">Important!!! Please read PixarTee's terms and conditions before uploading your graphics.</Typography>
                        </div>
                        </Popover>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <YourGraphicList 
                          graphics={yourgraphicsviewset?.yourgraphics} 
                          currentUploadGraphics={selectedUploadGraphics} 
                          isUploading={isUploadingGraphic}
                          uploadprogress={uploadgraphicprogress}
                          onAddGraphicClick={handleAddYourGraphicClick} 
                          onUploadClick={uploadGraphic}
                          onScrollList={handleYourGraphicScrollEnd} 
                          onDragItem={handleOnDragYourGraphicStart} 
                          onAddGraphicItembarClick={handleOnAddGraphicItembarClick}
                          onEditGraphicItembarClick={handleOnEditGraphicItembarClick}
                          onDeleteGraphicItembarClick={handleOpenDeleteYourgraphicDialog} />
                        <Dialog
                          open={opendeleteyourgraphicdialog}
                          onClose={handleCloseDeleteYourgraphicDialog}
                          aria-labelledby="delete-yourgraphic-dialog-title"
                          aria-describedby="delete-yourgraphic-dialog-description"
                        >
                          <DialogTitle id="delete-yourgraphic-dialog-title">
                            {`Deleting graphic ${deleteyourgraphicitemname}`}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="delete-yourgraphic-dialog-description">
                              {`You are about to delete ${deleteyourgraphicitemname} from Your Graphics. Are you sure?`}
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleCloseDeleteYourgraphicDialog}>No</Button>
                            <Button onClick={() => { handleOnDeleteGraphicItembarClick(deleteyourgraphicitemid) }} autoFocus>
                              Yes
                            </Button>
                          </DialogActions>
                        </Dialog>
                        <input type="file" id="yourgraphic" 
                          ref={yourgraphicFile} 
                          style={{display: 'none'}} 
                          accept="image/png,image/svg+xml"
                          onChange={handleYourGraphicSelected} />
                        
                      </Grid>
                    </Grid>
                    
                  </Stack>
                  ):(
                    <Button
                        variant="contained"
                        color="primary"             
                    >
                      <Link to="/signin?action=signin">
                      Sign In to use Your Graphics
                      </Link>
                        
                    </Button>
                  )}
              </GraphicTabPanel>
              <GraphicTabPanel value={graphictab} index={1}>
              <Stack spacing={1} sx={{ width: '100%'}}>
                    <Grid container spacing={1}>
                      <Grid item xs={8}>
                        <Autocomplete
                          multiple
                          id="sharedgraphic-searchbox"
                          options={tags !== null ? tags:[]}
                          getOptionLabel={(option) => option.title}
                          defaultValue={sharedgraphicsviewset?.searchtags}
                          onChange={handleSharedGraphicTagChange}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              color="secondary"
                              label="Search Shared Graphics"
                              placeholder="Enter graphic tags"
                            />
                          )}
                          
                        />
                      </Grid>
                      <Grid item xs={3}>
                      <FormControl variant="standard" color="secondary">
                        <InputLabel id="sharedgraphic-sortby">Sort by</InputLabel>
                        <Select
                          labelId="sharedgraphic-sortby"
                          value={sharedgraphicsviewset?.sortby as string}
                          onChange={handleSharedGraphicSortbyChange}
                          label="Sort by"
                        >
                          <MenuItem value={'-upload_dt'}>Newest</MenuItem>
                          <MenuItem value={'upload_dt'}>Oldest</MenuItem>
                        </Select>
                      </FormControl>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton 
                          color="secondary"
                          aria-owns={openSharedGraphicHelpPopover ? 'sharedgraphic-help-popover' : undefined}
                          aria-haspopup="true"
                          onMouseEnter={handleSharedGraphicHelpPopoverOpen}
                          onMouseLeave={handleSharedGraphicHelpPopoverClose}
                        >
                          <HelpIcon />
                        </IconButton>
                        <Popover
                          id="sharedgraphic-help-popover"
                          sx={{
                            pointerEvents: 'none',
                          }}
                          open={openSharedGraphicHelpPopover}
                          anchorEl={helpSharedGraphicAnchorEl}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          onClose={handleSharedGraphicHelpPopoverClose}
                          disableRestoreFocus
                          PaperProps={{
                            style: {
                              width: '400px', height: '250px', padding: '10px'
                            }
                          }}
                        >
                        <div>
                          <Typography variant="subtitle2" component="div">Help for Shared Photos</Typography>
                          <Typography variant="caption">
                          <ul>
                            <li>You may use shared graphics in your design. A shared graphic may be included muliple times in one design.</li>
                            <li>The cost of using a shared graphic is determined based on the number of prints. You may include the same shared graphic several times in one design, and pay for each printed design.</li>
                            <li>Look for free and discounted graphics where we update occasionally</li>
                            <li>Enter graphic tags in the searchbox to search for shared photos.</li>
                          </ul>
                          </Typography>
                          <Typography variant="subtitle2" component="div">Important!!! Please read PixarTee's terms and conditions before uploading your graphics.</Typography>
                        </div>
                        </Popover>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <SharedGraphicList 
                          graphics={sharedgraphicsviewset?.sharedgraphics} 
                          onScrollList={handleSharedGraphicScrollEnd} 
                          onDragItem={handleOnDragYourGraphicStart} 
                          onAddGraphicItembarClick={handleOnAddGraphicItembarClick}
                          onViewGraphicItembarClick={handleOnViewGraphicItembarClick}
                        />
                    
                      </Grid>
                    </Grid>
                    
                  </Stack>
              </GraphicTabPanel>
            </Grid>
            </Grid>
            </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'placetext'} onChange={handleChange('placetext')}>
          <AccordionSummary aria-controls="placetext-content" id="placetext-header" 
          //classes={{ expanded: classes.accordionexpanded }}
          >
            <Typography variant="h6">Place Text</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    
                    <TextareaAutosize 
                      maxRows={7}
                      minRows={7}
                      placeholder="Enter text"
                      style={{ width: '100%' }}
                      onChange={handleInputTextChanged}
                    />

                  </Grid>
                  <Grid item xs={6}>
                    <Grid container direction="column" spacing={1}>
                      <Grid item xs={12}>
                        <Autocomplete
                          disablePortal
                          id="font-searchbox"
                          options={fonts ? fonts:[]}
                          getOptionLabel={(option) => option.name}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                            <FormControl>
                              <FormLabel><Typography variant='subtitle2' fontSize={10}>{option.name}</Typography></FormLabel>
                              <Typography variant='body1' fontSize='large'
                                sx={{
                                  ml: 2,
                                  fontFamily: `${option.name}`
                                }}>{option.sample}</Typography>
                            
                            </FormControl>
                            </Box>
                            
                          )}
                          onChange={handleFontFamilyChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              color="secondary"
                              label="Font Family"
                              placeholder="Enter font-family name"
                            />
                          )}
                          
                        />
                        
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container direction="row">
                          <Grid item xs={6}>
                          <FormControl variant="standard" color="secondary">
                          <InputLabel id="font-size-select">Font Size</InputLabel>
                          <Select
                            labelId="font-size-select"
                            label="Font Size"
                            value={fontsize}
                            onChange={handleFontSizeChange}
                            sx={{
                              width: 72
                            }}
                          >
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={11}>11</MenuItem>
                            <MenuItem value={12}>12</MenuItem>
                            <MenuItem value={14}>14</MenuItem>
                            <MenuItem value={16}>16</MenuItem>
                            <MenuItem value={18}>18</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={22}>22</MenuItem>
                            <MenuItem value={24}>24</MenuItem>
                            <MenuItem value={26}>26</MenuItem>
                            <MenuItem value={28}>28</MenuItem>
                            <MenuItem value={36}>36</MenuItem>
                            <MenuItem value={48}>48</MenuItem>
                            <MenuItem value={72}>72</MenuItem>
                            <MenuItem value={96}>96</MenuItem>
                            <MenuItem value={120}>120</MenuItem>
                          </Select>
                          </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                          <FormControl variant="standard" color="secondary">
                          <InputLabel htmlFor="font-color-picker">
                            Font Color
                          </InputLabel>
                          <Input
                            id="font-color-picker"
                            startAdornment={
                              <InputAdornment position="start">
                                <CircleIcon sx={{ color: fontcolor }} />
                              </InputAdornment>
                            }
                            value={fontcolor}
                            onClick={handleFontColorPickerPopoverOpen}
                            
                          />
                          </FormControl> 
                          <Popover
                            id="font-colorpicker-popover"
                        
                            open={openFontcolorpickerPopover}
                            anchorEl={fontcolorpickerAnchorEl}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            onClose={handleFontColorPickerPopoverClose}
                            disableRestoreFocus
                            
                          >
                          <SketchPicker color={fontcolor} onChangeComplete={handleFontcolorChangeComplete} />
                        </Popover>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleOnAddText}
                        >
                          Place text on design
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ width: '100%' }}>
                  
                    <Typography variant='subtitle2' fontSize={10}>Preview</Typography>
                    <Grid container alignItems="center" justifyContent="center">
                      <Grid item xs={12}>
                      <Box
                        sx={{
                          height: 150,
                          overflow: 'auto',
                          m: 1
                        }}>
                          <Typography
                          id="inputtext-preview"
                          alignContent="center"
                          sx={{
                            fontFamily: fontfamily,
                            fontSize: fontsize,
                            color: fontcolor
                          }}
                          >{inputtext}</Typography>
                          
                      </Box>
                      </Grid>
                    </Grid>
                    
                  
                  
                </Box>
              </Grid>
            </Grid>
            </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'sizequan'} onChange={handleChange('sizequan')}>
          <AccordionSummary aria-controls="panel3-content" id="panel3-header" 
          //classes={{ expanded: classes.accordionexpanded }}
          >
            <Typography variant="h6">Select size and quantity</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
            {sizes && sizes?.map((sc: SizeChart) => (
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">
                    {sc.sizename} - {sc.measurement}
                    </Typography>
                    <Typography variant="caption">
                    Availability: {sc.availability}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {/* <ButtonGroup size="small" aria-label="quantity selection">
                      <Button onClick={() => handleIncrement(sc.id)}>+</Button> */}
                      <TextField 
                        label="Quantity" 
                        id={`quan-${sc.id}`} 
                        type="number" 
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="standard"
                        inputProps={{
                          value: sc.quantity ? sc.quantity:0,
                          step: 1,
                          min: 0,
                          max: sc.limitquantity>0 ? sc.limitquantity:sc.availability
                        }}
                        sx={{
                          width: 50
                        }}
                        onChange={(e)=> { 
                          e.preventDefault();
                          e.stopPropagation();
                          handleQuantityChange(e as React.ChangeEvent<HTMLInputElement>,sc.id)
                        }}
                        error={sc.hasquanerr !== undefined && sc.hasquanerr !== null ? sc.hasquanerr:false}
                        helperText={sc.hasquanerr !== undefined && sc.hasquanerr !== null && sc.hasquanerr ? "Invalid quantity":""}
                      />
                          
                      
                      {/* <Button onClick={() => handleDecrement(sc.id)}>-</Button>
                    </ButtonGroup> */}
                  </Grid>
                </Grid>
              </Grid>
            ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        {/* <Dialog
          open={openquanconstraint}
          onClose={handleClosequanconstraint}
          aria-labelledby="quan-alert-dialog-title"
          aria-describedby="quan-alert-dialog-description"
        >
          <DialogTitle id="quan-alert-dialog-title">
            {"Limited purchasing quantity of the product"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This product has limited purchasing quantity for each order. 
              Please fill in the inquiry for a purchase over the allowed limit. 
              We apologize for this inconvenience.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button>Make Inquiry</Button>
            <Button onClick={handleClosequanconstraint} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog> */}
      </div>
      )
  }

