import * as React from 'react';
import compose from 'recompose/compose';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';
import { Autocomplete, Avatar, Button, Divider, FormControl, FormHelperText, Grid, Icon, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import { authorize_url, client_id, redirect_uri, redirect_iframe_uri, google_client_id, public_url, rest_endpoint, grecaptcha_site_key } from '../settings';
import { guid } from '../utilities/utilities';
import { withFormHook } from '../hooks/FormValidationHook';
import { Controller } from 'react-hook-form';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { toAbsoluteUrl } from '../_helpers';
import debounce from 'lodash/debounce';
import { withTranslationHook } from '../hooks/TranslationHook';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LanguageToggler } from '../layouts/components/widgets/LanguageToggler';
import { Link, withRouter } from 'react-router-dom';
import { resolve } from 'dns';
import { registerUserAPI } from '../reducers/func/userFunc';
import { returntypeof } from 'react-redux-typescript';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { backgroundPosition } from 'html2canvas/dist/types/css/property-descriptors/background-position';
import { backgroundRepeat } from 'html2canvas/dist/types/css/property-descriptors/background-repeat';


const styles = (theme: any) => ({
    paper: {
        height: 'auto',
        marginTop: 2,
        padding: 20,
        alignItems: 'top',
        justifyContent: 'center',
        backgroundImage: `url(${toAbsoluteUrl('/media/misc/bluri-dark-signup-bg.jpg')}) !important`,
        backgroundPosition: '395px 0px',
        backgroundRepeat: 'no-repeat'
    },
    paperbody: {
        justifyContent: 'center',
    },
    button1: {
        backgroundColor: '#fb0d1d'
    },
    button2: {
        backgroundColor: '#444444'
    },
    button3: {
        backgroundColor: '#227aee'
    }
})

interface Country {
    name: string;
    dial_code: string;
    code: string;
}

class Component extends React.Component<any, {
    showpwd: boolean,
    phonecodes: any,
    emaillang: string,
    regsubmit?: boolean,
    regsuccess?: boolean
}> {
    googleSignInRef: React.RefObject<any>
    regpwdRef: React.RefObject<any>

    constructor(props: any) {
        super(props)
        this.onRegistrationSubmit = this.onRegistrationSubmit.bind(this)
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this)
        this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this)
        this.checkExisting = this.checkExisting.bind(this)
        this.handleBack = this.handleBack.bind(this)
        this.fetchPhoneCodes = this.fetchPhoneCodes.bind(this)
        this.handleEmailLanguageChange = this.handleEmailLanguageChange.bind(this)
        this.state = {
            showpwd: false,
            phonecodes: [],
            emaillang: props.i18n.lang,
            regsubmit: false,
            regsuccess: false
        }
        this.googleSignInRef = React.createRef()
        this.regpwdRef = React.createRef()
        this.fetchPhoneCodes(toAbsoluteUrl("/lang/phonecodes.json"))
    }
    async fetchPhoneCodes(url: string) {
        const codes = await new Promise((resolve) => {
            fetch(url)
                .then((response) => response.json())
                .then((data) => resolve(data))
        })
        this.setState({ ...this.state, phonecodes: codes})
    }
    
    onRegistrationSubmit(data: any) {
        console.log(data) 
        const grecaptcha = (window as any).grecaptcha
        if (grecaptcha) {
            grecaptcha.ready(() => {
                grecaptcha.execute(grecaptcha_site_key, {action: 'submit'}).then((token: any) => {
                    // Add your logic to submit to your backend server here.
                    // console.log(token)
                    var country: Country | null = null 
                    if (data.countrycode !== '' && data.countrycode !== null && data.countrycode !== undefined) {
                        country = this.state.phonecodes.find((c: Country) => c.code === data.countrycode)
                    }
                    var mobileno = ''
                    if (data.mobileno !== '' && data.mobileno !== null && data.mobileno !== undefined) {
                        if (country !== null) {
                            mobileno = country.dial_code + (data.mobileno.charAt(0)==='0' ? data.mobileno.substring(1):data.mobileno)
                        } else {
                            mobileno = data.mobileno
                        }
                    }
                         
                    const reqdata = {
                        token: token,
                        username: data.email,
                        email: data.email,
                        first_name: data.firstname,
                        last_name: data.lastname,
                        password: data.password,
                        display_name: data.displayname,
                        role: 2,
                        mobile: mobileno,
                        subscribed: true,
                        preflang: this.state.emaillang
                    }
                    this.registerUser(reqdata)
                })
            })
        }
    }
    async registerUser(reqdata: any) {
        try {
            const { data } = await registerUserAPI(reqdata)
            this.setState({...this.state, regsubmit: true, regsuccess: true})
        } catch(e) {
            console.log("Registration failed")
            this.setState({...this.state, regsubmit: true, regsuccess: false})
        }
        
    }
    handleClickShowPassword() {
        this.setState({ ...this.state, showpwd: !this.state.showpwd })
    }
    
    handleMouseDownPassword(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault()
    }

    handleBack() {
        this.props.history.push(this.props.user.currentUri)
    }

    handleEmailLanguageChange(event: SelectChangeEvent) {
        this.setState({ ...this.state, emaillang: event.target.value as string });
    }

    checkExisting(value: any) {
        
        return new Promise((resolve) => { 
            fetch(`${rest_endpoint}checkexistinguser/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'username': value
                }) 
            })
            .then(response => {
                if (response.status===200) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
            .catch((error) => {
                console.error('Error:', error)
                resolve(false)
            })
        })

    }

    
    componentDidMount() {
        // if (this.googleSignInRef.current !== null) {
        //     const google = (window as any).google
        //     if (google) {
        //         google.accounts.id.initialize({
        //             client_id: google_client_id,
        //             ux_mode: 'redirect',
        //             login_uri: `${rest_endpoint}googlesignin`
        //         })
        //         // google.accounts.id.prompt()
        //         google.accounts.id.renderButton(this.googleSignInRef.current, {
        //         theme: 'outline',
        //         size: 'large',
        //         width: 350
        //         })
        //     }
        // }
        // const script = document.createElement("script");
        // script.id = "recaptcha-script"
        // script.src = "https://www.google.com/recaptcha/api.js?render=6LeFHPQdAAAAAKPodnHr7DqCtpSlB31I5rQvatPy";
        // script.async = true;

        // document.head.appendChild(script);
        const recaptchaEl = document.getElementsByClassName('grecaptcha-badge') as HTMLCollectionOf<HTMLElement>
        if (recaptchaEl && recaptchaEl.length > 0) {
            // document.head.removeChild(script)
            recaptchaEl[0].style.visibility = 'visible'
        }
    }

    componentDidUpdate() {
        // if (this.googleSignInRef.current !== null) {
        //     const google = (window as any).google
        //     if (google) {
        //         google.accounts.id.initialize({
        //             client_id: google_client_id,
        //             ux_mode: 'redirect',
        //             login_uri: `${rest_endpoint}googlesignin`
        //         })
        //         // google.accounts.id.prompt()
        //         google.accounts.id.renderButton(this.googleSignInRef.current, {
        //         theme: 'outline',
        //         size: 'large',
        //         width: 350
        //         })
        //     }
        // }
    }

    render() {
        const { classes, handleSubmit, control, watch, t, supportedLangs } = this.props
        return (
            <>
            <Grid container sx={{display: 'flex' }}>
                <Grid item xs={6} md={6}>
                <Grid container justifyContent="flex-start">
                    <IconButton
                        onClick={this.handleBack}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                <Grid container justifyContent="flex-end">
                    <LanguageToggler />
                </Grid>
                </Grid>
            </Grid>
            <Box sx={{margin: 'auto'}}>
                <Paper
                  className={classes.paper}
                >
                    {!this.state.regsubmit ? (
                        <Stack direction='row' spacing={2}>

                            <Box sx={{
                                width: 355
                            }}>
                                <Grid container 
                                        spacing={2}
                                        direction="column"
                                        alignItems="center"
                                        justifyContent="center">
                                        <Grid item xs={12} sx={{ justifyContent: 'center' }}>
                                        <img src={toAbsoluteUrl('/media/logos/pixartee-logo.png')} width={150} />
                                        </Grid>
                                        <Grid item xs={12}>
                                        <Typography variant="h6">Sign Up for a New Account</Typography>
                                        </Grid>
                                </Grid>
                            
                                <form id="registration-form" method="post">
                                <Stack spacing={1} sx={{ paddingTop: '10px' }}>
                                    <Controller
                                        name="firstname"
                                        control={control}
                                        rules={{ 
                                            required: {
                                                value: true,
                                                message: "Firstname is required"
                                            }
                                        }}
                                        render={({ field, fieldState, formState }) => 
                                        <TextField
                                            {...field}
                                            variant="standard"
                                            label="Firstname"
                                            color="secondary"
                                            error={fieldState.invalid}
                                            helperText={fieldState.invalid ? fieldState.error?.message:''}
                                        />}
                                    />
                                    <Controller
                                        name="lastname"
                                        control={control}
                                        rules={{ 
                                            required: {
                                                value: true,
                                                message: "Lastname is required"
                                            }
                                        }}
                                        render={({ field, fieldState, formState }) => 
                                        <TextField
                                            {...field}
                                            variant="standard"
                                            label="Lastname"
                                            color="secondary"
                                            error={fieldState.invalid}
                                            helperText={fieldState.invalid ? fieldState.error?.message:''}
                                        />}
                                    />
                                    <Controller
                                        name="displayname"
                                        control={control}
                                        rules={{ 
                                            required: {
                                                value: false,
                                                message: "Name your account"
                                            }
                                        }}
                                        render={({ field, fieldState, formState }) => 
                                        <TextField
                                            {...field}
                                            variant="standard"
                                            label="Display Name"
                                            color="secondary"
                                            error={fieldState.invalid}
                                            helperText={fieldState.invalid ? fieldState.error?.message:''}
                                        />}
                                    />
                                    <Controller
                                        name="email"
                                        control={control}
                                        rules={{ 
                                            required: {
                                                value: true,
                                                message: "E-mail is required"
                                            },
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Invalid e-mail address"
                                            },
                                            validate: debounce(async (value:any) => {
                                                
                                                const response = await fetch(`${rest_endpoint}checkexistinguser/`, {
                                                    method: 'POST',
                                                    headers: {
                                                    'Content-Type': 'application/json'
                                                    },
                                                    body: JSON.stringify({
                                                        'username': value
                                                    }) 
                                                })
                                                const status = await response.status
                                                if (status===200) {
                                                    return true
                                                } else {
                                                    return false
                                                }
                                                
                                            })
                                        }}
                                        render={({ field, fieldState, formState }) => 
                                        <TextField
                                            {...field}
                                            variant="standard"
                                            label="E-mail Address"
                                            color="secondary"
                                            error={fieldState.invalid}
                                            helperText={fieldState.invalid ? 
                                                (fieldState.error?.type !== 'validate' ? fieldState.error?.message:'This e-mail address has already been registered.'):''}
                                        />}
                                    />
                                    <Controller
                                        name="password"
                                        control={control}
                                        rules={{ 
                                            required: {
                                                value: true,
                                                message: "Password is required"
                                            },
                                            pattern: {
                                                value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                                message: "Password must contain at least 8 characters, 1 number, 1 capital letter, and 1 special character"
                                            },
                                            // minLength: {
                                            //     value: 8,
                                            //     message: "Password must be at least 8 characters"
                                            // }

                                        }}
                                        render={({ field, fieldState, formState }) => 
                                        <TextField
                                            {...field}
                                            type={this.state.showpwd ? "text":"password"}
                                            variant="standard"
                                            label="Password"
                                            color="secondary"
                                            error={fieldState.invalid}
                                            helperText={fieldState.invalid ? fieldState.error?.message:''}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                    onMouseDown={this.handleMouseDownPassword}
                                                    edge="end"
                                                    >
                                                    {this.state.showpwd ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                    </IconButton>
                                                </InputAdornment>,
                                            }}
                                            ref={this.regpwdRef}
                                        />}
                                    />
                                    <Controller
                                        name="retypepassword"
                                        control={control}
                                        rules={{ 
                                            required: {
                                                value: true,
                                                message: "Re-type Password is required"
                                            },
                                            validate: (value) => value === watch('password') || "Passwords don't match"

                                        }}
                                        render={({ field, fieldState, formState }) => 
                                        <TextField
                                            {...field}
                                            type={this.state.showpwd ? "text":"password"}
                                            variant="standard"
                                            label="Re-type Password"
                                            color="secondary"
                                            error={fieldState.invalid}
                                            helperText={fieldState.invalid ? fieldState.error?.message:''}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                    onMouseDown={this.handleMouseDownPassword}
                                                    edge="end"
                                                    >
                                                    {this.state.showpwd ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                    </IconButton>
                                                </InputAdornment>,
                                            }}
                                        />}
                                    />
                                    <Grid container sx={{display: 'flex'}}>
                                        <Grid item xs={5}>
                                        <Controller
                                            name="countrycode"
                                            control={control}
                                            rules={{ 
                                                required: false
                                            }}
                                            render={({ field, fieldState, formState }) => 
                                            <Autocomplete
                                            {...field}
                                            disablePortal
                                            options={this.state.phonecodes}
                                            sx={{ width: 140 }}
                                            getOptionLabel={(option: any) => option.code}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                <img
                                                    loading="lazy"
                                                    width="20"
                                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                    alt=""
                                                />
                                                {option.name} ({option.code}) {option.dial_code}
                                                </Box>
                                            )}
                                            renderInput={(params: any) => 
                                                <TextField {...params} 
                                                    label="Country (Optional)" 
                                                    variant="standard" 
                                                    color="secondary"
                                                />}
                                            onChange={(_event, data) => field.onChange(data?.code ?? '')}
                                            />}
                                        />
                                        </Grid>
                                        <Grid item xs={7}>
                                        <Controller
                                            name="mobileno"
                                            control={control}
                                            rules={{ 
                                                required: false,
                                                pattern: {
                                                    value: /^[0-9]*$/,
                                                    message: "Enter number only"
                                                }
                                            }}
                                            render={({ field, fieldState, formState }) => 
                                            <TextField
                                                {...field}
                                                variant="standard"
                                                label="Mobile Number (Optional)"
                                                color="secondary"
                                                sx={{ width: '100%' }}
                                                error={fieldState.invalid}
                                                helperText={fieldState.invalid ? fieldState.error?.message:''}
                                            />}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Controller
                                        name="emaillang"
                                        control={control}
                                        rules={{ 
                                            required: false
                                        }}
                                        render={({ field, fieldState, formState }) => 
                                        <FormControl>
                                        <Select
                                            {...field}
                                            value={this.state.emaillang}
                                            defaultValue={this.state.emaillang}
                                            label="E-mail language"
                                            onChange={this.handleEmailLanguageChange}
                                            variant="standard"
                                            color="secondary"
                                        >
                                            {Object.entries(supportedLangs).map(
                                                ([code, name]) => (
                                                    <MenuItem value={code}>{name as string}</MenuItem>
                                                )
                                            )}
                                        </Select>
                                        <FormHelperText>Which language would you like us to contact you?</FormHelperText>
                                        </FormControl>}
                                    />
                                    
                                    <div
                                        className="g-recaptcha"
                                        data-sitekey={grecaptcha_site_key}
                                        data-size="invisible"
                                    ></div>
                                    <Button 
                                        variant="contained" 
                                        color="primary"
                                        onClick={handleSubmit(this.onRegistrationSubmit)}
                                    >
                                        Sign Up
                                    </Button>
                                    <Typography variant='body1'>By signing up, you agree to our <Link to="/terms">Term</Link> and <Link to="/policy">Policy</Link></Typography>
                                    <Typography variant='body1'>Already a member? <Link to="/signin">Sign In</Link></Typography>

                                </Stack>
                                </form>
                            </Box>
                            <Box sx={{
                                color: 'white',
                                width: 500,
                                paddingLeft: 5,
                                display: {
                                    xs: 'none',
                                    sm: 'none',
                                    md: 'flex'
                                }
                            }}>
                                Member benefits
                            </Box>
                        </Stack>
                    ):(
                        <></>
                    )}
                    
                    
                    
                </Paper>
            </Box>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({}, dispatch)
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user,
        i18n: state.i18n
    }
}
const stateProps = returntypeof(mapStateToProps)
const dispatchProps = returntypeof(mapDispatchToProps)
export default compose(
    withRouter,
    withFormHook,
    withTranslationHook,
    withStyles(styles),
    connect<typeof stateProps, typeof dispatchProps, {}>(mapStateToProps, mapDispatchToProps)
)(Component)