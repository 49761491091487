import * as React from 'react'
import { toAbsoluteUrl } from '../../../../_helpers'
import SVG from 'react-inlinesvg'
import { AppBar, Box, Button, Grid, Toolbar, Typography, ToggleButton, Divider, Popover, Popper, Paper, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Slider, LinearProgress, Tabs, Tab, Switch, ImageList, ImageListItem, Autocomplete, TextField, InputLabel, Select, MenuItem, Input, InputAdornment, SelectChangeEvent } from '@mui/material'
import { makeStyles } from '@mui/styles';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import TransformIcon from '@mui/icons-material/Transform';
import CropIcon from '@mui/icons-material/Crop';
import ExpandIcon from '@mui/icons-material/Expand';
import DeleteIcon from '@mui/icons-material/Delete';
import FlipToFrontIcon from '@mui/icons-material/FlipToFront';
import FlipToBackIcon from '@mui/icons-material/FlipToBack';
import FilterHdrIcon from '@mui/icons-material/FilterHdr';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import FilterCenterFocusIcon from '@mui/icons-material/FilterCenterFocus';
import PublishIcon from '@mui/icons-material/Publish';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import FlipIcon from '@mui/icons-material/Flip';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import OpacityIcon from '@mui/icons-material/Opacity';
import CircleIcon from '@mui/icons-material/Circle';
import LightIcon from '@mui/icons-material/Light';
import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone';
import { DesignContext, DesignLayer, DesignRefs, DesignState, DesignView } from '../../../../providers/DesignRefContext';

import { applypresetAPI, getyourphotoAPI, photopublishAPI, presetlistAPI, removebgAPI } from '../../../../reducers/func/photoFunc';
import { generaterandomstr } from '../../../../utilities/utilities';
import { getyourgraphicAPI, graphicpublishAPI } from '../../../../reducers/func/graphicFunc';
import { Rotate90DegreesCcw } from '@mui/icons-material';
import { Group, Image, Layer, Stage, Text } from 'react-konva';
import useImage from 'use-image';
import Konva from 'konva';
import { CartAssetUsagePrice, CartCustomizedItem, CartItem, CartItemProductPrice, CartQuantity, Font, ProductPrice, StoreCart, YourGraphic, YourPhoto } from '../../../../types';
import { useAppSelector, useAppDispatch } from '../../../../reducers/hooks';
import { SketchPicker } from 'react-color';

const useStyles = makeStyles((theme: any) => ({
    toolbar: {
        height: 50,
        marginLeft: theme.spacing(6),
        marginRight: theme.spacing(2)
    },
    tbtext: {
        alignItems: 'center'
    }
}))

interface PublishConfig {
    mimeType: string
    quality?: number
}

interface PhotoEditorTabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

function PhotoEditorTabPanel(props: PhotoEditorTabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`photo-editor-tabpanel-${index}`}
        aria-labelledby={`photo-editor-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 1 , height: 500}}>
            {children}
            </Box>
        )}
        </div>
    )
}

function photoeditorTabProps(index: number) {
    return {
        id: `photo-editor-tab-${index}`,
        'aria-controls': `photo-editor-tabpanel-${index}`,
    };
}

// interface FilterAttr {
//     attrname: string
//     attrvalue: number
// }
interface PhotoFilter {
    filtername: string
    filterattr?: any
}

interface FilterPreset {
    id: string
    presetname: string
    preview: string
    active: number
}

interface PhotoEditProps {
    srcid?: string,
    srcname?: string,
    previewimage?: string
    publishimage?: string
    previewimagedata?: any
    publishimagedata?: any
    previewattr?: any
    publishattr?: any
    previewimageRef?: any
    publishimageRef?: any
    filters?: PhotoFilter[] | null
    presets?: FilterPreset[] | null
    autoremovebg?: boolean
    setPhotoMenuAnchorEl?: (el: any) => void
    applyPhoto?: (item: YourPhoto) => void
    applyGraphic?: (item: YourGraphic) => void
    resetChange?: () => void
    updatePhotoEditProps?: (props: any) => void
}



function PhotoEditControl(props: PhotoEditProps) {
    const { updatePhotoEditProps } = props
    const [photoeditortab, setPhotoeditortab] = React.useState(0)
    const [RGBfilter, setRGBfilter] = React.useState(false)
    const [HSLfilter, setHSLfilter] = React.useState(false)
    const [autoremovebg, setAutoremovebg] = React.useState(false)
    const [filters, setFilters] = React.useState<PhotoFilter[] | null>(null)
    const [rgbattr, setRGBattr] = React.useState<any>(null)
    const [hslattr, setHSLattr] = React.useState<any>(null)
    // const [rvalue, setRvalue] = React.useState(150)
    // const [gvalue, setGvalue] = React.useState(150)
    // const [bvalue, setBvalue] = React.useState(150)
    // const [hvalue, setHvalue] = React.useState(180)
    // const [svalue, setSvalue] = React.useState(0)
    // const [lvalue, setLvalue] = React.useState(0)
    const [brightness, setBrightness] = React.useState(0)
    const [contrast, setContrast] = React.useState(0)
    const [blur, setBlur] = React.useState(0)

    React.useEffect(() => {
        if (props.filters) {
            props.filters.forEach((filter: PhotoFilter) => {
                if (filter.filtername === 'RGB') {
                    setRGBfilter(true) 
                    setRGBattr(filter.filterattr)
                }
                if (filter.filtername === 'HSL') {
                    setHSLfilter(true) 
                    setHSLattr(filter.filterattr) 
                }
                if (filter.filtername === 'Brighten') {
                    setBrightness(filter.filterattr['brightness']) 
                }
                if (filter.filtername === 'Contrast') {
                    setContrast(filter.filterattr['contrast']) 
                }
                if (filter.filtername === 'Blur') {
                    setBlur(filter.filterattr['blurRadius']) 
                }
                
            })
            setFilters(props.filters)
        }
        if (props.autoremovebg) {
            setAutoremovebg(props.autoremovebg)
        }
        

    }, [props.filters, props.autoremovebg])
    

    const handlePhotoeditorTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setPhotoeditortab(newValue);
    }

    const handleRGBfilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.previewattr.ref.current) {
            if (event.target.checked) {
                const node = props.previewattr.ref.current
                
                node?.cache()
                node?.filters().push(Konva.Filters.RGB)
                const pubnode = props.publishattr.ref.current
                // const current_pubfilters = pubnode?.filters()
                pubnode?.cache()
                // pubnode?.filters().push(Konva.Filters.RGB)
                if (node?.red() === 0 || pubnode?.red() === 0) {
                    node?.red(150)
                    pubnode?.red(150)
                } 
                if (node?.green() === 0 || pubnode?.green() === 0) {
                    node?.green(150)
                    pubnode?.green(150)
                }
                if (node?.blue() === 0 || pubnode?.blue() === 0) {
                    node?.blue(150)
                    pubnode?.blue(150)
                }
                
                const new_filter: PhotoFilter = {
                    filtername: 'RGB',
                    filterattr: {
                            red: node?.red(),
                            green: node?.green(),
                            blue: node?.blue()
                        }
                    
                }
                
                filters!.push(new_filter)
                setFilters(filters)
                setRGBattr(new_filter.filterattr)
            } else {
                const node = props.previewattr.ref.current
                const pubnode = props.publishattr.ref.current
                
                const current_filters = node?.filters()
                
                const rgbfilterIdx = current_filters.findIndex((f: any) => f.name === 'RGB')
                if (rgbfilterIdx > -1) {
                    current_filters.splice(rgbfilterIdx,1)
                    filters!.splice(rgbfilterIdx,1)
                }
                node?.cache()
                node?.filters(current_filters)
                pubnode?.cache()
                pubnode?.filters(current_filters)
                setFilters(filters)
            }
        }
        setRGBfilter(event.target.checked);
    }

    const handleRchannelChange = (event: Event, newValue: number | number[]) => {
        
        if (props.previewattr.ref.current) {
            const node = props.previewattr.ref.current
            node.red(newValue as number)
            const pubnode = props.publishattr.ref.current
            pubnode.red(newValue as number)
            const new_filters = filters!.map((filter: PhotoFilter) =>
                filter.filtername === 'RGB' ? {
                    ...filter,
                    filterattr: {
                        ...filter.filterattr,
                        red: node.red()
                    }
                }:filter
            )
            setFilters(new_filters)
            setRGBattr({
                ...rgbattr,
                red: node.red()
            })
        }
    }

    const handleGchannelChange = (event: Event, newValue: number | number[]) => {
        
        if (props.previewattr.ref.current) {
            const node = props.previewattr.ref.current
            node.green(newValue as number)
            const pubnode = props.publishattr.ref.current
            pubnode.green(newValue as number)
            const new_filters = filters!.map((filter: PhotoFilter) =>
                filter.filtername === 'RGB' ? {
                    ...filter,
                    filterattr: {
                        ...filter.filterattr,
                        green: node.green()
                    }
                }:filter
            )
            setFilters(new_filters)
            setRGBattr({
                ...rgbattr,
                green: node.green()
            })
        }
    }

    const handleBchannelChange = (event: Event, newValue: number | number[]) => {
        
        if (props.previewattr.ref.current) {
            const node = props.previewattr.ref.current
            node.blue(newValue as number)
            const pubnode = props.publishattr.ref.current
            pubnode.blue(newValue as number)
            const new_filters = filters!.map((filter: PhotoFilter) =>
                filter.filtername === 'RGB' ? {
                    ...filter,
                    filterattr: {
                        ...filter.filterattr,
                        blue: node.blue()
                    }
                }:filter
            )
            setFilters(new_filters)
            setRGBattr({
                ...rgbattr,
                blue: node.blue()
            })
        }
    }

    const handleHSLfilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.previewattr.ref.current) {
            if (event.target.checked) {
                const node = props.previewattr.ref.current
                node?.cache()
                node?.filters().push(Konva.Filters.HSL)
                const pubnode = props.publishattr.ref.current
                pubnode?.cache()
                // pubnode?.filters().push(Konva.Filters.HSL)
                if (node?.hue() === 0 || pubnode?.hue() === 0) {
                    node?.hue(180)
                    pubnode?.hue(180)
                } 
                
                const new_filter: PhotoFilter = {
                    filtername: 'HSL',
                    filterattr: {
                            hue: node?.hue(),
                            saturation: node?.saturation(),
                            luminance: node?.luminance()
                        }
                    
                }
                filters!.push(new_filter)
                setFilters(filters)
                setHSLattr(new_filter.filterattr)
                
            } else {
                const node = props.previewattr.ref.current
                const pubnode = props.publishattr.ref.current
                const current_filters = node?.filters()
                const hslfilterIdx = current_filters.findIndex((f: any) => f.name === 'HSL')
                if (hslfilterIdx > -1) {
                    current_filters.splice(hslfilterIdx,1)
                    filters!.splice(hslfilterIdx,1)
                }
                node?.cache()
                node?.filters(current_filters)
                pubnode?.cache()
                pubnode?.filters(current_filters)
                setFilters(filters)
            }
        }
        setHSLfilter(event.target.checked);
    }

    const handleHueChange = (event: Event, newValue: number | number[]) => {
        
        if (props.previewattr.ref.current) {
            const node = props.previewattr.ref.current
            const pubnode = props.publishattr.ref.current
            node.hue(newValue as number)
            pubnode.hue(newValue as number)
            const new_filters = filters!.map((filter: PhotoFilter) =>
                filter.filtername === 'HSL' ? {
                    ...filter,
                    filterattr: {
                        ...filter.filterattr,
                        hue: node.hue()
                    }
                }:filter
            )
            setFilters(new_filters)
            setHSLattr({
                ...hslattr,
                hue: node.hue()
            })
        }
    }

    const handleSaturationChange = (event: Event, newValue: number | number[]) => {
        
        if (props.previewattr.ref.current) {
            const node = props.previewattr.ref.current
            const pubnode = props.publishattr.ref.current
            node.saturation(newValue as number)
            pubnode.saturation(newValue as number)
            const new_filters = filters!.map((filter: PhotoFilter) =>
                filter.filtername === 'HSL' ? {
                    ...filter,
                    filterattr: {
                        ...filter.filterattr,
                        saturation: node.saturation()
                    }
                }:filter
            )
            setFilters(new_filters)
            setHSLattr({
                ...hslattr,
                saturation: node.saturation()
            })
        }
    }

    const handleLuminanceChange = (event: Event, newValue: number | number[]) => {
        
        if (props.previewattr.ref.current) {
            const node = props.previewattr.ref.current
            const pubnode = props.publishattr.ref.current
            node.luminance(newValue as number)
            pubnode.luminance(newValue as number)
            const new_filters = filters!.map((filter: PhotoFilter) =>
                filter.filtername === 'HSL' ? {
                    ...filter,
                    filterattr: {
                        ...filter.filterattr,
                        luminance: node.luminance()
                    }
                }:filter
            )
            setFilters(new_filters)
            setHSLattr({
                ...hslattr,
                luminance: node.luminance()
            })
        }
    }

    const handleBrightnessChange = (event: Event, newValue: number | number[]) => {
        
        if (props.previewattr.ref.current) {
            const node = props.previewattr.ref.current
            const pubnode = props.publishattr.ref.current
            node.brightness(newValue as number)
            pubnode.brightness(newValue as number)
            const new_filters = filters!.map((filter: PhotoFilter) =>
                filter.filtername === 'Brighten' ? {
                    ...filter,
                    filterattr: {
                        ...filter.filterattr,
                        brightness: node.brightness()
                    }
                }:filter
            )
            setFilters(new_filters)
            setBrightness(node.brightness())
        }
    }

    const handleContrastChange = (event: Event, newValue: number | number[]) => {
        
        if (props.previewattr.ref.current) {
            const node = props.previewattr.ref.current
            const pubnode = props.publishattr.ref.current
            node.contrast(newValue as number)
            pubnode.contrast(newValue as number)
            const new_filters = filters!.map((filter: PhotoFilter) =>
                filter.filtername === 'Contrast' ? {
                    ...filter,
                    filterattr: {
                        ...filter.filterattr,
                        contrast: node.contrast()
                    }
                }:filter
            )
            setFilters(new_filters)
            setContrast(node.contrast())
        }
    }

    const handleBlurChange = (event: Event, newValue: number | number[]) => {
        
        if (props.previewattr.ref.current) {
            const node = props.previewattr.ref.current
            const pubnode = props.publishattr.ref.current
            node.blurRadius(newValue as number)
            pubnode.blurRadius(newValue as number)
            const new_filters = filters!.map((filter: PhotoFilter) =>
                filter.filtername === 'Blur' ? {
                    ...filter,
                    filterattr: {
                        ...filter.filterattr,
                        blurRadius: node.blurRadius()
                    }
                }:filter
            )
            setFilters(new_filters)
            setBlur(node.blurRadius())   
        }
    }

    const handleAutoremovebg = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.previewattr.ref.current) {
            if (event.target.checked) {
                if (props.srcid) {
                    removebgAPI(props.srcid)
                    .then((response) => {
                        const data = response.data.processeddata
                        // const node = props.previewattr.ref.current
                        // let photo = new window.Image()
                        // photo.src = data
                        // photo.onload = () => {
                            if (updatePhotoEditProps) {
                                updatePhotoEditProps({
                                    preview: data,
                                    publish: data,
                                    filters: filters,
                                    autoremovebg: true
                                })
                            }
                        //}
                    })
                    .catch((error) => {
                        console.log("Error applying autoremove bg: ", error)
                    })
                }
                
            } else {
                
                
            }
        }
        //setAutoremovebg(event.target.checked)
    }

    const handleApplyPreset = (presetname: string) => {
        if (props.previewattr.ref.current) {
            
            if (props.srcid) {
                applypresetAPI(props.srcid, presetname)
                .then((response) => {
                    const data = response.data.processeddata
                    const BrightenFilter: PhotoFilter = {
                        filtername: 'Brighten',
                        filterattr: 
                            {
                                brightness: 0
                            }
                        
                    }
                    const ContrastFilter: PhotoFilter = {
                        filtername: 'Contrast',
                        filterattr: 
                            {
                                contrast: 0
                            }
                        
                    }
                    const BlurFilter: PhotoFilter = {
                        filtername: 'Blur',
                        filterattr: 
                            {
                                blurRadius: 0
                            }
                        
                    }
                    const filters: PhotoFilter[] = []
                    filters.concat([BrightenFilter, ContrastFilter, BlurFilter])
                    // let photo = new window.Image()
                    // photo.src = data
                    // photo.onload = () => {
                        if (updatePhotoEditProps) {
                            updatePhotoEditProps({
                                preview: data,
                                publish: data,
                                filters: filters,
                                autoremovebg: false
                            })
                        }
                    //}
                })
                .catch((error) => {
                    console.log("Error applying preset: ", error)
                })
            }
                
            
        }
        //setAutoremovebg(false)
    }

    return (
        <>
        <Tabs value={photoeditortab} onChange={handlePhotoeditorTabChange}>
            <Tab label="Adjustment" {...photoeditorTabProps(0)} />
            <Tab label="Presets" {...photoeditorTabProps(1)} />
            
        </Tabs>
        <PhotoEditorTabPanel value={photoeditortab} index={0}>
            <Grid container direction="column" spacing={1} alignItems="flex-start">
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={6}>
                            <Grid container direction="column">
                                <Grid item xs={12}>
                                <FormControl>
                                <FormLabel id="activate-rgb">RGB Filter:</FormLabel>
                                <Switch 
                                    checked={RGBfilter}
                                    onChange={handleRGBfilterChange}
                                />
                                </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                <FormControl>
                                <FormLabel id="adjust-red-channel">R</FormLabel>
                                <Slider
                                    aria-labelledby="adjust-red-channel"
                                    defaultValue={rgbattr ? rgbattr['red']:150}
                                    min={0}
                                    max={255}
                                    valueLabelDisplay="auto"
                                    color="secondary"
                                    sx={{ width: '180px'}}
                                    onChange={handleRchannelChange}
                                    disabled={!RGBfilter}
                                    
                                    />


                                </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                <FormControl>
                                <FormLabel id="adjust-green-channel">G</FormLabel>
                                <Slider
                                    aria-labelledby="adjust-green-channel"
                                    defaultValue={rgbattr ? rgbattr['green']:150}
                                    min={0}
                                    max={255}
                                    valueLabelDisplay="auto"
                                    color="secondary"
                                    sx={{ width: '180px'}}
                                    disabled={!RGBfilter}
                                    onChange={handleGchannelChange}
                                    />


                                </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                <FormControl>
                                <FormLabel id="adjust-blue-channel">B</FormLabel>
                                <Slider
                                    aria-labelledby="adjust-blue-channel"
                                    defaultValue={rgbattr ? rgbattr['blue']:150}
                                    min={0}
                                    max={255}
                                    valueLabelDisplay="auto"
                                    color="secondary"
                                    sx={{ width: '180px'}}
                                    disabled={!RGBfilter}
                                    onChange={handleBchannelChange}
                                    />


                                </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container direction="column">
                                <Grid item xs={12}>
                                <FormControl>
                                <FormLabel id="activate-hsl">HSL Filter:</FormLabel>
                                <Switch 
                                    checked={HSLfilter}
                                    onChange={handleHSLfilterChange}
                                />
                                </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                <FormControl>
                                <FormLabel id="adjust-hue-channel">H</FormLabel>
                                <Slider
                                    aria-labelledby="adjust-hue-channel"
                                    defaultValue={hslattr ? hslattr['hue']:180}
                                    min={0}
                                    max={359}
                                    valueLabelDisplay="auto"
                                    color="secondary"
                                    sx={{ width: '180px'}}
                                    disabled={!HSLfilter}
                                    onChange={handleHueChange}
                                    />


                                </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                <FormControl>
                                <FormLabel id="adjust-saturation-channel">S</FormLabel>
                                <Slider
                                    aria-labelledby="adjust-saturation-channel"
                                    defaultValue={hslattr ? hslattr['saturation']:0}
                                    min={-1}
                                    max={1}
                                    step={0.1}
                                    marks={true}
                                    valueLabelDisplay="auto"
                                    color="secondary"
                                    sx={{ width: '180px'}}
                                    disabled={!HSLfilter}
                                    onChange={handleSaturationChange}
                                    />


                                </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                <FormControl>
                                <FormLabel id="adjust-luminance-channel">L</FormLabel>
                                <Slider
                                    aria-labelledby="adjust-luminance-channel"
                                    defaultValue={hslattr ? hslattr['luminance']:180}
                                    min={-1}
                                    max={1}
                                    step={0.1}
                                    marks={true}
                                    valueLabelDisplay="auto"
                                    color="secondary"
                                    sx={{ width: '180px'}}
                                    disabled={!HSLfilter}
                                    onChange={handleLuminanceChange}
                                    />


                                </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={6}>
                            <Grid container direction="column">
                                <Grid item xs={12}>
                                <FormControl>
                                <FormLabel id="adjust-brightness">Brightness:</FormLabel>
                                    <Slider
                                        aria-labelledby="adjust-brightness"
                                        defaultValue={brightness}
                                        min={-1}
                                        max={1}
                                        step={0.1}
                                        marks={true}
                                        valueLabelDisplay="auto"
                                        color="secondary"
                                        sx={{ width: '180px'}}
                                        onChange={handleBrightnessChange}
                                        />
                                </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                <FormControl>
                                <FormLabel id="adjust-contrast">Contrast:</FormLabel>
                                    <Slider
                                        aria-labelledby="adjust-contrast"
                                        defaultValue={contrast}
                                        min={-100}
                                        max={100}
                                        step={10}
                                        marks={true}
                                        valueLabelDisplay="auto"
                                        color="secondary"
                                        sx={{ width: '180px'}}
                                        onChange={handleContrastChange}
                                        />
                                </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                <FormControl>
                                <FormLabel id="adjust-blur">Blur:</FormLabel>
                                    <Slider
                                        aria-labelledby="adjust-blur"
                                        defaultValue={blur}
                                        min={0}
                                        max={10}
                                        valueLabelDisplay="auto"
                                        color="secondary"
                                        sx={{ width: '180px'}}
                                        onChange={handleBlurChange}
                                        />
                                </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container direction="column">
                                <Grid item xs={12}>
                                <FormControl>
                                <FormLabel id="activate-rm-bg">Background Autoremove:</FormLabel>
                                <Switch 
                                    checked={autoremovebg}
                                    onChange={handleAutoremovebg}
                                />
                                </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </PhotoEditorTabPanel>
        <PhotoEditorTabPanel value={photoeditortab} index={1}>
            <ImageList
                variant="quilted"
                sx={{ width: 400, height: 500 }} cols={3} rowHeight={100}>
                <>
               {props.presets?.map((item: FilterPreset) => (
                   
                    <ImageListItem key={item.id}>
                        <img
                            src={`${item.preview}`}
                            srcSet={`${item.preview}`}
                            alt={item.presetname}
                            loading="lazy"
                            onClick={() => handleApplyPreset(item.presetname)}
                        />
                    
                    </ImageListItem>
                    
               ))}
               </>
            </ImageList>
        </PhotoEditorTabPanel>
        </>
    )
}

function PhotoEditPreview(props: PhotoEditProps) {
    const {setPhotoMenuAnchorEl, applyPhoto, applyGraphic, resetChange} = props
    
    const [publishLayerprogress, setPublishLayerprogress] = React.useState(0)
    const [isPublishingLayer, setIsPublishingLayer] = React.useState(false)

    const [image, status] = useImage(props.previewimage as string, 'anonymous')
    const [pubimage, pubimgstatus] = useImage(props.publishimage as string, 'anonymous')
    //const image = props.previewimagedata
    React.useEffect(() => {
        if (image && pubimage) {
            const node = props.previewattr.ref.current
            const pubnode = props.publishattr.ref.current
            node?.cache()
            let filters:any = []
            if (props.filters) {
                props.filters.forEach((filter: PhotoFilter) => {
                    if (filter.filtername === 'RGB') {
                        filters.push(Konva.Filters.RGB) 
                    }
                    if (filter.filtername === 'HSL') {
                        filters.push(Konva.Filters.HSL) 
                    }
                    if (filter.filtername === 'Brighten') {
                        filters.push(Konva.Filters.Brighten) 
                    }
                    if (filter.filtername === 'Contrast') {
                        filters.push(Konva.Filters.Contrast) 
                    }
                    if (filter.filtername === 'Blur') {
                        filters.push(Konva.Filters.Blur) 
                    }
                    for (const [k,v] of Object.entries(filter.filterattr)) {
                        node[k](v)
                    }
                    // filter.filterattr.forEach((attr: FilterAttr) => {
                    //     node[attr.attrname](attr.attrvalue)
                    // })
                })
            } else {
                filters = [Konva.Filters.Brighten, Konva.Filters.Contrast, Konva.Filters.Blur]
            }
            
            node?.filters(filters)
            pubnode?.filters(filters)
            
        }
    }, [image, pubimage])

    const handleApplyPhoto = () => {
        const pubnode = props.publishattr.ref.current
        const pubdata = pubnode.toDataURL({
            mimeType: 'image/jpeg',
            quality: 0.8
        })
        const pubname = `processed-${generaterandomstr(6)}-${props.srcname?.split('.')[0]}`
        setPublishLayerprogress(0)
        setIsPublishingLayer(true)
        photopublishAPI(pubdata, `${pubname}.jpg`, (event: any) => {
            setPublishLayerprogress(Math.round((100 * event.loaded) / event.total))
        }).then((response: any) => {
            if (setPhotoMenuAnchorEl) {
                setPhotoMenuAnchorEl(null)
            }

            if (applyPhoto) {
                applyPhoto(response.data.photo)
            }
        }).catch((e) => { 
            console.log("Error publishing processed photo: ", e.message)
        })
    }

    const handleApplyGraphic = () => {
        const pubnode = props.publishattr.ref.current
        const pubdata = pubnode.toDataURL({
            mimeType: 'image/png'
        })
        const pubname = `processed-${generaterandomstr(6)}-${props.srcname?.split('.')[0]}`
        setPublishLayerprogress(0)
        setIsPublishingLayer(true)
        graphicpublishAPI(pubdata, `${pubname}.png`, (event: any) => {
            setPublishLayerprogress(Math.round((100 * event.loaded) / event.total))
        }).then((response: any) => {
            setIsPublishingLayer(false)
            if (setPhotoMenuAnchorEl) {
                setPhotoMenuAnchorEl(null)
            }
            if (applyGraphic) {
                applyGraphic(response.data.graphic)
            }
        }).catch((e) => { 
            setIsPublishingLayer(false)
            console.log("Error publishing processed photo to graphic: ", e.message)
        })
    }

    const handleCancel = () => {
        if (setPhotoMenuAnchorEl) {
            setPhotoMenuAnchorEl(null)
        }
        if (resetChange) {
            resetChange()
        }
    }

    return (
        <Grid container direction="column" spacing={1} alignItems="flex-start">
            <Grid item>
                <div style={{
                        position: 'relative',
                        width: '224px',
                        height: '224px'
                    }}>
                <div style={{
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        width: '224px',
                        height: '224px'
                    }}>
                    <Stage
                        width={224}
                        height={224}>
                        
                        <Layer>
                            <Image
                                image={image}
                                {...props.previewattr}
                            />
                        </Layer>
                        
                    </Stage>
                </div>
                <div style={{ overflow: 'hidden' }}>
                <div style={{
                        visibility: 'hidden',
                        position: 'absolute',
                        top: '-600px',
                        left: '-600px',
                        width: '1280px',
                        height: '1280px'
                    }}>
                    <Stage
                        visible={true}
                        width={1280}
                        height={1280}>
                        <Layer>
                            <Image
                                image={pubimage}
                                {...props.publishattr}
                            />
                        </Layer>

                    </Stage>
                </div>
                </div>
                </div>
            </Grid>
            <Grid item>
                <Grid container direction="row" spacing={1} alignItems="center" justifyContent="center">
                    <Grid item>
                        <Button variant="contained"
                                color="secondary"
                                onClick={props.autoremovebg ? handleApplyGraphic:handleApplyPhoto}>
                            Apply
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained"
                                color="secondary"
                                onClick={handleCancel}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

interface TextEditProps {
    textgrpattr?: any
    textattr?: any
    fonts?: Font[] | null
    pathpreset?: string
    // setTextMenuAnchorEl?: (el: any) => void
    applyText?: () => void
    resetChange?: () => void
    updateTextEditProps?: (props: any) => void
}

function TextEditControl(props: TextEditProps) {
    const { fonts, textattr, updateTextEditProps } = props
    const [fontfamily, setFontFamily] = React.useState<Font | undefined | null>(null)
    const [fontsize, setFontSize] = React.useState('24')
    const [fontcolorpickerAnchorEl, setFontcolorpickerAnchorEl] = React.useState<HTMLElement | null>(null);
    const [fontcolor, setFontColor] = React.useState('#000000')
    // const [pathpreset, setPathpreset] = React.useState('horizontal')
    
    React.useEffect(() => {
        if (textattr) {
            const font: Font | undefined = fonts?.find((f: Font) => f.name === textattr['fontFamily'])
            setFontFamily(font)
            setFontSize(textattr['fontSize'])
            setFontColor(textattr['fill'])
        } 
    }, [textattr])

    const handleFontFamilyChange = (event: React.SyntheticEvent, value: any) => {
        if (value) {
            setFontFamily(value)
        } else {
            setFontFamily(null)
        }
        if(updateTextEditProps) {
            updateTextEditProps({
                ...props,
                textattr: {
                    ...textattr,
                    fontFamily: value
                }
            })
        }
    }

    const handleFontSizeChange = (event: SelectChangeEvent) => {
        
        setFontSize(event.target.value)
        if(updateTextEditProps) {
            updateTextEditProps({
                ...props,
                textattr: {
                    ...textattr,
                    fontSize: event.target.value
                }
            })
        }
    }

    const openFontcolorpickerPopover = Boolean(fontcolorpickerAnchorEl)

    const handleFontColorPickerPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setFontcolorpickerAnchorEl(event.currentTarget);
    }

    const handleFontColorPickerPopoverClose = () => {
        setFontcolorpickerAnchorEl(null);
    }

    const handleFontcolorChangeComplete = (color: any) => {
        setFontColor(color.hex)
        if(updateTextEditProps) {
            updateTextEditProps({
                ...props,
                textattr: {
                    ...textattr,
                    fill: color.hex
                }
            })
        }
    }

    // const handlePathPresetChange = (event: SelectChangeEvent) => {
        
    //     setFontSize(event.target.value)
    //     if(updateTextEditProps) {
    //         updateTextEditProps({
    //             ...props,
    //             textattr: {
    //                 ...textattr,
    //                 fontSize: event.target.value
    //             }
    //         })
    //     }
    // }    


    return (
        <>
        <Grid container direction="column" spacing={1} alignItems="flex-start">
            <Grid item xs={12}>
                <Autocomplete
                    disablePortal
                    id="edit-font-searchbox"
                    value={fontfamily}
                    options={fonts ? fonts:[]}
                    getOptionLabel={(option) => option.name}
                    sx={{ width: 200 }}
                    renderOption={(props, option) => (
                    <Box component="li" {...props}>
                    <FormControl>
                        <FormLabel><Typography variant='subtitle2' fontSize={10}>{option.name}</Typography></FormLabel>
                        <Typography variant='body1' fontSize='large'
                        sx={{
                            ml: 2,
                            fontFamily: `${option.name}`
                        }}>{option.sample}</Typography>
                    
                    </FormControl>
                    </Box>
                    
                    )}
                    onChange={handleFontFamilyChange}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        color="secondary"
                        label="Font Family"
                        placeholder="Enter font-family name"
                    />
                    )}
                    
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row">
                    <Grid item xs={6}>
                    <FormControl variant="standard" color="secondary">
                    <InputLabel id="edit-font-size-select">Font Size</InputLabel>
                    <Select
                        labelId="font-size-select"
                        label="Font Size"
                        value={fontsize}
                        onChange={handleFontSizeChange}
                        sx={{
                            width: 100
                        }}
                        >
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={16}>16</MenuItem>
                        <MenuItem value={18}>18</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={22}>22</MenuItem>
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={26}>26</MenuItem>
                        <MenuItem value={28}>28</MenuItem>
                        <MenuItem value={36}>36</MenuItem>
                        <MenuItem value={48}>48</MenuItem>
                        <MenuItem value={72}>72</MenuItem>
                        <MenuItem value={96}>96</MenuItem>
                        <MenuItem value={120}>120</MenuItem>
                    </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                    <FormControl variant="standard" color="secondary">
                    <InputLabel htmlFor="font-color-picker">
                    Font Color
                    </InputLabel>
                    <Input
                    id="font-color-picker"
                    startAdornment={
                        <InputAdornment position="start">
                        <CircleIcon sx={{ color: fontcolor }} />
                        </InputAdornment>
                    }
                    value={fontcolor}
                    onClick={handleFontColorPickerPopoverOpen}
                    
                    />
                    </FormControl> 
                    <Popover
                        id="font-colorpicker-popover"
                    
                        open={openFontcolorpickerPopover}
                        anchorEl={fontcolorpickerAnchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        onClose={handleFontColorPickerPopoverClose}
                        disableRestoreFocus
                        
                        >
                        <SketchPicker color={fontcolor} onChangeComplete={handleFontcolorChangeComplete} />
                    </Popover>
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid item xs={12}>
                <FormControl variant="standard" color="secondary">
                <InputLabel id="edit-path-preset-select">Path Preset</InputLabel>
                <Select
                    labelId="path-preset-select"
                    label="Path Preset"
                    onChange={handlePathPresetChange}
                    sx={{
                        width: 100
                    }}
                    >
                    <MenuItem value='horizontal'>Horizontal</MenuItem>
                    <MenuItem value='vertical'>Vertical</MenuItem>
                    <MenuItem value='curve'>Curve</MenuItem>
                    <MenuItem value='circular'>Circular</MenuItem>
                </Select>
                </FormControl>
            </Grid> */}
        </Grid>
        </>
    )

}

function TextEditPreview(props: TextEditProps) {

    const {applyText, resetChange} = props

    const handleApplyText = () => {
        
        // if (setTextMenuAnchorEl) {
        //     setTextMenuAnchorEl(null)
        // }
        if (applyText) {
            applyText()
        }
    }

    const handleCancel = () => {
        // if (setTextMenuAnchorEl) {
        //     setTextMenuAnchorEl(null)
        // }
        if (resetChange) {
            resetChange()
        }
    }

    return (
        <Grid container direction="column" spacing={1} alignItems="flex-start">
            <Grid item>
                <div style={{
                        position: 'relative',
                        width: '224px',
                        height: '224px'
                    }}>
                <div style={{
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        width: '224px',
                        height: '224px'
                    }}>
                    <Stage
                        width={224}
                        height={224}>
                        
                        <Layer>
                            <Group
                                {...props.textgrpattr}
                            >
                            <Text
                                {...props.textattr}
                            />
                            </Group>
                        </Layer>
                        
                    </Stage>
                </div>
                
                </div>
            </Grid>
            <Grid item>
                <Grid container direction="row" spacing={1} alignItems="center" justifyContent="center">
                    <Grid item>
                        <Button variant="contained"
                                color="secondary"
                                onClick={handleApplyText}>
                            Apply
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained"
                                color="secondary"
                                onClick={handleCancel}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

interface ShapeProps {
    shapeattr?: any
    updateShapePropsEdit?: (props: any) => void
    applyShape?: () => void
    resetShape?: () => void
}

function ShadowTools(props: ShapeProps) {
    const { shapeattr, updateShapePropsEdit, applyShape, resetShape } = props
    const [shadowenabled, setShadowEnabled] = React.useState(false)
    const [shadowcolor, setShadowColor] = React.useState('#000000')
    const [shadowblur, setShadowBlur] = React.useState(0)
    const [shadowopacity, setShadowOpacity] = React.useState(1)
    const [shadowoffsetX, setShadowOffsetX] = React.useState(0)
    const [shadowoffsetY, setShadowOffsetY] = React.useState(0)
    const [shadowcolorpickerAnchorEl, setShadowcolorpickerAnchorEl] = React.useState<HTMLElement | null>(null);
    React.useEffect(() => {
        if (shapeattr) {
            if (shapeattr['shadowEnabled']) {
                setShadowEnabled(shapeattr['shadowEnabled'])
            }
            if (shapeattr['shadowColor']) {
                setShadowColor(shapeattr['shadowColor'])
            }
            if (shapeattr['shadowBlur']) {
                setShadowBlur(shapeattr['shadowBlur'])
            }
            if (shapeattr['shadowOpacity']) {
                setShadowOpacity(shapeattr['shadowOpacity'])
            }
            if (shapeattr['shadowOffsetX']) {
                setShadowOffsetX(shapeattr['shadowOffsetX'])
            }
            if (shapeattr['shadowOffsetY']) {
                setShadowOffsetX(shapeattr['shadowOffsetY'])
            }

        }
    }, [shapeattr])

    const handleShadowEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        if (updateShapePropsEdit) {
            updateShapePropsEdit({
                ...props,
                shapeattr: {
                    ...props.shapeattr,
                    shadowEnabled: event.target.checked
                }
            })
        }
    }

    const openshadowcolorpickerPopover = Boolean(shadowcolorpickerAnchorEl)

    const handleShadowColorPickerPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (shadowenabled) {
            setShadowcolorpickerAnchorEl(event.currentTarget);
        }
    }

    const handleShadowColorPickerPopoverClose = () => {
        setShadowcolorpickerAnchorEl(null);
    }

    const handleShadowcolorChangeComplete = (color: any) => {
        setShadowColor(color.hex)
        if(updateShapePropsEdit) {
            updateShapePropsEdit({
                ...props,
                shapeattr: {
                    ...shapeattr,
                    shadowColor: color.hex
                }
            })
        }
    }

    const handleShadowBlurChange = (event: Event, newValue: number | number[]) => {
        
        setShadowBlur((newValue as number))
        if(updateShapePropsEdit) {
            updateShapePropsEdit({
                ...props,
                shapeattr: {
                    ...shapeattr,
                    shadowBlur: (newValue as number)
                }
            })
        }
    }

    const handleShadowOpacityChange = (event: Event, newValue: number | number[]) => {
        
        setShadowOpacity((newValue as number)/100)
        if(updateShapePropsEdit) {
            updateShapePropsEdit({
                ...props,
                shapeattr: {
                    ...shapeattr,
                    shadowOpacity: (newValue as number)/100
                }
            })
        }
    }

    const handleShadowOffsetXChange = (event: Event, newValue: number | number[]) => {
        
        setShadowOffsetX((newValue as number))
        if(updateShapePropsEdit) {
            updateShapePropsEdit({
                ...props,
                shapeattr: {
                    ...shapeattr,
                    shadowOffsetX: (newValue as number)
                }
            })
        }
    }

    const handleShadowOffsetYChange = (event: Event, newValue: number | number[]) => {
        
        setShadowOffsetY((newValue as number))
        if(updateShapePropsEdit) {
            updateShapePropsEdit({
                ...props,
                shapeattr: {
                    ...shapeattr,
                    shadowOffsetY: (newValue as number)
                }
            })
        }
    }

    const handleApply = () => {
        
        // if (setTextMenuAnchorEl) {
        //     setTextMenuAnchorEl(null)
        // }
        if (applyShape) {
            applyShape()
        }
    }

    const handleCancel = () => {
        // if (setTextMenuAnchorEl) {
        //     setTextMenuAnchorEl(null)
        // }
        if (resetShape) {
            resetShape()
        }
    }


    return (
        <Grid container direction="column" spacing={1} sx={{ padding: 2}}>
            
            <Grid item xs={12}>
                <Grid container direction='row' spacing={2}>
                    <Grid item xs={6} alignContent='start'>
                        <FormControl>
                        <FormLabel id="activate-rgb">Enable shadow:</FormLabel>
                        <Switch 
                            checked={shadowenabled}
                            onChange={handleShadowEnabledChange}
                        />
                        </FormControl>
                    </Grid> 
                    <Grid item xs={6}>
                        <FormControl variant="standard" color="secondary">
                        <InputLabel htmlFor="shadow-color-picker">
                        Shadow Color
                        </InputLabel>
                        <Input
                        id="shadow-color-picker"
                        startAdornment={
                            <InputAdornment position="start">
                            <CircleIcon sx={{ color: shadowcolor }} />
                            </InputAdornment>
                        }
                        value={shadowcolor}
                        disabled={!shadowenabled}
                        onClick={handleShadowColorPickerPopoverOpen}
                        sx={{ width: 150 }}
                        />
                        </FormControl>
                        <Popover
                            id="shadow-colorpicker-popover"
                        
                            open={openshadowcolorpickerPopover}
                            anchorEl={shadowcolorpickerAnchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            onClose={handleShadowColorPickerPopoverClose}
                            disableRestoreFocus
                            
                            >
                            <SketchPicker color={shadowcolor} onChangeComplete={handleShadowcolorChangeComplete} />
                        </Popover>
                    </Grid>
                </Grid>
            </Grid> 
            <Grid item xs={12}>
                <Grid container direction='row' spacing={2} alignItems='self-start'>
                    <Grid item xs={6}>
                        <FormControl>
                        <FormLabel id="adjust-shadow-blur-label">Shadow Blur</FormLabel>
                        <Slider
                            aria-labelledby="adjust-shadow-blur-label"
                            //value={opacity}
                            defaultValue={shadowblur}
                            //marks
                            valueLabelDisplay="auto"
                            color="secondary"
                            sx={{ width: 150}}
                            onChange={handleShadowBlurChange}/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                    <FormControl>
                    <FormLabel id="adjust-shadow-opacity-label">Shadow Opacity</FormLabel>
                    <Slider
                        aria-labelledby="adjust-shadow-opacity-label"
                        //value={opacity}
                        defaultValue={shadowopacity*100}
                        //marks
                        valueLabelDisplay="auto"
                        color="secondary"
                        sx={{ width: 150}}
                        onChange={handleShadowOpacityChange}/>
                    </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction='row' spacing={2}>
                    <Grid item xs={6}>
                        <FormControl>
                        <FormLabel id="adjust-shadow-offsetx-label">Shadow Offset X</FormLabel>
                        <Slider
                            aria-labelledby="adjust-shadow-offsetx-label"
                            //value={opacity}
                            defaultValue={shadowoffsetX}
                            //marks
                            valueLabelDisplay="auto"
                            color="secondary"
                            sx={{ width: 150}}
                            onChange={handleShadowOffsetXChange}/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl>
                        <FormLabel id="adjust-shadow-offsety-label">Shadow Offset Y</FormLabel>
                        <Slider
                            aria-labelledby="adjust-shadow-offsety-label"
                            //value={opacity}
                            defaultValue={shadowoffsetY}
                            //marks
                            valueLabelDisplay="auto"
                            color="secondary"
                            sx={{ width: 150}}
                            onChange={handleShadowOffsetYChange}/>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
                
            <Grid item xs={12}>
                <Grid container direction="row" spacing={1} alignItems="center" justifyContent="center">
                    <Grid item>
                        <Button variant="contained"
                                color="secondary"
                                onClick={handleApply}>
                            Apply
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained"
                                color="secondary"
                                onClick={handleCancel}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

async function fetchpresets(): Promise<any> {
    const res = await presetlistAPI().then((response) => {
        return response.data
    })
    .catch((e) => {
        console.log("Error fetching presets: ", e)
        return null
    })
    return res
}

export const DesignToolbar: React.FC = () => {
    const classes = useStyles()
    const userInfo = useAppSelector((state) => state.user)
    const currency = useAppSelector((state) => state.currency)
    const cart = useAppSelector((state) => state.cart)
    const dispatch = useAppDispatch()
    const [publishMenuAnchorEl, setPublishMenuAnchorEl] = React.useState<HTMLElement | null>(null);
    const [cropMenuAnchorEl, setCropMenuAnchorEl] = React.useState<HTMLElement | null>(null);
    const [opacityMenuAnchorEl, setOpacityMenuAnchorEl] = React.useState<HTMLElement | null>(null);
    const [shadowMenuAnchorEl, setShadowMenuAnchorEl] = React.useState<HTMLElement | null>(null);
    const [photoMenuAnchorEl, setPhotoMenuAnchorEl] = React.useState<HTMLElement | null>(null);
    const [textMenuAnchorEl, setTextMenuAnchorEl] = React.useState<HTMLElement | null>(null);
    const [textpathMenuAnchorEl, setTextpathMenuAnchorEl] = React.useState<HTMLElement | null>(null);
    
    const [publishLayerOption, setPublishLayerOption] = React.useState("graphic")
    const [JPEGQuality, setJPEGQuality] = React.useState<number>(0.8)
    //const [opacity, setOpacity] = React.useState<number>(-1)
    const [publishLayerprogress, setPublishLayerprogress] = React.useState(0)
    const [isPublishingLayer, setIsPublishingLayer] = React.useState(false)
    //const [photoeditortab, setPhotoeditortab] = React.useState(0)
    const [photoeditprops, setPhotoeditprops] = React.useState<PhotoEditProps | null>(null)
    const [texteditprops, setTexteditprops] = React.useState<TextEditProps | null>(null)
    const [shapeprops, setShapeProps] = React.useState<ShapeProps | null>(null)
    //const [photoeditcontrolprops, setPhotoeditcontrolprops] = React.useState<PhotoEditControlProps | null>(null)
    //const [RGBfilter, setRGBfilter] = React.useState(false)

    const { designstate, designdispatch, designrefs } = React.useContext(DesignContext)
    const { views, curview, yourphotosviewset, yourgraphicsviewset, fonts, cartitem } = designstate as DesignState
    const { expandKeepAspect, moveCenter, selecttool, applyChange, resetChange, removeLayer, undo, redo, bringToFront, bringToBack,
            flipHorizontal, flipVertical, adjustOpacity, insertProps, updateTextProps, updateShapeProps,
            setYourPhotosViewset, setYourGraphicsViewset, addToShoppingCart, updateShoppingCart } = designdispatch
    const { stage } = designrefs as DesignRefs
    
    const previewphotoeditRef = React.useRef<any>()
    const publishphotoeditRef = React.useRef<any>()
    const previewtexteditRef = React.useRef<any>()
    // const previewtextpathRef = React.useRef<any>()
    // const switchRGBRef = React.useRef<any>()
    // const photoeditRRef = React.useRef<any>()
    // const [previewLayer, setPreviewLayer] = React.useState<any>(null)
    // const [exportLayer, setExportLayer] = React.useState<any>(null)

    const view = views?.find((v: DesignView) => v.view===curview)
    const curlayer = view !== undefined && view !== null ? view?.curlayer:null
    const curtool = view !== undefined && view !== null ? view?.curtool:null
    const layer: DesignLayer | undefined = view?.layers?.find((l: DesignLayer) => l.id === curlayer)
    let cur_totalprice = 0.00
    let cur_totalquantity = 0
    // let basetotalprice = 0.00
    let customizationfee = 0.00
    let itemusagefee = 0.00
    
    if (cartitem) {
        views?.forEach((v: DesignView) => {
            const found = cartitem.customizeditems?.find((cc: CartCustomizedItem) => cc.position === v.view)
            if (found) {
                if (currency.isocode.toLowerCase() === 'usd') {
                    customizationfee = customizationfee + 1.50
                } else {
                    customizationfee = customizationfee + 50
                }
                
            }

        })

        if (cartitem.customizeditems) {
            cartitem.customizeditems.forEach((cc: CartCustomizedItem) => {
                if (cc.src_id && cc.src_owner !== parseInt(userInfo.id as string) && cc.prices && !cc.isfree && cc.discount != null) {
                    let cc_usageprices = cc.prices.find((p: CartAssetUsagePrice) => p.currency === currency.isocode.toLowerCase())
                    // itemusagefee = itemusagefee + cc.prices[currency.isocode.toLowerCase()] - cc.prices[currency.isocode.toLowerCase()]*cc.discount/100
                    if (cc_usageprices) {
                        itemusagefee = itemusagefee + cc_usageprices.price - cc_usageprices.price*cc.discount/100
                    }
                    
                }
            })
        }
        let ci_productprice = cartitem?.product?.prices?.find((p:ProductPrice) => p.currency === currency.isocode.toLowerCase())
        let productprice = 0.00
        if (ci_productprice) {
            productprice = ci_productprice.price
        }
        cur_totalprice = cartitem?.sizequantities?.map((item: CartQuantity) => item.quantity*(productprice - productprice*(cartitem.product?.discount as number)/100 + customizationfee + itemusagefee)).reduce((p:number,c:number)=>p+c,0) as number
        cur_totalquantity = cartitem?.sizequantities?.map((item: CartQuantity) => item.quantity).reduce((p:number,c:number)=>p+c,0) as number
        
        
    }
    let cur_cartitem: CartItem | null = null
    cart.storecarts?.forEach((sc: StoreCart) => {
        const it = sc.incart_items?.find((c: CartItem) => c.item_id === cartitem?.item_id)
        if (it) {
            cur_cartitem = it
        }
    })
    let is_added_to_cart = cur_cartitem ? true:false
    // cur_totalprice = basetotalprice + customizationfee + itemusagefee
    
    // const previewLayer = layer?.props.previewlayerRef.current
    // const exportLayer = layer?.props.exportlayerRef.current
    
    const openCropMenu = Boolean(cropMenuAnchorEl)
    const cropMenuId = openCropMenu ? 'cropmenu-popper' : undefined
   
    const openPublishShapeMenu = Boolean(publishMenuAnchorEl)
    const publishshapeMenuId = openPublishShapeMenu ? 'publish-shape-menu-popper' : undefined

    const openOpacityMenu = Boolean(opacityMenuAnchorEl)
    const opacityMenuId = openOpacityMenu ? 'opacitymenu-popper' : undefined

    const openShadowMenu = Boolean(shadowMenuAnchorEl)
    const shadowMenuId = openShadowMenu ? 'shadowmenu-popper' : undefined

    const openPhotoMenu = Boolean(photoMenuAnchorEl)
    const photoMenuId = openPhotoMenu ? 'photomenu-popper' : undefined

    const openTextMenu = Boolean(textMenuAnchorEl)
    const textMenuId = openTextMenu ? 'textmenu-popper' : undefined

    const openTextpathMenu = Boolean(textpathMenuAnchorEl)
    const textpathMenuId = openTextpathMenu ? 'textpathmenu-popper' : undefined
    
    const now = (new Date()).getTime() / 1000
    const expandEnabled = curlayer !== undefined && curlayer !== null
    const expandVerticalEnabled = curlayer !== undefined && curlayer !== null && layer !== undefined && layer.props.actualH > layer.props.actualW
    const expandHorizontalEnabled = curlayer !== undefined && curlayer !== null && layer !== undefined && layer.props.actualH <= layer.props.actualW
    const moveCenterEnabled = curlayer !== undefined && curlayer !== null
    const cropEnabled = curlayer !== undefined && curlayer !== null && layer?.props.type === 'photo-layer'
    const publishShapeEnabled = curlayer !== undefined && curlayer !== null
    const undoEnabled = curlayer !== undefined && curlayer !== null && layer?.current !== undefined && layer?.propshistory !== undefined && layer?.current >= layer?.propshistory.length - 1 && layer?.current > 0 
    const redoEnabled = curlayer !== undefined && curlayer !== null && layer?.current !== undefined && layer?.propshistory !== undefined && layer?.current < layer?.propshistory.length - 1
    const deleteEnabled = curlayer !== undefined && curlayer !== null
    const btfEnabled = curlayer !== undefined && curlayer !== null && layer !== undefined && view?.layers !== undefined && view?.layers !== null && layer?.props.idx < view?.layers.length - 1
    const ftbEnabled = curlayer !== undefined && curlayer !== null && layer !== undefined && view?.layers !== undefined && view?.layers !== null && layer?.props.idx == view?.layers.length - 1 && view?.layers.length > 3 
    const shadowtoolEnabled = curlayer !== undefined && curlayer !== null
    const adjustOpacityEnabled = curlayer !== undefined && curlayer !== null
    const fliphorizontalEnabled = curlayer !== undefined && curlayer !== null
    const flipverticalEnabled = curlayer !== undefined && curlayer !== null
    const photoeditorEnabled = curlayer !== undefined && curlayer !== null && layer?.props.type === 'photo-layer' && userInfo.isLoggedIn && userInfo.tokenExpireOn > now
    const texteditorEnabled = curlayer !== undefined && curlayer !== null && layer?.props.type === 'text-layer'
    const textpatheditorEnabled = curlayer !== undefined && curlayer !== null && layer?.props.type === 'textpath-layer'

    const handleCroptoolClick = (event: React.MouseEvent<HTMLElement>) => {
        setCropMenuAnchorEl(cropMenuAnchorEl ? null:event.currentTarget)
        if (!cropMenuAnchorEl) {
            selecttool('crop')
        } else {
            resetChange()
        }
    }

    const handleApplyCrop = () => {
        setCropMenuAnchorEl(null)
        applyChange()
    }

    const handleCancelCrop = () => {
        setCropMenuAnchorEl(null)
        resetChange()
    }

    const handlePublishShapeClick = (event: React.MouseEvent<HTMLElement>) => {
        setPublishMenuAnchorEl(publishMenuAnchorEl ? null:event.currentTarget)
        if (!publishMenuAnchorEl) {
            selecttool('publish-shape')
        } 
    }

    const handleChangePublishLayerOption = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPublishLayerOption((event.target as HTMLInputElement).value)
    }

    const handleJpegQualityChange = (event: Event, newValue: number | number[]) => {
        setJPEGQuality(newValue as number)
    }

    const handlePublishLayerSubmit = () => {
        var config: PublishConfig = {
            mimeType: 'image/png'
        }
        if (publishLayerOption === 'photo') {
            config = {
                ...config,
                mimeType: 'image/jpeg',
                quality: JPEGQuality as number
            }
            
        }
        //const stagedata = stage.current.toDataURL(config)
        //const previewdata = layer?.props.previewlayerRef.current.toDataURL(config)
        //const exportdata = layer?.props.exportlayerRef.current.toDataURL(config)
        const exportdata = layer?.props.exportshapeGrpRef.current.toDataURL(config)
        const exportname = `copy-${generaterandomstr(6)}-${layer?.props.itemname?.split('.')[0]}`
        setPublishLayerprogress(0)
        setIsPublishingLayer(true)
        if (publishLayerOption === 'photo') {
            photopublishAPI(exportdata, `${exportname}.jpg`, (event: any) => {
                setPublishLayerprogress(Math.round((100 * event.loaded) / event.total))
            }).then((response) => {
                setIsPublishingLayer(false)
                setPublishMenuAnchorEl(null)
                return getyourphotoAPI(yourphotosviewset?.searchtags, 1, yourphotosviewset?.sortby as string)
            }).then((photos) => {
                setYourPhotosViewset(photos.data.results, photos.data.total_pages, 1, yourphotosviewset?.searchtags, yourphotosviewset?.sortby as string)
            }).catch((e) => { 
                setIsPublishingLayer(false)
                console.log("Error publishing photo: ", e.message)
            })
        } else {
            graphicpublishAPI(exportdata, `${exportname}.png`, (event: any) => {
                setPublishLayerprogress(Math.round((100 * event.loaded) / event.total))
            }).then((response) => {
                setIsPublishingLayer(false)
                setPublishMenuAnchorEl(null)
                return getyourgraphicAPI(yourgraphicsviewset?.searchtags, 1, yourgraphicsviewset?.sortby as string)
            }).then((graphics) => {
                setYourGraphicsViewset(graphics.data.results, graphics.data.total_pages, 1, yourgraphicsviewset?.searchtags, yourgraphicsviewset?.sortby as string)
            }).catch((e) => { 
                setIsPublishingLayer(false)
                console.log("Error publishing photo: ", e)
            })
        }
    }

    const handleCancelPublish = () => {
        setPublishMenuAnchorEl(null)
    }

    const handleAdjustOpacityClick = (event: React.MouseEvent<HTMLElement>) => {
        
        setOpacityMenuAnchorEl(opacityMenuAnchorEl ? null:event.currentTarget)
        if (!opacityMenuAnchorEl) {
            selecttool('adjust-opacity')
        } 
    }

    // if (previewLayer !== undefined && previewLayer !== null && curtool === 'adjust-opacity' && opacity < 0) {
    //     previewLayer.opacity(0.5)
    //     setOpacity(previewLayer.opacity())
    // }

    const handleOpacityChange = (event: Event, newValue: number | number[]) => {
        
        //console.log(layer?.props)
        
        // previewLayer.opacity((newValue as number)/100)
        // exportLayer.opacity((newValue as number)/100)
        //setOpacity(newValue as number)
        adjustOpacity((newValue as number)/100)
    }

    const handleApplyOpacity = () => {
        setOpacityMenuAnchorEl(null)
        applyChange()
    }

    const handleCancelOpacity = () => {
        setOpacityMenuAnchorEl(null)
        resetChange()
    }

    const handleDeleteLayer = () => {
        removeLayer()
    }

    const handleFlipHorizontal = () => {
        flipHorizontal()
    }

    const handleFlipVertical = () => {
        flipVertical()
    }

    const handlePhotoMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setPhotoMenuAnchorEl(photoMenuAnchorEl ? null:event.currentTarget)
        if (!photoMenuAnchorEl) {
            let previewW = layer?.props.previewW as number
            let previewH = layer?.props.previewH as number
            let canvasW = 224
            let canvasH = 224
            let previewscale = previewW >= previewH ? canvasW/previewW:canvasH/previewH
            let viewW = previewW*previewscale
            let viewH = previewH*previewscale
            let previewX = (canvasW - viewW)/2
            let previewY = (canvasH - viewH)/2

            let publish_canvasW = 1280
            let publish_canvasH = 1280
            let publishW = layer?.props.actualW as number
            let publishH = layer?.props.actualH as number
            let publishscale = publishW >= publishH ? publish_canvasW/publishW:publish_canvasH/publishH
            let publishX = (publish_canvasW - publishW*publishscale)/2
            let publishY = (publish_canvasH - publishH*publishscale)/2
            
            
            fetchpresets().then((results) => {
                const BrightenFilter: PhotoFilter = {
                    filtername: 'Brighten',
                    filterattr: 
                        {
                            brightness: 0
                        }
                    
                }
                const ContrastFilter: PhotoFilter = {
                    filtername: 'Contrast',
                    filterattr: 
                        {
                            contrast: 0
                        }
                    
                }
                const BlurFilter: PhotoFilter = {
                    filtername: 'Blur',
                    filterattr: 
                        {
                            blurRadius: 0
                        }
                    
                }
                let filters: PhotoFilter[] = []
                filters = filters.concat([BrightenFilter, ContrastFilter, BlurFilter])
                let presets = null
                presets = results
                const props = {
                    srcid: layer?.props.itemId,
                    srcname: layer?.props.itemname,
                    previewimage: layer?.props.preview,
                    publishimage: layer?.props.actual,
                    filters: filters,
                    presets: presets,
                    autoremovebg: false,
                    previewattr: {
                        x: previewX,
                        y: previewY,
                        width: previewW,
                        height: previewH,
                        scaleX: previewscale,
                        scaleY: previewscale,
                        ref: previewphotoeditRef
                    },
                    publishattr: {
                        x: publishX,
                        y: publishY,
                        width: publishW,
                        height: publishH,
                        scaleX: publishscale,
                        scaleY: publishscale,
                        ref: publishphotoeditRef
                    },
                }
            
                setPhotoeditprops(props)
                selecttool('photo-editor')
            })

            
        } 
    }

    const updatePhotoEditProps = (newprops: any) => {
        const props: PhotoEditProps = {
            ...photoeditprops,
            previewimage: newprops.preview,
            publishimage: newprops.publish,
            filters: newprops.filters,
            autoremovebg: newprops.autoremovebg
        }
        setPhotoeditprops(props)
    }

    const handleApplyEditedPhoto = (photo: YourPhoto) => {
        if (photo.preview !== undefined) {
            
            let previewphoto = new window.Image()
            previewphoto.src = photo.preview
            previewphoto.onload = () => {
                let curexportW = layer?.props.exportshapeGrpAttr.width * layer?.props.exportshapeGrpAttr.scaleX
                let curexportH = layer?.props.exportshapeGrpAttr.height * layer?.props.exportshapeGrpAttr.scaleY 
                let actualW = photo.actual_w
                let actualH = photo.actual_h
                let exportscaleX = curexportW/actualW
                let exportscaleY = curexportH/actualH
                
                const props = {
                    ...layer?.props,
                    itemId: photo.id,
                    itemname: photo.photo_name,
                    itemowner: photo.owner,
                    type: 'photo-layer',
                    actualW: photo.actual_w,
                    actualH: photo.actual_h,
                    actual: photo.actual,
                    previewW: photo.preview_w,
                    previewH: photo.preview_h,
                    preview: photo.preview,
                    previewdata: previewphoto,
                    exportshapeGrpAttr: {
                        ...layer?.props.exportshapeGrpAttr,
                        width: actualW,
                        height: actualH,
                        scaleX: exportscaleX,
                        scaleY: exportscaleY,
                        clipWidth: actualW,
                        clipHeight: actualH
                    },
                    exportshapeAttr: {
                        ...layer?.props.exportshapeAttr,
                        width: actualW,
                        height: actualH,
                        crop: {
                            x: 0,
                            y: 0,
                            width: actualW,
                            height: actualH
                        }
                    }
                }
                insertProps(props)
            }
        }
        getyourphotoAPI(yourphotosviewset?.searchtags, 1, yourphotosviewset?.sortby as string)
        .then((photos) => {
            setYourPhotosViewset(photos.data.results, photos.data.total_pages, 1, yourphotosviewset?.searchtags, yourphotosviewset?.sortby as string)
        }).catch((e) => { 
            console.log("Error fetching YourPhoto: ", e.message)
        })
    }

    const handleApplyEditedGraphic = (photo: YourGraphic) => {
        if (photo.preview !== undefined) {
            
            let previewphoto = new window.Image()
            previewphoto.src = photo.preview
            previewphoto.onload = () => {
                let curexportW = layer?.props.exportshapeGrpAttr.width * layer?.props.exportshapeGrpAttr.scaleX
                let curexportH = layer?.props.exportshapeGrpAttr.height * layer?.props.exportshapeGrpAttr.scaleY 
                let actualW = photo.actual_w
                let actualH = photo.actual_h
                let exportscaleX = curexportW/actualW
                let exportscaleY = curexportH/actualH
                
                const props = {
                    ...layer?.props,
                    itemId: photo.id,
                    itemname: photo.graphic_name,
                    itemowner: photo.owner,
                    type: 'graphic-layer',
                    actualW: photo.actual_w,
                    actualH: photo.actual_h,
                    actual: photo.actual,
                    previewW: photo.preview_w,
                    previewH: photo.preview_h,
                    preview: photo.preview,
                    previewdata: previewphoto,
                    exportshapeGrpAttr: {
                        ...layer?.props.exportshapeGrpAttr,
                        width: actualW,
                        height: actualH,
                        scaleX: exportscaleX,
                        scaleY: exportscaleY,
                        clipWidth: actualW,
                        clipHeight: actualH
                    },
                    exportshapeAttr: {
                        ...layer?.props.exportshapeAttr,
                        width: actualW,
                        height: actualH,
                        crop: {
                            x: 0,
                            y: 0,
                            width: actualW,
                            height: actualH
                        }
                    }
                }
                insertProps(props)
            }
        }
        getyourgraphicAPI(yourgraphicsviewset?.searchtags, 1, yourgraphicsviewset?.sortby as string)
        .then((graphics) => {
            setYourGraphicsViewset(graphics.data.results, graphics.data.total_pages, 1, yourgraphicsviewset?.searchtags, yourgraphicsviewset?.sortby as string)
        }).catch((e) => { 
            console.log("Error fetching YourPhoto: ", e.message)
        })
    }

    const handleTextMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setTextMenuAnchorEl(textMenuAnchorEl ? null:event.currentTarget)
        if (!textMenuAnchorEl) {
            let previewW = layer?.props.previewW as number
            let previewH = layer?.props.previewH as number
            let canvasW = 224
            let canvasH = 224
            let previewscale = previewW >= previewH ? canvasW/previewW:canvasH/previewH
            let viewW = previewW*previewscale
            let viewH = previewH*previewscale
            let previewX = (canvasW - viewW)/2
            let previewY = (canvasH - viewH)/2
            
            
            const props = {
                fonts: fonts,
                textgrpattr: {
                    ...layer?.props.shapeGrpAttr,
                    x: previewX,
                    y: previewY,
                    scaleX: previewscale,
                    scaleY: previewscale
                },
                textattr: {
                    ...layer?.props.shapeAttr,
                    ref: previewtexteditRef
                }
            }
        
            setTexteditprops(props)
            selecttool('text-editor')
            

            
        } 
    }

    const updateTextEditProps = (newprops: any) => {
        
        setTexteditprops(newprops)
        const props = {
            textattr: {
                ...layer?.props.shapeAttr,
                fontFamily: newprops.textattr.fontFamily,
                fontSize: newprops.textattr.fontSize,
                fill: newprops.textattr.fill
            },
            exporttextattr: {
                ...layer?.props.exportshapeAttr,
                fontFamily: newprops.textattr.fontFamily,
                fontSize: (+newprops.textattr.fontSize)*20/3,
                fill: newprops.textattr.fill
            }
        }
        updateTextProps(props)
        
    }

    const handleApplyEditedText = () => {
        setTextMenuAnchorEl(null)
        applyChange()
    }

    const handleCancelEditedText = () => {
        setTextMenuAnchorEl(null)
        resetChange()
    }

    const handleShadowMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setShadowMenuAnchorEl(shadowMenuAnchorEl ? null:event.currentTarget)
        if (!shadowMenuAnchorEl) {
            
            const props = {
                shapeattr: {
                    ...layer?.props.shapeAttr
                }
            }
        
            setShapeProps(props)
            selecttool('shadow-tool')
            

            
        } 
    }

    const updateShadowProps = (newprops: any) => {
        
        setShapeProps(newprops)
        const props = {
            shapeattr: {
                ...layer?.props.shapeAttr,
                shadowEnabled: newprops.shapeattr.shadowEnabled,
                shadowColor: newprops.shapeattr.shadowColor,
                shadowBlur: newprops.shapeattr.shadowBlur,
                shadowOpacity: newprops.shapeattr.shadowOpacity,
                shadowOffsetX: newprops.shapeattr.shadowOffsetX,
                shadowOffsetY: newprops.shapeattr.shadowOffsetY,
            },
            exportshapeattr: {
                ...layer?.props.exportshapeAttr,
                shadowEnabled: newprops.shapeattr.shadowEnabled,
                shadowColor: newprops.shapeattr.shadowColor,
                shadowBlur: newprops.shapeattr.shadowBlur,
                shadowOpacity: newprops.shapeattr.shadowOpacity,
                shadowOffsetX: newprops.shapeattr.shadowOffsetX,
                shadowOffsetY: newprops.shapeattr.shadowOffsetY,
            }
        }
        updateShapeProps(props)
        
    }

    const handleApplyEditedShadow = () => {
        setShadowMenuAnchorEl(null)
        applyChange()
    }

    const handleCancelEditedShadow = () => {
        setShadowMenuAnchorEl(null)
        resetChange()
    }

    const handleAddtoCart = () => {
        addToShoppingCart()
    }

    const handleUpdateCart = () => {
        updateShoppingCart()
    }

    return (
        <AppBar position="static" color="transparent" sx={{
            position: 'fixed',
            top: '55px',
            right: '0',
            left: '0',
            zIndex: 1100,
            backgroundColor: '#EEF0F8'
        }}>
            <Toolbar className={classes.toolbar} variant="dense">
                <Grid container alignItems="center">
                    <Grid item xs={9} md={9}>
                    <Grid container 
                        direction="row" 
                        spacing={0} 
                        alignItems="center"
                        sx={{ 
                            height: 50 }}>
                        <Grid item>
                            <Grid container spacing={0.25}>
                                <Grid item>
                                    <ToggleButton 
                                        value='publish-shape'
                                        aria-describedby={publishshapeMenuId}
                                        selected={curtool === 'publishshape'}
                                        disabled={!publishShapeEnabled}
                                        onClick={handlePublishShapeClick}>
                                        <PublishIcon />
                                    </ToggleButton>
                                    <Popper
                                        id={publishshapeMenuId}
                                        open={openPublishShapeMenu}
                                        anchorEl={publishMenuAnchorEl}
                                        placement='bottom-start'
                                    >
                                        <Paper elevation={6} sx={{ padding: '15px', marginTop: '20px' }}>
                                        <Grid container 
                                            direction="column"
                                            alignItems="center"
                                            justifyContent="center">
                                            <Grid item>
                                            <FormControl>
                                            <FormLabel id="publish-layer-option-label">Publish layer as</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="publish-layer-option-label"
                                                name="publish-layer-option"
                                                defaultValue="graphic"
                                                value={publishLayerOption}
                                                onChange={handleChangePublishLayerOption}
                                                sx={{ paddingLeft: '5px'}}
                                            >
                                                <FormControlLabel value="photo" control={<Radio />} label="Photo (.jpg)" />
                                                <FormControlLabel value="graphic" control={<Radio />} label="Graphic (.png)" />
                                
                                            </RadioGroup>
                                            </FormControl>
                                            </Grid>
                                            <Grid item>
                                            <FormControl>
                                            <FormLabel id="publish-jpeg-quality-label">JPEG Quality</FormLabel>
                                            <Slider
                                                aria-labelledby="publish-jpeg-quality-label"
                                                value={JPEGQuality}
                                                defaultValue={0.6}
                                                step={0.1}
                                                marks
                                                min={0.0}
                                                max={1.0}
                                                valueLabelDisplay="auto"
                                                disabled={publishLayerOption !== 'photo'}
                                                color="secondary"
                                                sx={{ width: '200px'}}
                                                onChange={handleJpegQualityChange}/>

                                            
                                            
                                            </FormControl>
                                            </Grid>
                                            {isPublishingLayer && (
                                            <Grid item>
                                                <Grid container direction="column" justifyContent="center" alignItems="center">
                                                    <Box sx={{ width: '200px' }}>
                                                    <LinearProgress 
                                                        variant='determinate' 
                                                        color='secondary'
                                                        value={publishLayerprogress} />
                                                    </Box>
                                                    <Typography variant='caption'>Publishing layer...</Typography>
                                                </Grid>
                                            </Grid>
                                            )}
                                            <Grid item>
                                            <Grid container direction="row" spacing={1} alignItems="center" justifyContent="center">
                                                <Grid item>
                                                    <Button variant="contained"
                                                            color="secondary"
                                                            onClick={handlePublishLayerSubmit}>
                                                        Publish
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button variant="contained"
                                                            color="secondary"
                                                            onClick={handleCancelPublish}>
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            </Grid>
                                        </Grid>
                                        
                                        </Paper>
                                    </Popper>
                                </Grid>
                                <Grid item>
                                    <ToggleButton 
                                        value='undo-item'
                                        onClick={() => undo()}
                                        disabled={!undoEnabled}>
                                        <UndoIcon />
                                    </ToggleButton>
                                </Grid>
                                <Grid item>
                                    <ToggleButton 
                                        value='redo-item'
                                        onClick={() => redo()}
                                        disabled={!redoEnabled}>
                                        <RedoIcon />
                                    </ToggleButton>
                                </Grid>
                                <Grid item>
                                    <ToggleButton 
                                        value='delete-item'
                                        disabled={!deleteEnabled}
                                        onClick={handleDeleteLayer}>
                                        <DeleteIcon />
                                    </ToggleButton>
                                </Grid>
                                <Grid item>
                                <Divider orientation="vertical" flexItem sx={{ margin: 0.5, height: "37px !important" }} />
                                </Grid>
                                <Grid item>
                                    <ToggleButton 
                                        value='move-center'
                                        disabled={!moveCenterEnabled}
                                        onClick={() => moveCenter(curlayer)}>
                                    <FilterCenterFocusIcon />
                                    </ToggleButton>
                                </Grid>
                                <Grid item>
                                    <ToggleButton 
                                        value='expand-keepaspect'
                                        disabled={!expandEnabled}
                                        onClick={() => expandKeepAspect(curlayer)}>
                                    <AspectRatioIcon />
                                    </ToggleButton>
                                </Grid>
                                <Grid item>
                                    <ToggleButton 
                                        value='expand-horizontal'
                                        disabled={!expandHorizontalEnabled}
                                        onClick={() => expandKeepAspect(curlayer)}>
                                    <ExpandIcon sx={{ transform: "rotate(90deg)" }} />
                                    </ToggleButton>
                                </Grid>
                                <Grid item>
                                    <ToggleButton 
                                        value='expand-vertical'
                                        disabled={!expandVerticalEnabled}
                                        onClick={() => expandKeepAspect(curlayer)}>
                                        <ExpandIcon />
                                    </ToggleButton>
                                </Grid>
                                <Grid item>
                                    <ToggleButton 
                                        value='flip-horizontal'
                                        disabled={!fliphorizontalEnabled}
                                        onClick={handleFlipHorizontal}>
                                        <FlipIcon />
                                    </ToggleButton>
                                </Grid>
                                <Grid item>
                                    <ToggleButton 
                                        value='flip-vertical'
                                        disabled={!flipverticalEnabled}
                                        onClick={handleFlipVertical}>
                                        <FlipIcon 
                                            sx={{
                                                transform: "rotate(90deg)"
                                            }} />
                                    </ToggleButton>
                                </Grid>
                                <Grid item>
                                    <ToggleButton
                                        value='crop-tool'
                                        aria-describedby={cropMenuId}
                                        selected={curtool === 'crop'}
                                        disabled={!cropEnabled}
                                        onClick={handleCroptoolClick}>
                                        <CropIcon />
                                    </ToggleButton>
                                    <Popper
                                        id={cropMenuId}
                                        open={openCropMenu}
                                        anchorEl={cropMenuAnchorEl}
                                        placement='bottom-start'
                                    >
                                        <Paper elevation={6} sx={{ padding: '15px', marginTop: '20px' }}>
                                        <Grid container direction="row" spacing={1} alignItems="center" justifyContent="center">
                                            <Grid item>
                                                <Button variant="contained"
                                                        color="secondary"
                                                        onClick={handleApplyCrop}>
                                                    Crop
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained"
                                                        color="secondary"
                                                        onClick={handleCancelCrop}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        </Paper>
                                    </Popper>
                                </Grid>
                                
                                <Grid item>
                                <Divider orientation="vertical" flexItem sx={{ margin: 0.5, height: "37px !important" }} />
                                </Grid>
                                <Grid item>
                                    <ToggleButton 
                                        value='fliptofront'
                                        onClick={() => bringToFront()}
                                        disabled={!btfEnabled}>
                                        <FlipToFrontIcon />
                                    </ToggleButton>
                                </Grid>
                                <Grid item>
                                    <ToggleButton 
                                        value='fliptoback'
                                        onClick={() => bringToBack()}
                                        disabled={!ftbEnabled}>
                                        <FlipToBackIcon />
                                    </ToggleButton>
                                </Grid>
                                <Grid item>
                                    <ToggleButton 
                                        value='adjust-opacity'
                                        disabled={!adjustOpacityEnabled}
                                        aria-describedby={opacityMenuId}
                                        selected={curtool === 'adjust-opacity'}
                                        onClick={handleAdjustOpacityClick}>
                                        <OpacityIcon />
                                    </ToggleButton>
                                    <Popper
                                        id={opacityMenuId}
                                        open={openOpacityMenu}
                                        anchorEl={opacityMenuAnchorEl}
                                        placement='bottom-start'
                                    >
                                        <Paper elevation={6} sx={{ padding: '15px', marginTop: '20px' }}>
                                        <Grid container direction="column" spacing={1} alignItems="center" justifyContent="center">
                                            <Grid item>
                                            <FormControl>
                                            <FormLabel id="adjust-opacity-label">Opacity</FormLabel>
                                            <Slider
                                                aria-labelledby="adjust-opacity-label"
                                                //value={opacity}
                                                defaultValue={layer?.props.previewlayerAttr.opacity*100}
                                                //marks
                                                valueLabelDisplay="auto"
                                                color="secondary"
                                                sx={{ width: '200px'}}
                                                onChange={handleOpacityChange}/>


                                            </FormControl> 
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" spacing={1} alignItems="center" justifyContent="center">
                                            <Grid item>
                                                <Button variant="contained"
                                                        color="secondary"
                                                        onClick={handleApplyOpacity}>
                                                    Apply
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained"
                                                        color="secondary"
                                                        onClick={handleCancelOpacity}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        </Paper>
                                    </Popper>
                                </Grid>
                                <Grid item>
                                    <ToggleButton
                                        value='shadow-tool'
                                        disabled={!shadowtoolEnabled}
                                        aria-describedby={shadowMenuId}
                                        selected={curtool === 'shadow-tool'}
                                        onClick={handleShadowMenuClick}
                                    >
                                        <LightIcon />
                                    </ToggleButton>
                                    <Popper
                                        id={shadowMenuId}
                                        open={openShadowMenu}
                                        anchorEl={shadowMenuAnchorEl}
                                        placement='bottom-start'
                                    >
                                        <Paper elevation={6} sx={{ padding: '15px', marginTop: '20px' }}>
                                        <Grid container direction="column" spacing={1} alignItems="center" justifyContent="center">
                                            <Grid item xs={12}>
                                                <ShadowTools 
                                                    {...shapeprops}
                                                    updateShapePropsEdit={updateShadowProps}
                                                    applyShape={handleApplyEditedShadow}
                                                    resetShape={handleCancelEditedShadow}
                                                />
                                            </Grid>
                                        </Grid>
                                        </Paper>
                                    </Popper>
                                </Grid>
                                <Grid item>
                                <Divider orientation="vertical" flexItem sx={{ margin: 0.5, height: "37px !important" }} />
                                </Grid>
                                <Grid item>
                                    <ToggleButton 
                                        value='photo-editor'
                                        disabled={!photoeditorEnabled}
                                        aria-describedby={photoMenuId}
                                        selected={curtool === 'photo-editor'}
                                        onClick={handlePhotoMenuClick}>
                                        <FilterHdrIcon />
                                    </ToggleButton>
                                    <Popper
                                        id={photoMenuId}
                                        open={openPhotoMenu}
                                        anchorEl={photoMenuAnchorEl}
                                        placement='bottom-end'
                                    >
                                        <Paper elevation={6} sx={{ padding: '15px', marginTop: '20px' }}>
                                        <div style={{ width: '680px'}}>
                                        <Grid container direction="row" spacing={1} alignItems="flex-start" justifyContent="flex-start">
                                            <Grid item xs={8}>
                                                <PhotoEditControl 
                                                {...photoeditprops}
                                                updatePhotoEditProps={updatePhotoEditProps}/>
                                                
                                            </Grid>
                                            
                                            <Grid item xs={4}>
                                                <PhotoEditPreview 
                                                    {...photoeditprops} 
                                                    setPhotoMenuAnchorEl={setPhotoMenuAnchorEl}
                                                    applyPhoto={handleApplyEditedPhoto}
                                                    applyGraphic={handleApplyEditedGraphic}
                                                    resetChange={resetChange} />
                                            
                                            </Grid>
                                        </Grid>
                                        </div>
                                        </Paper>
                                    </Popper>
                                </Grid>
                                <Grid item>
                                    <ToggleButton 
                                        value='text-editor'
                                        disabled={!texteditorEnabled}
                                        aria-describedby={photoMenuId}
                                        selected={curtool === 'text-editor'}
                                        onClick={handleTextMenuClick}>
                                        <TextFieldsIcon />
                                    </ToggleButton>
                                    <Popper
                                        id={textMenuId}
                                        open={openTextMenu}
                                        anchorEl={textMenuAnchorEl}
                                        placement='bottom-end'
                                    >
                                        <Paper elevation={6} sx={{ padding: '15px', marginTop: '20px' }}>
                                        <div style={{ width: '680px'}}>
                                        <Grid container direction="row" spacing={1} alignItems="flex-start" justifyContent="flex-start">
                                            <Grid item xs={8}>
                                                <TextEditControl 
                                                {...texteditprops}
                                                updateTextEditProps={updateTextEditProps}/>
                                                
                                            </Grid>
                                            
                                            <Grid item xs={4}>
                                                <TextEditPreview 
                                                    {...texteditprops} 
                                                    applyText={handleApplyEditedText}
                                                    resetChange={handleCancelEditedText} />
                                            
                                            </Grid>
                                        </Grid>
                                        </div>
                                        </Paper>
                                    </Popper>
                                </Grid>
                                <Grid item>
                                    <ToggleButton
                                        value='textpath-editor'
                                        disabled={!textpatheditorEnabled}
                                        aria-describedby={textpathMenuId}
                                        selected={curtool === 'textpath-editor'}
                                    >
                                        <TextRotationNoneIcon />
                                    </ToggleButton>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                        
                        
                    </Grid>
                    </Grid>
                    <Grid item xs={3} md={3}>
                    <Grid container justifyContent="flex-end" sx={{ display: { xs: 'none', sm: 'flex' } }}>
                        
                        <Grid item>
                            <Box sx={{ display: 'flex' }}>
                                <Typography variant="h6" sx={{ paddingTop: 0.5 }}>
                                Current Price: {`${currency.symbol}${cur_totalprice}`}
                                </Typography>
                                {is_added_to_cart ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={cur_totalquantity <= 0}
                                        startIcon={<AddShoppingCartIcon />}
                                        sx={{ marginLeft: 2 }}
                                        onClick={handleUpdateCart}
                                    >
                                        Update cart
                                    </Button>
                                ):(
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={cur_totalquantity <= 0}
                                        startIcon={<AddShoppingCartIcon />}
                                        sx={{ marginLeft: 2 }}
                                        onClick={handleAddtoCart}
                                    >
                                        Add to cart
                                    </Button>
                                )}
                                
                                
                            </Box>
                        </Grid>
                    </Grid>
                    </Grid>
                </Grid>
                
                
                
            </Toolbar>
        </AppBar>
    )
}