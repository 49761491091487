import { Autocomplete, Box, Button, Checkbox, Chip, Divider, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, MenuItem, Paper, Popover, Stack, TextField, Typography } from '@mui/material'
import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import HelpIcon from '@mui/icons-material/Help';
import { toAbsoluteUrl } from '../../../../_helpers';
import zIndex from '@mui/material/styles/zIndex';
import { useAppSelector } from '../../../../reducers/hooks';
import { omise_test_pubkey, rest_endpoint } from '../../../../settings';
import { attachcardAPI } from '../../../../reducers/func/userFunc';

interface IPaymentCardFormModal {
    onAddSuccess: () => void
    onClose: () => void
}

const AddPaymentCard: React.FC<IPaymentCardFormModal> = (props: IPaymentCardFormModal) => {
    const { onClose, onAddSuccess } = props
    const [backenderror, setBackendError] = React.useState(false)
    const [carderror, setCardError] = React.useState(false)
    const [carderrormsg, setCardErrorMsg] = React.useState('')
    const [countries, setCountries] = React.useState<any>([])
    const [statehint, setStateHint] = React.useState<any>([])
    const [cityhint, setCityHint] = React.useState<any>([])
    const [postalhint, setPostalHint] = React.useState<any>([])
    const [country, setCountry] = React.useState<any | null>(null)
    const [st, setSt] = React.useState<any | null>(null)
    const [city, setCity] = React.useState<any | null>(null)
    const [postal, setPostal] = React.useState<any | null>(null)
    const [defaultcard, setDefaultCard] = React.useState(false)
    const [helpSecurityCodeAnchorEl, setHelpSecurityCodeAnchorEl] = React.useState<HTMLElement | null>(null)
    const [cardbrand, setCardBrand] = React.useState<string | null>(null)
    const form = useForm()

    const lang = useAppSelector((state) => state.i18n.lang)
    const user = useAppSelector((state) => state.user)

    const fetchCountries = async (url: string) => {
        const ct = await new Promise((resolve) => {
            fetch(url)
                .then((response) => response.json())
                .then((data) => resolve(data))
        })
        setCountries(ct)
    }

    if (countries.length <= 0) {
        fetchCountries(toAbsoluteUrl("/lang/phonecodes.json"))
    }

    const months = Array.from({length: 12}, (item, i) => {
        let m = new Date(0, i).toLocaleString('en-US', {month: 'numeric'})
        m = ("0" + m).slice(-2)
        return m
    })

    const years = Array.from({length: 10}, (item, i) => {
        const d = new Date()
        return new Date(d.getFullYear()+i,0).toLocaleString('en-US', {year: 'numeric'})
    })

    const handleCheckDefaultCard = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDefaultCard(event.target.checked);
    }

    const handleClickClose = () => {
        onClose()
    }

    const cardlookup = (cardno: string) => {
        if (cardno.length === 2) {
    
            if (cardno.slice(0,2) === "35") {
                setCardBrand("jcb")
            }
            else if (cardno.slice(0,2) === "36") {
                setCardBrand("diners")
            }
            else if (cardno.slice(0,2) === "37") {
                setCardBrand("amex")
            }
            else if (cardno.slice(0,2) === "62") {
                setCardBrand("unionpay")
            }
            else if (cardno.slice(0,2) === "65") {
                setCardBrand("discover")
            }
            else if (cardno.slice(0,2) === "67") {
                setCardBrand("maestro")
            }
            else if (cardno.slice(0,1) === "4") {
                setCardBrand("visa")
            }
            else if (cardno.slice(0,1) === "5") {
                setCardBrand("mastercard")
            }
            else {
                setCardBrand(null)
            }
        } 
        else if (cardno.length < 2) {
            setCardBrand(null) 
        }
    }

    const handleSecurityCodeHelpOpen = (event: React.MouseEvent<HTMLElement>) => {
        setHelpSecurityCodeAnchorEl(event.currentTarget)
    }

    const handleSecurityCodeHelpClose = () => {
        setHelpSecurityCodeAnchorEl(null)
    } 

    const openSecurityCodeHelp = Boolean(helpSecurityCodeAnchorEl)
    
    const onNewCardSubmit = async (data: any) => {
        const Omise = (window as any).Omise
        Omise.setPublicKey(omise_test_pubkey)
        // tokenize card
        Omise.createToken("card",
        {
          "expiration_month": data.expiration_month as number,
          "expiration_year": data.expiration_year as number ,
          "name": data.card_owner,
          "number": data.card_no.replace(/[^0-9]/g, ''),
          "security_code": data.security_code as number,
          "street1": data.street1,
          "street2": data.street2,
          "city": data.city,
          "state": data.state,
          "postal_code": data.postal,
          "country": data.country
        }, (statusCode: any, response: any) => {
            if (statusCode == 200) {
                setCardError(false)
                setCardErrorMsg("")
                const token = response["id"]
                // attach card to customer
                attachcardAPI(token, defaultcard)
                .then((response) => {
                    onAddSuccess()
                })
                .catch((error) => {
                    console.log(error)
                })
            } else {
                setCardError(true)
                setCardErrorMsg(response.message)
            }
        });
    }

    React.useEffect(() => {

        const fetchAddressHint = async (query: string) => {
            const url = `${rest_endpoint}addresshints/getstates${query}`
            const hints = await new Promise((resolve) => {
                fetch(url)
                    .then((response) => response.json())
                    .then((data) => resolve(data))
            })
            setStateHint(hints)
        }

        if (country) {
            const query = `?country=${country.code}&lang=${lang}`
            fetchAddressHint(query)
            
        }
    }, [country])

    React.useEffect(() => {

        const fetchAddressHint = async (query: string) => {
            const url = `${rest_endpoint}addresshints/getcities${query}`
            const hints = await new Promise((resolve) => {
                fetch(url)
                    .then((response) => response.json())
                    .then((data) => resolve(data))
            })
            setCityHint(hints)
        }

        if (country && st) {
            const query = `?country=${country.code}&state=${st}&lang=${lang}`
            fetchAddressHint(query)
            
        }
    }, [st])

    React.useEffect(() => {
        const fetchAddressHint = async (query: string) => {
            const url = `${rest_endpoint}addresshints/getpostal${query}`
            const hints = await new Promise((resolve) => {
                fetch(url)
                    .then((response) => response.json())
                    .then((data) => resolve(data))
            })
            setPostalHint(hints)
        }

        if (country && st && city) {
            const query = `?country=${country.code}&state=${st}&city=${city}&lang=${lang}`
            fetchAddressHint(query)
            
        }
    }, [city])



    return (
        <>
        <Paper
            elevation={3}
            sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 484,
                pt: 2,
                pb: 3
            }}
        >
            <Stack spacing={1}>
            <Grid container direction='row' spacing={0} sx={{ width: 484 }}>
                <Grid item xs={6}>
                <Typography variant='h6' sx={{ ml: 2, pt: '4px' }}>
                    Add a New Payment Card
                </Typography>
                </Grid>
                <Grid item xs={4}>
                    {backenderror && (
                        <Chip icon={<ErrorIcon />} color='error' label="Backend Error. Please try again later." />
                    )}
                    
                </Grid>
                <Grid item sx={{ width: '40px', ml: 2 }}>
                    <IconButton
                        onClick={handleClickClose}
                    >
                        <CloseIcon sx={{ fontSize: 24 }} />
                    </IconButton>
                </Grid>
            </Grid>
            
            <Divider />
            <Grid container direction='column' spacing={1} sx={{ width: 436 }}>
                <Grid item xs={12} sx={{ width: 436, ml: 3, mr: 3, paddingLeft: "0px !important" }}>
                    <form>
                        <Stack spacing={1}>
                            {carderror && (
                                <Chip icon={<ErrorIcon />} color='error' label={carderrormsg} />
                            )}
                            <Controller
                                name='card_no'
                                control={form.control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Please enter 15 or 16 digits."
                                    },
                                    maxLength: {
                                        value: 19,
                                        message: "Maximum length of a card number should not exceed 16 digits."
                                    },
                                    minLength: {
                                        value: 17,
                                        message: "Minimum length of a card number should be 15 digits."
                                    }
                                }}
                                render={({ field, fieldState, formState }) => 
                                <TextField
                                    {...field}
                                    variant="standard"
                                    label="Credit/Debit Card Number"
                                    color="secondary"
                                    onChange={(event) => {
                                        let value = event.target.value.replace(/[^0-9]/g, '')
                                        cardlookup(value as string)
                                        if (value.slice(0,2)=="37" && value.length==15) {
                                            field.onChange(value.replace(/^(.{4})(.{6})(.*)$/, "$1 $2 $3"))
                                        }
                                        else if (value.slice(0,2)!="37") {
                                            let blk = value.match(/.{1,4}/g)
                                            if (blk && blk?.length > 1) {
                                                field.onChange(blk.join(' ') as string)
                                            } 
                                            else if (blk) {
                                                field.onChange(blk![0] as string)
                                            }
                                            else {
                                                field.onChange('')
                                            }
                                            
                                            
                                        }
                                        else {
                                            field.onChange(value)
                                        }
                                        
                                        
                                        
                                    }}
                                    error={fieldState.invalid}
                                    helperText={fieldState.invalid ? fieldState.error?.message:''}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                            aria-label="card brand"
                                            edge="end"
                                            sx={{mr: 1}}
                                            >
                                            {cardbrand ? <img src={toAbsoluteUrl(`/media/png/${cardbrand}.png`)} width={32} /> : <CreditCardIcon sx={{ fontSize: 28 }} />}
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />}
                            />
                            <Controller
                                name='card_owner'
                                control={form.control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Please enter card owner's name."
                                    }
                                }}
                                render={({ field, fieldState, formState }) => 
                                <TextField
                                    {...field}
                                    variant="standard"
                                    label="Card Owner's Name"
                                    color="secondary"
                                    onChange={(event) => {
                                        field.onChange(event.target.value)
                                        
                                    }}
                                    error={fieldState.invalid}
                                    helperText={fieldState.invalid ? fieldState.error?.message:''}
                                    
                                />}
                            />
                            <Grid container direction='row' spacing={2}>
                                <Grid item xs={7} sx={{ ml: 0, mr: 0, paddingLeft: "0px !important", paddingTop: "0px !important" }}>
                                    <Stack direction='row'>
                                        <Controller 
                                            name='expiration_month'
                                            control={form.control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "Expiration month is required."
                                                }
                                            }}
                                            render={({ field, fieldState, formState }) => 
                                            <TextField
                                                {...field}
                                                select={true}
                                                variant="standard"
                                                label="Expiration Month"
                                                color="secondary"
                                                onChange={(event) => {    
                                                    field.onChange(event.target.value)
                                                }}
                                                error={fieldState.invalid}
                                                helperText={fieldState.invalid ? fieldState.error?.message:''}
                                                SelectProps={{
                                                    MenuProps: {
                                                        sx: {
                                                            zIndex: 2000
                                                        }
                                                    }
                                                    
                                                       
                                                }}
                                                sx={{
                                                    width: 120
                                                }}
                                            >
                                                {months.map((m) => (
                                                    <MenuItem key={m} value={m} >
                                                        {m}
                                                    </MenuItem>
                                                ))}
                                                
                                            </TextField>}
                                        />
                                        <Controller 
                                            name='expiration_year'
                                            control={form.control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "Expiration year is required."
                                                }
                                            }}
                                            render={({ field, fieldState, formState }) => 
                                            <TextField
                                                {...field}
                                                select={true}
                                                variant="standard"
                                                label="Expiration Year"
                                                color="secondary"
                                                onChange={(event) => {    
                                                    field.onChange(event.target.value)
                                                }}
                                                error={fieldState.invalid}
                                                helperText={fieldState.invalid ? fieldState.error?.message:''}
                                                SelectProps={{
                                                    MenuProps: {
                                                        sx: {
                                                            zIndex: 2000
                                                        }
                                                    }
                                                    
                                                       
                                                }}
                                                sx={{
                                                    width: 120,
                                                    ml: 2
                                                }}
                                            >
                                                {years.map((y) => (
                                                    <MenuItem key={y} value={y} >
                                                        {y}
                                                    </MenuItem>
                                                ))}
                                                
                                            </TextField>}
                                        />
                                        
                                    </Stack>
                                </Grid>
                                <Grid item xs={5} sx={{ paddingTop: "0px !important" }}>
                                    <Stack direction='row'>
                                        <Controller 
                                            name='security_code'
                                            control={form.control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "Security code is required."
                                                },
                                                maxLength: {
                                                    value: 4,
                                                    message: "Maximum length of a security code is 4 digits."
                                                },
                                                minLength: {
                                                    value: 3,
                                                    message: "Minimum length of a security code is 3 digits."
                                                }
                                            }}
                                            render={({ field, fieldState, formState }) => 
                                            <TextField
                                                {...field}
                                                variant="standard"
                                                label="Security Code"
                                                color="secondary"
                                                onChange={(event) => { 
                                                    let value = event.target.value.replace(/[^0-9]/g, '')   
                                                    field.onChange(value)
                                                }}
                                                error={fieldState.invalid}
                                                helperText={fieldState.invalid ? fieldState.error?.message:''}
                                                
                                                sx={{
                                                    width: 100
                                                }}
                                            />}
                                        />
                                        <IconButton
                                            color="secondary"
                                            aria-owns={openSecurityCodeHelp ? 'security-code-help-popover' : undefined}
                                            aria-haspopup="true"
                                            onMouseEnter={handleSecurityCodeHelpOpen}
                                            onMouseLeave={handleSecurityCodeHelpClose}
                                            sx={{
                                                width: 42,
                                                height: 42
                                            }}
                                          >
                                            <HelpIcon />
                                        </IconButton>
                                        <Popover
                                            id="security-code-help-popover"
                                            sx={{
                                                pointerEvents: 'none',
                                            }}
                                            open={openSecurityCodeHelp}
                                            anchorEl={helpSecurityCodeAnchorEl}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                            onClose={handleSecurityCodeHelpClose}
                                            disableRestoreFocus
                                            PaperProps={{
                                                style: {
                                                width: '300px', height: '350px', padding: '10px'
                                                }
                                            }}
                                            >
                                            <div>
                                            <Typography variant="subtitle2" component="div">Where is card's security code?</Typography>
                                            <Typography variant="caption">
                                            Security code or CVV or CVC is a 3-digit or 4-digit code on the back of your card. For AMEX card CVV code is printed on the front.
                                            
                                            </Typography>
                                            
                                            </div>
                                            <img src={toAbsoluteUrl('/media/png/cvv_guide.png')} width={250} />
                                            </Popover>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Typography variant='subtitle1' sx={{ paddingTop: "16px !important" }}>Billing Address (Optional)</Typography>
                            <Controller
                                name='street1'
                                control={form.control}
                                rules={{
                                    required: {
                                        value: false,
                                        message: "Billing Address is not required."
                                    }
                                }}
                                render={({ field, fieldState, formState }) => 
                                <TextField
                                    {...field}
                                    variant="standard"
                                    label="Home or Building Address"
                                    color="secondary"
                                    onChange={(event) => {
                                        field.onChange(event.target.value)
                                        
                                    }}
                                    error={fieldState.invalid}
                                    helperText={fieldState.invalid ? fieldState.error?.message:''}
                                    
                                />}
                            />
                            <Controller
                                name='street2'
                                control={form.control}
                                rules={{
                                    required: {
                                        value: false,
                                        message: "Billing Address is not required."
                                    }
                                }}
                                render={({ field, fieldState, formState }) => 
                                <TextField
                                    {...field}
                                    variant="standard"
                                    label="Street Name"
                                    color="secondary"
                                    onChange={(event) => {
                                        field.onChange(event.target.value)
                                        
                                    }}
                                    error={fieldState.invalid}
                                    helperText={fieldState.invalid ? fieldState.error?.message:''}
                                    
                                />}
                            />
                            <Grid container direction='row' spacing={2}>
                                <Grid item xs={6} sx={{ ml: 0, mr: 0, paddingLeft: "0px !important" }}>
                                <Controller
                                    name="country"
                                    control={form.control}
                                    rules={{ 
                                        required: {
                                            value: true,
                                            message: "Country is required."
                                        }
                                    }}
                                    render={({ field, fieldState, formState }) => 
                                    <Autocomplete
                                    {...field}
                                    disablePortal
                                    options={countries}
                                    sx={{ width: 200 }}
                                    value={country}
                                    onChange={(event: any, newValue: any | null) => {
                                        field.onChange(newValue.code)
                                        setCountry(newValue);
                                    }}
                                    getOptionLabel={(option: any) => option.name}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        <img
                                            loading="lazy"
                                            width="20"
                                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                            alt=""
                                        />
                                        {option.name} ({option.code})
                                        </Box>
                                    )}
                                    renderInput={(params: any) => 
                                        <TextField {...params} 
                                            label="Country" 
                                            variant="standard" 
                                            color="secondary"
                                        />}
                                    //onChange={(_event, data) => field.onChange(data?.code ?? '')}
                                    />}
                                />
                                </Grid>
                                <Grid item xs={6} sx={{ paddingLeft: "0px !important" }}>
                                <Controller
                                    name="state"
                                    control={form.control}
                                    rules={{ 
                                        required: {
                                            value: true,
                                            message: "State or Province is required"
                                        }
                                    }}
                                    render={({ field, fieldState, formState }) => 
                                    <Autocomplete
                                    {...field}
                                    disablePortal
                                    freeSolo
                                    options={statehint}
                                    sx={{ width: 200 }}
                                    value={st}
                                    onChange={(event: any, newValue: any | null) => {
                                        field.onChange(newValue)
                                        setSt(newValue);
                                    }}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                        {option}
                                        </Box>
                                    )}
                                    renderInput={(params: any) => 
                                        <TextField {...params} 
                                            label="State/Province" 
                                            variant="standard" 
                                            color="secondary"
                                        />}
                                    //onChange={(_event, data) => field.onChange(data?.code ?? '')}
                                    />}
                                />
                                </Grid>
                            </Grid>
                            <Grid container direction='row' spacing={2}>
                                <Grid item xs={6} sx={{ ml: 0, mr: 0, paddingLeft: "0px !important" }}>
                                <Controller
                                    name="city"
                                    control={form.control}
                                    rules={{ 
                                        required: {
                                            value: true,
                                            message: "City or District is required"
                                        }
                                    }}
                                    render={({ field, fieldState, formState }) => 
                                    <Autocomplete
                                    {...field}
                                    disablePortal
                                    freeSolo
                                    options={cityhint}
                                    sx={{ width: 200 }}
                                    value={city}
                                    onChange={(event: any, newValue: any | null) => {
                                        field.onChange(newValue)
                                        setCity(newValue);
                                    }}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                        {option}
                                        </Box>
                                    )}
                                    renderInput={(params: any) => 
                                        <TextField {...params} 
                                            label="City/District" 
                                            variant="standard" 
                                            color="secondary"
                                        />}
                                    //onChange={(_event, data) => field.onChange(data?.code ?? '')}
                                    />}
                                />
                                </Grid>
                                <Grid item xs={6} sx={{ paddingLeft: "0px !important" }}>
                                <Controller
                                    name="postal"
                                    control={form.control}
                                    rules={{ 
                                        required: {
                                            value: true,
                                            message: "City or District is required"
                                        }
                                    }}
                                    render={({ field, fieldState, formState }) => 
                                    <Autocomplete
                                    {...field}
                                    disablePortal
                                    freeSolo
                                    options={postalhint}
                                    sx={{ width: 200 }}
                                    value={postal}
                                    onChange={(event: any, newValue: any | null) => {
                                        field.onChange(newValue)
                                        setPostal(newValue);
                                    }}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                        {option}
                                        </Box>
                                    )}
                                    renderInput={(params: any) => 
                                        <TextField {...params} 
                                            label="Postal Code" 
                                            variant="standard" 
                                            color="secondary"
                                        />}
                                    //onChange={(_event, data) => field.onChange(data?.code ?? '')}
                                    />}
                                />
                                </Grid>
                            </Grid>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox color='secondary' checked={defaultcard} onChange={handleCheckDefaultCard} />} label="Set as default card" />
                            </FormGroup>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    width: 436
                                }} 
                                onClick={form.handleSubmit(onNewCardSubmit)}
                            >
                                Save Address
                            </Button>
                        </Stack>
                    </form>
                </Grid>
            </Grid>
            </Stack>
        </Paper>
        </>
    )
}

export const AddPaymentCardForm = React.forwardRef((props: IPaymentCardFormModal, ref: any) => {
    return (
        <div ref={ref}>
            <AddPaymentCard {...props} />
        </div>
    )
})