import * as React from 'react'
import { styled } from '@mui/material/styles';
import { render } from '@testing-library/react';
import { Layer, Stage, Image, Rect, Text, Transformer, Group, Circle, TextPath } from 'react-konva';
import { Mockup, MockupList, YourGraphic, YourPhoto } from '../../../../types';
import { useAppDispatch, useAppSelector } from '../../../../reducers/hooks';
import { fetchMockup } from '../../../../actions/designActions';
import { DesignContext, DesignLayer, DesignRefs, DesignState, DesignView } from '../../../../providers/DesignRefContext';
import { Box, Fab, FabProps, Grid, ToggleButton } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import Konva from 'konva';
import useImage from 'use-image';
import MockupLayer from '../layers/MockupLayer';
import PrintframeLayer from '../layers/PrintframeLayer';
//import { ExportPhotoLayer, PreviewPhotoLayer } from '../layers/PhotoLayer';
// import { ExportGraphicLayer, PreviewGraphicLayer } from '../layers/GraphicLayer';
// import { ExportTextLayer, PreviewTextLayer } from '../layers/TextLayer';


const CustomFab = styled((props: FabProps) => (
    <Fab 
        size="small" 
        color="primary" 
        {...props}/>
))(({ theme }) => ({
    [theme.breakpoints.down('lg')]: {
        right: 80
    },
    [theme.breakpoints.up('lg')]: {
        right: 80
    },
    [theme.breakpoints.up('xl')]: {
        right: 25
    }
}))

interface PhotoLayerProps {
    id: string
    idx: number
    itemId: string
    itemname: string
    itemowner: string
    type: string
    visible: boolean
    actualW: number
    actualH: number
    actual: string
    previewW: number
    previewH: number
    preview: string,
    previewdata?: any,
    actualdata?: any,
    // previewimage?: string
    // actualimage?: string
    // previewimagedata?: any
    // actualimagedata?: any
    // opacity?: number
    selected: boolean
    //currentsrc: number
    shapeGrpAttr?: any
    shapeAttr?: any
    shapeboxAttr?: any
    cropmaskAttr?: any
    exportshapeGrpAttr?: any
    exportshapeAttr?: any
    previewlayerAttr?: any
    exportlayerAttr?: any
    previewlayerRef?: any
    exportlayerRef?: any 
    shapeGrpRef?: any
    shapeboxRef?: any
    shapeRef?: any
    trRef?: any
    croptrRef?: any
    cropmaskRef?: any
    cropmasktrRef?: any
    exportshapeGrpRef?: any
    exportshapeRef?: any
    curlayer?: string | null
    curtool?: string | null
    frameX: number
    frameY: number
    frameW: number
    frameH: number
    moveShape?: (x: number, y: number) => void
    moveCropBox?: (x: number, y: number) => void
    moveCropMask?: (x: number, y: number) => void
    transformShape?: (x: number, y: number, sx: number, sy: number, r: number) => void
    transformCropBox?: (x: number, y: number, sx: number, sy: number, r: number) => void
    transformCropMask?: (x: number, y: number, sx: number, sy: number, r: number) => void
    selectLayer?: (id: string) => void
}

interface GraphicLayerProps {
    id: string
    idx: number
    itemId: string
    itemname: string
    itemowner: string
    type: string
    visible: boolean
    actualW: number
    actualH: number
    actual: string
    previewW: number
    previewH: number
    preview: string,
    previewdata?: any,
    actualdata?: any,
    // previewimage?: string
    // actualimage?: string
    // previewimagedata?: any
    // actualimagedata?: any
    // opacity?: number
    selected: boolean
    //currentsrc: number
    shapeGrpAttr?: any
    shapeAttr?: any
    shapeboxAttr?: any
    exportshapeGrpAttr?: any
    exportshapeAttr?: any
    previewlayerAttr?: any
    exportlayerAttr?: any
    previewlayerRef?: any
    exportlayerRef?: any 
    shapeGrpRef?: any
    shapeboxRef?: any
    shapeRef?: any
    trRef?: any
    exportshapeGrpRef?: any
    exportshapeRef?: any
    curlayer?: string | null
    curtool?: string | null
    frameX: number
    frameY: number
    frameW: number
    frameH: number
    moveShape?: (x: number, y: number) => void
    moveCropBox?: (x: number, y: number) => void
    moveCropMask?: (x: number, y: number) => void
    transformShape?: (x: number, y: number, sx: number, sy: number, r: number) => void
    transformCropBox?: (x: number, y: number, sx: number, sy: number, r: number) => void
    transformCropMask?: (x: number, y: number, sx: number, sy: number, r: number) => void
    selectLayer?: (id: string) => void
}

interface TextLayerProps {
    id: string
    idx: number
    type: string
    visible: boolean
    actualW: number
    actualH: number
    pathpreset?: string
    actualdata?: any
    previewW: number
    previewH: number
    previewdata?: any
    selected: boolean
    //currentsrc: number
    shapeGrpAttr?: any
    shapeAttr?: any
    shapeboxAttr?: any
    exportshapeGrpAttr?: any
    exportshapeAttr?: any
    previewlayerAttr?: any
    exportlayerAttr?: any
    previewlayerRef?: any
    exportlayerRef?: any 
    shapeGrpRef?: any
    shapeboxRef?: any
    shapeRef?: any
    trRef?: any
    exportshapeGrpRef?: any
    exportshapeRef?: any
    stageRef?: any
    curlayer?: string | null
    curtool?: string | null
    frameX: number
    frameY: number
    frameW: number
    frameH: number
    updateText?: (t:any, h: number) => void
    moveShape?: (x: number, y: number) => void
    moveCropBox?: (x: number, y: number) => void
    moveCropMask?: (x: number, y: number) => void
    transformShape?: (x: number, y: number, sx: number, sy: number, r: number) => void
    transformCropBox?: (x: number, y: number, sx: number, sy: number, r: number) => void
    transformCropMask?: (x: number, y: number, sx: number, sy: number, r: number) => void
    selectLayer?: (id: string) => void
}




export const DesignStage: React.FC = () => {
    // const [canvaswidth, setCanvaswidth] = React.useState<number>(700)
    // const [canvasheight, setCanvasheight] = React.useState<number>(700)
    //const [mockup, setMockup] = React.useState<any>(null)
    const { designstate, designdispatch, designrefs } = React.useContext(DesignContext)
    const { canvaswidth, canvasheight, 
        views, curview, zoomlevel, dragdesignitem } = designstate as DesignState
    const { stage, exportstage, previewstage } = designrefs as DesignRefs
    const { addPhotoLayer, addGraphicLayer, zoomIn, zoomOut, moveShape, moveCropMask, moveCropBox, updateText,
            selectLayer, transformShape, transformCropMask, transformCropBox, setView } = designdispatch
    // const { selproduct, selcolor, curview, layers } = useAppSelector<DesignState>((state) => state.design)
    // const { mockups, selmockup } = useAppSelector<MockupList>((state) => state.mockup)
    // const dispatch = useAppDispatch()
    const view = views?.find((v: DesignView) => v.view===curview)
    const curlayer = view !== undefined && view !== null ? view?.curlayer:null
    const curtool = view !== undefined && view !== null ? view?.curtool:null
    
    let previewframeX = (canvaswidth as number-(view?.previewwidth as number)*(1.1**(zoomlevel as number)))/2
    let previewframeY = (canvasheight as number-(view?.previewheight as number)*(1.1**(zoomlevel as number)))/2
    let previewframeW = (view?.previewwidth as number)*(1.1**(zoomlevel as number))
    let previewframeH = (view?.previewheight as number)*(1.1**(zoomlevel as number))

    let exportframeX = 0
    let exportframeY = 0
    let exportframeW = (view?.exportwidth as number)
    let exportframeH = (view?.exportheight as number)

    const handleClickStage = (e: any) => {
        const clickempty = e.target === e.target.getStage() || e.target.id().includes('mockup-layer-img') || e.target.id().includes('printframe-layer-rect') || e.target.id().includes('printframe-layer-defaulttext')
        if (clickempty && curtool !== 'crop') {
            selectLayer(null)
        }
    }
    
    const PreviewPhotoLayer = (props: PhotoLayerProps) => {
        const { moveShape, moveCropBox, moveCropMask,
                transformShape, transformCropBox, transformCropMask,
                selectLayer } = props
        
        React.useEffect(() => {
            if (props.curlayer !== null && props.selected && props.trRef !== undefined && props.shapeboxRef !== undefined) {
              // we need to attach transformer manually
              props.trRef.current?.nodes([props.shapeboxRef.current]);
              props.trRef.current?.getLayer().batchDraw();
              
            }
            if (props.curlayer !== null && props.curtool === 'crop' && props.croptrRef !== undefined && props.cropmaskRef !== undefined 
                && props.cropmaskRef.current !== null && props.cropmasktrRef !== undefined) {
               props.croptrRef.current?.nodes([props.shapeboxRef.current]);
               props.croptrRef.current?.getLayer().batchDraw();  
    
               props.cropmasktrRef.current?.nodes([props.cropmaskRef.current]);
               props.cropmasktrRef.current?.getLayer().batchDraw(); 
            }
          }, [props, props.selected, props.curlayer, props.curtool]);
        
        
        
        //const [image, status] = useImage(props.previewimage as string, 'anonymous')
        const image = props.previewdata
        return (
        <Group
            id={`preview-${props.id}`}
            {...props.previewlayerAttr}
            ref={props.previewlayerRef}
        >
            <Group
                clipX={props.frameX}
                clipY={props.frameY}
                clipWidth={props.frameW}
                clipHeight={props.frameH}
            >
            <Group
                {...props.shapeGrpAttr}
                
                ref={props.shapeGrpRef}
            >
           
            <Image
                {...props.shapeAttr}
                image={image}
                ref={props.shapeRef}
            />
            <Rect 
                {...props.shapeboxAttr}
                draggable={true}
                onDragEnd={(e) => {
                    if (props.curtool !== 'crop') {
                        if (moveShape) moveShape(e.target.x(), e.target.y())
                    } else {
                        if (moveCropBox) moveCropBox(e.target.x(), e.target.y())
                    }
                    
                }}
                onTransformEnd={(e) => {
                    if (props.curtool !== 'crop') {
                        if (transformShape) transformShape(e.target.x(), e.target.y(), e.target.scaleX(), e.target.scaleY(), e.target.rotation())
                    } else {
                        if (transformCropBox) transformCropBox(e.target.x(), e.target.y(), e.target.scaleX(), e.target.scaleY(), e.target.rotation())
                    }
                }}
                
                onClick={() => { props.curtool !== 'crop' && selectLayer && selectLayer(props.id) }}
                ref={props.shapeboxRef}
           />
            </Group>
            
            </Group>
            
            {props.selected && props.curtool === 'transform' && (
                <Transformer
                ref={props.trRef}
                
              />
            )}
            <Image
                ref={props.cropmaskRef}
                {...props.cropmaskAttr}
                image={image}
                opacity={0.5}
                draggable={true}
                onDragEnd={(e) => {
                    if (moveCropMask) moveCropMask(e.target.x(), e.target.y())
                }}
                onTransformEnd={(e) => {
                    if (transformCropMask) transformCropMask(e.target.x(), e.target.y(), e.target.scaleX(), e.target.scaleY(), e.target.rotation())
                }}
                visible={props.selected && props.curtool === 'crop'}
                />
            {props.selected && props.curtool === 'crop' && (
                <>
                
                <Transformer
                    ref={props.croptrRef}
                 />
                <Transformer
                    ref={props.cropmasktrRef}
                    borderStroke="deeppink"
                    borderDash={[5, 5]}
                    anchorStroke="deeppink"
                    anchorSize={10}
                    anchorCornerRadius={5}
                 />
                
                </>
                
                
            )}
        </Group>
        )
    }

    const PublishpreviewPhotoLayer = (props: PhotoLayerProps) => {
        const image = props.previewdata
        return (
        <Group
            id={`publish-preview-${props.id}`}
            {...props.previewlayerAttr}
            
        >
            <Group
                clipX={props.frameX}
                clipY={props.frameY}
                clipWidth={props.frameW}
                clipHeight={props.frameH}
            >
            <Group
                {...props.shapeGrpAttr}
                
                
            >
           
            <Image
                {...props.shapeAttr}
                image={image}
                
            />
            
            </Group>
            
            </Group>
            
        
        </Group>
        )
    }
    
    const ExportPhotoLayer = (props: PhotoLayerProps) => {
        
        const [image, status] = useImage(props.actual as string, 'anonymous')
        return (
        <Group
            id={`export-${props.id}`}
            {...props.exportlayerAttr}
            ref={props.exportlayerRef}
        >
            <Group
                clipX={props.frameX}
                clipY={props.frameY}
                clipWidth={props.frameW}
                clipHeight={props.frameH}
            >    
            <Group
                ref={props.exportshapeGrpRef}
                {...props.exportshapeGrpAttr}
            >
            <Image
                {...props.exportshapeAttr}
                image={image}
                ref={props.exportshapeRef}
            />
            </Group>
            </Group>
        </Group>
        )
    }

    const PreviewGraphicLayer = (props: GraphicLayerProps) => {
        const { moveShape, transformShape,
            selectLayer } = props
    
        React.useEffect(() => {
            if (props.selected && props.trRef !== undefined && props.shapeboxRef !== undefined) {
              // we need to attach transformer manually
              props.trRef.current?.nodes([props.shapeboxRef.current]);
              props.trRef.current?.getLayer().batchDraw();
              
            }
            
          }, [props.selected]);
        
        
        const image = props.previewdata
        return (
        <Group
            id={`preview-${props.id}`}
            {...props.previewlayerAttr}
            ref={props.previewlayerRef}
        >
            <Group
                clipX={props.frameX}
                clipY={props.frameY}
                clipWidth={props.frameW}
                clipHeight={props.frameH}
            >
            <Group
                {...props.shapeGrpAttr}
                
                ref={props.shapeGrpRef}
            >
           
            <Image
                {...props.shapeAttr}
                image={image}
                ref={props.shapeRef}
            />
            <Rect 
                {...props.shapeboxAttr}
                draggable={true}
                onDragEnd={(e) => {
                    if (moveShape) moveShape(e.target.x(), e.target.y())
                }}
                onTransformEnd={(e) => {
                    if (transformShape) transformShape(e.target.x(), e.target.y(), e.target.scaleX(), e.target.scaleY(), e.target.rotation())
                }}
                
                onClick={() => { if (selectLayer) selectLayer(props.id) }}
                ref={props.shapeboxRef}
           />
            </Group>
            
            </Group>
            {props.selected && props.curtool === 'transform' && (
                <Transformer
                ref={props.trRef}
                
              />
            )}
            
        </Group>
        )
    }

    const PublishpreviewGraphicLayer = (props: GraphicLayerProps) => {
        const image = props.previewdata
        return (
        <Group
            id={`publish-preview-${props.id}`}
            {...props.previewlayerAttr}
            
        >
            <Group
                clipX={props.frameX}
                clipY={props.frameY}
                clipWidth={props.frameW}
                clipHeight={props.frameH}
            >
            <Group
                {...props.shapeGrpAttr}
                
                
            >
           
            <Image
                {...props.shapeAttr}
                image={image}
                
            />
            
            </Group>
            
            </Group>
           
            
        </Group>
        )
    }
    
    const ExportGraphicLayer = (props: GraphicLayerProps) => {
        const [image, status] = useImage(props.actual as string, 'anonymous')
        return (
        
        <Group
            id={`export-${props.id}`}
            {...props.exportlayerAttr}
            ref={props.exportlayerRef}
        >
            <Group
                ref={props.exportshapeGrpRef}
                {...props.exportshapeGrpAttr}
            >
            <Image
                {...props.exportshapeAttr}
                image={image}
                ref={props.exportshapeRef}
            />
            </Group>
        </Group>
        )
    
    }

    const PreviewTextLayer = (props: TextLayerProps) => {
        const { updateText, moveShape, 
            transformShape, 
            selectLayer } = props
        const [textgrpVisible, setTextgrpVisible] = React.useState(true)
        const [texttrVisible, setTexttrVisible] = React.useState(false)
        
        const handleTextInlineEdit = () => {
            
            const stageNode = props.stageRef.current
            const textgrpNode = props.shapeGrpRef.current
            const textNode = props.shapeRef.current
            const tr = props.trRef.current
            // Hide text and transformer
            setTextgrpVisible(false)
            setTexttrVisible(false)
            
            // Create textarea over canvas with absolute position
            // first we need to find position for textarea
            // how to find it?
    
            // at first lets find position of text group node relative to the stage:
            const textPosition = textgrpNode.absolutePosition() 
            // so position of textarea will be the sum of positions above:
            var areaPosition = {
                x: stageNode.container().offsetLeft + textPosition.x,
                y: stageNode.container().offsetTop + textPosition.y,
            }
            // get container of stage
            var container = document.querySelector('#preview-container')
            // create textarea and style it
            var textarea = document.createElement('textarea');
            if (container) {
                container.appendChild(textarea);
            }
            // apply many styles to match text on canvas as close as possible
            // remember that text rendering on canvas and on the textarea can be different
            // and sometimes it is hard to make it 100% the same. But we will try...
            const scale = textNode.getAbsoluteScale().x
            textarea.value = textNode.text();
            textarea.style.position = 'absolute';
            textarea.style.top = areaPosition.y + 'px';
            textarea.style.left = areaPosition.x + 'px';
            textarea.style.width = textNode.width()*scale - textNode.padding() * 2 + 'px';
            textarea.style.height = textNode.height()*scale - textNode.padding() * 2 + 5 + 'px';
            textarea.style.fontSize = textNode.fontSize()*scale + 'px';
            textarea.style.border = 'none';
            textarea.style.padding = '0px';
            textarea.style.margin = '0px';
            textarea.style.overflow = 'hidden';
            textarea.style.background = 'none';
            textarea.style.outline = 'none';
            textarea.style.resize = 'none';
            textarea.style.lineHeight = textNode.lineHeight();
            textarea.style.fontFamily = textNode.fontFamily();
            textarea.style.transformOrigin = 'left top';
            textarea.style.textAlign = textNode.align();
            textarea.style.color = textNode.fill();
            const rotation = textNode.rotation();
            var transform = '';
            if (rotation) {
                transform += 'rotateZ(' + rotation + 'deg)';
            }
    
            var px = 0;
            // also we need to slightly move textarea on firefox
            // because it jumps a bit
            var isFirefox =
            navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
            if (isFirefox) {
            px += 2 + Math.round(textNode.fontSize() / 20);
            }
            transform += 'translateY(-' + px + 'px)';
    
            textarea.style.transform = transform;
    
            // reset height
            textarea.style.height = 'auto';
            // after browsers resized it we can set actual value
            textarea.style.height = textarea.scrollHeight + 3 + 'px';
    
            textarea.focus();
    
            const removeTextarea = () => {
                const scale = textNode.getAbsoluteScale().x
                const newH = (textarea.offsetHeight + 3 )/scale
                textarea!.parentNode!.removeChild(textarea);
                window.removeEventListener('click', handleOutsideClick);
                
                if (updateText) updateText(textNode.text(), newH)
            }
      
            const setTextareaWidth = (newWidth: number) => {
                if (!newWidth) {
                  // set width for placeholder
                  newWidth = textNode.placeholder.length * textNode.fontSize();
                }
                // some extra fixes on different browsers
                var isSafari = /^((?!chrome|android).)*safari/i.test(
                  navigator.userAgent
                );
                var isFirefox =
                  navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
                if (isSafari || isFirefox) {
                  newWidth = Math.ceil(newWidth);
                }
      
                var isEdge = /Edge/.test(navigator.userAgent);
                if (isEdge) {
                  newWidth += 1;
                }
                textarea.style.width = newWidth + 'px';
              }
      
              textarea.addEventListener('keydown', (e) => {
                // hide on enter
                // but don't hide on shift + enter
                
                if (e.key === 'Enter' && !e.shiftKey) {
                  textNode.text(textarea.value);
                  removeTextarea();
                }
                // on esc do not set value back to node
                if (e.key === 'Esc') {
                  removeTextarea();
                }
              });
      
              textarea.addEventListener('keydown', (e) => {
                const scale = textNode.getAbsoluteScale().x;
                setTextareaWidth(textNode.width() * scale);
                textarea.style.height = 'auto';
                textarea.style.height =
                  textarea.scrollHeight + textNode.fontSize() * scale + 'px';
              });
      
              const handleOutsideClick = (e: any) => {
                if (e.target !== textarea) {
                  textNode.text(textarea.value);
                  removeTextarea();
                }
              }
              setTimeout(() => {
                window.addEventListener('click', handleOutsideClick);
              });
        }
        
        React.useEffect(() => {
            if (props.selected && props.trRef !== undefined && props.shapeboxRef !== undefined) {
              // we need to attach transformer manually
              props.trRef.current?.nodes([props.shapeboxRef.current]);
              props.trRef.current?.getLayer().batchDraw();
              setTexttrVisible(true)
            }
    
            if (props.shapeboxRef) {
                props.shapeboxRef.current?.on('dblclick dbltap', () => {
                    handleTextInlineEdit()
                })
            }
            
          }, [props.selected]);
        
        
        return (
        <Group
            id={`preview-${props.id}`}
            {...props.previewlayerAttr}
            ref={props.previewlayerRef}
        >
            <Group
                clipX={props.frameX}
                clipY={props.frameY}
                clipWidth={props.frameW}
                clipHeight={props.frameH}
            >
            <Group
                {...props.shapeGrpAttr}
                visible={textgrpVisible}
                ref={props.shapeGrpRef}
            >
           
            {/* <Image
                {...props.shapeAttr}
                image={image}
                ref={props.shapeRef}
            /> */}
            <Text 
                {...props.shapeAttr}
                padding={6}
                ref={props.shapeRef}
                
            />
            <Rect 
                {...props.shapeboxAttr}
                draggable={true}
                onDragEnd={(e) => {
                    if (moveShape) moveShape(e.target.x(), e.target.y())
                }}
                onTransformEnd={(e) => {
                    if (transformShape) transformShape(e.target.x(), e.target.y(), e.target.scaleX(), e.target.scaleY(), e.target.rotation())
                }}
                
                onClick={() => { 
                    if (props.id === props.curlayer) {
                        handleTextInlineEdit() 
                    } else {
                        if (selectLayer) selectLayer(props.id)
                    }
                }}
                // onDblClick={() => { handleTextInlineEdit() }}
                
                ref={props.shapeboxRef}
           />
            </Group>
            
            </Group>
            {props.selected && props.curtool === 'transform' && (
                <Transformer
                visible={texttrVisible}
                ref={props.trRef}
                
              />
            )}
            
        </Group>
        )
    }

    const PublishpreviewTextLayer = (props: TextLayerProps) => {
        return (
            <Group
                id={`publish-preview-${props.id}`}
                {...props.previewlayerAttr}
                ref={props.previewlayerRef}
            >
                <Group
                    clipX={props.frameX}
                    clipY={props.frameY}
                    clipWidth={props.frameW}
                    clipHeight={props.frameH}
                >
                <Group
                    {...props.shapeGrpAttr}
                    
                >
               
                
                <Text 
                    {...props.shapeAttr}
                    padding={6}
                    
                />
                
                </Group>
                
                </Group>
                
                
            </Group>
            )
    }
    
    const ExportTextLayer = (props: TextLayerProps) => {
        
        return (
        
        <Group
            id={`export-${props.id}`}
            {...props.exportlayerAttr}
            ref={props.exportlayerRef}
        >
            <Group
                ref={props.exportshapeGrpRef}
                {...props.exportshapeGrpAttr}
            >
            <Text
                {...props.exportshapeAttr}
                padding={6}
                ref={props.exportshapeRef}
            />
            </Group>
        </Group>
        )
    }

    return (
        <>
        
        <div style={{
            position: 'relative',
            width: '700px',
            height: '700px'
        }}>
        <div id="preview-container"
            onDragOver={(e) => {
                e.preventDefault()
            }}
            onDrop={(e) => {
                e.preventDefault()
                if (dragdesignitem !== null && dragdesignitem !== undefined) {
                    if (dragdesignitem.itemtype === 'yourphoto') {
                        const yourphoto: YourPhoto = dragdesignitem.itemobj as YourPhoto
                        if (yourphoto.preview !== undefined) {
                            //const [image, status] = useImage(yourphoto.preview, 'anonymous')
                            let previewphoto = new window.Image()
                            previewphoto.crossOrigin = 'anonymous'
                            previewphoto.src = yourphoto.preview
                            previewphoto.onload = () => {
                                let previewW = yourphoto.preview_w //previewphoto.width
                                let previewH = yourphoto.preview_h
                                let previewscale = (previewW >= previewH ? (view?.previewwidth as number)/previewW:(view?.previewheight as number)/previewH)*(1.1**(zoomlevel as number))
                                let viewW = previewW*previewscale
                                let viewH = previewH*previewscale
                                // let normW = viewW/((previewwidth as number) * (1.1**(zoomlevel as number)))
                                // let normH = viewH/((previewheight as number) * (1.1**(zoomlevel as number)))
                                let previewX = (canvaswidth as number-viewW*(1.1**(zoomlevel as number)))/2
                                let previewY = (canvasheight as number-viewH*(1.1**(zoomlevel as number)))/2
                                // let frameX = (canvaswidth as number-(previewwidth as number)*(1.1**(zoomlevel as number)))/2
                                // let frameY = (canvasheight as number-(previewheight as number)*(1.1**(zoomlevel as number)))/2
                                // let normX = (previewX-frameX)/((previewwidth as number) * (1.1**(zoomlevel as number)))
                                // let normY = (previewY-frameY)/((previewheight as number) * (1.1**(zoomlevel as number)))
                                // let actualphoto = new window.Image()
                                // actualphoto.crossOrigin = 'Anonymous'
                                // actualphoto.src = yourphoto.actual
                                // actualphoto.onload = () => {
                                let actualW = yourphoto.actual_w
                                let actualH = yourphoto.actual_h
                                let exportscale = (actualW >= actualH ? (view?.exportwidth as number)/actualW:(view?.exportheight as number)/actualH)
                                let exportW = actualW*exportscale
                                let exportH = actualH*exportscale
                                
                                let exportX = ((view?.exportwidth as number) - exportW)/2
                                let exportY = ((view?.exportheight as number) - exportH)/2
                                const layerIdx = view?.layers?.length as number
                                const layer: DesignLayer = {
                                    id: `${view?.view}-layer-${layerIdx}`,
                                    props: {
                                        idx: layerIdx,
                                        itemId: yourphoto.id,
                                        itemname: yourphoto.photo_name,
                                        itemowner: yourphoto.owner,
                                        type: 'photo-layer',
                                        usageprices: yourphoto.prices,
                                        discount: yourphoto.discount,
                                        isfree: yourphoto.is_free,
                                        actualW: actualW,
                                        actualH: actualH,
                                        actual: yourphoto.actual,
                                        previewW: previewW,
                                        previewH: previewH,
                                        preview: yourphoto.preview,
                                        previewdata: previewphoto,
                                        selected: true,
                                        previewlayerAttr: {
                                            opacity: 1,
                                        },
                                        exportlayerAttr: {
                                            opacity: 1,
                                        },
                                        shapeGrpAttr: {
                                            x: previewX,
                                            y: previewY,
                                            width: previewW,
                                            height: previewH,
                                            scaleX: previewscale,
                                            scaleY: previewscale,
                                            rotation: 0,
                                            clipX: 0,
                                            clipY: 0,
                                            clipWidth: previewW,
                                            clipHeight: previewH
                                        },
                                        shapeboxAttr: {
                                            x: 0,
                                            y: 0,
                                            width: previewW,
                                            height: previewH,
                                            scaleX: 1,
                                            scaleY: 1,
                                            rotation: 0
                                        },
                                        shapeAttr: {
                                            x: 0,
                                            y: 0,
                                            width: previewW,
                                            height: previewH,
                                            scaleX: 1,
                                            scaleY: 1,
                                            rotation: 0,
                                            offsetX: 0,
                                            offsetY: 0,
                                            crop: {
                                                x: 0,
                                                y: 0,
                                                width: previewW,
                                                height: previewH
                                            }
                                        },
                                        cropmaskAttr: {
                                            x: previewX,
                                            y: previewY,
                                            width: previewW,
                                            height: previewH,
                                            scaleX: previewscale,
                                            scaleY: previewscale,
                                            rotation: 0
                                        },
                                        exportshapeGrpAttr: {
                                            x: exportX,
                                            y: exportY,
                                            width: actualW,
                                            height: actualH,
                                            scaleX: exportscale,
                                            scaleY: exportscale,
                                            rotation: 0,
                                            clipX: 0,
                                            clipY: 0,
                                            clipWidth: actualW,
                                            clipHeight: actualH
                                        },
                                        exportshapeAttr: {
                                            x: 0,
                                            y: 0,
                                            width: actualW,
                                            height: actualH,
                                            scaleX: 1,
                                            scaleY: 1,
                                            rotation: 0,
                                            crop: {
                                                x: 0,
                                                y: 0,
                                                width: actualW,
                                                height: actualH
                                            }
                                        }
                                        
                                    }

                                }
                                addPhotoLayer([layer])
                            }

                            
                        }
                    }
                    if (dragdesignitem.itemtype === 'yourgraphic') {
                        const yourgraphic: YourGraphic = dragdesignitem.itemobj as YourGraphic
                        if (yourgraphic.preview !== undefined) {
                            let previewgraphic = new window.Image()
                            previewgraphic.crossOrigin = 'anonymous'
                            previewgraphic.src = yourgraphic.preview
                            previewgraphic.onload = () => {
                                let previewW = yourgraphic.preview_w
                                let previewH = yourgraphic.preview_h
                                let previewscale = (previewW >= previewH ? (view?.previewwidth as number)/previewW:(view?.previewheight as number)/previewH)*(1.1**(zoomlevel as number))
                                let viewW = previewW*previewscale
                                let viewH = previewH*previewscale
                                let previewX = (canvaswidth as number-viewW*(1.1**(zoomlevel as number)))/2
                                let previewY = (canvasheight as number-viewH*(1.1**(zoomlevel as number)))/2
                                // let actualgraphic = new window.Image()
                                // actualgraphic.src = yourgraphic.actual
                                // actualgraphic.onload = () => {
                                let actualW = yourgraphic.actual_w
                                let actualH = yourgraphic.actual_h
                                let exportscale = (actualW >= actualH ? (view?.exportwidth as number)/actualW:(view?.exportheight as number)/actualH)
                                let exportW = actualW*exportscale
                                let exportH = actualH*exportscale
                                
                                let exportX = ((view?.exportwidth as number) - exportW)/2
                                let exportY = ((view?.exportheight as number) - exportH)/2
                                const layerIdx = view?.layers?.length as number
                                const layer: DesignLayer = {
                                    id: `${view?.view}-layer-${layerIdx}`,
                                    props: {
                                        idx: layerIdx,
                                        itemId: yourgraphic.id,
                                        itemname: yourgraphic.graphic_name,
                                        itemowner: yourgraphic.owner,
                                        type: 'graphic-layer',
                                        usageprices: yourgraphic.prices,
                                        discount: yourgraphic.discount,
                                        isfree: yourgraphic.is_free,
                                        actualW: actualW,
                                        actualH: actualH,
                                        actual: yourgraphic.actual,
                                        previewW: previewW,
                                        previewH: previewH,
                                        preview: yourgraphic.preview,
                                        previewdata: previewgraphic,
                                        selected: true,
                                        previewlayerAttr: {
                                            opacity: 1,
                                        },
                                        exportlayerAttr: {
                                            opacity: 1,
                                        },
                                        shapeGrpAttr: {
                                            x: previewX,
                                            y: previewY,
                                            width: previewW,
                                            height: previewH,
                                            scaleX: previewscale,
                                            scaleY: previewscale,
                                            rotation: 0,
                                            clipX: 0,
                                            clipY: 0,
                                            clipWidth: previewW,
                                            clipHeight: previewH
                                        },
                                        shapeboxAttr: {
                                            x: 0,
                                            y: 0,
                                            width: previewW,
                                            height: previewH,
                                            scaleX: 1,
                                            scaleY: 1,
                                            rotation: 0
                                        },
                                        shapeAttr: {
                                            x: 0,
                                            y: 0,
                                            width: previewW,
                                            height: previewH,
                                            scaleX: 1,
                                            scaleY: 1,
                                            rotation: 0,
                                            offsetX: 0,
                                            offsetY: 0,
                                            crop: {
                                                x: 0,
                                                y: 0,
                                                width: previewW,
                                                height: previewH
                                            }
                                        },
                                        
                                        exportshapeGrpAttr: {
                                            x: exportX,
                                            y: exportY,
                                            width: actualW,
                                            height: actualH,
                                            scaleX: exportscale,
                                            scaleY: exportscale,
                                            rotation: 0,
                                            clipX: 0,
                                            clipY: 0,
                                            clipWidth: actualW,
                                            clipHeight: actualH
                                        },
                                        exportshapeAttr: {
                                            x: 0,
                                            y: 0,
                                            width: actualW,
                                            height: actualH,
                                            scaleX: 1,
                                            scaleY: 1,
                                            rotation: 0,
                                            crop: {
                                                x: 0,
                                                y: 0,
                                                width: actualW,
                                                height: actualH
                                            }
                                        }
                                        
                                    }
                
                                }
                                addGraphicLayer([layer])
                            //     }
                  
                            }
                        }
                    }
                }
            }}
            
        >
        <Stage 
            width={canvaswidth}
            height={canvasheight}
            onMouseDown={handleClickStage}
            ref={stage}
        >
            <>
            {views?.map((v: DesignView) => {
            return (
                    <>
                      <Layer
                        id={`${v.view}-preview-layer`}
                        visible={v.view===curview}
                      >      
                        {v.layers !== null && v.layers?.map((layer) => {
                            if (layer.props.type === 'mockup-layer') {
                                return (
                                <MockupLayer 
                                    id={layer.id}
                                    {...layer.props}
                                />
                                )
                            }
                            if (layer.props.type === 'printframe-layer') {
                                return (
                                <PrintframeLayer 
                                    id={layer.id}
                                    canvaswidth={canvaswidth}
                                    canvasheight={canvasheight}
                                    {...layer.props}
                                />
                                )
                            }
                            if (layer.props.type === 'photo-layer') {
                                return (
                                <PreviewPhotoLayer
                                    id={layer.id}
                                    curlayer={curlayer}
                                    curtool={curtool}
                                    frameX={previewframeX}
                                    frameY={previewframeY}
                                    frameW={previewframeW}
                                    frameH={previewframeH}
                                    moveShape={moveShape}
                                    moveCropBox={moveCropBox}
                                    moveCropMask={moveCropMask}
                                    transformShape={transformShape}
                                    transformCropBox={transformCropBox}
                                    transformCropMask={transformCropMask}
                                    selectLayer={selectLayer}
                                    {...layer.props}
                                />
                                )
                            }
                            if (layer.props.type === 'graphic-layer') {
                                return (
                                <PreviewGraphicLayer
                                    id={layer.id}
                                    curlayer={curlayer}
                                    curtool={curtool}
                                    frameX={previewframeX}
                                    frameY={previewframeY}
                                    frameW={previewframeW}
                                    frameH={previewframeH}
                                    moveShape={moveShape}
                                    moveCropBox={moveCropBox}
                                    moveCropMask={moveCropMask}
                                    transformShape={transformShape}
                                    transformCropBox={transformCropBox}
                                    transformCropMask={transformCropMask}
                                    selectLayer={selectLayer}
                                    {...layer.props}
                                />
                                )
                            }
                            if (layer.props.type === 'text-layer') {
                                return (
                                <PreviewTextLayer
                                    id={layer.id}
                                    stageRef={stage}
                                    curlayer={curlayer}
                                    curtool={curtool}
                                    frameX={previewframeX}
                                    frameY={previewframeY}
                                    frameW={previewframeW}
                                    frameH={previewframeH}
                                    moveShape={moveShape}
                                    transformShape={transformShape}
                                    selectLayer={selectLayer}
                                    updateText={updateText}
                                    {...layer.props}
                                />
                                )
                            }
                
                        })}
                        
                      </Layer> 
                    </>    
                )
            }
            )}
            </>
            
        </Stage>
        </div>
        <div 
        style={{
            visibility: 'hidden',
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '100px',
            height: '100px',
            overflow: 'auto'
            // width: `${view?.exportwidth as number}`,
            // height: `${view?.exportheight as number}`
        }}
        >
        <Stage
            visible={true}
            width={view?.exportwidth as number}
            height={view?.exportheight as number}
            ref={exportstage}
        >
            <>
            {views?.map((v: DesignView) => {
            return (
                    <Layer
                        id={`${v.view}-export-layer`}
                        // visible={v.view===curview}
                    >    
                        {v.layers !== null && v.layers?.map((layer) => {
                            if (layer.props.type === 'photo-layer') {
                                return (
                                <ExportPhotoLayer
                                    id={layer.id}
                                    frameX={exportframeX}
                                    frameY={exportframeY}
                                    frameW={exportframeW}
                                    frameH={exportframeH}
                                    {...layer.props}
                                />
                                )
                            }
                            if (layer.props.type === 'graphic-layer') {
                                return (
                                <ExportGraphicLayer
                                    id={layer.id}
                                    frameX={exportframeX}
                                    frameY={exportframeY}
                                    frameW={exportframeW}
                                    frameH={exportframeH}
                                    {...layer.props}
                                />
                                )
                            }
                            if (layer.props.type === 'text-layer') {
                                return (
                                <ExportTextLayer
                                    id={layer.id}
                                    frameX={exportframeX}
                                    frameY={exportframeY}
                                    frameW={exportframeW}
                                    frameH={exportframeH}
                                    {...layer.props}
                                />
                                )
                            }
                        })}
                    </Layer>    
                )
            
            }
            )}
            </>
            
        </Stage>
        </div>
        <div 
        style={{
            visibility: 'hidden',
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '100px',
            height: '100px',
            overflow: 'auto'
            // width: `${view?.exportwidth as number}`,
            // height: `${view?.exportheight as number}`
        }}
        >
        <Stage
            visible={true}
            width={canvaswidth}
            height={canvasheight}
            ref={previewstage}
        >
            <>
            {views?.map((v: DesignView) => {
            return (
                    <Layer
                        id={`${v.view}-preview-publish-layer`}
                        // visible={v.view===curview}
                    >    
                        {v.layers !== null && v.layers?.map((layer) => {
                            if (layer.props.type === 'mockup-layer') {
                                return (
                                <MockupLayer 
                                    id={`publish-preview-${layer.id}`}
                                    {...layer.props}
                                />
                                )
                            }
                            if (layer.props.type === 'photo-layer') {
                                return (
                                <PublishpreviewPhotoLayer
                                    id={layer.id}
                                    frameX={previewframeX}
                                    frameY={previewframeY}
                                    frameW={previewframeW}
                                    frameH={previewframeH}
                                    {...layer.props}
                                />
                                )
                            }
                            if (layer.props.type === 'graphic-layer') {
                                return (
                                <PublishpreviewGraphicLayer
                                    id={layer.id}
                                    frameX={previewframeX}
                                    frameY={previewframeY}
                                    frameW={previewframeW}
                                    frameH={previewframeH}
                                    {...layer.props}
                                />
                                )
                            }
                            if (layer.props.type === 'text-layer') {
                                return (
                                <PublishpreviewTextLayer
                                    id={layer.id}
                                    frameX={previewframeX}
                                    frameY={previewframeY}
                                    frameW={previewframeW}
                                    frameH={previewframeH}
                                    {...layer.props}
                                />
                                )
                            }
                        })}
                    </Layer>    
                )
            
            }
            )}
            </>
            
        </Stage>
        </div>
        <div
            style={{
                position: 'absolute' as 'absolute',
                top: 96,
                left: 0
            }}
        >
            <Grid container
                direction="column"
                spacing={2}
                alignItems="center"
                sx={{
                    width: 75
                }}>
            {views?.map((v:DesignView) => {
                if (v.layers?.length as number > 0) {
                    return (
                    <Grid item>
                    <ToggleButton
                        value={`${v.view}-design-view`}
                        selected={v.view === curview}
                        onClick={() => setView(v.view)}
                        >
                        <img src={v.thumb} width={75} />
                    </ToggleButton>
                    </Grid>
                    )
                }
            }
            )}
            </Grid>
            
        </div>
        
        
        <CustomFab 
            aria-label="zoomin" 
            onClick={() => { zoomIn() }}
            disabled={zoomlevel as number < 8 ? false:true}
            sx={{
                position: 'absolute' as 'absolute',
                top: 96
            }}>
                <ZoomInIcon fontSize="large" />
        </CustomFab>
        <CustomFab
            aria-label="zoomout" 
            onClick={() => { zoomOut() }}
            disabled={zoomlevel as number > 0 ? false:true}
            sx={{
                position: 'absolute' as 'absolute',
                top: 144
            }}>
                <ZoomOutIcon fontSize="large" />
        </CustomFab>
        </div>
        
        </>
    )
}