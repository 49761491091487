import * as React from 'react'
import { Box, Button, CircularProgress, Grid, Paper, Stack, Typography } from '@mui/material'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { bindActionCreators } from 'redux'
import { igLoginFulfilled } from '../actions/userActions'
import { returntypeof } from 'react-redux-typescript'
import compose from 'recompose/compose'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { toAbsoluteUrl } from '../_helpers'
import { IdToken } from '../providers/djangoOAuth2'
import { withRouter } from 'react-router-dom'
import { ig_app_client_id, ig_app_redirect_uri, ig_authorize_url } from '../settings'

const styles = (theme: any) => ({
    box: {
        margin: 'auto'
    },
    paper: {
        width: 400,
        height: 400,
        marginTop: 50,
        paddingTop: 10,
        alignItems: 'top',
        justifyContent: 'center',
    }
})

// export interface IdToken {
//     iss: string
//     sub: string
//     aud: string
//     exp: number
//     iat: number
//     auth_time: number
//     jti: string
//     at_hash: string
//     nonce: string
//     usr: string
// }

class Component extends React.Component<any, 
{
    loading: boolean,
    success: boolean
}> {
    accessToken: string | null
    uid: string | null
    username: string | null
    completed: boolean

    constructor(props: any) {
        super(props)
        this.handleRetry = this.handleRetry.bind(this)
        this.handleBack = this.handleBack.bind(this)
        this.accessToken = null!
        this.uid = null!
        this.username = null!
        this.completed = false
        this.state = {
            loading: true,
            success: false
        }
    }

    handleRetry() {
        //this.props.history.push('/signin')
        let authorizer: string = `${ig_authorize_url}?client_id=${ig_app_client_id}&redirect_uri=${ig_app_redirect_uri}&scope=user_profile%2Cuser_media&response_type=code`
        window.location.assign(authorizer)
    }

    handleBack() {
        const { user } = this.props
        this.props.history.push(user.currentUri)
    }

    componentDidMount() {
        const redirectUrl = window.location.href;
        const authError = redirectUrl.match(/error=([^&]+)/) 
        if (authError) {
            window.setTimeout(() => {
                this.setState({loading: false, success: false})

            }, 3000)
        } else {
            const accessTokenMatch = redirectUrl.match(/access_token=([^&]+)/)
            if (accessTokenMatch) {
                this.accessToken = accessTokenMatch[1]
            }

            const uidMatch = redirectUrl.match(/user=([^&]+)/)
            if (uidMatch) {
                this.uid = uidMatch[1]
            }

            const nameMatch = redirectUrl.match(/username=([^&]+)/)
            if (nameMatch) {
                this.username = nameMatch[1]
            }

            window.setTimeout(() => {
                this.setState({loading: false, success: true})

            }, 3000)
        }
        

    }

    componentDidUpdate() {
        const { user, igLoginFulfilled } = this.props
        if (this.state.success && !this.completed) {
            let expiration = new Date().getTime() / 1000
            expiration = expiration + 3600
            this.completed = true
            igLoginFulfilled(this.uid, this.username, this.accessToken, expiration)
        } else if (this.state.success && this.completed) {
            // window.location.assign(process.env.PUBLIC_URL+user.currentUri)
            this.props.history.push(user.currentUri)
        } 
    }

    render() {
        const { classes } = this.props
        return (
            <>
            {/* <Box className={classes.box}>
                <Paper className={classes.paper}> */}
            <Box sx={{margin: 'auto'}}>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh', marginTop: '50px' }}
                >
                <Grid item xs={3}>
                <Paper
                  sx={{
                    width: 400,
                    height: 400,
                    alignItems: 'top',
                    justifyContent: 'center',
                    padding: '15px',
                    paddingTop: '20px'
                  }}
                >
                    <Grid container 
                        spacing={2}
                        direction="column"
                        alignItems="center"
                        justifyContent="center">
                        <Grid item xs={12} sx={{ justifyContent: 'center' }}>
                        <img src={toAbsoluteUrl('/media/logos/pixartee-logo.png')} width={150} />
                        </Grid>
                        <Grid item xs={12}>
                        <Typography variant="h6">Processing access data</Typography>
                        </Grid>
                    </Grid>
                    
                        
                        {/* {!this.state.success ? (
                            <LockIcon 
                            sx={{ 
                                fontSize: 64,
                                color: '#444444',
                                position: 'absolute',
                                top: 168,
                                left: 168
                            }}
                            />
                        ):(
                            <LockOpenIcon 
                            sx={{ 
                                fontSize: 64,
                                color:'#444444',
                                position: 'absolute',
                                top: 168,
                                left: 168
                            }}
                            />
                        )} */}
                        {this.state.loading ? (
                            <>
                            <Grid container 
                                spacing={2}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                paddingTop={10}
                                >
                            <Grid item xs={12} sx={{justifyContent: "center"}}>
                            <Box sx={{position: 'relative', display: 'inline-flex', width: 84}}>
                            <CircularProgress
                            size={84}
                            color='primary'
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                zIndex: 1,
                            }}
                            />
                            
                            <LockIcon 
                            sx={{ 
                                fontSize: 56,
                                top: 14,
                                left: 14,
                                position: 'absolute',
                                color: '#444444'
                            }}
                            />
                            
                            </Box>
                            </Grid>
                            </Grid>
                            </>
                        ):(
                            <>
                            {(!this.state.success && !this.state.loading) && (
                                <Stack spacing={2} sx={{ paddingTop: '20px' }}>
                                <Typography variant="body1" color="error">Access Denied. You are not authorized.</Typography>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={this.handleRetry}>
                                    Retry
                                </Button>
                                <Button 
                                    variant="outlined" 
                                    color="primary" 
                                    onClick={this.handleBack}>
                                    Go Back
                                </Button>
                                </Stack>
                            )}
                            </>
                        )}
                            
                        
                    
                </Paper>
                </Grid>
            </Grid>
            </Box>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        igLoginFulfilled
    }, dispatch)
}
const mapStateToProps = (state: any) => {
    return {
        user: state.user
    }
}
const stateProps = returntypeof(mapStateToProps);
const dispatchProps = returntypeof(mapDispatchToProps);

export default compose(
    withRouter,
    withStyles(styles),
   connect<typeof stateProps, typeof dispatchProps, {}>(mapStateToProps, mapDispatchToProps)
)(Component);
