import { Button, Grid, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import * as React from 'react'
import { Offer } from '../../../../types'

interface IOfferListItemProps {
    item: Offer
    onCollect: (offer: string) => void
}

const OfferListItem = (props: IOfferListItemProps) => {
    const { item, onCollect } = props

    let desc = ""
    if (item.scheme === 0) {
        desc = `${item.discount_percent}% discount of your total purchase`
    } 
    else if (item.scheme === 1) {
        desc = `${item.discount_amount} ${item.discount_currency?.toUpperCase()} discount of your total purchase`
    } 
    else if (item.scheme === 2) {
        desc = `Buy ${item.min_buy} Get ${item.amt_free} Free`
    } 
    return (
        <ListItem
            key={`offer-${item.id}`}
            secondaryAction={
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                        width: 100
                    }} 
                    onClick = {() => onCollect(item.id)}
                >
                    Collect
                </Button>
            }
        >
            <ListItemText
                primary={item.offer_name}
                secondary={
                    <React.Fragment>
                        <Stack>
                        <Typography variant='caption'>
                            {desc}
                        </Typography>
                        <Grid container direction="row" spacing={1}>
                        <Grid item>
                            <Typography variant='caption'>
                            Valid from: {item.start_on}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='caption'>
                            to: {item.expired_on}
                            </Typography>
                        </Grid>
                    </Grid>
                        </Stack>
                    </React.Fragment>
                }>

            </ListItemText>
        </ListItem>
    )
}

export default OfferListItem