import { Box, Typography } from '@mui/material';
import * as React from 'react'
import { DragDropContext, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import DraggableDesignLayerListItem from './DraggableDesignLayerListItem';
import { DesignLayerListItem } from './types';

export interface DesignLayerListProps {
    items: DesignLayerListItem[] | null
    onDragEnd: OnDragEndResponder
    onMoveUp: (idx: number) => void
    onMoveDown: (idx: number) => void
    onRemove: (idx: number) => void
}

const DraggableDesignLayerList = React.memo(({ items, onDragEnd, onMoveUp, onMoveDown, onRemove}: DesignLayerListProps) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable 
                droppableId='droppable-designlayer-list'
                >
                {provided => (
                    <Box 
                        sx={{
                            width: 300,
                            minWidth: 300,
                            minHeight: 64
                        }}
                        ref={provided.innerRef} {...provided.droppableProps}>
                        {items && items!.length > 0 ? items!.map((item, index) => (
                            <DraggableDesignLayerListItem item={item} index={index} size={items.length} onMoveUp={onMoveUp} onMoveDown={onMoveDown} onRemove={onRemove} />
                        )):(
                            <Typography variant='subtitle1'>
                                Empty
                            </Typography>
                        )}
                        {provided.placeholder}
                    </Box>
                )}
            </Droppable>
        </DragDropContext>
    )
})

export default DraggableDesignLayerList