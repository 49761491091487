import axios from "axios"
import { rest_endpoint } from "../../settings"
import { ShippingAddress } from "../../types"

export const checkaddrexistsAPI = (name: string) => {
    let formData = new FormData()
    formData.append("address_name", name)
    let url = `${rest_endpoint}shippingaddresses/checkexists/`
    return axios.post<any>(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

export const addshippingaddrAPI = (item: ShippingAddress) => {
    let formData = new FormData()
    formData.append("address_name", item.address_name)
    formData.append("addr1", item.addr1)
    formData.append("addr2", item.addr2)
    formData.append("country", item.country)
    formData.append("state", item.state)
    formData.append("city", item.city)
    formData.append("county", item.county)
    formData.append("postal", item.postal)
    formData.append("lat", item.lat as unknown as string)
    formData.append("lng", item.lng as unknown as string)
    formData.append("default", item.default as unknown as string)
    let url = `${rest_endpoint}shippingaddresses/addaddress/`
    return axios.post<any>(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

export const updateshippingaddrAPI = (item: ShippingAddress) => {
    let formData = new FormData()
    formData.append("address_name", item.address_name)
    formData.append("addr1", item.addr1)
    formData.append("addr2", item.addr2)
    formData.append("country", item.country)
    formData.append("state", item.state)
    formData.append("city", item.city)
    formData.append("county", item.county)
    formData.append("postal", item.postal)
    formData.append("lat", item.lat as unknown as string)
    formData.append("lng", item.lng as unknown as string)
    formData.append("default", item.default as unknown as string)
    let url = `${rest_endpoint}shippingaddresses/${item.id}/updateaddress/`
    return axios.post<any>(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

export const getshippingaddrAPI = () => {
    let url = `${rest_endpoint}shippingaddresses/myaddress/`
    return axios.get<any>(url)
}