enum AT {
    USER_LOGIN = 'USER_LOGIN',
    USER_LOGIN_FULFILLED = 'USER_LOGIN_FULFILLED',
    IG_LOGIN_FULFILLED = 'IG_LOGIN_FULFILLED',
    USER_LOGOUT = 'USER_LOGOUT',
    USER_LOGOUT_FULFILLED = 'USER_LOGOUT_FULFILLED',
    USER_SIGNUP = 'USER_SIGNUP',
    USER_SIGNUP_FULFILLED = 'USER_SIGNUP_FULFILLED',
    USER_SIGNUP_FAILED = 'USER_SIGNUP_FAILED',
    USER_SET_CURRENT_URI = 'USER_SET_CURRENT_URI',
    CURRENCY_SET = 'CURRENCY_SET',
    ADD_CART_ITEM = 'ADD_CART_ITEM',
    UPDATE_CART_ITEM = 'UPDATE_CART_ITEM',
    REMOVE_CART_ITEM = 'REMOVE_CART_ITEM',
    REPLACE_CART = 'REPLACE_CART',
    SELECT_CHECKOUT_CARTITEM = 'SELECT_CHECKOUT_CARTITEM',
    DESELECT_CHECKOUT_CARTITEM = 'DESELECT_CHECKOUT_CARTITEM',
    APPLY_COUPON = 'APPLY_COUPON',
    APPLY_COUPON_FULFILLED = 'APPLY_COUPON_FULFILLED'
}

export default AT
