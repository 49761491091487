import axios from "axios"
import { rest_endpoint } from "../../settings"
import { CartItem } from "../../types"

export const addcartitemAPI = (store: string, item: CartItem) => {
    let formData = new FormData()
    formData.append("store", store)
    formData.append("item_id", item.item_id as string)
    formData.append("product", item.product?.id as unknown as string)
    formData.append("color", item.color?.id as unknown as string)
    // formData.append("product_name", item.product_name as string)
    // formData.append("item_type", item.item_type as string)
    formData.append("previewthumb", JSON.stringify(item.previewthumb))
    formData.append("exportcustomizeddesign", JSON.stringify(item.exportcustomizeddesign))
    formData.append("customizeditems", JSON.stringify(item.customizeditems))
    formData.append("sizequantities", JSON.stringify(item.sizequantities))
    formData.append("templates", JSON.stringify(item.templates))
    let url = `${rest_endpoint}carts/addcartitem/`
    return axios.post<any>(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

export const updatecartitemAPI = (item: CartItem) => {
    let formData = new FormData()
    formData.append("item_id", item.item_id as string)
    formData.append("product", item.product?.id as unknown as string)
    formData.append("color", item.color?.id as unknown as string)
    // formData.append("product_id", item.product_id as unknown as string)
    // formData.append("color_id", item.color_id as unknown as string)
    // formData.append("product_name", item.product_name as string)
    // formData.append("item_type", item.item_type as string)
    formData.append("previewthumb", JSON.stringify(item.previewthumb))
    formData.append("exportcustomizeddesign", JSON.stringify(item.exportcustomizeddesign))
    formData.append("customizeditems", JSON.stringify(item.customizeditems))
    formData.append("sizequantities", JSON.stringify(item.sizequantities))
    formData.append("templates", JSON.stringify(item.templates))
    let url = `${rest_endpoint}carts/updatecartitem/`
    return axios.post<any>(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

export const getcartAPI = () => {
    let url = `${rest_endpoint}carts/`
    return axios.get<any>(url)
}

export const applycouponAPI = (store: string, coupon_id: string) => {
    let formData = new FormData()
    formData.append("store", store)
    formData.append("coupon_id", coupon_id as unknown as string)
    let url = `${rest_endpoint}carts/applycoupon/`
    return axios.post<any>(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

export const removecouponAPI = (item_id: string, coupon_id: string) => {
    let formData = new FormData()
    formData.append("item_id", item_id)
    formData.append("coupon_id", coupon_id as unknown as string)
    let url = `${rest_endpoint}carts/removecoupon/`
    return axios.post<any>(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}