import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { MaterialUIThemeProvider } from '../layouts';
import Routes from './Routes';


class Component extends React.Component {
  render() {
    return (
      <Router basename="/">
        <MaterialUIThemeProvider>
          <Routes />
        </MaterialUIThemeProvider>
      </Router>
    );
  }
}

export default Component;
