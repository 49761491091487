import { AppBar, Box, Button, ButtonProps, IconButton, Menu, MenuItem, styled, Toolbar, ToolbarProps, Typography } from '@mui/material'
import * as React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { checkIsActive, toAbsoluteUrl } from '../../_helpers'
import { AnimateLoading } from "../../_partials/controls"
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles } from '@mui/styles';
import { UserToggler } from './widgets/UserToggler'
import { LanguageToggler } from './widgets/LanguageToggler'
import { CurrencyToggler } from './widgets/CurrencyToggler'
import { ShoppingCartToggler } from './widgets/ShoppingCartToggler'
import useTranslations from '../../i18n/useTranslation'


const useStyles = makeStyles((theme: any) => ({
    toolbar: {
        height: 55,
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0)
    }
}))

const NavBtn = styled((props: ButtonProps) => (
    <Button disableRipple {...props}/>
))(({ theme }) => ({
    color: theme.palette.secondary.main,
    '&:hover': {
        color: theme.palette.primary.main,
        background: 'none'
    },
    ':disabled': {
        color: theme.palette.primary.main,
    }
}))

const NavActiveBtn = styled((props: ButtonProps) => (
    <Button disableRipple {...props}/>
))(({ theme }) => ({
    color: theme.palette.primary.main,
    '&:hover': {
        color: theme.palette.primary.main,
        background: 'none'
    }
}))

export const MainAppbar: React.FC = () => {
    const classes = useStyles()
    const location = useLocation()
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const { t } = useTranslations()
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    }

    const getHeaderLogo = () => {
        
        return toAbsoluteUrl(`/media/logos/pixartee-logo.png`);
    }
    return (
        <>
        <div
            className={`header header-fixed`}
            id="app_header"
          >
        <div className={`container-fluid d-flex align-items-stretch justify-content-between`}>
        <AnimateLoading />
        
        <AppBar position="static" elevation={1} 
            sx={{ 
                backgroundColor: 'white', 
                height: '55px', 
                zIndex: 1300,
                position: 'fixed',
                top: '0',
                right: '0',
                left: '0', 
                paddingLeft: '15px',
                paddingRight: '15px',
            }}>
            <Toolbar disableGutters className={classes.toolbar} variant="dense"
                sx={{
                    paddingLeft: '5px',
                    paddingRight: '5px',
                }}
            >
            <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: 'none', md: 'flex' } }}
            >
                <div className="header-logo">
                    <Link to="/">
                        <img alt="logo" src={getHeaderLogo()} width={150} />
                    </Link>
                </div>
            </Typography>
            <Box sx={{ width: 55, display: { xs: 'block', md: 'none' } }}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="secondary"
                    >
                    <MenuIcon fontSize='inherit' />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                    }}
                    PaperProps={{  
                        style: {  
                          width: 100,  
                        },  
                     }} 
                >
                
                    <MenuItem key={'design-navitem'} onClick={handleCloseNavMenu}>
                        <Typography textAlign="center">Design</Typography>
                    </MenuItem>
                    <MenuItem key={'store-navitem'} onClick={handleCloseNavMenu}>
                        <Typography textAlign="center">Store</Typography>
                    </MenuItem>
                
                </Menu>
            </Box>
            <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
            >
                <div className="header-logo">
                    <Link to="/">
                        <img alt="logo" src={getHeaderLogo()} width={150} />
                    </Link>
                </div>
            </Typography>
            <Box sx={{ ml: 2, flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                
                <NavBtn
                    key={'design-navitem'}
                    disabled={checkIsActive(location, '/design')}
                    sx={{ my: 2, display: 'block' }}
                >
                    <Link to="/design" style={{ color: 'inherit' }}>
                    {t.topmenu.design}
                    </Link>
                </NavBtn>
                <NavBtn
                    key={'store-navitem'}
                    disabled={checkIsActive(location, '/store')}
                    sx={{ my: 2, display: 'block' }}
                >
                    <Link to="/store" style={{ color: 'inherit' }}>
                    {t.topmenu.store}
                    </Link>
                </NavBtn>
            
            </Box>
            
            <Box sx={{ flexGrow: 0, display: 'flex', }}>
            {/* <div className="topbar"> */}
                <UserToggler />
                {!(checkIsActive(location,'/checkout') || checkIsActive(location,'/cart')) && (
                    <ShoppingCartToggler />
                )}
                <LanguageToggler />
                <CurrencyToggler />
            {/* </div> */}
            </Box>
            </Toolbar>
        </AppBar>
    
        </div>
        </div>
        </>
    )
} 