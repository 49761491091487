import { ActionObject, CartItem, Coupon, StoreCart } from '../types'
import AT from '../types/ActionTypes'

export const addcartitem = (store: string, cartitem: CartItem): ActionObject => 
({
    type: AT.ADD_CART_ITEM,
    store,
    cartitem
})

export const updatecartitem = (store: string, cartitem: CartItem): ActionObject =>
({
    type: AT.UPDATE_CART_ITEM,
    store,
    cartitem
})

export const removecartitem = (store: string, item_id: string): ActionObject =>
({
    type: AT.REMOVE_CART_ITEM,
    store,
    item_id
})

export const replacecart = (cart: StoreCart[]): ActionObject =>
({
    type: AT.REPLACE_CART,
    cart
})

export const selectcartitemcheckout = (store: string, item_id: string): ActionObject =>
({
    type: AT.SELECT_CHECKOUT_CARTITEM,
    store,
    item_id
})

export const deselectcartitemcheckout = (store: string, item_id: string): ActionObject =>
({
    type: AT.DESELECT_CHECKOUT_CARTITEM,
    store,
    item_id
})

export const applycoupon = (store: string, coupon: Coupon): ActionObject => 
({
    type: AT.APPLY_COUPON,
    store,
    coupon
})

export const applycouponfulfilled = (coupon: Coupon, store: string): ActionObject =>
({
    type: AT.APPLY_COUPON_FULFILLED,
    coupon,
    store
})