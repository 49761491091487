import { Box, Divider, FormControl, Grid, IconButton, Input, InputAdornment, InputBase, InputLabel, OutlinedInput, Paper, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, {useMemo} from "react";
import { Link, useLocation } from "react-router-dom";
import useTranslations from "../../i18n/useTranslation";
import { checkIsActive, toAbsoluteUrl } from "../../_helpers";
import { useHtmlClassService } from "../core/LayOut";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ShareIcon from '@mui/icons-material/Share';
import StoreIcon from '@mui/icons-material/Store';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MailIcon from '@mui/icons-material/Mail';

const useStyles = makeStyles((theme: any) => ({
    designfooter: {
        position: 'fixed' as 'fixed',
        paddingLeft: theme.spacing(2),
        backgroundColor: theme.palette.secondary.main
    },
    footerlink: {
        color: '#ffffff !important',
        '&:hover': {
            textDecoration: 'underline !important'
        }
    }
}))
export const Footer: React.FC = (props) => {
    const today = new Date().getFullYear()
    const uiService = useHtmlClassService();
    const location = useLocation()
    const { t } = useTranslations()

    const layoutProps = useMemo(() => {
        return {
        footerClasses: uiService.getClasses("footer", true),
        footerContainerClasses: uiService.getClasses("footer_container", true)
        };
    }, [uiService]);
    const classes = useStyles()
    return (
        <>
        {checkIsActive(location, '/design') ? (
            <div
            className={classes.designfooter}
            id="app_footer"
            style={{
                bottom: 0,
                left:0,
                right: 0,
                zIndex: 1500,
                width: '100%',
                height: '25px',
                opacity: 0.8 
            }}
            >
                <Grid container direction="row" spacing={2}>
                    <Grid item>
                    <Typography variant="caption" sx={{color: 'white'}}>Copyright {today.toString()} &copy;{" "}Ableplant Solution Co.Ltd.</Typography>
                    </Grid>
                    <Grid item>
                    <Typography variant="caption" sx={{color: 'white'}}><Link to="/terms">Terms of Services</Link></Typography>
                    </Grid>
                    <Grid item>
                    <Typography variant="caption" sx={{color: 'white'}}><Link to="/privacy">Privacy Policy</Link></Typography>
                    </Grid>
                </Grid>
            </div>
        ):(
            <Box sx={{
                display: {
                    xs: 'none',
                    md: 'flex'
                }
            }}>
            <footer
                style={{
                    background: '#222222',
                    height: 'auto',
                    width: '100vw',
                    paddingTop: 20,
                    paddingBottom: 10,
                    color: '#fff',
                    
                }}
            >
                <Box ml={8} mr={8} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                   
                }}>
                    <Grid container direction={'column'} spacing={1}>
                        <Grid item>
                            <Grid container direction={'row'} spacing={1}>
                                <Grid item xs={3}>
                                    <Grid container direction={'row'}>
                                        <Grid item xs={2} sx={{ height: '100%'}}>
                                            <AlternateEmailIcon fontSize="large" />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                                                {t.footer.contact}
                                            </Typography>
                                            <Stack spacing={0}>
                                                
                                                <Typography variant="caption">
                                                Phone: +66-8-6-382-9528
                                                </Typography>
                                                <Typography variant="caption">
                                                Line: @pixartee
                                                </Typography>
                                                <Typography variant="caption">
                                                Messenger: @pixartee
                                                </Typography>
                                                <Typography variant="caption">
                                                WhatsApp: @pixartee
                                                </Typography>

                                            </Stack>
                                            
                                        </Grid>
                                    </Grid>
                                    
                                </Grid>
                                <Grid item xs={3}>
                                <Grid container direction={'row'}>
                                    <Grid item xs={2} sx={{ height: '100%'}}>
                                        <ShareIcon fontSize="large" />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                                            {t.footer.share}
                                        </Typography>
                                        <Stack spacing={0}>
                                            
                                            <Typography variant="caption">
                                            Share your photos and graphics and earn money every time your shared design is used.
                                            </Typography>
                                            <Typography variant="caption">
                                            <Link to={"/account"}>Share your design now</Link>
                                            </Typography>
                                            

                                        </Stack>
                                        
                                    </Grid>
                                </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                <Grid container direction={'row'}>
                                    <Grid item xs={2} sx={{ height: '100%'}}>
                                        <StoreIcon fontSize="large" />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                                            {t.footer.buildstore}
                                        </Typography>
                                        <Stack spacing={0}>
                                            
                                            <Typography variant="caption">
                                            Are you a product owner? Open online apparel store with us. We offer lowest transaction fee and comprehensive online marketing tools  
                                            </Typography>
                                            <Typography variant="caption">
                                            <Link to={"/managestore"}>Open your store now</Link>
                                            </Typography>
                                            

                                        </Stack>
                                        
                                    </Grid>
                                </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                <Grid container direction={'row'}>
                                    <Grid item xs={2} sx={{ height: '100%'}}>
                                        <LocalShippingIcon fontSize="large" />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                                            {t.footer.track}
                                        </Typography>
                                        <Stack spacing={0}>
                                            
                                            <Typography variant="caption">
                                            Find out when your online purchase will arrive.  
                                            </Typography>
                                            <Typography variant="caption">
                                            <Link to={"/tracking"}>Track Your Order</Link>
                                            </Typography>
                                            

                                        </Stack>
                                        
                                    </Grid>
                                </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Grid container direction={'row'} spacing={1}>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                                        {t.footer.aboutus}
                                    </Typography>
                                    <Stack spacing={0}>
                                        <Typography variant="caption">
                                        <Link to={"/aboutus"} className={classes.footerlink}>About PixarTee</Link>
                                        </Typography>
                                        <Typography variant="caption">
                                        <Link to={"/aboutcustomfashion"} className={classes.footerlink}>Customizable Fashion</Link>
                                        </Typography>
                                        <Typography variant="caption">
                                        <Link to={"/affiliation"} className={classes.footerlink}>Affiliate Program</Link>
                                        </Typography>
                                        <Typography variant="caption">
                                        <Link to={"/aboutstore"} className={classes.footerlink}>Online Fashion Store Platform</Link>
                                        </Typography>
                                        <Typography variant="caption">
                                        <Link to={"/getdesign"} className={classes.footerlink}>OEM Service</Link>
                                        </Typography>
                                        <Typography variant="caption">
                                        <Link to={"/quality"} className={classes.footerlink}>Our Quality Standard</Link>
                                        </Typography>
                                        <Typography variant="caption">
                                        <Link to={"/sustainability"} className={classes.footerlink}>Sustainability</Link>
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                                        {t.footer.help}
                                    </Typography>
                                    <Stack spacing={0}>
                                        <Typography variant="caption">
                                        <Link to={"/customerservices"} className={classes.footerlink}>Customer Services</Link>
                                        </Typography>
                                        <Typography variant="caption">
                                        <Link to={"/account"} className={classes.footerlink}>Manage Account</Link>
                                        </Typography>
                                        <Typography variant="caption">
                                        <Link to={"/returnpolicy"} className={classes.footerlink}>Return Policy</Link>
                                        </Typography>
                                        <Typography variant="caption">
                                        <Link to={"/shipping"} className={classes.footerlink}>Shipping Information</Link>
                                        </Typography>
                                        <Typography variant="caption">
                                        <Link to={"/cookies"} className={classes.footerlink}>Cookies Setting</Link>
                                        </Typography>
                                        <Typography variant="caption">
                                        <Link to={"/howtocustomize"} className={classes.footerlink}>How to design your customized printed T-shirt</Link>
                                        </Typography>
                                        <Typography variant="caption">
                                        <Link to={"/howtoshare"} className={classes.footerlink}>How to share your design</Link>
                                        </Typography>
                                        <Typography variant="caption">
                                        <Link to={"/howtosell"} className={classes.footerlink}>How to sell your product</Link>
                                        </Typography>

                                    </Stack>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                                        {t.footer.explore}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Stack spacing={2}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                                        {t.footer.subscribe}
                                    </Typography>
                                    <Paper
                                    component="form"
                                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: { md: 180, lg: 250 } }}
                                    >
                                    <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Enter E-mail to Subscribe"
                                        inputProps={{ 'aria-label': 'subscribe' }}
                                    />
                                    <IconButton color="primary" sx={{ p: '10px' }} aria-label="search">
                                        <MailIcon />
                                    </IconButton>
                                    
                                    </Paper>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                                        {t.footer.follow}:
                                    </Typography>
                                    <Stack direction={"row"} spacing={2}>
                                    <img src={toAbsoluteUrl("/media/logos/socials/facebook-white-32x32.png")} alt="facebook" style={{width: "2rem"}} />
                                    <img src={toAbsoluteUrl("/media/logos/socials/instagram-white-32x32.png")} alt="instagram" style={{width: "2rem"}} />
                                    <img src={toAbsoluteUrl("/media/logos/socials/pinterest-white-32x32.png")} alt="pinterest" style={{width: "2rem"}} />
                                    <img src={toAbsoluteUrl("/media/logos/socials/tik-tok-white-32x32.png")} alt="tiktok" style={{width: "2rem"}} />
                                    <img src={toAbsoluteUrl("/media/logos/socials/youtube-white-32x32.png")} alt="youtube" style={{width: "2rem"}} />
                                    
                                    </Stack>
                                    <Typography variant="caption">Tag us your look with: #pixartee</Typography>
                                    </Stack>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Grid container direction={"row"}>
                                <Grid item xs={3}>
                                    <img alt="logo" src={toAbsoluteUrl("/media/logos/pixartee-logo-white-150.png")} width={150} />
                                </Grid>
                                <Grid item xs={9}>
                                    <Stack direction={"row"} spacing={2}>
                                    
                                    <Typography variant="caption">&copy;{" "} {today.toString()} AblePlant Solution Co.Ltd.</Typography>  
                                
                                    <Divider orientation="vertical" variant="middle" sx={{ height: '15px !important'}}  />
                                    <Typography variant="caption">
                                        <Link to={"/terms"} className={classes.footerlink}>Terms of Use</Link>
                                    </Typography>
                                    <Divider orientation="vertical" variant="middle" sx={{ height: '15px !important'}}  />
                                    <Typography variant="caption">
                                        <Link to={"/privacy"} className={classes.footerlink}>Privacy Policy</Link>
                                    </Typography>
                                    <Divider orientation="vertical" variant="middle" sx={{ height: '15px !important'}}  />
                                    <Typography variant="caption">
                                        <Link to={"/sitemap"} className={classes.footerlink}>Site Map</Link>
                                    </Typography>
                                    </Stack>
    
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </footer>
            </Box>
        // <div
        // className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
        // id="app_footer"
        // >
        // <div
        //     className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
        // >
        //     <div className="text-dark order-1 order-md-1">
        //     <span className="text-muted font-weight-bold mr-2">{today.toString()}</span> &copy;{" "}
        //     <a
        //         href="http://www.pixartee.com"
        //         target="_blank"
        //         rel="noopener noreferrer"
        //         className="text-dark-75 text-hover-primary"
        //     >
        //         PixarTee
        //     </a>
        //     </div>
        //     <div className="nav nav-dark order-1 order-md-2">
        //     <a
        //         href="http://www.pixatee.com/about"
        //         target="_blank"
        //         rel="noopener noreferrer"
        //         className="nav-link pr-3 pl-0"
        //     >
        //         About
        //     </a>
            
        //     <a
        //         href="http://www.pixatee.com/contact"
        //         target="_blank"
        //         rel="noopener noreferrer"
        //         className="nav-link pl-3 pr-0"
        //     >
        //         Contact
        //     </a>
        //     </div>
        //     <div className="order-1 order-md-2">
        //         <div className="container">
        //             <div className="row">
        //                 <div className="col-sm-5">
        //                 <span className="font-weight-bold mr-5">
        //                     Follow us on:
        //                 </span>
        //                 </div>
        //                 <div className="col-sm-2">
        //                 <a>
        //                 <img src={toAbsoluteUrl("/media/logos/socials/facebook-32x32.png")} alt="facebook" style={{width: "2rem"}} />
        //                 </a>
        //                 </div>
        //                 <div className="col-sm-2">
        //                 <a>
        //                 <img src={toAbsoluteUrl("/media/logos/socials/instagram-32x32.png")} alt="facebook" style={{width: "2rem"}} />
        //                 </a>
        //                 </div>
        //                 <div className="col-sm-2">
        //                 <a>
        //                 <img src={toAbsoluteUrl("/media/logos/socials/twitter-32x32.png")} alt="facebook" style={{width: "2rem"}} />
        //                 </a>
        //                 </div>
        //             </div>
        //         </div>
                

                
                
        //     </div>
        // </div>
        // </div>
        )}
        </>
    )
}