import { resolve } from "path";
import { langUrl } from "./i18nConfig";

export const fetchTranslations = (lang: string) => {
    // console.log(langUrl)
    return new Promise((resolve) => {
        fetch(langUrl.replace("{lang}", lang))
            .then((response) => response.json())
            .then((data) => resolve(data))
    })
}