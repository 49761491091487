import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { applycouponfulfilled } from "../actions/shoppingcartAction";
import { ActionObject, CartItem, ShoppingCartState, ApplyCoupon, Product, Coupon, StoreCart } from "../types";
import AT from "../types/ActionTypes";
import { applycouponAPI } from "./func/cartFunc";
import { RootState } from "./store";

const initialState: ShoppingCartState = {
    storecarts: []
}

const shoppingcartReducer = (state: ShoppingCartState = initialState, action: ActionObject) => {
        const cart = state.storecarts
        
        switch (action.type) {
            case AT.ADD_CART_ITEM:

                const addcartitems = cart?.map((c: StoreCart) => {
                    if (c.store.id === action.store) { 
                        c.incart_items?.push({
                            ...action.cartitem 
                        })
                        
                    }
                    return c
                })
                
                return {
                    storecarts: addcartitems
                }
                
            case AT.UPDATE_CART_ITEM:
                let updatecartitems = cart?.map((c: StoreCart) => 
                    c.store.id === action.store ? 
                    {
                        ...c,
                        incart_items: c.incart_items?.map((ci: CartItem) => 
                        ci.item_id === action.cartitem.item_id ? action.cartitem:ci)
                    }:c)
                return {
                    storecarts: updatecartitems
                }
                
            case AT.REMOVE_CART_ITEM:
                let removecartitems = cart?.map((c: StoreCart) => 
                    c.store.id === action.store ? 
                    {
                        ...c,
                        incart_items: c.incart_items?.splice(c.incart_items?.findIndex((ci: CartItem) => ci.item_id===action.item_id),1)
                    }:c)
                return {
                    storecarts: removecartitems
                }
                // const removedIdx = state.cartitems?.findIndex((c: CartItem) => c.item_id===action.id)
                // if (removedIdx) {
                //     const removeditems = state.cartitems?.splice(removedIdx, 1)
                //     return {
                //         cartitems: removeditems
                //     }
                // }
                // return state
            case AT.REPLACE_CART:
                return {
                    storecarts: action.cart
                }
            case AT.SELECT_CHECKOUT_CARTITEM:
                let selcartitems = cart?.map((c: StoreCart) => 
                c.store.id === action.store ? 
                {
                    ...c,
                    incart_items: c.incart_items?.map((ci: CartItem) => 
                    ci.item_id === action.item_id ? 
                    {
                        ...ci,
                        checkingout: true
                    }:ci)
                }:c)
                return {
                    storecarts: selcartitems
                }
                // return state
            case AT.DESELECT_CHECKOUT_CARTITEM:
                let deselcartitems = cart?.map((c: StoreCart) => 
                c.store.id === action.store ? 
                {
                    ...c,
                    incart_items: c.incart_items?.map((ci: CartItem) => 
                    ci.item_id === action.item_id ? 
                    {
                        ...ci,
                        checkingout: false
                    }:ci)
                }:c)
                return {
                    storecarts: deselcartitems
                }
                // return state
            case AT.APPLY_COUPON_FULFILLED:
                let applycouponcartitems = cart?.map((c: StoreCart) => {
                    if (c.store.id === action.store) {
                        const existcoupon = c.coupons?.find((cp: Coupon) => cp.id === action.coupon.id)  
                        if (existcoupon) {
                            c.coupons?.push(action.coupon)
                        }
                    }
                    return c
                })
                return {
                    storecarts: applycouponcartitems
                }
                // return state

            default:
                return state
                
        }
    }

const getCart = (state: RootState) => state.cart
// export async function* ApplyCouponRemote(item_id: string, coupon_id: string) {
//     try {
//         yield call(applycouponAPI, item_id, coupon_id)
//     }
//     catch(e) {
//         console.log("Error from function call.", e)
//     }
// }    

export function* cartSaga() {
    yield takeLatest<ApplyCoupon>(AT.APPLY_COUPON, function* applycouponAction(arg: ApplyCoupon) {
        try {
            const { store, coupon } = arg
            // const cart: ReturnType<typeof getCart> = yield select(getCart)
            // let offer = coupon.offer
            // let storecarts = cart.storecarts
            yield call(applycouponAPI, store, coupon.id as string)
            yield put(applycouponfulfilled(coupon, store))
            // if (storecarts) {
            //     yield all(storecarts.map((sc: StoreCart) => {
            //         if (offer.store) {
            //             if (sc.store.id === offer.store.id) {
            //                 call(applycouponAPI, sc.store.id as string, coupon.id as string)
            //                 put(applycouponfulfilled(coupon, sc.store.id as string))
            //             }
            //         } else {
            //             call(applycouponAPI, sc.store.id as string, coupon.id as string)
            //             put(applycouponfulfilled(coupon, sc.store.id as string))
            //         }
                    
            //     }))
            // }
                
            
        }
        catch(e) {
            console.log("Error from function call.", e)
        }
    })
}
export default shoppingcartReducer