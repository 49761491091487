import { Badge, Box, Button, Grid, IconButton, List, ListSubheader, Popover, Typography } from '@mui/material';
import * as React from 'react'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { CartItem, StoreCart } from '../../../types';
import ShoppingCartListItem from './lists/ShoppingCartListItem';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurrentUri } from '../../../actions/userActions';

export const ShoppingCartToggler: React.FC = () => {
    const user = useAppSelector((state) => state.user)
    const cart = useAppSelector((state) => state.cart)
    const dispatch = useAppDispatch()
    const history = useHistory()
    const [shoppingCartPopoverAnchorEl, setShoppingCartPopoverAnchorEl] = React.useState<HTMLElement | null>(null);
    React.useEffect(() => {
        if (user.currentUri === "/checkout") history.push("/checkout")
    }, [user.currentUri])

    const handleShoppingCartPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setShoppingCartPopoverAnchorEl(event.currentTarget);
    }

    const handleShoppingCartPopoverClose = () => {
        setShoppingCartPopoverAnchorEl(null);
    }

    const openShoppingCartPopover = Boolean(shoppingCartPopoverAnchorEl)
    
    const handleProceedToCheckOut = () => {
        dispatch(setCurrentUri('/checkout'))
    }

    let totalcartitems = 0
    cart.storecarts?.forEach((sc: StoreCart) => {
        if (sc.incart_items) {
            totalcartitems += sc.incart_items?.length
        }
    })
    return (
        <>
        <IconButton 
            aria-owns={openShoppingCartPopover ? 'shoppingcart-popover' : undefined}
            aria-haspopup="true"
            onClick={handleShoppingCartPopoverOpen}
            sx={{
                width: '55px',
                height: '55px'
            }}
            >
            <Badge badgeContent={totalcartitems} color="primary">
            <ShoppingCartIcon fontSize='large' />
            </Badge>
        </IconButton>
        <Popover
            id="shoppingcart-popover"
            open={openShoppingCartPopover}
            anchorEl={shoppingCartPopoverAnchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            marginThreshold={60}
            onClose={handleShoppingCartPopoverClose}
            disableRestoreFocus
            PaperProps={{
                elevation: 2,
                style: {
                width: '400px'
                }
            }}
        >
            <Grid container direction="column">
                <Grid item xs={12}>
                    <Box
                        sx={{
                            width: 400,
                            minWidth: 400,
                            minHeight: 64
                        }}
                    >
                        {cart.storecarts && cart.storecarts.length > 0 ? 
                        (
                        <>
                            {cart.storecarts?.map((sc: StoreCart) => (
                                <List
                                    component="nav"
                                    subheader={
                                        <ListSubheader component="div" id={`${sc.store.store_name}-cart`}>
                                        {sc.store.store_name}
                                        </ListSubheader>
                                    }
                                >
                                    {sc.incart_items?.map((item: CartItem) => (
                                    <ShoppingCartListItem item={item} />
                                    ))}
                                </List>
                            ))}
                        
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{
                                    padding: 2
                                    
                                }}
                            >
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    width: 400
                                }} 

                            >
                            <Link to="/cart" style={{ color: 'white' }}>
                            View Cart
                            </Link>
                            
                                
                            </Button>
                            </Box>
                        </>
                        
                        ):(
                            <Box
                                sx={{
                                    padding: 2
                                }}
                            >
                            <Typography variant='subtitle1'>
                                Empty
                            </Typography>
                            </Box>
                        )}

                        
                    </Box> 
                </Grid>
            </Grid> 
        </Popover>
        </>
    )
}